import { createContext, useEffect, useState } from 'react';

export const wsContext = createContext();

export const wsURL = `${process.env.REACT_APP_WS_URL}`;

const formatMessage = (data) => {
    try {
        const parsed = JSON.parse(new TextDecoder().decode(data))
        return parsed
    } catch (err) {
        return data
    }
}

const getTimestamp = () => {
    return new Date().getTime();
}

export const useWebSocketLite = ({
    socketUrl,
    retry: defaultRetry = 3,
    retryInterval = 1500
}) => {
    const [data, setData] = useState();
    const [send, setSend] = useState(() => () => undefined);
    const [retry, setRetry] = useState(defaultRetry)
    const [readyState, setReadyState] = useState(false)
    
    const ws = new WebSocket(socketUrl);

    useEffect(() => {
        // const ws = new WebSocket(socketUrl);
        ws.onopen = () => {
            console.log('Connected to socket');
            setReadyState(true)
        }

        setSend(() => {
            return (data) => {
                try {
                    const d = JSON.stringify(data)
                    ws.send(d)
                    return true;
                } catch (err) {
                    return false;
                }
            }
        })
        ws.onmessage = (ev) => {
            const msg = formatMessage(ev.data)
            setData({ message: msg, timestamp: getTimestamp() })
        }

        ws.onerror = (event) => {
            console.error('WebSocket error:', event);
            setReadyState(false)
            if (retry > 0) {
                setTimeout(() => {
                    setRetry((retry) => retry - 1)
                }, retryInterval)
            }
        }

        ws.onClose = () => {
            setReadyState(false)
            if (retry > 0) {
                setTimeout(() => {
                    setRetry((retry) => retry - 1)
                }, retryInterval)
            }
        }

        return () => {
            ws.close()
        }
    }, [retry])

    return { send, data, readyState, ws }
}

export default null;
