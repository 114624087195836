import {
  CLOSE_MODALS,
  SET_ENGAGE_MODAL,
  SET_FORGOT_PASSWORD_MODAL,
  SET_PRODUCTION_WORKFLOW_MODAL,
  SET_SEGMENT_MODAL,
  SET_SOCIAL_BROADCAST_MODAL,
  SET_STATISTICS_MODAL,
  SET_UPLOAD_PEA_MODAL,
  SET_MISSION_MAP_MODAL,
  TRIGGERED_NOTIF_COMMENT_ID,
  TRIGGERED_NOTIF_PAGE_NUMBER,
  HIDE_EMPTY_COMMENTS_ERR,
} from "../types"

export const DEFAULT_STATE_COMPANY = {
  socialBroadcastModal: false,
  segmentModal: false,
  engageModal: false,
  productionWorkflowModal: false,
  statisticsModal: false,
  uploadPeaModal: false,
  forgotPasswordModal: false,
  missionMapModal: false,
  triggeredNotifCommentId: null,
  triggeredNotifPageNumber: null,
  hideErrMsg: true,
}

export default (state = DEFAULT_STATE_COMPANY, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_SOCIAL_BROADCAST_MODAL:
      return {
        ...state,
        socialBroadcastModal: payload,
      }
    case SET_SEGMENT_MODAL:
      return {
        ...state,
        segmentModal: payload,
      }
    case SET_ENGAGE_MODAL:
      return {
        ...state,
        engageModal: payload,
      }
    case SET_PRODUCTION_WORKFLOW_MODAL:
      return {
        ...state,
        productionWorkflowModal: payload,
      }
    case TRIGGERED_NOTIF_COMMENT_ID:
      return {
        ...state,
        triggeredNotifCommentId: payload,
      }
    case TRIGGERED_NOTIF_PAGE_NUMBER:
      return {
        ...state,
        triggeredNotifPageNumber: payload,
      }
    case HIDE_EMPTY_COMMENTS_ERR:
      return {
        ...state,
        hideErrMsg: payload,
      }
    case SET_STATISTICS_MODAL:
      return {
        ...state,
        statisticsModal: payload,
      }
    case SET_UPLOAD_PEA_MODAL:
      return {
        ...state,
        uploadPeaModal: payload,
      }
    case CLOSE_MODALS:
      return {
        ...DEFAULT_STATE_COMPANY,
      }
    case SET_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        forgotPasswordModal: payload,
      }
    case SET_MISSION_MAP_MODAL:
      return {
        ...state,
        missionMapModal: payload,
      }
    default:
      return state
  }
}
