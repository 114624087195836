import React, { Component } from "react";
import cx from "classnames";
import EyeOpenIcon from "../../../icons/eye-regular.svg";
import EyeCloseIcon from "../../../icons/eye-slash-regular.svg";
import styles from "./Inputs.module.scss";

export default class CustomInput extends Component {
  state = {
    eyeToggled: false,
    originalType: this.props.type,
    type: this.props.type,
  };

  handleEyeIcon = (e) => {
    this.setState(({ eyeToggled, type, originalType }) => ({
      eyeToggled: !eyeToggled,
      type: !eyeToggled ? "text" : originalType,
    }));
  };

  render() {
    const {
      input: { value, onChange },
      className,
      meta: { touched, error, warning },
      eyeIcon = false,
    } = this.props;

    return (
      <div className={cx(className, styles.CustomInput)}>
        <div className={styles.InputWrapper}>
          <input
            name={this.props.name}
            id={this.props.id}
            placeholder={this.props.placeholder}
            className={cx("form-control", styles.InputFormControl)}
            type={this.state.type}
            value={value}
            onChange={onChange}
            onKeyUp={this.props.handleClearError}
            maxLength={this.props.id === "password" ? 30 : null}
          />
          {eyeIcon && (
            <span className={styles.EyeButton} onClick={this.handleEyeIcon}>
              {this.state.eyeToggled ? (
                <img src={EyeCloseIcon} />
              ) : (
                <img src={EyeOpenIcon} />
              )}
            </span>
          )}
        </div>
        {this.props.label && (
          <label htmlFor={this.props.id}>{this.props.label}</label>
        )}
        {touched && error && (
          <div className={styles.ErrorMessage}>{<span>{error}</span>}</div>
        )}
      </div>
    );
  }
}
