import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PeaVideoPage from './PeaVideoPage';
import * as actions from '../../store/actions';
import styles from './PeaVideo.module.scss';

const PeaVideoContainer = ({
  showPeaPageModal,
  openFrom,
  companyId,
  setShowPeaPageModal,
  isAuth,
  getPeaVideo,
  pea,
  videoId,
  addComment,
  account,
  clearPeaVideoStories,
  clearStory,
  clearComments,
}) => {
  const isDesktop = useMediaQuery({
    query: 'only screen and (min-width: 1199px)',
  });
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const peaVideoId = id ? id : videoId;

  let isWorkdayGuidePage = history.location.state?.isWorkdDayGuidePage;

  if (isDesktop && location.pathname !== '/') {
    setShowPeaPageModal(
      true,
      peaVideoId,
      isWorkdayGuidePage ? 'workday-guide' : openFrom,
      companyId
    );
  } else if (!isDesktop && !location.pathname.match('pea/')) {
    setShowPeaPageModal(
      false,
      null,
      isWorkdayGuidePage ? 'workday-guide' : openFrom,
      companyId
    );
    history.push(`/pea/${peaVideoId}`);
  }

  useEffect(() => {
    getPeaVideo(peaVideoId);
  }, [peaVideoId, getPeaVideo]);

  const handleHide = () => {
    if (
      account &&
      (account.account_type === 'Business' || account.account_type === 'Admin')
    ) {
      history.push('/broadcasting-pod');
    } else {
      if (openFrom === 'workday-guide') {
        history.push('/workday-guide');
      } else if (openFrom === 'company') {
        history.push(`/company/${companyId}`);
      } else {
        history.push('/');
      }
    }

    clearStory();
    clearComments();
    clearPeaVideoStories();
  };

  return !showPeaPageModal ? (
    pea && (
      <PeaVideoPage
        pea={pea}
        mobile={true}
        isAuth={isAuth}
        addComment={addComment}
        setShowPeaPageModal={setShowPeaPageModal}
      />
    )
  ) : (
    <Modal
      show={showPeaPageModal}
      dialogClassName='modal-90w'
      className={styles.PeaVideoModal}
      aria-labelledby='example-custom-modal-styling-title'
      onHide={() => {
        handleHide();
        if (openFrom === 'business') {
          history.push('/business');
          setShowPeaPageModal(false, null, openFrom, companyId);
        } else {
          setShowPeaPageModal(false, null, openFrom, companyId);
        }
      }}
    >
      <div className={styles.CloseIconWrapper}>
        <div className={styles.CloseIcon}>+</div>
      </div>
      <Modal.Body>
        {pea && (
          <PeaVideoPage
            pea={pea}
            isAuth={isAuth}
            account={account}
            addComment={addComment}
            mobile={!showPeaPageModal}
            setShowPeaPageModal={setShowPeaPageModal}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  pea: state.pea.pea,
  isAuth: state.auth.isAuthenticated,
  account: state.auth.account,
  showPeaPageModal: state.peaPageModal.showPeaPageModal,
  videoId: state.peaPageModal.videoId,
  openFrom: state.peaPageModal.openFrom,
  companyId: state.peaPageModal.companyId,
});

const mapDispatchToProps = (dispatch) => ({
  setShowPeaPageModal: (data, id, openFrom, companyId) =>
    dispatch(actions.setShowPeaPageModal(data, id, openFrom, companyId)),
  getPeaVideo: (id) => dispatch(actions.retrievePea(id)),
  addComment: (pea, comment) => dispatch(actions.addComment(pea, comment)),
  clearPeaVideoStories: () => dispatch(actions.clearPeaVideoStories()),
  clearStory: () => dispatch(actions.clearStory()),
  clearComments: () => dispatch(actions.clearComments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeaVideoContainer);
