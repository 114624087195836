import axios from "../../utils/axiosConfig"
import {
    GET_ACCOUNT_NOTIFICATION_START,
    GET_ACCOUNT_NOTIFICATION_SUCCESS,
    UPDATE_ACCOUNT_NOTIFICATION_SUCCESS,
    GET_ACCOUNT_NOTIFICATION_FAIL,
    GET_NOTIFICATION_LOADER_START,
    GET_NOTIFICATION_LOADER_FAIL,
    SHOW_NOTIFICATIONS_DROPDOWN,
    NOTIFICATION_COUNT,
    REFRESH_ACCOUNT_NOTIFICATION,
    NOTIFICATION_NOT_EXIST,
    SUCCESS_PAGINATE_NOTIFICATION,
    FAIL_PAGINATE_NOTIFICATION,
} from "../types"

export const tokenConfig = (getState) => {
  const token = getState().auth.token

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  if (token) {
    config.headers["Authorization"] = `Token ${token}`
  }

  return config
}

export const getNotifications = (read = "false", type = "", page, size = 5) => async (dispatch, getState) => {
    dispatch({ type: GET_ACCOUNT_NOTIFICATION_START })
    dispatch({ type: GET_NOTIFICATION_LOADER_START })
    let fltrs = []
    fltrs.push(`page_size=${size}`)
    if (page) fltrs.push(`page=${page}`)
    if (read) fltrs.push(`read=${read}`)
    if (type) fltrs.push(`type=${type}`)
    await axios
    .get(`/accounts/notifications/?${fltrs.join('&')}`, tokenConfig(getState))
    .then((res) => {
      if (res.data && res.data.results) {
        dispatch({
          type: GET_ACCOUNT_NOTIFICATION_SUCCESS,
          payload: res.data.results
        })
      }
      if (res.data.next) {
        dispatch({
          type: SUCCESS_PAGINATE_NOTIFICATION,
          payload: res.data.next
        })
      }
      if (!res.data.next) {
        dispatch({
          type: FAIL_PAGINATE_NOTIFICATION,
        })
  
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_NOTIFICATION_LOADER_FAIL,
      })

      console.log(err)
    })
}

export const refreshNotifications = (read = "false", type = "", page) => async (dispatch, getState) => {
    let fltrs = []
    fltrs.push(`page_size=${5}`)
    if (page) fltrs.push(`page=${page}`)
    if (read) fltrs.push(`read=${read}`)
    if (type) fltrs.push(`type=${type}`)
    await axios
    .get(`/accounts/notifications/?${fltrs.join('&')}`, tokenConfig(getState))
    .then((res) => {
      if (res.data && res.data.results) {
        dispatch({
          type: REFRESH_ACCOUNT_NOTIFICATION,
          payload: res.data.results
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getNotificationsCount = (read = "false", type = "", page) => async (dispatch, getState) => {
    let fltrs = []
    if (page) fltrs.push(`page=${page}`)
    if (read) fltrs.push(`read=${read}`)
    if (type) fltrs.push(`type=${type}`)
    await axios
    .get(`/accounts/notifications/total?${fltrs.join('&')}`, tokenConfig(getState))
    .then((res) => {
      if (res.data && res.data.count) {
        dispatch({
          type: NOTIFICATION_COUNT,
          payload: res.data.count || 0
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const updateNotifications = (id, data) => async (dispatch, getState) => {
    // dispatch({ type: GET_ACCOUNT_NOTIFICATION_START })
    await axios
    .patch(`/accounts/notifications/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      if (res && res.data && res.data.success) {
        dispatch({
          type: UPDATE_ACCOUNT_NOTIFICATION_SUCCESS,
          payload: id,
        })
        // dispatch(getNotifications({ read: false }))
      }
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ACCOUNT_NOTIFICATION_FAIL,
      // })
      if(id){
        dispatch({
          type: UPDATE_ACCOUNT_NOTIFICATION_SUCCESS,
          payload: id,
        })
      }
      if(err?.response?.status === 404){
        dispatch({ 
          type: NOTIFICATION_NOT_EXIST,
          payload: "The message has been deleted by the user." 
        })
      }
    })
}

export const setShowNotificationsDropdown = (state) => async (dispatch, getState) => {
  dispatch({
    type: SHOW_NOTIFICATIONS_DROPDOWN,
    payload: state
  })
}