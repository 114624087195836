import { toast } from 'react-toastify';
import axios from './axiosConfig';

export const getMemberProfile = async (access_token, user = null) => {
  let resp = {
    profilePic: undefined,
    id: undefined,
    firstName: undefined,
    lastName: undefined,
  };

  await axios
    .get(
      `/accounts/linkedin/member_profile/?access_token=${access_token}&user=${user}`
    )
    .then((res) => {
      if (res && res.data && res.data.data) {
        // console.log('MEMBER INFO: ' + JSON.stringify(res.data.data))
        // sessionStorage.setItem('linkedin-profile-pic', res.data.data.profilePicture["displayImage~"].elements[0].identifiers[0].identifier)
        // sessionStorage.setItem('linkedin-id', res.data.data.id)
        // sessionStorage.setItem('linkedin-fname', res.data.data.firstName.localized.en_US)
        // sessionStorage.setItem('linkedin-lname', res.data.data.lastName.localized.en_US)
        resp = {
          profilePic: res.data.data?.profilePicture
            ? res.data.data?.profilePicture['displayImage~']?.elements[0]
                ?.identifiers[0]?.identifier
            : null,
          id: res.data.data?.id,
          firstName: res.data.data.firstName.localized.en_US,
          lastName: res.data.data.lastName.localized.en_US,
        };
      }
    })
    .catch((err) => console.log('Error at Get Member Profile-----', err));

  return resp;
};

export const getMemberEmail = async (access_token) => {
  let resp = {
    email: undefined,
  };
  await axios
    .get(`/accounts/linkedin/member_email/?access_token=${access_token}`)
    .then((res) => {
      if (res && res.data && res.data.data) {
        // console.log('MEMBER EMAIL: ' + JSON.stringify(res.data.data))
        // sessionStorage.setItem('linkedin-email', res.data.data.elements[0]["handle~"].emailAddress);
        resp = {
          email: res.data.data.elements[0]['handle~'].emailAddress,
        };
      }
    })
    .catch((err) => console.log(err));

  return resp;
};

export const getMemberInfo = async (token, user = null) => {
  let resp = {
    profile: undefined,
    email: undefined,
  };

  await getMemberProfile(token, user)
    .then((res) => (resp.profile = res))
    .catch((err) => console.log(err));
  await getMemberEmail(token)
    .then((res) => (resp.email = res.email))
    .catch((err) => console.log(err));

  return resp;
};

export const registerLinkedInAccount = async ({
  firstName,
  lastName,
  email,
  password,
  account_type,
  account_image,
  company_role,
  linkedin_id,
  linkedin_access_token,
  company,
  isBusiness,
}) => {
  const img = await fetch(account_image);
  const blob = await img.blob();
  const fl = new File([blob], `${password}.jpg`, { type: blob.type });

  let formData = new FormData();
  formData.append('first_name', firstName);
  formData.append('last_name', lastName);
  formData.append('email', email);
  formData.append('password', password);
  formData.append('account_type', account_type);
  formData.append('linkedin_id', linkedin_id);
  formData.append('linkedin_access_token', linkedin_access_token);
  formData.append('account_image', fl, fl.name);

  if (isBusiness) {
    formData.append('company', company);
    formData.append('company_role', company_role);
  }

  // let formDataObject = {
  //   first_name: firstName,
  //   last_name: lastName,
  //   email: email,
  //   password: password,
  //   account_type: account_type,
  //   linkedin_id: linkedin_id,
  //   linkedin_access_token: linkedin_access_token,
  //   account_image: {
  //     file: fl,
  //     name: fl.name,
  //   },
  // };

  let resp = null;

  await axios
    .post(`/accounts/linkedin/register_account/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => (resp = res))
    .catch((err) => {
      let msg = 'Something went wrong. Please try again.';
      if (err?.response && err?.response?.data) {
        if (
          typeof err?.response?.data === 'object' &&
          Object.keys(err?.response?.data).length > 0
        ) {
          let e = [];
          for (let k in err?.response?.data.detail) {
            e.push(
              `${err?.response?.data.detail[k]}`.charAt(0).toUpperCase() +
                `${err?.response?.data.detail[k]}`.slice(1)
            );
          }
          msg = e.join(' | ');
        } else if (typeof err?.response?.data === 'string') {
          msg = err?.response?.data;
        }
      }
      if (typeof msg === 'string') {
        toast.error(msg);
      }
    });

  return resp;
};

export const generateAccessToken = async ({
  auth_code,
  redirect_uri,
  client_id,
  client_secret,
}) => {
  let resp = null;
  let bodyDetails = {
    grant_type: 'authorization_code',
    code: auth_code,
    redirect_uri: redirect_uri,
    client_id: client_id,
    client_secret: client_secret,
  };

  let formBody = [];
  for (let prop in bodyDetails) {
    let key = encodeURIComponent(prop);
    let val = encodeURIComponent(bodyDetails[prop]);
    formBody.push(key + '=' + val);
  }
  formBody = formBody.join('&');

  await axios
    .get(`/accounts/linkedin/access_token/?${formBody}`)
    .then((res) => {
      if (res && res.data && res.data.data) {
        sessionStorage.setItem('linkedin-token', res.data.data.access_token);
        resp = res;
      }
    })
    .catch((err) => console.log(err));

  return { token: resp?.data?.data?.access_token };
};
