import React from "react"
import classNames from "classnames"
import Tooltip from "../../../Tooltip/Tooltip"
import { ReactComponent as InfoIcon } from "../../../../../icons/info.svg"
import styles from "./BusinessDropdown.module.scss"

const BusinessDropdownItem = ({
  icon,
  title,
  action,
  tooltipText,
  dropdownType,
  setToggle=()=>{}
}) => {
  return (
    <li
      className={classNames(styles.BusinessDropdownItem, styles[dropdownType])}
    >
      <span
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          action(e)
          setToggle(false)
        }}
        className={styles.Link}
      >
        {icon && <img src={icon} alt={title} className={styles.Icon} />}
        <div
          className={styles.Title}
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
      </span>
      {tooltipText && (
        <Tooltip text={tooltipText} tooltipStyles={styles.BusinessTooltip}>
          <InfoIcon className={styles.InfoIcon} />
        </Tooltip>
      )}
    </li>
  )
}

export default BusinessDropdownItem
