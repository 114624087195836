import React from "react";
import styles from "./Backdrop.module.scss";

const Backdrop = ({ show, onClose, onScroll, bgColor }) => {
  return (
    show && (
      <div
        onClick={() => {
          onClose();
        }}
        className={styles.Backdrop}
        style={bgColor}
      ></div>
    )
  );
};

export default Backdrop;
