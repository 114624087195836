import { GET_COMPANIES } from "../types"
import axios from "../../utils/axiosConfig"

export const getChannels = () => (dispatch) => {
  axios
    .get("/accounts/company")
    .then((res) => {
      dispatch({ type: GET_COMPANIES, payload: res.data })
    })
    .catch((err) => {
      console.log(err)
    })
}
