import { SET_SHOW_PEAPAGE_MODAL, CLEAR_OPEN_FROM } from "../types"

export const setShowPeaPageModal =
  (data, videoId, openFrom, companyId) => (dispatch) => {
    dispatch({
      type: SET_SHOW_PEAPAGE_MODAL,
      payload: data,
      videoId,
      openFrom,
      companyId,
    })
  }

export const clearOpenFrom = () => (dispatch) =>
  dispatch({ type: CLEAR_OPEN_FROM })
