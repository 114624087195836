import React, { useEffect } from "react"
import classNames from "classnames"
import { faTag, faTv } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PBTabs from "../PBTabs/PBTabs"
import Button from "../UI/Button/Button"
import { LABELS } from "../../const"
import { SearchResultLoader } from "../UI/Loader/Loader"
import FollowingItem from "../Header/FollowingTooltip/FollowingItem/FollowingItem"
import styles from "./SearchInfoModal.module.scss"

const cx = classNames.bind(styles)

const searchResultLoaders = [1, 2, 3, 4]

const SearchInfoModal = ({
  searchTerm,
  initialTab = "Programme Title",
  closeModal,
  channels,
  stories,
  onFollow,
  onUnfollow,
  updateInterest,
  selectedCategories,
  selectedTags,
  isAuth,
  following,
  showModal,
  tags,
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "initial"
    }
  }, [showModal])

  const ids = stories.map((story) => story.id)
  const filterStories = stories.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  )

  const filteredSelectedTags = selectedTags.map(
    (interest) => interest.split("-Desired-Effect")[0].split("-Tag")[0]
  )

  const storyItems = searchTerm
    ? filterStories.filter(
        (_) =>
          _.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          _.categories.some(
            (c) => c.toLowerCase() === searchTerm.toLowerCase()
          ) ||
          _.tags.some((t) => t.toLowerCase() === searchTerm.toLowerCase())
      )
    : []

  const tagItems = searchTerm
    ? tags
        .map((tag) => tag.name)
        .filter((_) => _.toLowerCase().includes(searchTerm.toLowerCase()))
    : []

  const categoryItems = searchTerm
    ? LABELS.filter(
        (label) =>
          label.type === "TV Channels" && label.broadcasting === "public"
      ).filter((_) => _.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : []

  const channelItems = searchTerm
    ? channels.filter((_) =>
        _.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : []

  const desiredEffectsItems = searchTerm
    ? LABELS.filter((label) => label.type === "Target Responses").filter((_) =>
        _.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : []

  if (!showModal) return <></>

  return (
    <div
      className={styles.SearchInfoModal}
      onPointerEnter={() => document.getElementById("searchBox").blur()}
    >
      <PBTabs initialTab={initialTab} tabStyles={styles.SearchInfoTabs}>
        <div label="Programme Title">
          <ul className={styles.ChannelList}>
            {stories.length > 0
              ? storyItems.map((item) => (
                  <li key={item.id}>
                    <FollowingItem
                      showButton={false}
                      type="Story"
                      item={item}
                      companies={channels}
                      searchInfoModal={(b) => {
                        if (!b) {
                          closeModal()
                        }
                      }}
                    />
                  </li>
                ))
              : searchResultLoaders.map((searchResult) => (
                  <li key={searchResult}>
                    <SearchResultLoader key={searchResult} />
                  </li>
                ))}
          </ul>
          {!storyItems.length && searchTerm && (
            <div className={styles.NoStoriesMessage}>
              No stories found with these terms.
            </div>
          )}
        </div>
        <div label="TV Channels">
          <div className={styles.CategoryList}>
            {categoryItems.map((category) => (
              <div key={category} className={styles.ButtonWrapper}>
                <Button
                  key={category}
                  buttonStyle={cx(styles.CategoryButton, {
                    Selected: selectedCategories
                      ? selectedCategories.includes(category.name)
                      : false,
                  })}
                  onClick={() => updateInterest({ category: category.name })}
                >
                  {category.name}
                </Button>
              </div>
            ))}
          </div>
          {!categoryItems.length && searchTerm && (
            <div className={styles.NoStoriesMessage}>
              No TV Channels found with these terms.
            </div>
          )}
        </div>
        <div label="Company Studio">
          <ul className={styles.ChannelList}>
            {channelItems.map((item) => (
              <li key={item.name}>
                <FollowingItem
                  item={item}
                  type="Channels"
                  onFollow={() => onFollow({ company: item.id })}
                  onUnfollow={(followItem) => onUnfollow(followItem)}
                  showButton={isAuth}
                  isFollowing={
                    following &&
                    following.find(
                      (followingItem) => followingItem.company.id === item.id
                    )
                  }
                  following={following}
                  searchInfoModal={(b) => {
                    if (!b) {
                      closeModal()
                    }
                  }}
                />
              </li>
            ))}
          </ul>
          {!channelItems.length && searchTerm && (
            <div className={styles.NoStoriesMessage}>
              No Company Studio found with these terms.
            </div>
          )}
        </div>
        <div label="Tags">
          <div className={styles.TagList}>
            {tagItems.map((tag) => (
              <div
                key={tag}
                className={cx(styles.TagDetails, {
                  Selected: filteredSelectedTags
                    ? filteredSelectedTags.includes(tag)
                    : false,
                })}
                onClick={() => updateInterest({ tag: `${tag}-Tag` })}
              >
                <div className={styles.SearchTag}>
                <FontAwesomeIcon className={styles.TagIcon} icon={faTag} />
                <span className={styles.TagName}>{tag}</span>
                </div>
              </div>
            ))}
          </div>
          {!tagItems.length && searchTerm && (
            <div className={styles.NoStoriesMessage}>
              No Tags found with these terms.
            </div>
          )}
        </div>
        <div label="Desired Effects!">
          <div className={styles.SeriesList}>
            {desiredEffectsItems.map((desiredEffect) => (
              <div
                key={desiredEffect.name}
                className={cx(styles.SeriesDetails, {
                  Selected: filteredSelectedTags
                    ? filteredSelectedTags.includes(desiredEffect.name)
                    : false,
                })}
                onClick={() =>
                  updateInterest({
                    tag: `${desiredEffect.name}-Desired-Effect`,
                  })
                }
              >
                <div className={styles.DesiredTag}>
                <FontAwesomeIcon className={styles.SeriesIcon} icon={faTv} />
                <span className={styles.SeriesName}>{desiredEffect.name}</span>
                </div>
              </div>
            ))}
          </div>
          {!desiredEffectsItems.length && searchTerm && (
            <div className={styles.NoStoriesMessage}>
              No Desired Effects found with these terms.
            </div>
          )}
        </div>
      </PBTabs>
      <div onClick={() => closeModal()} className={styles.BottomTermWrapper}>
        See all results for "{searchTerm}"
      </div>
    </div>
  )
}

export default SearchInfoModal
