export const getDropdownData = ({
  isAuth,
  signout,
  login,
  join,
  interests,
  following,
  watchlist,
  account,
  history,
  workdayGuide,
}) => {
  let items = []

  if (isAuth && account.account_type === "Basic") {
    items = [
      {
        label: "Sign out",
        separator: false,
        callback: signout,
      },
    ]
  }
  if (!isAuth) {
    items = [
      {
        label: "Login",
        separator: false,
        callback: login,
      },
      {
        label: "Join",
        separator: false,
        callback: join,
      },
    ]
  }

  if (isAuth) {
    items = items.concat([
      {
        label: "Following",
        separator: false,
        callback: following,
        belongsTo: "app",
      },
      {
        label: "Watchlist",
        separator: false,
        callback: watchlist,
        belongsTo: "app",
      },
    ])
  }

  items = items.concat({
    label: "Explore",
    separator: false,
    callback: () => {},
    belongsTo: "app",
  })

  if (isAuth) {
    items = items.concat({
      label: "Build your own Snack box",
      separator: false,
      callback: interests,
      belongsTo: "app",
    })
  }

  if (isAuth) {
    if (isAuth && account.account_type !== "Basic") {
      return items.concat([
        {
          label: "Peabox Originals",
          separator: false,
          callback: () => {},
          belongsTo: "app",
        },
        {
          label: "Your Workday Guide",
          separator: false,
          callback: workdayGuide,
          style: {
            fontColor: "FontColorPrimary",
          },
          belongsTo: "app",
        },
        {
          label: "Our Pea Story!",
          separator: false,
          callback: () => {},
          style: {
            fontColor: "FontColorWeirdGreen",
          },
          belongsTo: "app",
        },
        {
          label: "Career-Heroes!",
          separator: false,
          callback: () => {},
          style: {
            fontColor: "FontColorPrimary",
          },
          belongsTo: "app",
        },
        {
          label: "My Account",
          separator: true,
          callback: () => history.push("/settings/basic/profile"),
          belongsTo: "app",
        },
        {
          label: "Company",
          separator: false,
          callback: () => {},
          children: [
            {
              label: "Power-up!",
              separator: false,
              belongsTo: "pod",

              callback: () => {},
            },
            {
              label: "Sign out",
              separator: false,
              callback: signout,
            },
            {
              label: "Account",
              separator: false,
              callback: () => {
                if (account.business && account.account_type === "Admin") {
                  history.push("/settings/company/profile-company")
                } else if (account.business) {
                  history.push("/settings/company/user-profile")
                }
              },
              belongsTo: "pod",
            },
            {
              label: "How-to-Heroes!",
              separator: false,
              belongsTo: "pod",
              style: {
                fontColor: "FontColorWeirdBlue",
              },

              callback: () => {},
            },
          ],
        },
      ])
    } else {
      return items.concat([
        {
          label: "Peabox Originals",
          separator: false,
          callback: () => {},
          belongsTo: "app",
        },
        {
          label: "Your Workday Guide",
          separator: false,
          callback: workdayGuide,
          style: {
            fontColor: "FontColorPrimary",
          },
          belongsTo: "app",
        },
        {
          label: "Our Pea Story!",
          separator: false,
          callback: () => {},
          style: {
            fontColor: "FontColorWeirdGreen",
          },
          belongsTo: "app",
        },
        {
          label: "Career-Heroes!",
          separator: false,
          callback: () => {},
          style: {
            fontColor: "FontColorPrimary",
          },
          belongsTo: "app",
        },
        {
          label: "My Account",
          separator: true,
          callback: () => history.push("/settings/basic/profile"),
          belongsTo: "app",
        },
        {
          label: "Company",
          separator: false,
          callback: () => {},
          children: [
            {
              label: "Power-up!",
              separator: false,
              belongsTo: "pod",

              callback: () => {},
            },
            {
              label: "Account",
              separator: false,
              callback: () => {
                if (account.business && account.account_type === "Admin") {
                  history.push("/settings/company/profile-company")
                } else if (account.business) {
                  history.push("/settings/company/user-profile")
                }
              },
              belongsTo: "pod",
            },
            {
              label: "How-to-Heroes!",
              separator: false,
              belongsTo: "pod",
              style: {
                fontColor: "FontColorWeirdBlue",
              },

              callback: () => {},
            },
          ],
        },
      ])
    }
  } else {
    return items.concat([
      {
        label: "Peabox Originals",
        separator: false,
        callback: () => {},
        belongsTo: "app",
      },
      {
        label: "Your Workday Guide",
        separator: false,
        callback: workdayGuide,
        style: {
          fontColor: "FontColorPrimary",
        },
        belongsTo: "app",
      },
      {
        label: "Our Pea Story!",
        separator: false,
        callback: () => {},
        style: {
          fontColor: "FontColorWeirdGreen",
        },
        belongsTo: "app",
      },
      {
        label: "Career-Heroes!",
        separator: false,
        callback: () => {},
        style: {
          fontColor: "FontColorPrimary",
        },
        belongsTo: "app",
      },
      {
        label: "Company",
        separator: false,
        callback: () => {},
        children: [
          {
            label: "Power-up!",
            separator: false,
            callback: () => {},
          },
          {
            label: "Resources",
            separator: false,
            callback: () => {},
          },
        ],
        belongsTo: "pod",
      },
    ])
  }
}
