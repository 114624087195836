import {
  ADD_COMMENT,
  GET_COMMENTS_SUCCESS,
  GET_STORIES_FAIL,
  USER_INTEREST_STORIES_START,
  USER_INTEREST_STORIES_FAIL,
  STOP_GET_STORIES,
  GET_STORIES_START,
  GET_STORIES_SUCCESS,
  GET_WATCH_LIST_STORIES_START,
  GET_WATCH_LIST_STORIES_SUCCESS,
  GET_WATCH_LIST_STORIES_FAIL,
  RETRIEVE_STORY_START,
  RETRIEVE_STORY_FAIL,
  RETRIEVE_STORY_SUCCESS,
  GET_REPLIES_SUCCESS,
  ADD_REPLY,
  GET_COMMENTS_START,
  GET_COMMENTS_FAIL,
  RETRIEVE_STORY_STATISTICS_START,
  RETRIEVE_STORY_STATISTICS_SUCCESS,
  RETRIEVE_STORY_STATISTICS_FAIL,
  CLEAR_STORY,
  CLEAR_COMMENTS,
  REMOVE_COMMENT_ASSIGNMENT,
  ADD_PEA_FAIL,
  ADD_PEA_SUCCESS,
  GET_LABELS_START,
  GET_LABELS_FAIL,
  GET_LABELS_SUCCESS,
  ADD_LABEL_START,
  ADD_LABEL_FAIL,
  ADD_LABEL_SUCCESS,
  UPDATE_PEA_START,
  UPDATE_PEA_FAIL,
  UPDATE_PEA_SUCCESS,
  ADD_EMBED_START,
  ADD_EMBED_FAIL,
  ADD_EMBED_SUCCESS,
  UPDATE_EMBED_START,
  UPDATE_EMBED_FAIL,
  UPDATE_EMBED_SUCCESS,
  SET_STORY_FILTERS,
  CLEAR_STORY_FILTERS,
  GET_SERIES_STORIES_START,
  GET_SERIES_STORIES_FAIL,
  GET_SERIES_STORIES_SUCCESS,
  SORT_STORIES,
  SET_TOP_STORY,
  COMPANY_FEATURED_VIDEO,
  COMPANY_FEATURED_VIDEO_START,
  COMPANY_FEATURED_VIDEO_FAIL,
  SORT_SERIES_STORIES,
  DELETE_PEA_START,
  DELETE_PEA_FAIL,
  DELETE_PEA_SUCCESS,
  FILTERED_BROADCASTING_PEAS,
  FILTERED_HOME_STORIES,
  RETRIEVE_SERIES,
  RETRIEVE_COMPANY_START,
  RETRIEVE_COMPANY_SUCCESS,
  RETRIEVE_COMPANY_FAIL,
  GET_COMPANY_STORIES_SUCCESS,
  ADD_PEA_LABELS_START,
  ADD_PEA_LABELS_FAIL,
  ADD_PEA_LABELS_SUCCESS,
  UPDATE_COMMENT_START,
  UPDATE_COMMENT_FAIL,
  UPDATE_COMMENT_SUCCESS,
  DELETE_COMMENT_START,
  DELETE_COMMENT_FAIL,
  DELETE_COMMENT_SUCCESS,
  INCREMENT_NOTIFICATION,
  CLEAR_NOTIFICATION,
  WATCHLIST_PEA,
  FILTERED_WATCHLIST,
  GET_WORKDAYGUIDE_STORIES_START,
  GET_WORKDAYGUIDE_STORIES_END,
  GET_WORKDAYGUIDE_STORIES_SUCCESS,
  FILTER_WORKDAYGUIDE_STORIES_START,
  FILTER_WORKDAYGUIDE_STORIES_END,
  FILTER_WORKDAYGUIDE_STORIES_SUCCESS,
  WORKDAY_GUIDE_FILTER,
  AUTH_SIGN_OUT,
  TOGGLE_PEA_DROPDOWN,
  LIKE_PEA,
  RETRIEVE_UPLOAD_TOKEN_START,
  SET_LABEL_VALUE,
  WORKDAY_GUIDE_SORT_BY_DROPDOWN,
  WORKDAY_GUIDE_DESIRED_EFFECT_DROPDOWN,
  CLEAR_COMPANY_STORIES,
  CLEAR_PEA_VIDEO_STORIES,
  PAGINATE_COMMENTS_SUCCESS,
  PAGINATE_PREVIOUS_COMMENTS_SUCCESS,
  PAGINATE_COMMENTS_URL,
  EMPTY_COMMENTS_FOR_NOTIFICATION,
  PAGINATE_PREVIOUS_COMMENTS_URL,
  PAGINATE_REPLIES_SUCCESS,
  PAGINATE_REPLIES_URL,
  TOGGLE_PEA_DROPLIST_SUCCESS,
  FILTERED_AIRDROP_STORIES,
  CLEAR_COMPANY,
  HIDE_COMPANY_PEAS_SUCCESS,
} from "../types"

const updateFilterState = (storyFilters, storyFilter) => {
  const existingId = storyFilters.find(
    (filter) => filter.id === storyFilter.id
  )

  const existingName = storyFilters.find(
    (filter) => filter.name === storyFilter.name
  )

  if (existingName) {
    storyFilters = [
      ...storyFilters.filter((filter) => filter.name !== existingName.name),
    ]
    
  }
  if (existingId) {
    const index = storyFilters.indexOf(existingId)
    storyFilters[index] = storyFilter
  } else {
    storyFilters = [...storyFilters, storyFilter]
  }

  return storyFilters
}

const DEFAULT_STATE = {
  stories: [],
  homeStories: [],
  companyStories: [],
  companyPreviewStories: [],
  peaVideoStories: [],
  broadcastingStories: [],
  airdropStories: [],
  story: null,
  company: null,
  page: 1,
  seriesOnlyPage: 1,
  homePage: 1,
  companyPage: 1,
  peaVideoPage: 1,
  broadcastingPage: 1,
  userInterestStoriesPage: 1,
  statistics: null,
  loadingStatistics: false,
  storiesLoading: false,
  stopFetchingStories: false,
  loading: false,
  uploadPeaLoading: false,
  hasMore: false,
  homeHasMore: false,
  homeInterestHasMore: false,
  startFetchInterestStories: false,
  podHasMore: false,
  comments: [],
  loadingComments: false,
  peaVideoLoadingComments: false,
  replies: [],
  labels: [],
  loadingLabels: false,
  storyFilters: [],
  seriesStories: [],
  series: null,
  seriesLoading: false,
  seriesHasMore: false,
  notifications: [],
  loadingWatchlist: false,
  watchlists: [],
  workdayGuideStories: [],
  workdayGuideFilter: "",
  peaDropdownOpen: "",
  labelValue: "",
  workdayGuideSortByFilter: false,
  workdayGuideDesiredEffectFilter: false,
  commentNextPageUrl: null,
  commentPreviousPageUrl: null,
  repliesNextPageUrl: null,
  peaVideoCommentNextPageUrl: null,
  topStory: null,
  companyFeaturedVideo: null,
  isTransformOrUpload: false,
}

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case AUTH_SIGN_OUT:
      return DEFAULT_STATE
    case SET_LABEL_VALUE:
      return {
        ...state,
        labelValue: payload,
      }
    case TOGGLE_PEA_DROPDOWN:
      return {
        ...state,
        peaDropdownOpen: payload,
      }
    case RETRIEVE_UPLOAD_TOKEN_START:
      return {
        ...state,
        uploadPeaLoading: true,
      }
    case ADD_EMBED_START:
    case UPDATE_EMBED_START:
      return {
        ...state,
        loading: true,
      }
    case ADD_PEA_FAIL:
      return {
        ...state,
        uploadPeaLoading: false,
      }
    case UPDATE_PEA_START:
    case GET_LABELS_START:
    case RETRIEVE_STORY_START:
    case DELETE_PEA_START:
    case RETRIEVE_COMPANY_START:
    case ADD_PEA_LABELS_START:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_EMBED_FAIL:
    case UPDATE_PEA_FAIL:
    case GET_LABELS_FAIL:
    case RETRIEVE_STORY_FAIL:
    case ADD_EMBED_FAIL:
    case DELETE_PEA_FAIL:
    case RETRIEVE_COMPANY_FAIL:
    case ADD_PEA_LABELS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_EMBED_SUCCESS:
      return {
        ...state,
        loading: false,
        broadcastingStories: [
          ...state.broadcastingStories.map((story) =>
            story.id === payload.id ? { ...story, embed: payload.embed } : story
          ),
        ],
      }
    case ADD_EMBED_SUCCESS:
      return {
        ...state,
        loading: false,
        broadcastingStories: [
          ...state.broadcastingStories.map((story) =>
            story.id === payload.id ? { ...story, embed: payload.embed } : story
          ),
        ],
      }
    case ADD_PEA_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        broadcastingStories: state.broadcastingStories.map((story) =>
          story.id === payload.peaId && payload.labels
            ? { ...story, labels: payload.labels }
            : story
        ),
        seriesStories:
          payload.series &&
          state.series &&
          state.series.id === payload.series.id
            ? state.seriesStories.map((story) =>
                story.id === payload.peaId && payload.labels
                  ? { ...story, labels: payload.labels }
                  : story
              )
            : state.seriesStories,
      }
    case GET_STORIES_START:
      return {
        ...state,
        loading: true,
        storiesLoading: true,
        stopFetchingStories: false,
      }
    case GET_STORIES_FAIL:
      return {
        ...state,
        loading: false,
        hasMore: false,
        homeHasMore: false,
        storiesLoading: false,
      }
    case USER_INTEREST_STORIES_START:
      return {
        ...state,
        startFetchInterestStories: true,
      }
    case USER_INTEREST_STORIES_FAIL:
      return {
        ...state,
        homeInterestHasMore: false,
        startFetchInterestStories: false,
      }
    case GET_STORIES_SUCCESS:
      if (action.callFrom.includes("home") && action.isSeriesOnly) {
        return {
          ...state,
          seriesOnlyPage: action.hasMore
            ? state.seriesOnlyPage + 1
            : state.seriesOnlyPage,
          homeStories: [
            ...state.homeStories.filter((story) => story.series),
            ...payload,
          ],
          homePage: 1,
          companyPage: 1,
          companyStories: [],
          homeHasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      } else if (action.callFrom.includes("home")) {
        return {
          ...state,
          homePage: state.homePage + 1,
          homeStories: [...state.homeStories, ...payload],
          companyPage: 1,
          // companyStories: [],
          homeHasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      } else if (action.callFrom.includes("company")) {
        return {
          ...state,
          companyPage: state.companyPage + 1,
          companyStories: [...state.companyStories, ...payload],
          hasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      } else if (
        action.callFrom.includes("broadcastPea") &&
        !action.shouldReload
      ) {
        return {
          ...state,
          broadcastingPage: action.currentPage,
          broadcastingStories: [...state.broadcastingStories, ...payload],
          companyPage: 1,
          companyStories: [],
          podHasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      } else if (
        action.callFrom.includes("broadcastPea") &&
        action.shouldReload
      ) {
        return {
          ...state,
          broadcastingPage: action.currentPage,
          broadcastingStories: payload,
          companyPage: 1,
          companyStories: [],
          podHasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      } else if (action.callFrom.includes("embed")) {
        return {
          ...state,
          broadcastingPage: state.broadcastingPage,
          broadcastingStories: [
            ...payload,
            ...state.broadcastingStories.filter(
              (payloadItem) =>
                !payload.some((stateItem) => stateItem.id === payloadItem.id)
            ),
          ],
          companyPage: 1,
          companyStories: [],
          podHasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      } else if (action.callFrom.includes("peaVideo")) {
        return {
          ...state,
          peaVideoPage: state.peaVideoPage + 1,
          peaVideoStories: [...state.peaVideoStories, ...payload],
          companyPage: 1,
          companyStories: [],
          hasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      } else if (action.callFrom.includes("airdrop")) {
        return {
          ...state,
          airdropStories: [...payload],
        }
      } else if (action.callFrom.includes("userInterestHome")) {
        return {
          ...state,
          userInterestStoriesPage: action.hasMore
            ? state.userInterestStoriesPage + 1
            : 1,
          homeStories: [...state.homeStories, ...payload],
          companyPage: 1,
          companyStories: [],
          homeInterestHasMore: action.hasMore,
          loading: false,
          storiesLoading: false,
        }
      }
    case STOP_GET_STORIES:
      return {
        ...state,
        stopFetchingStories: true,
      }
    case GET_WATCH_LIST_STORIES_START:
      return {
        ...state,
        loadingWatchlist: true,
      }

    case GET_WATCH_LIST_STORIES_FAIL:
      return {
        ...state,
        loadingWatchlist: false,
      }
    case GET_WATCH_LIST_STORIES_SUCCESS:
      const uniqueWatchlistPeas = payload.filter(
        (newItem) =>
          !state.watchlists.some(
            (existingItem) => existingItem.id === newItem.id
          )
      )
      return {
        ...state,
        loadingWatchlist: false,
        watchlists: [...state.watchlists, ...uniqueWatchlistPeas],
      }
    case RETRIEVE_STORY_SUCCESS:
      return {
        ...state,
        story: payload,
        loading: false,
      }
    case DELETE_COMMENT_START:
    case UPDATE_COMMENT_START:
    case GET_COMMENTS_START:
      return {
        ...state,
        loadingComments: true,
        peaVideoLoadingComments: true,
      }
    case DELETE_COMMENT_FAIL:
    case UPDATE_COMMENT_FAIL:
    case GET_COMMENTS_FAIL:
      return {
        ...state,
        loadingComments: false,
        peaVideoLoadingComments: false,
      }
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: payload,
        loadingComments: false,
        peaVideoLoadingComments: false,
      }
    case PAGINATE_COMMENTS_SUCCESS:
      if (action.callFrom === "peaVideo") {
        return {
          ...state,
          comments: [...state.comments, ...payload],
          peaVideoLoadingComments: false,
        }
      } else {
        return {
          ...state,
          comments: [...state.comments, ...payload],
          loadingComments: false,
        }
      }
    case PAGINATE_PREVIOUS_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: [...payload],
      }
    case PAGINATE_COMMENTS_URL:
      if (action.callFrom === "peaVideo") {
        return {
          ...state,
          peaVideoCommentNextPageUrl: payload,
        }
      } else {
        return {
          ...state,
          commentNextPageUrl: payload,
        }
      }
    case EMPTY_COMMENTS_FOR_NOTIFICATION:
      return {
        ...state,
        comments: [],
      }
    case PAGINATE_PREVIOUS_COMMENTS_URL:
      return {
        ...state,
        commentPreviousPageUrl: payload,
      }
    case GET_REPLIES_SUCCESS:
      return {
        ...state,
        loadingComments: false,
        replies: payload,
      }
    case PAGINATE_REPLIES_SUCCESS:
      return {
        ...state,
        replies: [...state.replies, ...payload],
      }
    case PAGINATE_REPLIES_URL:
      return {
        ...state,
        repliesNextPageUrl: payload,
      }
    case ADD_COMMENT:
      return {
        ...state,
        comments: [payload, ...state.comments],
      }
    case ADD_REPLY:
      return {
        ...state,
        replies: [payload, ...state.replies],
      }
    case UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment.id === payload.id ? { ...payload } : comment
        ),
        loadingComments: false,
      }
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: state.comments.filter((comment) => comment.id !== payload),
        loadingComments: false,
      }
    case RETRIEVE_STORY_STATISTICS_START:
      return {
        ...state,
        loadingStatistics: true,
        loading: true,
      }
    case RETRIEVE_STORY_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: payload,
        loadingStatistics: false,
        loading: false,
      }
    case RETRIEVE_STORY_STATISTICS_FAIL:
      return {
        ...state,
        loadingStatistics: false,
        loading: false,
      }
    case CLEAR_COMPANY_STORIES:
      return {
        ...state,
        companyPage: 1,
        companyStories: [],
      }
    case CLEAR_STORY:
      return {
        ...state,
        story: null,
        statistics: null,
      }
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      }
    case REMOVE_COMMENT_ASSIGNMENT:
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment.id === payload.id
            ? { ...comment, comment: payload.comment }
            : comment
        ),
      }
    case ADD_PEA_SUCCESS:
      let latest_peas = []
      let index = 0
      for (let i = 0; i < state.broadcastingStories.length; i++) {
        if (
          payload.series &&
          payload.order_series == 1 &&
          state.broadcastingStories[i].series &&
          state.broadcastingStories[i].series?.id === payload.series.id
        ) {
          //do nothing
        } else {
          latest_peas[index] = state.broadcastingStories[i]
          index++
        }
      }

      return {
        ...state,
        broadcastingStories: [
          ...latest_peas,
          (payload.series && payload.order_series === 1) || !payload.series
            ? payload
            : [],
        ].flat(),
        seriesStories:
          payload.series &&
          state.series &&
          state.series.id === payload.series.id
            ? [...state.seriesStories, payload]
            : state.seriesStories,
        loading: false,
        uploadPeaLoading: false,
        isTransformOrUpload: true,
      }
    case GET_LABELS_SUCCESS:
      return {
        ...state,
        labels: payload,
        loading: false,
      }
    case ADD_LABEL_START:
      return {
        ...state,
        loadingLabels: true,
      }
    case ADD_LABEL_FAIL:
      return {
        ...state,
        loadingLabels: false,
      }
    case ADD_LABEL_SUCCESS:
      return {
        ...state,
        labels: [...state.labels, payload],
        loadingLabels: false,
      }
    case UPDATE_PEA_SUCCESS:
      let updated_stories = []
      let updated_index = 0

      let updated_top_pea = []

      for (let i = 0; i < state.broadcastingStories.length; i++) {
        let s = state.broadcastingStories[i]

        if (
          s.id === payload.id &&
          s.order === 1 &&
          payload.order != 1 &&
          updated_top_pea.length === 0
        ) {
          updated_top_pea = state.broadcastingStories.filter(
            (p) =>
              p.series &&
              payload.series &&
              p.series.id === payload.series.id &&
              p.order_series === 1
          )
          continue
        }

        if (s.id === payload.id) {
          continue
        } else if (
          s.series &&
          payload.series &&
          s.order_series === payload.order_series &&
          s.series.id === payload.series.id
        ) {
          continue
        } else if (
          payload.series &&
          payload.order_series > 1 &&
          s.series &&
          s.order_series != 1 &&
          s.series.id === payload.series.id
        ) {
          continue
        } else {
          updated_stories[updated_index] = s
          updated_index++
        }
      }

      if (!payload.order_series || payload.order_series === 1) {
        if (payload.order === 1) {
          updated_stories.unshift(payload)
        } else {
          updated_stories = [...updated_stories, payload]
        }
      }

      if (updated_top_pea.length > 0 && updated_top_pea[0]) {
        updated_top_pea[0].order = 1
        updated_stories.unshift(updated_top_pea[0])
      }

      return {
        ...state,
        story: payload,
        loading: false,
        topStory:
          updated_top_pea.length > 0 ? updated_top_pea[0] : state.topStory,
        uploadPeaLoading: false,
        seriesStories:
          payload.series &&
          state.series &&
          state.series.id === payload.series.id
            ? state.seriesStories.map((story) =>
                story.id === payload.id ? { ...payload } : story
              )
            : payload.series === null
            ? state.seriesStories?.filter((item) => item?.id !== payload.id)
            : state.seriesStories,
        broadcastingStories: [...updated_stories],
        airdropStories: state.airdropStories.map((pea) =>
          pea.pea.id === payload.id ? { ...pea, pea: payload } : pea
        ),
        isTransformOrUpload: true,
      }
    case DELETE_PEA_SUCCESS:
      return {
        ...state,
        loading: false,
        broadcastingStories: state.broadcastingStories.filter(
          (pea) => pea.id !== payload
        ),
        topStory:
          state.topStory.id === payload
            ? state.broadcastingStories.filter((p) => p.id !== payload)[0]
            : state.topStory,
        airdropStories: state.airdropStories.filter(
          (pea) => pea.pea.id !== payload
        ),
        seriesStories: state.seriesStories.filter((pea) => pea.id !== payload),
      }
    case FILTERED_BROADCASTING_PEAS:
      const filteredBroadCastingStories = state.broadcastingStories.filter(
        (item) => !payload.some((p) => p.id === item.id)
      )
      const filteredSeriesStories = state.seriesStories.filter(
        (item) => !payload.some((p) => p.id === item.id)
      )

      return {
        ...state,
        broadcastingStories: filteredBroadCastingStories,
        seriesStories: filteredSeriesStories,
      }
    case FILTERED_HOME_STORIES:
      const filteredHomeStories = state.homeStories.filter(
        (item) => !payload.some((p) => p.id === item.id)
      )
      return {
        ...state,
        homeStories: filteredHomeStories,
      }
    case SET_STORY_FILTERS:
      return {
        ...state,
        storyFilters: updateFilterState(state.storyFilters, payload),
      }
    case CLEAR_STORY_FILTERS:
      return {
        ...state,
        storyFilters: [],
      }
    case COMPANY_FEATURED_VIDEO_START:
      return {
        ...state,
        loading: true,
    }
    case COMPANY_FEATURED_VIDEO_FAIL:
      return {
        ...state,
        loading: false,
    }
    case GET_SERIES_STORIES_START:
      return {
        ...state,
        seriesLoading: true,
      }
    case GET_SERIES_STORIES_FAIL:
      return {
        ...state,
        seriesLoading: false,
      }
    case GET_SERIES_STORIES_SUCCESS:
      return {
        ...state,
        seriesLoading: false,
        seriesStories:
          state.series &&
          action.calledAfter != "Update" &&
          state.series.id === action.series.id
            ? [...state?.seriesStories, ...payload]
            : payload,
        seriesHasMore: action.hasMore,
        series: action.payload[0]?.series,
        page: action.page ? action.page : 1,
        loading: false,
      }
    case SORT_STORIES:
      return {
        ...state,
        broadcastingStories: payload,
      }
    case SET_TOP_STORY:
      return {
        ...state,
        topStory: payload,
        loading:false
      }
    case COMPANY_FEATURED_VIDEO:
      return {
        ...state,
        companyFeaturedVideo: payload,
        loading:false
      }
    case SORT_SERIES_STORIES:
      return {
        ...state,
        seriesStories: payload,
      }
    case RETRIEVE_SERIES:
      return {
        ...state,
        series: payload,
      }
    case RETRIEVE_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case CLEAR_COMPANY:
      return {
        ...state,
        company: null,
      }
    case GET_COMPANY_STORIES_SUCCESS:
      return {
        ...state,
        companyPreviewStories: payload,
      }
    case INCREMENT_NOTIFICATION:
      let currNotifications = []
      try {
        if (localStorage.getItem("notifications")) {
          currNotifications = JSON.parse(localStorage.getItem("notifications"))
        }
      } catch (err) {
        console.log(err)
      }
      currNotifications.push(payload)
      localStorage.setItem("notifications", JSON.stringify(currNotifications))
      return {
        ...state,
        notifications: currNotifications,
      }
    case CLEAR_NOTIFICATION:
      localStorage.setItem("notifications", JSON.stringify(payload))
      return {
        ...state,
        notifications: payload,
      }
    case WATCHLIST_PEA:
      if (payload.watch) {
        return {
          ...state,
          watchlists: [...state.watchlists, payload.pea],
        }
      }
      if (!payload.watch) {
        return {
          ...state,
          watchlists: state.watchlists.filter((w) => w.id !== payload.pea.id),
        }
      }
    case FILTERED_WATCHLIST:
      const filteredWatchlist = state.watchlists.filter(
        (item) => !payload.some((p) => p.id === item.id)
      )
      return {
        ...state,
        watchlists: filteredWatchlist,
      }
    case LIKE_PEA:
      if (payload.pea && !payload.pea.likes.includes(payload.userId)) {
        if (payload.callFrom === "home") {
          return {
            ...state,
            homeStories: [
              ...state.homeStories.filter(
                (story) => story.id !== payload.pea.id
              ),
              { ...payload.pea, likes: [...payload.pea.likes, payload.userId] },
            ],
          }
        } else if (payload.callFrom === "workday-guide") {
          return {
            ...state,
            workdayGuideStories: {
              ...state.workdayGuideStories,
              [payload.selectedLabel]: [
                ...state.workdayGuideStories[payload.selectedLabel].filter(
                  (story) => story.id !== payload.pea.id
                ),
                {
                  ...payload.pea,
                  likes: [...payload.pea.likes, payload.userId],
                },
              ],
            },
          }
        } else {
          return {
            ...state,
            peaVideoStories: [
              ...state.peaVideoStories.map((story) =>
                story.id === payload.pea.id
                  ? {
                      ...payload.pea,
                      likes: [...payload.pea.likes, payload.userId],
                    }
                  : story
              ),
            ],
            homeStories: [
              ...state.homeStories.map((story) =>
                story.id === payload.pea.id
                  ? {
                      ...payload.pea,
                      likes: [...payload.pea.likes, payload.userId],
                    }
                  : story
              ),
            ],
          }
        }
      } else {
        if (payload.callFrom === "home") {
          return {
            ...state,
            homeStories: [
              ...state.homeStories.filter(
                (story) => story.id !== payload.pea.id
              ),
              {
                ...payload.pea,
                likes: payload.pea.likes.filter((id) => id !== payload.userId),
              },
            ],
          }
        } else if (payload.callFrom === "workday-guide") {
          return {
            ...state,
            workdayGuideStories: {
              ...state.workdayGuideStories,
              [payload.selectedLabel]: [
                ...state.workdayGuideStories[payload.selectedLabel].filter(
                  (story) => story.id !== payload.pea.id
                ),
                {
                  ...payload.pea,
                  likes: payload.pea.likes.filter(
                    (id) => id !== payload.userId
                  ),
                },
              ],
            },
          }
        } else {
          return {
            ...state,
            peaVideoStories: [
              ...state.peaVideoStories.map((story) =>
                story.id === payload.pea.id
                  ? {
                      ...payload.pea,
                      likes: payload.pea.likes.filter(
                        (id) => id !== payload.userId
                      ),
                    }
                  : story
              ),
            ],
            homeStories: [
              ...state.homeStories.map((story) =>
                story.id === payload.pea.id
                  ? {
                      ...payload.pea,
                      likes: payload.pea.likes.filter(
                        (id) => id !== payload.userId
                      ),
                    }
                  : story
              ),
            ],
          }
        }
      }
    case GET_WORKDAYGUIDE_STORIES_START:
      return {
        ...state,
        loading: true,
      }
    case GET_WORKDAYGUIDE_STORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        workdayGuideStories: {
          ...state.workdayGuideStories,
          [payload.label]: payload.data,
        },
      }
    case GET_WORKDAYGUIDE_STORIES_END:
      return {
        ...state,
        loading: false,
      }
    case FILTER_WORKDAYGUIDE_STORIES_START:
      return {
        ...state,
        loading: true,
      }
    case FILTER_WORKDAYGUIDE_STORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        workdayGuideStories: {
          ...state.workdayGuideStories,
          [payload.label]: payload.data,
        },
      }
    case FILTER_WORKDAYGUIDE_STORIES_END:
      return {
        ...state,
        loading: false,
      }
    case WORKDAY_GUIDE_FILTER:
      return {
        ...state,
        workdayGuideFilter: payload,
      }
    case WORKDAY_GUIDE_SORT_BY_DROPDOWN:
      return {
        ...state,
        workdayGuideSortByFilter: payload,
      }
    case WORKDAY_GUIDE_DESIRED_EFFECT_DROPDOWN:
      return {
        ...state,
        workdayGuideDesiredEffectFilter: payload,
      }
    case CLEAR_PEA_VIDEO_STORIES:
      return {
        ...state,
        peaVideoStories: [],
        peaVideoPage: 1,
      }
    case TOGGLE_PEA_DROPLIST_SUCCESS:
      const temp = state.airdropStories.find((x) => x.pea.id === payload.pea.id)

      if (!temp) {
        return {
          ...state,
          airdropStories: [...state.airdropStories, payload],
        }
      } else {
        let updatedAirdropStories = state.airdropStories.filter(
          (x) => x.pea.id !== temp.pea.id
        )
        return {
          ...state,
          airdropStories: updatedAirdropStories,
        }
      }
    case HIDE_COMPANY_PEAS_SUCCESS:
      return { ...state, broadcastingStories: [] }

    case FILTERED_AIRDROP_STORIES:
      const filteredAirDropStories = state.airdropStories.filter(
        (item) => !payload.some((p) => p.pea.id === item.pea.id)
      )
      return {
        ...state,
        airdropStories: filteredAirDropStories,
      }
    default:
      return state
  }
}
