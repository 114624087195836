import React from 'react';

import styles from './PBCookiesMessage.module.scss';

const PBCookiesMessage = ({
                            onClickMessage
                          }) => {

  return (
    <div className={styles.CookiesMessage}>
      Peabox uses cookies to help give you the best experience.{" "}
      <b onClick={() => onClickMessage()}>Got it!</b>
    </div>
  );
};

export default PBCookiesMessage;
