import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Iframe from 'react-iframe';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Col, Container, Image, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import Info from './tabs/Info';
import Share from './tabs/Share';
import Comments from './tabs/Comments';
import PeaPageHeader from './PeaPageHeader';
import * as actions from '../../store/actions';
import Embed from '../../components/Embed/Embed';
import Button from '../../components/UI/Button/Button';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';

import { ReactComponent as ChevronLeftIcon } from '../../icons/common/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../icons/common/chevron-right.svg';
import styles from './PeaVideo.module.scss';
import './PeaVideo.scss';
import './ShareModal.scss';
// import Play from '../../../icons/play.svg';

const SV = window.SV;

const PeaVideoPage = ({
  isAuth,
  mobile = false,
  pea,
  addComment,
  likePea,
  account,
  hasMore,
  retrieveSeries,
  series,
  watchlistPea,
  setShowLoginModal,
  setShowPeaPageModal,
  updatePea,
  peaLoading,
  getStories,
  peaVideoPage,
  isSeriesOnly,
  seriesOnlyPage,
}) => {
  const [like, setLike] = useState(false);
  const [wish, setWish] = useState(false);
  const [tabKey, setTabKey] = useState('home');
  const [showEmbed, setShowEmbed] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const [showControls, setShowControls] = useState(true);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [URL, setURL] = useState('');
  const history = useHistory();
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);
  const parentWidthRef = useRef(null);
  const parentHeightRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    const isLiked = pea && account ? pea.likes.includes(account.id) : false;
    const isWatchlisted =
      pea && account ? pea.watchlists.includes(account.id) : false;

    setLike(isLiked);
    setWish(isWatchlisted);
  }, [pea, account]);

  useEffect(() => {
    if (pea) {
      try {
        setVideoPlayer(new SV.Player({ videoId: pea.reference_id }));
      } catch (err) {
        console.log(err);
      }

      setURL(
        `${window.location.protocol}//${window.location.hostname}/pea/${pea.id}`
      );
    }
  }, [pea]);

  useEffect(() => {
    if (videoPlayer) {
      videoPlayer.bind('play', () => setShowControls(false));
      videoPlayer.bind('pause', () => setShowControls(true));
      videoPlayer.bind('completed', () => setShowEmbed(true));
    }
  }, [videoPlayer]);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });
  const isSmallerMobile = useMediaQuery({ query: '(max-width: 375px)' });

  useEffect(() => {
    function handleResize() {
      if (parentWidthRef.current || parentHeightRef.current) {
        if (isMobile) {
          setParentWidth(Math.floor(parentWidthRef.current.offsetWidth));
        } else {
          setParentWidth(Math.floor(parentWidthRef.current.offsetWidth * 0.42));
          setParentHeight(Math.floor(400));
        }
      }
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function handleIframeLoad() {
    const iframe = parentHeightRef.current.querySelector('iframe');
    if (mobile) {
      setParentHeight(iframe.offsetHeight);
    } else {
      setParentHeight(400);
    }
  }

  useEffect(() => {
    if (pea !== null) {
      let commentsObject = [];
      for (const index in pea.comments) {
        commentsObject.push({
          comment: pea.comments[index].text,
          date: humanized_time_span(new Date(pea.comments[index].date)),
        });
      }
    }
  }, [pea]);

  useEffect(() => {
    if (pea && pea.series) {
      retrieveSeries({ series: pea.series.id });
    }
  }, [pea, retrieveSeries]);

  useEffect(() => {
    if (series && series.peas_count > 0) {
      const newEpisodes = series.peas.map((pea, index) => {
        return { label: `Episode ${index + 1}`, value: pea };
      });

      setEpisodes(newEpisodes);
    }
  }, [series]);

  const handleSelect = async (key) => {
    switch (key) {
      case 'comments':
        if (account && account.deactivated_at) {
          toast.error('Commenting is not allowed for deactivated accounts.');
        } else if (
          (!account || account.account_type !== 'Basic') &&
          !isMobile
        ) {
          setShowLoginModal(true);
        } else if ((!account || account.account_type !== 'Basic') && isMobile) {
          history.push('/sign-in');
        } else {
          setTabKey(key);
        }
        break;
      case 'like':
        if (!account || account.account_type !== 'Basic') {
          setShowLoginModal(true);
        } else {
          likePea({
            peaId: pea.id,
            userId: account.id,
            peaVideo: pea,
            selectedLabel: null,
            callFrom: 'peaVideo',
          });
          setTabKey(tabKey);
        }
        break;
      case 'watchlist':
        if (!account || account.account_type !== 'Basic') {
          setShowLoginModal(true);
        } else {
          if (!loading) {
            setLoading(true);
            const result = await watchlistPea({
              pea,
              watch: !wish,
              userId: account.id,
            });

            if (result) {
              setLoading(false);
            }
          }
          setTabKey(tabKey);
        }
        break;
      case 'share':
        if (account && account.deactivated_at) {
          toast.error('Sharing peas is not allowed for deactivated accounts.');
        } else if (!account || account.account_type !== 'Basic') {
          setShowLoginModal(true);
        } else {
          setTabKey(key);
        }
        break;
      default:
        setTabKey(key);
    }
  };

  const changeEpisode = ({ peaId, prev, next }) => {
    let index = series.peas.indexOf(peaId);
    let episode = null;

    setShowEmbed(false);

    if (prev && index - 1 !== -1) {
      episode = series.peas[index - 1];
    } else if (next && index + 1 !== series.peas.length) {
      episode = series.peas[index + 1];
    } else if (!next && !prev) {
      episode = peaId;
    }

    if (!episode) {
      return;
    } else {
      history.push(`/pea/${episode}`);
    }
  };

  const handleShare = () => updatePea({ id: pea.id, shares: pea.shares + 1 });

  const handleNativeShare = async ({ title, url }) => {
    if (typeof window !== undefined) {
      if (typeof navigator !== undefined && pea) {
        if (navigator.share) {
          const blob = await fetch(pea.image).then((response) =>
            response.blob()
          );
          let name = Date.now() + '-' + pea.title + '.png';
          let file = new File([blob], name, {
            type: blob.type,
            lastModified: Date.now(),
          });

          let shareData = {
            text:
              'Snack on thiss Tiny Break..\nYourTinyBreaks\n' + title + '\n',
            url,
            // files: [file],
          };
          navigator
            .share(shareData)
            .then(() => handleShare())
            .catch((err) => console.log('Error sharing', err));
        } else {
          alert('Share not supported by this browser');
        }
      }
    }
  };

  const getMoreStories = () => {
    if (!hasMore || loading) return;

    if (account) {
      getStories(
        peaVideoPage,
        undefined,
        'peaVideo',
        account.account_type,
        isSeriesOnly,
        seriesOnlyPage
      );
    } else {
      getStories(
        peaVideoPage,
        undefined,
        'peaVideo',
        null,
        isSeriesOnly,
        seriesOnlyPage
      );
    }
  };

 

  return (
    <Container
      fluid={true}
      style={{
        padding: isSmallerMobile ? '0 0' : isMobile ? '0 15px' : '0 21px',
        overflowX: 'hidden',
      }}
    >
      {!isMobile && (
        <PeaPageHeader
          pea={pea}
          isAuth={isAuth}
          isFollowing={isFollowing}
          setIsFollowing={setIsFollowing}
          setShowPeaPageModal={setShowPeaPageModal}
        />
      )}
      <Row ref={parentWidthRef}>
        <Col
          lg={{ span: 6, order: 2 }}
          xs={{ span: 12, order: 1 }}
          className={styles.VideoWrapperVideoPage}
        >
          <div className={classNames(styles.VideoWrapper, styles[pea.format])}>
            {showEmbed &&
              pea &&
              pea.embed &&
              pea.embed.link &&
              pea.embed.type && (
                <Embed
                  link={pea.embed.link}
                  type={pea.embed.type}
                  embedStyles={styles.VideoEmbed}
                  parentWidth={parentWidth}
                  parentHeight={parentHeight}
                />
              )}
            {pea?.series && episodes && episodes.length > 0 && (
              <>
                <ChevronLeftIcon
                  onClick={() =>
                    changeEpisode({ peaId: pea.id, prev: true, next: false })
                  }
                  className={classNames(
                    styles.ChevronLeft,
                    showControls && styles.Controls
                  )}
                />
                <ChevronRightIcon
                  onClick={() =>
                    changeEpisode({ peaId: pea.id, prev: false, next: true })
                  }
                  className={classNames(
                    styles.ChevronRight,
                    showControls && styles.Controls
                  )}
                />
                <ReactSelect
                  className={classNames(
                    'EpisodeContainer',
                    showControls && 'Controls'
                  )}
                  classNamePrefix='Episode'
                  isSearchable={false}
                  menuPortalTarget={isMobile && document.body}
                  options={episodes}
                  onChange={(option) =>
                    changeEpisode({
                      peaId: option.value,
                      prev: false,
                      next: false,
                    })
                  }
                  value={episodes.find((option) => option.value === pea.id)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </>
            )}

            <div ref={parentHeightRef}>
              <Iframe
                id={`PeaPlayer-${pea.id}`}
                onLoad={handleIframeLoad}
                className={classNames(
                  styles.VideoPlayer,
                  styles[pea.format],
                  'sproutvideo-player'
                )}
                url={`${pea.iframe}?bigPlayButton=false&showControls=true`}
                frameBorder='0'
                allowFullScreen
                fill
              />
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 1 }}
          lg={{ span: 12, order: 1 }}
        >
          {isMobile && (
            <PeaPageHeader
              pea={pea}
              isAuth={isAuth}
              isFollowing={isFollowing}
              setIsFollowing={setIsFollowing}
              setShowPeaPageModal={setShowPeaPageModal}
            />
          )}
        </Col>
        <Col
          md={{ span: 12, order: 2 }}
          lg={{ span: 6, order: 3 }}
          xs={{ span: 12, order: 3 }}
          className={
            isMobile
              ? styles.TitleTabsWrapperPeaVideoPageMobile
              : styles.TitleTabsWrapperPeaVideoPage
          }
        >
          <h1 className={styles.PeaVideoTitle}>
            {pea !== null ? pea.title : ''}
            {pea.description ? <p>{pea.description}</p> : null}
          </h1>
          {isMobile && (
            <section className={styles.buttonsWrapper}>
              <Button
                onClick={() => {
                  if (!account || account.account_type !== 'Basic') {
                    history.push('/sign-in');
                  } else {
                    likePea({
                      peaId: pea.id,
                      userId: account.id,
                      peaVideo: pea,
                      selectedLabel: null,
                      callFrom: 'peaVideo',
                    });
                  }
                }}
                buttonStyle={clsx(
                  styles.buttonTab,
                  like ? styles.buttonTabActive : ''
                )}
              >
                {like ? 'Liked' : 'Like'}
              </Button>
              {pea !== null && (
                <>
                  <Button
                    buttonStyle={styles.buttonTabShare}
                    onClick={() => {
                      if (account && account.deactivated_at) {
                        toast.error(
                          'Sharing peas is not allowed for deactivated accounts.'
                        );
                      } else if (!account || account.account_type !== 'Basic') {
                        history.push('/sign-in');
                      } else {
                        handleShare();
                        handleNativeShare({
                          title: pea.title,
                          url: `${window.location.protocol}//${window.location.hostname}/pea/${pea.id}`,
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon className='mr-1' icon={faShareAlt} /> Share
                  </Button>
                </>
              )}
              <Button
                buttonStyle={clsx(
                  styles.buttonTabAddToWatchList,
                  wish ? styles.buttonTabActive : ''
                )}
                onClick={async () => {
                  if (!account || account.account_type !== 'Basic') {
                    history.push('/sign-in');
                  } else {
                    if (!loading) {
                      setLoading(true);
                      const result = await watchlistPea({
                        pea,
                        watch: !wish,
                        userId: account.id,
                      });

                      if (result) {
                        setLoading(false);
                      }
                    }
                  }
                }}
                loading={loading}
              >
                <FontAwesomeIcon icon={wish ? faMinus : faPlus} /> Watchlist
              </Button>
            </section>
          )}
          <Row className={styles.peavideoDetail}>
            <Col lg={12}>
              <Tabs
                activeKey={tabKey}
                onSelect={(key) => handleSelect(key)}
                defaultActiveKey='home'
                id='uncontrolled-tab-example'
                className={
                  styles.MenuTabsPeaVideoPage +
                  (isMobile
                    ? ' justify-content-center ' + styles.noFlex
                    : ' justify-content-sm-between')
                }
              >
                <Tab
                  eventKey='home'
                  title='Info'
                  tabClassName={mobile ? styles.mobileTab : ''}
                >
                  <Info pea={pea} />
                </Tab>
                <Tab eventKey='comments' title='Comments'>
                  <Comments
                    addComment={addComment}
                    account={account}
                    isAuth={isAuth}
                    pea={pea}
                  />
                </Tab>
                <Tab
                  eventKey='like'
                  title={like ? 'Liked' : 'Like'}
                  tabClassName={clsx(
                    isMobile ? styles.hiddenTab : '',
                    like ? 'active' : ''
                  )}
                />
                <Tab
                  eventKey='share'
                  title='Share'
                  tabClassName={isMobile ? styles.hiddenTab : ''}
                >
                  {pea !== null ? (
                    <Share pea={pea} isMobile={isMobile} action={handleShare} />
                  ) : (
                    <></>
                  )}
                </Tab>
                <Tab
                  eventKey='watchlist'
                  title={
                    wish ? (
                      <span>
                        <FontAwesomeIcon icon={faMinus} /> Watchlist
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon icon={faPlus} /> Watchlist
                      </span>
                    )
                  }
                  tabClassName={clsx(
                    isMobile ? styles.hiddenTab : '',
                    wish && account && !account.deactivated_at ? 'active' : ''
                  )}
                />
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.RelatedPeas}>
        <Row>
          <Col md={12}>
            <h3
              className={
                styles.RelatedTitile +
                ' ' +
                (isMobile || isTablet ? '' : styles.alignLeft)
              }
            >
              Related Peas
            </h3>
          </Col>
          <Col md={12}>
            <VideoPlayer
              type='Related'
              isAuth={isAuth}
              searchPressed={false}
              afterSearchPressed={() => null}
              showHeader={false}
              peaPage={isMobile}
              callFrom='peaVideo'
              currentVideo={pea}
            />
          </Col>
          <div className={styles.BlurPeasRelated}>
            <div className={styles.BlurWrapper}>
              <Button
                buttonStyle={styles.LoadMoreButton}
                disabled={!hasMore}
                onClick={getMoreStories}
                loading={peaLoading}
              >
                Load More
              </Button>
            </div>
          </div>
        </Row>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  comments: state.stories.comments,
  series: state.stories.series,
  account: state.auth.account,
  hasMore: state.stories.hasMore,
  peaLoading: state.stories.loading,
  peaVideoPage: state.stories.peaVideoPage,
  isSeriesOnly: state.searchBox.isSeriesOnly,
  seriesOnlyPage: state.stories.seriesOnlyPage,
});

const mapDispatchToProps = (dispatch) => ({
  likePea: ({ peaId, userId, peaVideo, selectedLabel, callFrom }) =>
    dispatch(
      actions.likePea({ peaId, userId, peaVideo, selectedLabel, callFrom })
    ),
  watchlistPea: ({ pea, watch, userId }) =>
    dispatch(actions.watchlistPea({ pea, watch, userId })),
  retrieveSeries: (id) => dispatch(actions.retrieveSeries(id)),
  followCompany: ({ company }) => dispatch(actions.followCompany({ company })),
  unfollowCompany: ({ followingItem }) =>
    dispatch(actions.unfollowCompany({ followingItem })),
  setShowLoginModal: (b) => dispatch(actions.setShowLoginModal(b)),
  updatePea: ({ id, labels, applyLabels, shares }) =>
    dispatch(actions.updatePea({ id, labels, applyLabels, shares })),
  getStories: (
    page,
    company,
    callFrom,
    account_type,
    isSeriesOnly,
    seriesOnlyPage
  ) =>
    dispatch(
      actions.getStories(
        page,
        company,
        callFrom,
        account_type,
        isSeriesOnly,
        seriesOnlyPage
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeaVideoPage);

function humanized_time_span(date, ref_date, date_formats, time_units) {
  //Date Formats must be be ordered smallest -> largest and must end in a format with ceiling of null
  date_formats = date_formats || {
    past: [
      { ceiling: 60, text: '$seconds seconds ago' },
      { ceiling: 3600, text: '$minutes minutes ago' },
      { ceiling: 86400, text: '$hours hours ago' },
      { ceiling: 2629744, text: '$days days ago' },
      { ceiling: 31556926, text: '$months months ago' },
      { ceiling: null, text: '$years years ago' },
    ],
    future: [
      { ceiling: 60, text: 'in $seconds seconds' },
      { ceiling: 3600, text: 'in $minutes minutes' },
      { ceiling: 86400, text: 'in $hours hours' },
      { ceiling: 2629744, text: 'in $days days' },
      { ceiling: 31556926, text: 'in $months months' },
      { ceiling: null, text: 'in $years years' },
    ],
  };
  //Time units must be be ordered largest -> smallest
  time_units = time_units || [
    [31556926, 'years'],
    [2629744, 'months'],
    [86400, 'days'],
    [3600, 'hours'],
    [60, 'minutes'],
    [1, 'seconds'],
  ];

  date = new Date(date);
  ref_date = ref_date ? new Date(ref_date) : new Date();
  var seconds_difference = (ref_date - date) / 1000;

  var tense = 'past';
  if (seconds_difference < 0) {
    tense = 'future';
    seconds_difference = 0 - seconds_difference;
  }

  function get_format() {
    for (var i = 0; i < date_formats[tense].length; i++) {
      if (
        date_formats[tense][i].ceiling == null ||
        seconds_difference <= date_formats[tense][i].ceiling
      ) {
        return date_formats[tense][i];
      }
    }
    return null;
  }

  function get_time_breakdown() {
    var seconds = seconds_difference;
    var breakdown = {};
    for (var i = 0; i < time_units.length; i++) {
      var occurences_of_unit = Math.floor(seconds / time_units[i][0]);
      seconds = seconds - time_units[i][0] * occurences_of_unit;
      breakdown[time_units[i][1]] = occurences_of_unit;
    }
    return breakdown;
  }

  function render_date(date_format) {
    var breakdown = get_time_breakdown();
    var time_ago_text = date_format.text.replace(/\$(\w+)/g, function () {
      return breakdown[arguments[1]];
    });
    return depluralize_time_ago_text(time_ago_text, breakdown);
  }

  function depluralize_time_ago_text(time_ago_text, breakdown) {
    for (var i in breakdown) {
      if (breakdown[i] === 1) {
        var regexp = new RegExp('\\b' + i + '\\b');
        time_ago_text = time_ago_text.replace(regexp, function () {
          return arguments[0].replace(/s\b/g, '');
        });
      }
    }
    return time_ago_text;
  }

  return render_date(get_format());
}
