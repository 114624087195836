import React, { useRef, useState } from "react"
import Carousel from "react-bootstrap/Carousel"
import { useMediaQuery } from "react-responsive"
import swipeIcon from "../../icons/swipe.png"
import styles from "./VideoPlayer.module.scss"

function VideoCarousel({ videoList, carouselKey }) {
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1199px)" })

  const carouselRef = useRef(null)
  const [isCarouselHovered, setIsCarouselHovered] = useState(false)

  return (
    <Carousel
      interval={null}
      controls={!isMobileOrTablet}
      ref={carouselRef}
      onMouseEnter={(event) => {
        setIsCarouselHovered(true)
      }}
      onMouseLeave={(event) => {
        setIsCarouselHovered(false)
      }}
    >
      {videoList.map((video, index) => (
        <Carousel.Item key={carouselKey + "_" + index}>
          {isMobileOrTablet ? (
            <img className={styles.swipeIcon} src={swipeIcon} alt={""} />
          ) : null}
          {React.cloneElement(video, {
            hoveringCarousel: isCarouselHovered,
            isCarousel: true,
          })}
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default VideoCarousel
