import {useEffect, useState} from 'react';

const getHeight = () => window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;


export function useCurrentHeight() {
  let [height, setHeight] = useState(getHeight());
  let [width, setWidth] = useState(getWidth());
  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setHeight(getHeight());
        setWidth(getWidth())
      }, 150);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  return {height, width};
}
