import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { Col, Image, Row } from "react-bootstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share"
import Email from "../../../icons/peapage/email.svg"
import Copy from "../../../icons/peapage/copylink.svg"
import { shareToLinkedin } from "../../../utils/utils"
import Twitter from "../../../icons/peapage/twitter.svg"
import FaceBook from "../../../icons/peapage/facebook.svg"
import Linkedin from "../../../icons/peapage/linkedin.svg"
import WhatsApp from "../../../icons/peapage/whatsapp.svg"
import Pinterest from "../../../icons/peapage/pinterest.svg"
import styles from "../PeaVideo.module.scss"
import { connect } from "react-redux"
import { LinkedIn } from "react-linkedin-login-oauth2"
import { generateAccessToken, getMemberProfile } from "../../../utils/linkedin"
import store from "../../../store/store"
import { toast } from "react-toastify"

const Share = ({ pea, isMobile, action, account, showOAuthModal, checkOAuthModal }) => {
  const [URL, setUrl] = useState(null)
  const [showAuthModal, setShowAuthModal] = useState(showOAuthModal)
  const [pinterestDescription, setPinterestDescription] = useState('');

  useEffect(() => {
    if (!window.location.href.includes("/pea/")) {
      setUrl(`${window.location.href}pea/${pea.id}`)
    } else {
      setUrl(window.location.href)
    }
  }, [pea])

  useEffect(() => {
    const description = `Snack on this Tiny Break..\nYourTinyBreaks\nwww.peabox.tv\n${pea.title}\n${URL}`;
    setPinterestDescription(description)

  }, [pea, URL])

  useEffect(()=> {
    setShowAuthModal(showOAuthModal)
  }, [showOAuthModal])


  const handleLinkedinShare = async () => {
    if ((account && account.linkedin_id && account.linkedin_access_token) || checkOAuthModal) {
      const result = await shareToLinkedin(
        "Snack on this Tiny Break..\nYourTinyBreaks\nwww.peabox.tv\n" +
        pea.title +
        "\n" +
        URL,
        account.id
      )
    }
  }
  const responseLinkedin = async (res) => {
    let success = res

    if (success) {
      const resAccessToken = await generateAccessToken({
        auth_code: res,
        redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
        client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
        client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      })
      if (resAccessToken) {
        const resMemberInfo = await getMemberProfile(resAccessToken?.token, account.id)
        if(resMemberInfo.id){
          store.dispatch({type: 'CHECK_OAUTH_MODAL', payload: true })
          store.dispatch({type: 'SHOW_OAUTH_MODAL', payload: true })  
          toast.success("Linkedin account added successfully.")
        }

      }
    }
  }

  return (
    <Row>
      <Col
        onClick={action}
        md={4}
        xs={isMobile ? 6 : 4}
        className={isMobile ? styles.PopoverShareButtons : styles.ShareButtons}
      >
        <FacebookShareButton
          url={URL}
          quote={
            "Snack on this Tiny Break..\nYourTinyBreaks\nwww.peabox.tv" +
            pea.title
          }
          ogMeta={true}
        >
          <Image src={FaceBook} /> Facebook
        </FacebookShareButton>
      </Col>
      <Col
        onClick={action}
        md={4}
        xs={isMobile ? 6 : 4}
        className={clsx(
          isMobile ? styles.PopoverShareButtons : styles.ShareButtons
        )}
      >
        <TwitterShareButton
          url={"\n" + URL}
          title={
            "Snack on this Tiny Break..\nYourTinyBreaks\nwww.peabox.tv\n" +
            pea.title
          }
        >
          <Image src={Twitter} /> Twitter
        </TwitterShareButton>
      </Col>
      <Col
        onClick={action}
        md={4}
        xs={isMobile ? 6 : 4}
        className={clsx(
          isMobile ? styles.PopoverShareButtons : styles.ShareButtons
        )}
      >

        {
          (showAuthModal && account && account.linkedin_id && account.linkedin_access_token) ||  (showAuthModal && checkOAuthModal) ?
            <div style={{ cursor: "pointer" }} onClick={handleLinkedinShare}>
              <Image src={Linkedin} /> LinkedIn
            </div>
            :
            <div onClick={(event)=> {
              event.stopPropagation()
              }}>
            <LinkedIn
              scope="r_liteprofile r_emailaddress w_member_social"
              clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
              redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URI}
              // onError={responseLinkedin}
              onSuccess={responseLinkedin}
            >
              {({ linkedInLogin }) => (
                < div
                  style={{ cursor: "pointer" }}
                  onClick={linkedInLogin}
                >
                  <Image src={Linkedin} /> LinkedIn
                </div>
              )}
            </LinkedIn>
           </div>
        }

      </Col>
      <Col
        onClick={action}
        md={4}
        xs={isMobile ? 6 : 4}
        className={clsx(
          isMobile ? styles.PopoverShareButtons : styles.ShareButtons
        )}
      >
        <a 
            data-pin-do="buttonBookmark" 
            data-pin-lang="en"
            href={`https://www.pinterest.com/pin/create/button/?url=${pea.image}&medial=${pea.image}&description=${encodeURIComponent(pinterestDescription)}`} 
            style={{textDecoration:"none", color:"inherit"}}
        >
          <Image src={Pinterest} /> Pinterest
        </a>
      </Col>
      <Col
        onClick={action}
        md={4}
        xs={isMobile ? 6 : 4}
        className={clsx(
          isMobile ? styles.PopoverShareButtons : styles.ShareButtons
        )}
      >
        <WhatsappShareButton
          url={"\n" + URL}
          title={
            "Snack on this Tiny Break..\nYourTinyBreaks\nwww.peabox.tv\n" +
            pea.title
          }
        >
          <Image src={WhatsApp} />
          WhatsApp
        </WhatsappShareButton>
      </Col>
      <Col
        md={4}
        xs={isMobile ? 6 : 4}
        className={clsx(
          isMobile ? styles.PopoverShareButtons : styles.ShareButtons
        )}
      >
        <CopyToClipboard text={URL} onCopy={() => alert("The link was copied")}>
          <span
            style={{
              cursor: "pointer",
            }}
          >
            <Image src={Copy} /> Copy link
          </span>
        </CopyToClipboard>
      </Col>
      <Col
        onClick={action}
        md={4}
        xs={isMobile ? 6 : 4}
        className={clsx(
          isMobile ? styles.PopoverShareButtons : styles.ShareButtons
        )}
      >
        <EmailShareButton
          subject={pea.title}
          url={"\n" + URL}
          body={
            "Snack on this Tiny Break..\nYourTinyBreaks\nwww.peabox.tv\n" +
            pea.title
          }
        >
          <Image src={Email} /> via E-mail
        </EmailShareButton>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  account: state.auth.account,
  showOAuthModal: state.auth.showOAuthModal,
  checkOAuthModal: state.auth.checkOAuthModal,
})

export default connect(
  mapStateToProps,
)(React.memo(Share))
