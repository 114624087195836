import React from "react"
import { connect } from "react-redux"
import { useMediaQuery } from "react-responsive"
import { Link } from "react-router-dom"
import DropdownItem from "./DropdownItem/DropdownItem"
import FacebookIcon from "../../../icons/facebook-icon.png"
import TwitterIcon from "../../../icons/twitter-icon.png"
import InstagramIcon from "../../../icons/instagram-icon.png"
import SpotifyIcon from "../../../icons/spotify-icon.svg"
import HamburgerIcon from "../../../icons/burger.svg"
import { ReactComponent as CloseIcon } from "../../../icons/close_icon.svg"
import { ReactComponent as MenuDotsIcon } from "../../../icons/dots.svg"
import styles from "./Dropdown.module.scss"

const Dropdown = ({
  items,
  showDropdown,
  setShowDropdown,
  isHomeVisited,
  showComponent,
  children,
  setShowLoginModal,
  account,
}) => {
  const isMobile = useMediaQuery({
    query: "only screen and (max-width: 1000px)",
  })

  if (!showComponent) {
    return <></>
  }

  return (
    <div className={styles.Dropdown}>
      {showDropdown && (
        <div
          style={{
            zIndex: 1,
          }}
          className={styles.Backdrop}
          onClick={() => setShowDropdown(false)}
        />
      )}
      <div className={styles.OpenButton}>
        <button
          className={styles.MenuButton}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {showDropdown ? (
            <CloseIcon className={styles.CloseIcon} />
          ) : isMobile ? (
            <img className={styles.HamburguerIcon} src={HamburgerIcon} alt="" />
          ) : (
            <MenuDotsIcon className={styles.MenuDots} />
          )}
        </button>
        {showDropdown && (
          <div
            className={`${styles.DropdownItems} ${
              !isHomeVisited ? styles.DropdownItemsHome : ""
            }`}
          >
            {children}
            {items.map((item) => (
              <DropdownItem
                key={item.label}
                item={item}
                setShowDropdown={setShowDropdown}
                account={account}
                setShowLoginModal={setShowLoginModal}
              />
            ))}
            <div className={styles.SocialMediaIcons}>
            <Link to={{ pathname: "https://facebook.com/" }} target="_blank">
                <img
                  alt="facebook"
                  src={FacebookIcon}
                  className={styles.MediaIcon}
                />
              </Link>
              <Link to={{ pathname: "https://twitter.com/" }} target="_blank">
                <img
                  alt="twitter"
                  src={TwitterIcon}
                  className={styles.MediaIcon}
                />
              </Link>
              <Link to={{ pathname: "https://instagram.com/" }} target="_blank">
                <img
                  alt="instagram"
                  src={InstagramIcon}
                  className={styles.MediaIcon}
                />
              </Link>
              <Link to={{ pathname: "https://spotify.com/" }} target="_blank">
                <img
                  alt="spotify"
                  src={SpotifyIcon}
                  className={styles.MediaIcon}
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isHomeVisited: state.searchBox.isHomeVisited,
  account: state.auth.account,
})

export default connect(mapStateToProps)(Dropdown)
