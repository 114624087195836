import React, { useCallback, useState } from "react"
import classNames from "classnames"
import Card from "../Card/Card"
import styles from "./Tooltip.module.scss"
import "./Tooltip.scss"

const Tooltip = ({ text, tooltipStyles, children }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [isOverlapping, setIsOverlapping] = useState(false)

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      const clientRect = domNode.getBoundingClientRect()
      const viewportWidth = document.documentElement.clientWidth

      if (clientRect.right > viewportWidth) {
        setIsOverlapping(true)
      }
    }
  }, [])

  return (
    <div
      className={styles.TooltipWrapper}
    >
      <span 
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      >
      {children}
      </span>
      {showTooltip && (
        <Card
          cardRef={callBackRef}
          cardStyles={classNames(
            styles.Tooltip,
            tooltipStyles,
            isOverlapping && styles.OverlappingTooltip
          )}
        >
          {text && /<\/?[a-z][\s\S]*>/i.test(text) ? (
            <div
              className={styles.Text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : (
            <p className={styles.Text}>{text}</p>
          )}
        </Card>
      )}
    </div>
  )
}

export default Tooltip

export const Tooltip2 = ({ text, tooltipStyles, children }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [isOverlapping, setIsOverlapping] = useState(false)

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      const clientRect = domNode.getBoundingClientRect()
      const viewportWidth = document.documentElement.clientWidth

      if (clientRect.right > viewportWidth) {
        setIsOverlapping(true)
      }
    }
  }, [])

  return (
    <div
      className={styles.TooltipWrapper}
      onClick={() => setShowTooltip(!showTooltip)}
      onBlur={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <Card
          cardRef={callBackRef}
          cardStyles={classNames(
            styles.Tooltip,
            tooltipStyles,
            isOverlapping && styles.OverlappingTooltip
          )}
        >
          {text && /<\/?[a-z][\s\S]*>/i.test(text) ? (
            <div
              className={styles.Text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : (
            <p className={styles.Text}>{text}</p>
          )}
        </Card>
      )}
    </div>
  )
}
