import axios from "../../utils/axiosConfig"
import { RETRIEVE_PEA_SUCCESS } from "../types"
import { tokenConfig } from "./authActions"

export const retrievePea = (pea) => async (dispatch) => {
  await axios
    .get(`/peas/pea/${pea}`)
    .then((res) => {
      dispatch({
        type: RETRIEVE_PEA_SUCCESS,
        payload: res.data,
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
