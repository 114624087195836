import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import PeaVideoContainer from '../PeaVideoPage/PeaVideoContainer';
import styles from './Gallery.module.scss';

const Gallery = ({
  searchValue,
  selectedFilter,
  sortByValue,
  isAuth,
  searchKeyPressed,
  setSearchKeyPressed,
  showHeader = true,
  showPeaPageModal,
  interest,
}) => {
  const videosFilter = (videos) => {
    let filteredVideos = videos;

    const interests = {
      channels: interest.categories,
      companies: interest.companies,
      tags: interest.tags
        .filter((tag) => tag.includes('Tag'))
        .map((tag) => tag.split('-Tag')[0]),
      desiredEffect: interest.tags
        .filter((tag) => tag.includes('Desired-Effect'))
        .map((tag) => tag.split('-Desired-Effect')[0]),
    };

    if (
      interests.channels.length ||
      interests.companies.length ||
      interests.tags.length ||
      interests.desiredEffect.length
    ) {
      filteredVideos = filteredVideos.filter(
        (video) =>
          interest.companies.includes(video.company) ||
          video.labels.some((l) =>
            interests.channels
              .map((_) => _)
              .includes(l.split('-TV Channels')[0])
          ) ||
          video.tags.some((t) => interests.tags.map((_) => _).includes(t)) ||
          video.labels.some((l) =>
            interests.desiredEffect
              .map((_) => _)
              .includes(l.split('-Target Responses')[0])
          )
      );
    } else {
      filteredVideos = videos;
    }

    return filteredVideos;
  };

  return (
    <div className={showHeader ? styles.Gallery : styles.GalleryNoHeader}>
      {showPeaPageModal && <PeaVideoContainer />}

      <VideoPlayer
        type='Gallery'
        searchValue={searchValue}
        selectedFilter={selectedFilter}
        sort={sortByValue}
        isAuth={isAuth}
        filter={videosFilter}
        searchPressed={searchKeyPressed}
        afterSearchPressed={() => setSearchKeyPressed(false)}
        showHeader={showHeader}
        callFrom='home'
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchValue: state.searchBox.searchValue,
    touched: state.searchBox.touched,
    selectedFilter: state.searchBox.selectedFilter,
    sortByValue: state.searchBox.sortByValue,
    isAuth: state.auth.isAuthenticated,
    searchKeyPressed: state.searchBox.searchKeyPressed,
    interest: state.auth.interest,
    showPeaPageModal: state.peaPageModal.showPeaPageModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTouched: (touched) => dispatch(actions.setTouched(touched)),
    setSearchKeyPressed: (value) =>
      dispatch(actions.setSearchKeyPressed(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
