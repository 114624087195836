import {SET_SHOW_SIGNUP_MODAL, SET_SHOW_LOGIN_MODAL} from '../types';


const DEFAULT_STATE = {
  showLoginModal: false,
  showSignUpModal: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case SET_SHOW_SIGNUP_MODAL: {
      return {...state, showSignUpModal: action.payload}
    }
    case SET_SHOW_LOGIN_MODAL: {
      return {...state, showLoginModal: action.payload}
    }

    default:
      return state;
  }
};
