import { toast } from "react-toastify"
import axios from "../../utils/axiosConfig"
import { modifyArray } from "../../utils/utils"
import { getMemberInfo, registerLinkedInAccount } from "../../utils/linkedin"
import {
  AUTH_END,
  AUTH_FAIL,
  AUTH_SIGN_OUT,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_SUCCESS_MSG,
  CLEAR_AUTH_MESSAGE,
  DEACTIVATE_ACCOUNT,
  REACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  DELETE_COMPANY,
  DELETE_COMPANY_ACCOUNT_START,
  DELETE_COMPANY_ACCOUNT_SUCCESS,
  DELETE_COMPANY_ACCOUNT_FAIL,
  FOLLOW_COMPANY,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  GET_FOLLOW_ITEMS_SUCCESS,
  GET_INTEREST_FAIL,
  GET_INTEREST_START,
  GET_INTEREST_SUCCESS,
  LOAD_ACCOUNT_FAIL,
  LOAD_ACCOUNT_START,
  LOAD_ACCOUNT_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  CLEAR_RESET_PASSWORD_ERROR,
  RETRIEVE_BUSINESS_ACCOUNT_FAIL,
  RETRIEVE_BUSINESS_ACCOUNT_START,
  RETRIEVE_BUSINESS_ACCOUNT_SUCCESS,
  RETRIEVE_COMPANY_ACCOUNT_FAIL,
  RETRIEVE_COMPANY_ACCOUNT_START,
  RETRIEVE_COMPANY_ACCOUNT_SUCCESS,
  SEND_INVITATIONS_FAIL,
  SEND_INVITATIONS_START,
  SEND_INVITATIONS_SUCCESS,
  UNFOLLOW_COMPANY,
  UPDATE_ACCOUNT_FAIL,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_FAIL,
  UPDATE_BUSINESS_ACCOUNT_START,
  UPDATE_BUSINESS_ACCOUNT_SUCCESS,
  UPDATE_COMPANY_ACCOUNT_FAIL,
  UPDATE_COMPANY_ACCOUNT_START,
  UPDATE_COMPANY_ACCOUNT_SUCCESS,
  UPDATE_INTEREST,
  VERIFY_EMAIL_FAIL,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  IS_LINKEDIN,
  CLEAR_ACCOUNT_FAIL_MSG,
  HIDE_COMPANY_PEAS_START,
  HIDE_COMPANY_PEAS_SUCCESS,
  HIDE_COMPANY_PEAS_FAIL,
  UNHIDE_COMPANY_PEAS_START,
  UNHIDE_COMPANY_PEAS_SUCCESS,
  CLEAR_FORGOT_PASSWORD_MESSAGES,
  IS_MOUNTED_POD,
} from "../types"

export const clearAccountUpdateErrorMsg = () => (dispatch) => {
  dispatch({ type: CLEAR_ACCOUNT_FAIL_MSG })
}

export const setAuthentication = (history) => async (dispatch, getState) => {
  dispatch({ type: LOAD_ACCOUNT_START })

  axios
    .get("/accounts/load-account", tokenConfig(getState))
    .then((res) => dispatch({ type: LOAD_ACCOUNT_SUCCESS, payload: res.data }))
    .catch(() => {
      dispatch({ type: LOAD_ACCOUNT_FAIL })
      localStorage.removeItem("token")
      history.push("")
    })
}

export const signInLinkedInResult = (success) => (dispatch) => {
  if (success) {
    sessionStorage.setItem("linkedin-code", success)
    // dispatch({ type: AUTH_SUCCESS, payload: success })
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: "Could not authenticate with Linked in.",
    })
  }
}

export const signUp =
  ({ email, password, companyRole = null, company = null }) =>
  async (dispatch) => {
    dispatch({ type: AUTH_START })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    let accountType = 1

    if (companyRole) {
      accountType = 2
    }

    const body = JSON.stringify({
      email,
      account_type: accountType,
      password,
      company_role: companyRole && companyRole.value,
      company: company && company,
    })

    let isSuccess = false

    await axios
      .post("/accounts/register", body, config)
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          dispatch({ type: AUTH_SUCCESS_MSG, payload: res.data.message })
          dispatch({ type: AUTH_FAIL, payload: "" })
          isSuccess = true
        }

        dispatch({ type: AUTH_END })
      })
      .catch((err) => {
        let msg = "Something went wrong. Please try again."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              if (
                typeof err.response.data[k] === "object" &&
                Object.keys(err.response.data[k]).length > 0
              ) {
                for (let l in err.response.data[k]) {
                  if (accountType == 2) {
                    e.push(
                      `${err.response.data[k][l]}`.charAt(0).toUpperCase() +
                        `${err.response.data[k][l]}`.slice(1)
                    )
                  } else {
                    e.push(
                      `${err.response.data[k][l]}`.charAt(0).toUpperCase() +
                        `${err.response.data[k][l]}`.slice(1)
                    )
                  }
                }
              }
            }
            msg = e.join(" | ")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        dispatch({ type: AUTH_SUCCESS_MSG, payload: "" })
        dispatch({ type: AUTH_FAIL, payload: msg })
        dispatch({ type: AUTH_END })
        isSuccess = false
      })

    return isSuccess
  }

export const signOut = (history) => (dispatch, getState) => {
  if (getState().auth?.token) {
    axios
      .post("/accounts/logout", null, tokenConfig(getState))
      .then(() => {
        dispatch({ type: AUTH_SIGN_OUT })
        localStorage.removeItem("token")
        history.push("")

        if(window.Beamer){
          window.Beamer.destroy()
        }
        dispatch({ type: IS_MOUNTED_POD, payload: false });
      })
      .catch((err) => console.log(err))
  }
}

export const signIn =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: AUTH_START })
    dispatch({ type: IS_MOUNTED_POD, payload: false });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    const body = JSON.stringify({
      email,
      password,
    })

    let response = { isSuccess: false, account: null }

    await axios
      .post("/accounts/login", body, config)
      .then((res) => {
        dispatch({ type: AUTH_SUCCESS, payload: res.data })
        dispatch({ type: AUTH_END })
        response = { isSuccess: true, account: res.data.account }
      })
      .catch((err) => {
        console.log(err)
        let msg = "Something went wrong. Please try again."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              e.push(
                `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
              )
            }
            msg = e.join(" | ")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        dispatch({ type: AUTH_FAIL, payload: msg })
        dispatch({ type: AUTH_END })
        response = { isSuccess: false, account: null }
      })

    return response
  }

export const clearAuthMessage = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AUTH_MESSAGE,
    })
  }
}

export const updateAccount =
  (
    id,
    firstName = null,
    lastName = null,
    username = null,
    accountImage,
    email = null,
    password = null
  ) =>
  async (dispatch, getState) => {
    let endOfCall = false
    dispatch({ type: UPDATE_ACCOUNT_START })

    let formData = new FormData()
    if (firstName) {
      formData.set("first_name", firstName)
    }

    if (lastName) {
      formData.set("last_name", lastName)
    }

    if (username) {
      formData.set("username", username)
    }

    if (accountImage) {
      formData.append("account_image", accountImage, accountImage.name)
    }

    if (email) {
      formData.set("email", email)
    }

    if (password) {
      formData.set("password", password)
    }

    await axios
      .patch(`/accounts/account/${id}/`, formData, tokenConfig(getState))
      .then((res) => {
        endOfCall = true
        let toastMsg = "Information has been successfully updated."

        dispatch({
          type: UPDATE_ACCOUNT_SUCCESS,
          payload: res.data.data,
          hasSentMail: res.data.sentMail,
        })
        if (
          firstName ||
          lastName ||
          username ||
          (accountImage && !email && !password)
        ) {
          toastMsg = "Profile has been successfully updated."
        } else if (
          email ||
          (password && !firstName && !lastName && !username && !accountImage)
        ) {
          toastMsg = "Account settings have been updated successfully."
        }
        toast.success(toastMsg)
      })
      .catch((err) => {
        let msg = "Something went wrong. Please try again."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            msg = err.response.data
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        dispatch({ type: UPDATE_ACCOUNT_FAIL, payload: msg })
      })
    return endOfCall
  }

export const reactivateAccount = () => async (dispatch, getState) => {
  let endOfCall = false
  console.log(tokenConfig(getState))
  console.log(getState())
  await axios
    .post("/accounts/reactivate", null, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: REACTIVATE_ACCOUNT, payload: res.data.data })
      toast.success(res.data.message)
      endOfCall = true
    })
    .catch((err) => {
      console.log(err)
    })
  return endOfCall
}

export const deactivateAccount = () => async (dispatch, getState) => {
  let endOfCall = false
  await axios
    .post("/accounts/deactivate", null, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DEACTIVATE_ACCOUNT, payload: res.data.data })
      toast.success(res.data.message)
      endOfCall = true
    })
    .catch((err) => {
      console.log(err)
      let msg = "Something went wrong. Please try again."
      if (err.response && err.response.data) {
        if (
          typeof err.response.data === "object" &&
          Object.keys(err.response.data).length > 0
        ) {
          let e = []
          for (let k in err.response.data) {
            e.push(
              `${err.response.data[k]}`.charAt(0).toUpperCase() +
                `${err.response.data[k]}`.slice(1)
            )
          }
          msg = e.join(" | ")
        } else if (typeof err.response.data === "string") {
          msg = err.response.data
        }
      }
      toast.error(msg)
    })
  return endOfCall
}

export const deleteAccount =
  (id, isCompanyMemberPage = false) =>
  async (dispatch, getState) => {
    if (isCompanyMemberPage) {
      dispatch({ type: DELETE_COMPANY_ACCOUNT_START })
    }

    let isDeleted = false
    await axios
      .delete(`/accounts/account/?account=${id}`, tokenConfig(getState))
      .then(() => {
        if (isCompanyMemberPage) {
          dispatch({ type: DELETE_COMPANY_ACCOUNT_SUCCESS, payload: id })
          toast.success("Company Studio Members updated successfully")
        } else {
          dispatch({ type: DELETE_ACCOUNT })
          toast.success("Account has been deleted successfully!")
        }
        isDeleted = true
      })
      .catch((err) => {
        if (isCompanyMemberPage) {
          dispatch({ type: DELETE_COMPANY_ACCOUNT_FAIL })
        }

        let msg = "Something went wrong. Please try again."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              e.push(
                `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
              )
            }
            msg = e.join(" | ")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        toast.error(msg)
      })
    return isDeleted
  }

export const deleteCompany = (id, history) => async (dispatch, getState) => {
  await axios
    .delete(`/accounts/company/?company=${id}`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: DELETE_COMPANY })
      toast.success("Your account has been deleted successfully.")
      localStorage.removeItem("token")
      history.push("")
    })
    .catch((err) => {
      let msg = "Something went wrong. Please try again."
      if (err.response && err.response.data) {
        if (
          typeof err.response.data === "object" &&
          Object.keys(err.response.data).length > 0
        ) {
          let e = []
          for (let k in err.response.data) {
            e.push(
              `${err.response.data[k]}`.charAt(0).toUpperCase() +
                `${err.response.data[k]}`.slice(1)
            )
          }
          msg = e.join(" | ")
        } else if (typeof err.response.data === "string") {
          msg = err.response.data
        }
      }
      toast.error(msg)
    })
}

export const hideCompanyPeas = (companyId) => async (dispatch, getState) => {
  dispatch({ type: HIDE_COMPANY_PEAS_START })
  await axios
    .patch(`/accounts/company/${companyId}/hide_peas/`, tokenConfig(getState))
    .then((response) => {
      dispatch({ type: HIDE_COMPANY_PEAS_SUCCESS, payload: response.data })
      toast.success("Account settings have been updated successfully.")
    })
    .catch((err) => {
      dispatch({ type: HIDE_COMPANY_PEAS_FAIL })

      let msg = "Something went wrong. Please try again."
      if (err.response && err.response.data) {
        if (
          typeof err.response.data === "object" &&
          Object.keys(err.response.data).length > 0
        ) {
          let e = []
          for (let k in err.response.data) {
            e.push(
              `${err.response.data[k]}`.charAt(0).toUpperCase() +
                `${err.response.data[k]}`.slice(1)
            )
          }
          msg = e.join(" | ")
        } else if (typeof err.response.data === "string") {
          msg = err.response.data
        }
      }
      toast.error(msg)
    })
}

export const unHideCompanyPeas = (companyId) => async (dispatch, getState) => {
  let isHidden = false
  dispatch({ type: UNHIDE_COMPANY_PEAS_START })

  await axios
    .patch(`/accounts/company/${companyId}/unhide_peas/`, tokenConfig(getState))
    .then((response) => {
      isHidden = true
      dispatch({ type: UNHIDE_COMPANY_PEAS_SUCCESS, payload: response.data })
      toast.success("Account settings have been updated successfully.")
    })
    .catch((err) => {
      dispatch({ type: UNHIDE_COMPANY_PEAS_SUCCESS })
      let msg = "Something went wrong. Please try again."
      if (err.response && err.response.data) {
        if (
          typeof err.response.data === "object" &&
          Object.keys(err.response.data).length > 0
        ) {
          let e = []
          for (let k in err.response.data) {
            e.push(
              `${err.response.data[k]}`.charAt(0).toUpperCase() +
                `${err.response.data[k]}`.slice(1)
            )
          }
          msg = e.join(" | ")
        } else if (typeof err.response.data === "string") {
          msg = err.response.data
        }
      }
      toast.error(msg)
    })

  return isHidden
}

export const retrieveCompanyAccount = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_COMPANY_ACCOUNT_START })

  await axios
    .get(`/accounts/company/${id}`, tokenConfig(getState))
    .then((res) =>
      dispatch({ type: RETRIEVE_COMPANY_ACCOUNT_SUCCESS, payload: res.data })
    )
    .catch((err) => {
      console.log(err)
      dispatch({ type: RETRIEVE_COMPANY_ACCOUNT_FAIL })
    })
}

export const retrieveBusinessAccount = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_BUSINESS_ACCOUNT_START })

  await axios
    .get(`/accounts/business/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_BUSINESS_ACCOUNT_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      dispatch({ type: RETRIEVE_BUSINESS_ACCOUNT_FAIL })
    })
}

export const updateBusinessAccount =
  (companyRole, email, password, image, id) => async (dispatch, getState) => {
    let endOfCall = false

    dispatch({ type: UPDATE_BUSINESS_ACCOUNT_START })
    let formData = new FormData()
    formData.set("company_role", companyRole)
    formData.set("email", email)

    if (password) {
      formData.set("password", password)
    }

    if (image) {
      formData.append("account_image", image, image.name)
    }

    await axios
      .patch(`/accounts/business/${id}/`, formData, tokenConfig(getState))
      .then((res) => {
        endOfCall = true
        dispatch({ type: UPDATE_BUSINESS_ACCOUNT_SUCCESS, payload: res.data })
        toast.success("Account settings have been updated successfully.")
      })
      .catch((err) => {
        let msg = "Something went wrong. Please try again."
        if (
          err &&
          err.response &&
          err.response.data &&
          typeof err.response.data === "string" &&
          err.response.data.includes("already exists.")
        ) {
          msg = "Account with this email already exists."
        } else if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              e.push(
                `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
              )
            }
            msg = e.join(" | ")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        dispatch({ type: UPDATE_BUSINESS_ACCOUNT_FAIL, payload: msg })
      })

    return endOfCall
  }

export const updateCompanyAccount =
  (
    name,
    description,
    image,
    location,
    field,
    employees,
    website,
    careersWebsite,
    content,
    is_hiring,
    id
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: UPDATE_COMPANY_ACCOUNT_START })

    let formData = new FormData()
    formData.set("name", name)
    formData.set("description", description)
    if (image) {
      formData.set("image", image, image.name)
    }
    formData.set("location", location)
    formData.set("field", field)
    formData.set("employees", employees)
    formData.set("website", website)
    formData.set("careers_website", careersWebsite)
    formData.set("is_hiring", is_hiring)
    content.forEach((c) => formData.append("content", c))

    await axios
      .patch(`/accounts/company/${id}/`, formData, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: UPDATE_COMPANY_ACCOUNT_SUCCESS, payload: res.data })
        let toastMsg = "Company Profile updated successfully"
        toast.success(toastMsg)
      })
      .catch((err) => {
        let msg = "Something went wrong. Please try again."
        if (err.response && err.response.data) {
          if (err.response.data.content) {
            msg = msg
          } else if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              e.push(
                `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
              )
            }
            msg = e.join(" | ")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        toast.error(msg)
        dispatch({ type: UPDATE_COMPANY_ACCOUNT_FAIL, payload: msg })
      })
  }

export const tokenConfig = (getState) => {
  const token = getState().auth.token

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  if (token) {
    config.headers["Authorization"] = `Token ${token}`
  }

  return config
}

export const sendInvitations =
  (recipients, message, company) => async (dispatch, getState) => {
    dispatch({ type: SEND_INVITATIONS_START })

    const body = JSON.stringify({
      recipients: recipients.includes(",")
        ? recipients.split(",")
        : [recipients],
      message: message,
      company: company,
    })

    try {
      let res = await axios.post(
        "/accounts/invitations/",
        body,
        tokenConfig(getState)
      )
      toast.success(res.data)
      dispatch({ type: SEND_INVITATIONS_SUCCESS })
      return true
    } catch (err) {
      console.log(err)
      let msg = "Something went wrong. Please try again."
      if (err.response && err.response.data) {
        if (
          typeof err.response.data === "object" &&
          Object.keys(err.response.data).length > 0
        ) {
          let e = []
          for (let k in err.response.data) {
            e.push(
              `${err.response.data[k]}`.charAt(0).toUpperCase() +
                `${err.response.data[k]}`.slice(1)
            )
          }
          msg = e.join(" | ")
        } else if (typeof err.response.data === "string") {
          msg = err.response.data
        }
      }
      toast.error(msg)
      dispatch({ type: SEND_INVITATIONS_FAIL })
      return false
    }
  }

export const forgotPassword =
  ({ email }) =>
  async (dispatch) => {
    let endOfCall = false
    dispatch({ type: FORGOT_PASSWORD_START })
    const formData = new FormData()
    formData.set("email", email)

    await axios
      .post("/accounts/reset-password/", formData)
      .then((res) => {
        toast.success("Success! An email should be sent to your email")
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload:
            "Success! A verification link is sent to your email with instruction for next step.",
        })
        dispatch({
          type: FORGOT_PASSWORD_FAIL,
          payload: "",
        })
        endOfCall = true
      })
      .catch((err) => {
        dispatch({
          type: FORGOT_PASSWORD_FAIL,
          payload: "We can't find any data related to this email",
        })
        endOfCall = true
        console.log(err)
      })

    return endOfCall
  }

export const resetPassword =
  ({ password, token }) =>
  async (dispatch) => {
    let endOfCall = false
    dispatch({ type: RESET_PASSWORD_START })
    const formData = new FormData()
    formData.set("password", password)
    formData.set("token", token)

    await axios
      .post(`/accounts/reset-password/confirm/?token=${token}`, formData)
      .then((res) => {
        if (res) {
          if (res.status) {
            sessionStorage.setItem(
              "hasUpdated",
              JSON.stringify({ token, hasUpdated: true })
            )
            localStorage.setItem("isUsed", JSON.stringify({ token: token }))
            dispatch({
              type: RESET_PASSWORD_SUCCESS,
              payload: "Success! New password set!",
            })
          } else if (res.password) {
            dispatch({
              type: RESET_PASSWORD_FAIL,
              payload: res.password,
            })
            localStorage.setItem("isUsed", JSON.stringify({ token: null }))
          }
        }
      })
      .catch((err) => {
        let msg =
          "Your request for password reset has expired! Please try requesting again."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            if (err.response.data.password) {
              for (let k in err.response.data.password) {
                e.push(
                  `${err.response.data.password[k]}`.charAt(0).toUpperCase() +
                    `${err.response.data.password[k]}`.slice(1)
                )
              }
            } else {
              for (let k in err.response.data) {
                e.push(
                  `${err.response.data[k]}`.charAt(0).toUpperCase() +
                    `${err.response.data[k]}`.slice(1)
                )
              }
            }
            msg = e.join("")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }

        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload: msg,
        })
      })

    return endOfCall
  }

export const verifyEmail =
  ({ token, email }) =>
  async (dispatch) => {
    dispatch({ type: VERIFY_EMAIL_START })

    await axios
      .patch(`/accounts/verify-email/${token}/?email=${email}`)
      .then((res) => {
        dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: res.data })
      })
      .catch((err) => {
        console.log(err)
        let msg = "Something went wrong. Please try again."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              e.push(
                `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
              )
            }
            msg = e.join(" | ")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        dispatch({ type: VERIFY_EMAIL_FAIL, payload: msg })
      })
  }

export const getAccountInterest =
  ({ interestId }) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_INTEREST_START })

    await axios
      .get(`/accounts/interest/${interestId}`, getState(tokenConfig))
      .then((res) => {
        dispatch({ type: GET_INTEREST_SUCCESS, payload: res.data })
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: GET_INTEREST_FAIL })
      })
  }

export const updateInterest =
  ({ category, tag, company }) =>
  async (dispatch, getState) => {
    const { id, categories, tags, companies } = getState().auth.interest

    const newCategories = modifyArray(categories, category)
    const newTags = modifyArray(tags, tag)
    const newCompanies = modifyArray(companies, company)

    const body = JSON.stringify({
      categories: newCategories,
      tags: newTags,
      companies: newCompanies,
    })

    await axios
      .patch(`/accounts/interest/${id}/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_INTEREST,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getAccountFollowing =
  ({ accountId, page = 1 }) =>
  async (dispatch, getState) => {
    await axios
      .get(
        `/accounts/following/?account=${accountId}&page=${page}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: GET_FOLLOW_ITEMS_SUCCESS, payload: res.data.results })
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const unfollowCompany =
  ({ followingItem }) =>
  async (dispatch, getState) => {
    let isFinished = false

    await axios
      .delete(`/accounts/following/${followingItem}`, tokenConfig(getState))
      .then(() => {
        dispatch({ type: UNFOLLOW_COMPANY, payload: followingItem })
        isFinished = true
      })
      .catch((err) => {
        console.log(err)
        isFinished = true
      })

    return isFinished
  }

export const followCompany =
  ({ company }) =>
  async (dispatch, getState) => {
    let isFinished = false
    const body = JSON.stringify({
      company_id: company,
    })

    await axios
      .post("/accounts/following/", body, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: FOLLOW_COMPANY, payload: res.data })
        isFinished = true
      })
      .catch((err) => {
        console.log(err)
        let msg = "Something went wrong."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              e.push(
                `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
              )
            }
            msg = e.join(" | ")
            toast.error(msg)
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
            toast.error(msg)
          }
        }
        isFinished = true
      })

    return isFinished
  }

export const loginWithLinkedIn = (token) => async (dispatch) => {
  let resp = { isSuccess: false, account: null }
  try {
    const res = await getMemberInfo(token)
    if (res && res.profile && res.email) {
      const registerRes = await registerLinkedInAccount({
        firstName: res.profile.firstName,
        lastName: res.profile.lastName,
        email: res.email,
        password: res.profile.id,
        account_type: 1,
        linkedin_id: res.profile.id,
        linkedin_access_token: token,
        account_image:
          res.profile && res.profile.profilePic
            ? res.profile.profilePic
            : null,
      })

      if(registerRes){
        dispatch({ type: AUTH_SUCCESS, payload: registerRes?.data })
        dispatch({ type: IS_LINKEDIN, payload: true })
        resp = { isSuccess: true, account: registerRes?.data?.account }
      }
      dispatch({ type: AUTH_END })  
      resp = { isSuccess: true, account: registerRes.data.account }
    }
  } catch (error) {
    dispatch({ type: AUTH_FAIL, payload: error.message })
    dispatch({ type: IS_LINKEDIN, payload: false })
    dispatch({ type: AUTH_END })
    resp = { isSuccess: false, account: null }
  }
  return resp
}

export const signupWithLinkedIn = (token, isBusiness, company = "") => async (dispatch) => {
  let resp = { isSuccess: false, account: null }
  try {
    const res = await getMemberInfo(token)
    if (res && res.profile && res.email) {
      const registerRes = await registerLinkedInAccount({
        firstName: res.profile.firstName,
        lastName: res.profile.lastName,
        email: res.email,
        password: res.profile.id,
        account_type: isBusiness ? 2 : 1,
        linkedin_id: res.profile.id,
        linkedin_access_token: token,
        account_image:
          res.profile && res.profile.profilePic
            ? res.profile.profilePic
            : null,
        company_role: 1,
        company: company,
        isBusiness,
      })
      dispatch({ type: AUTH_SUCCESS, payload: registerRes.data })
      dispatch({ type: IS_LINKEDIN, payload: true })
      dispatch({ type: AUTH_END })
      resp = { isSuccess: true, account: registerRes.data.account }
    }
  } catch (error) {
    
    
    dispatch({ type: AUTH_FAIL, payload: error.message })
    dispatch({ type: IS_LINKEDIN, payload: false })
    dispatch({ type: AUTH_END })
    resp = { isSuccess: false, account: null }
  }
  return resp
}

export const clearResetPasswordError = () => (dispatch) => {
  dispatch({ type: CLEAR_RESET_PASSWORD_ERROR })
}

export const clearForgotPasswordMessages = () => (dispatch) => {
  dispatch({ type: CLEAR_FORGOT_PASSWORD_MESSAGES })
}
