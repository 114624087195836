import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useHistory, Link } from 'react-router-dom';
import Button from '../../components/UI/Button/Button';
import Avatar from '../../components/UI/Avatar/Avatar';
import * as actions from '../../../src/store/actions';
import styles from './PeaVideo.module.scss';

const PeaPageHeader = ({
  isFollowing,
  setIsFollowing,
  followCompany,
  unfollowCompany,
  following,
  account,
  setShowLoginModal,
  setShowPeaPageModal,
  pea,
  openFrom,
  companyId,
}) => {
  const history = useHistory();
  const [followingItem, setFollowingItem] = useState({});
  const [loading, setLoading] = useState(false);
  const isTabletDevice = useMediaQuery({
    query: '(max-width: 1200px)',
  });

  // console.log('the pea', pea);

  const followButtonHandler = async () => {
    if (account && account.deactivated_at) {
      toast.error(
        'Following or unFollowing company is not allowed for deactivated accounts.'
      );
    } else {
      if (!loading) {
        if (account && account.account_type === 'Basic') {
          setLoading(true);
          if (!isFollowing) {
            const result = await followCompany({ company: pea.company });
            setIsFollowing(true);
            if (result) {
              setLoading(false);
            }
          } else {
            const result = await unfollowCompany({
              followingItem: followingItem.id,
            });
            setIsFollowing(false);
            if (result) {
              setLoading(false);
            }
          }
        } else {
          if (!isTabletDevice) {
            setShowLoginModal(true);
          } else {
            history.push('/sign-in');
          }
        }
      }
    }
  };

  let followingItems = following.map(
    (followingItem) => followingItem.company.id
  );

  useEffect(() => {
    if (following.length > 0 && followingItems) {
      const isCompanyFollowed = followingItems.includes(pea.company);
      setIsFollowing(isCompanyFollowed);

      if (isCompanyFollowed && following.length > 0) {
        setFollowingItem(
          following.find(
            (followingItem) =>
              followingItem.company && followingItem.company.id === pea.company
          )
        );
      }
    } else {
      setIsFollowing(false);
    }
  }, [followingItems, pea.company, following]);

  if (!pea.company) return <></>;
  return (
    <div className={styles.CompanyHeader}>
      <div>
        <Link
          // to={`/${pea.company_name?pea.company_name.replaceAll(" ","").toLowerCase():pea.company}/peabox.tv`}
          to={`/${pea.company}/peabox.tv`}
          className={styles.CompanyLink}
          onClick={() => {
            setShowPeaPageModal(false, null, openFrom, companyId);
          }}
        >
          <Avatar
            className='mr-3'
            src={pea.company_image}
            alt={pea.company_name}
            size={50}
          />{' '}
          {pea.company_name}
        </Link>
      </div>
      <div className={styles.FollowButtonWrapper}>
        <Button
          btnType={isFollowing ? 'Success' : 'Follow'}
          key={isFollowing ? 'following' : 'follow'}
          onClick={followButtonHandler}
          loading={loading}
        >
          {isFollowing ? 'Following' : 'Follow'}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  following: state.following.following,
  account: state.auth.account,
  openFrom: state.peaPageModal.openFrom,
  companyId: state.peaPageModal.companyId,
});

const mapDispatchToProps = (dispatch) => ({
  followCompany: ({ company }) => dispatch(actions.followCompany({ company })),
  unfollowCompany: ({ followingItem }) =>
    dispatch(actions.unfollowCompany({ followingItem })),
  setShowLoginModal: (b) => dispatch(actions.setShowLoginModal(b)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeaPageHeader);
