import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import "jquery/dist/jquery.min.js"
import "popper.js/dist/popper.js"
import "bootstrap/dist/js/bootstrap.min.js"
import "lazysizes"
import "./scss/toastify.scss"
import styles from "./App.module.scss"
import "./App.scss"
import loadable from "@loadable/component"
import { connect } from "react-redux"
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom"
import Layout from "./hoc/Layout/Layout"
import PrivateRoute from "./hoc/PrivateRoute/PrivateRoute"
import PBCookiesMessage from "./components/PBCookiesMessage/PBCookiesMessage"
import Home from "./containers/Home/Home"
import Login from "./containers/Auth/Login"
import Signup from "./containers/Auth/Signup"
import { LinkedInCallback } from "react-linkedin-login-oauth2"
import {
  setAuthentication,
  setIsHomeVisited,
  setUploadPeaModal,
} from "./store/actions"
import { wsContext, useWebSocketLite as useWebSocket } from "./utils/websocket"
import { watchCommentNotification } from "./utils/notification"
import BusinessLandingPage from "./containers/BusinessLandingPage/BusinessLandingPage"
import PageLoader from "./components/PageLoader"

const ResetPassword = loadable(() =>
  import("./containers/Auth/ResetPassword/ResetPassword")
)              
const VerifyEmail = loadable(() =>
  import("./containers/Auth/VerifyEmail/VerifyEmail")
)
const Interests = loadable(() => import("./containers/Interests/Interests"))
const Following = loadable(() => import("./containers/Following/Following"))
const Watchlist = loadable(() => import("./containers/Watchlist/Watchlist"))
const Company = loadable(() => import("./containers/Company/Company"))
const PeaVideoContainer = loadable(() =>
  import("./containers/PeaVideoPage/PeaVideoContainer")
)
const PeaEmbed = loadable(() => import("./containers/PeaEmbed/PeaEmbed"))
const BasicAccount = loadable(() =>
  import("./containers/Account/BasicAccount/BasicAccount")
)
const BusinessAccount = loadable(() =>
  import("./containers/Account/BusinessAccount/BusinessAccount")
)

const CompanyAccount = loadable(() =>
  import("./containers/Account/CompanyAccount/CompanyAccount")
)

const BroadcastingPod = loadable(() =>
  import("./containers/BroadcastingPod/BroadcastingPod")
)

const WorkdayGuide = loadable(() =>
  import("./containers/WorkdayGuide/WorkdayGuide")
)
const UploadPeaModal = loadable(() =>
  import("./containers/BroadcastingPod/UploadPeaModal/UploadPeaModal")
)

const App = ({
  location,
  setAuthentication,
  setIsHomeVisited,
  account,
  company,
  uploadPeaModal,
  setUploadPeaModal,
  loadingAccount,
}) => {
  const [showCookiesMessage, setShowCookiesMessage] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const { pathname } = location

  const history = useHistory()

  const ws = useWebSocket({
    socketUrl: `${process.env.REACT_APP_WS_URL}`,
  })
  // const wsClient = useRef();

  // const establishWsConnection = (wsClient) => {
  //   OnOpen(wsClient);
  // };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setAuthentication(history)
    }
  }, [])

  useEffect(() => {
    const showCookiesState = localStorage.getItem("showCookiesMessage")
    setShowCookiesMessage(showCookiesState !== "false")
    if (pathname !== "/") {
      setIsHomeVisited(true)
    }

    // eslint-disable-next-line
  }, [localStorage.getItem("token")])

  useEffect(() => {
    // console.log("WEB SOCKET DATA: ", ws);
    ws.ws.binaryType = "arraybuffer"
    if (ws?.data?.message) {
      // const uIntParse = new Uint16Array(ws.data.message)
      // let buff = arrayBuffToJson(uIntParse);
      watchCommentNotification({ buff: ws.data.message, account, company })
    }
  }, [ws.data])

  const hideCookiesMessage = () => {
    setShowCookiesMessage(false)
    localStorage.setItem("showCookiesMessage", false)
  }

  let routes = (
    <Switch>
      <Route exact path="/:id/peabox.tv" component={Company} />
      <Route exact path="/linkedin" component={LinkedInCallback} />
      <Route exact path="/sign-in" component={Login} />
      <Route exact path="/sign-up" component={Signup} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/verify-email/:token" component={VerifyEmail} />
      <Route path="/pea/:id" exact component={PeaVideoContainer} />
      <Route path="/embed/pea/:id" exact component={PeaEmbed} />
      <PrivateRoute exact path="/customize-interests" component={Interests} />
      <PrivateRoute exact path="/following" component={Following} />
      <PrivateRoute exact path="/watchlist" component={Watchlist} />
      <PrivateRoute
        exact
        path="/broadcasting-pod"
        component={BroadcastingPod}
      />
      <PrivateRoute path="/settings/basic" component={BasicAccount} />
      <PrivateRoute path="/settings/business" component={BusinessAccount} />
      <PrivateRoute path="/settings/company" component={CompanyAccount} />
      <PrivateRoute exact path="/workday-guide" component={WorkdayGuide} />
      <Route exact path="/" component={Home} />
      <Route exact path="/business" component={BusinessLandingPage} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  )

  return (
    <wsContext.Provider
      value={{
        wsClient: ws,
      }}
    >
      <div className={styles.App}>
        {showCookiesMessage && (
          <PBCookiesMessage onClickMessage={hideCookiesMessage} />
        )}
        {loadingAccount ? <PageLoader /> : <Layout>{routes}</Layout>}
        <UploadPeaModal
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          isVisible={uploadPeaModal}
          setIsVisible={() => setUploadPeaModal(uploadPeaModal)}
        />
      </div>
    </wsContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isSearchSubmitted: state.searchBox.isSearchSubmitted,
  account: state.auth.account,
  company: state.auth.company,
  uploadPeaModal: state.modal.uploadPeaModal,
  loadingAccount: state.auth.loadingAccount,
})

export default connect(mapStateToProps, {
  setAuthentication,
  setIsHomeVisited,
  setUploadPeaModal,
})(withRouter(App))
