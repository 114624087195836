import { GET_COMPANIES } from "../types"

const DEFAULT_STATE = {
  channels: [],
}

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_COMPANIES:
      return {
        ...state,
        channels: payload,
      }
    default:
      return state
  }
}
