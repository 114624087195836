import axios from "../../utils/axiosConfig"
import { TOGGLE_PEA_DROPLIST_SUCCESS } from "../types"
import { tokenConfig } from "./authActions"
import { toast } from "react-toastify"

export const toggleDroplist = (pea) => (dispatch, getState) => {
  axios
    .post(
      `/peas/droplist`,
      JSON.stringify({ pea: pea.id }),
      tokenConfig(getState)
    )
    .then((res) => {
      if (res && res.data && res.data.success) {
        const message = res.data?.message?.includes("has been deleted from droplist") ? "Pea has been deleted from droplist" : "Pea has been added to droplist"
        toast.success(message)
        dispatch({ type: TOGGLE_PEA_DROPLIST_SUCCESS, payload: res.data.data })
      } else {
        toast.error(res.data.message)
      }
    })
    .catch((err) => {
      console.log(err)
      toast.error(err.message)
    })
}
