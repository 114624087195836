import React from 'react';
import classNames from 'classnames';
import styles from './Tab.module.scss';

const cx = classNames.bind(styles);

const Tab = ({
               label,
               onClick,
               currentTab,
             }) => (
  <li
    className={cx(
      styles.TabListItem,
      {active: currentTab === label}
    )}
    onClick={() => onClick(label)}
  >
    {label}
  </li>
);

export default Tab;
