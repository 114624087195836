import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from '../../Button/Button';
import { Loader } from '../../Loader/Loader';
import BusinessDropdownItem from './BusinessDropdownItem/BusinessDropdownItem';
import styles from './BusinessDropdown.module.scss';

const BusinessDropdown = ({
  loading,
  items,
  isVisible,
  setIsVisible,
  setToggle = () => {},
  type,
  title,
  children,
  dropdownStyles,
  heading,
  dropdownTitleStyles,
}) => {
  const [isOverlapping, setIsOverlapping] = useState(false);

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      const clientRect = domNode.getBoundingClientRect();
      const viewportWidth = document.documentElement.clientWidth;

      if (clientRect.right > viewportWidth) {
        setIsOverlapping(true);
      }
    }
  }, []);

  return (
    <div
      className={classNames(
        styles.BusinessDropdown,
        styles[type],
        dropdownStyles
      )}
      id='openPeaDropdown'
    >
      {type === 'Comment' ? (
        <Button
          buttonStyle={styles.BusinessDropdownButton}
          btnType='Transparent'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsVisible('openCommentDropdown');
          }}
        >
          {children}
        </Button>
      ) : (
        <Button
          buttonStyle={styles.BusinessDropdownButton}
          btnType='Transparent'
          onClick={setIsVisible}
          id='openPeaDropdown'
        >
          {children}
        </Button>
      )}
      {isVisible && (
        <div
          ref={callBackRef}
          className={classNames(
            styles.LinksWrapper,
            isOverlapping ? styles.rightZero : styles.rightMiddle
          )}
        >
          {heading && heading.title && (
            <div className={styles.heading}>
              {heading?.series?.name && <div>{heading?.series?.name}</div>}
              {heading.order_series && (
                <div>
                  Episode {heading.order_series}:{' '}
                  <span>{heading?.title && heading.title}</span>
                </div>
              )}
            </div>
          )}
          {title && (
            <div
              className={
                dropdownTitleStyles ? dropdownTitleStyles : styles.TitleWrapper
              }
            >
              <h4>{title}</h4>
              {loading && <Loader />}
            </div>
          )}
          <ul className={styles.Links}>
            {items.map((item, index) => {
              const { id, icon, title, action, tooltipText } = item;

              return (
                <BusinessDropdownItem
                  key={`${title}-${id}-${index}`}
                  icon={icon}
                  title={title}
                  setToggle={setToggle}
                  action={() => action(item)}
                  tooltipText={tooltipText}
                  dropdownType={type}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BusinessDropdown;
