import React from "react"
import classNames from "classnames"
import Modal from "react-bootstrap/Modal"
import HeaderBackButton from "../../Header/HeaderBackButton/HeaderBackButton"
import styles from "./PBModal.module.scss"

const cx = classNames.bind(styles)

const PBModal = ({
  key,
  children,
  animation,
  show,
  onHide,
  centered,
  modalStyle,
  backdropStyle,
  closeIcon,
  autoFocus,
  isHomeVisited,
  showModalHeader,
  setShowTooltip,
  setDefaultValue,
  setComment,
  setActiveThread,
  backdrop,
  handleCommentDropdownToggle,
  setLabelError,
  setLinkError,
  hideErrorMessage,
  setPreviousCommentsUrl,
}) => (
  <Modal
    key={key}
    animation={animation}
    aria-labelledby="contained-modal-title-vcenter"
    show={show}
    centered={centered}
    autoFocus={autoFocus}
    enforceFocus={false}
    backdrop={backdrop}
    onHide={onHide}
    className={cx(
      styles.PBModal,
      !isHomeVisited ? cx(styles.PBModalHome) : "",
      modalStyle
    )}
    backdropClassName={cx(styles.ModalBackdrop, backdropStyle)}
  >
    {showModalHeader && (
      <Modal.Header className={cx("border-0", "p-0", styles.ModalHeader)}>
        <HeaderBackButton
          setShowTooltip={setShowTooltip}
          setDefaultValue={setDefaultValue}
          setComment={setComment}
          setActiveThread={setActiveThread}
          handleCommentDropdownToggle={handleCommentDropdownToggle}
          setLabelError={setLabelError}
          setLinkError={setLinkError}
          hideErrorMessage={hideErrorMessage}
          setPreviousCommentsUrl={setPreviousCommentsUrl}
        />
      </Modal.Header>
    )}
    {closeIcon && (
      <div className={styles.CloseIconWrapper}>
        <div className={styles.CloseIcon}>+</div>
      </div>
    )}
    <Modal.Body>{children}</Modal.Body>
  </Modal>
)

PBModal.defaultProps = {
  animation: true,
  closeIcon: true,
  autoFocus: true,
  isHomeVisited: true,
  showModalHeader: true,
}

export default PBModal
