import React from "react";
import classes from "./PageLoader.module.css";

export default function PageLoader() {
  return (
    <div className={classes.container}>
      <div className={classes["lds-ellipsis"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
