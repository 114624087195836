import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import $ from "jquery";
import registerServiceWorker from "./registerServiceWorker";
import store from "./store/store";
import App from "./App";
import "./index.scss";

function setModalStylingWorkaround() {
  let isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  let styleEl = document.createElement("style"),
    styleSheet;
  document.head.appendChild(styleEl);

  styleSheet = styleEl.sheet;
  if (isIos) {
    $(document).on("focus", "input, textarea", function () {
      if (isIos)
        styleSheet.insertRule(".modal {position: absolute !important;}", 0);
    });
    $(document).on("blur", "input, textarea", function () {
      if (isIos) styleSheet.deleteRule(".modal");
    });
  }
}

setModalStylingWorkaround();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
