import React, { useEffect, useState } from "react";
import InViewMonitor from "react-inview-monitor";
import VideoWrapper from "./VideoWrapper";

const InviewAutoPlayVideo = (props) => {
  const { disableOnScroll, onPlay, onPause } = props;
  const [didUserScroll, setUserScroll] = useState(false);
  const [stoppedMonitor, setStopMonitor] = useState(false);

  const handleScroll = () => {
    if (disableOnScroll && !didUserScroll) {
      setUserScroll(true);
      setStopMonitor(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line
  }, [didUserScroll]);


  return !stoppedMonitor ? (
    <InViewMonitor
      childPropsInView={{ isPlaying: true }}
      toggleChildPropsOnInView={true}
      intoViewMargin="-30%"
    >
      <VideoWrapper key={props.defaultURL} {...props} />
    </InViewMonitor>
  ) : (
    <VideoWrapper key={props.defaultURL} {...props} isPlaying={false} />
  );
};

export default InviewAutoPlayVideo;
