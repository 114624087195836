import React from "react"
import dayjs from "dayjs"
import { Col, Row } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import { roundNumber } from "../../../utils/utils"
import { ReactComponent as Eye } from "../../../icons/eye.svg"
import { ReactComponent as Heart } from "../../../icons/heart.svg"
import styles from "../PeaVideo.module.scss"

const Info = ({ pea }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" })
  const isTablet = useMediaQuery({ query: "(max-width: 1199px)" })
  const isTabletOrMobile = isMobile || isTablet

  return (
    <Row>
      {!isTabletOrMobile && (
        <Col
          md={12}
          xs={12}
          className={
            " justify " + styles.noPaddingRight + " " + styles.DesktopInfoTab
          }
        >
          {pea !== null ? (
            <section>
              <span>Published on </span>
              <span>
                {pea.broadcast_date
                  ? dayjs(pea.broadcast_date).format("MMM DD, YYYY")
                  : dayjs(pea.created_at).format("MMM DD, YYYY")}
              </span>
            </section>
          ) : (
            ""
          )}
          <span className={styles.alignRight + " " + styles.peaViews}>
            <Eye className={styles.infoIcon} />
            {pea && roundNumber(pea.views_count)}
          </span>
          <span
            className={(isMobile ? "" : " justify") + " " + styles.likesText}
          >
            <Heart className={styles.infoIcon} />
            {pea && roundNumber(pea?.likes?.length)} Likes
          </span>
        </Col>
      )}
      {isTabletOrMobile && (
        <Col
          md={12}
          xs={12}
          className={
            isMobile
              ? styles.alignCenter + " " + styles.publishedDate
              : " justify " + styles.noPaddingRight
          }
        >
          {pea !== null ? (
            <>
              <div className={styles.InfoRow1}>
                <span>Published on </span>
                <span>
                  {pea.broadcast_date
                    ? dayjs(pea.broadcast_date).format("MMM DD, YYYY")
                    : dayjs(pea.created_at).format("MMM DD, YYYY")}
                </span>
              </div>
              <span className={styles.WhiteSeparator_first}>----</span>
              <Eye className={styles.infoIcon} />{" "}
              {pea && roundNumber(pea.views_count)}
              <span className={styles.WhiteSeparator_second}>----</span>
              <Heart className={styles.infoIcon} />
              {pea && roundNumber(pea?.likes?.length)} Likes
            </>
          ) : (
            ""
          )}
        </Col>
      )}
    </Row>
  )
}

export default Info
