
export const getTimezoneOffset = (currDate) => {
    let offset = currDate.getTimezoneOffset(), o = Math.abs(offset)
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}

export const isWithin24hrs = (date) => {
    let then = new Date(date)
    let now = new Date();
    // console.log(`THEN: ${then.toJSON().replace("Z", getTimezoneOffset(then))}`)
    // now = new Date(`${now.toJSON().replace("Z", getTimezoneOffset(now))}`)
    // console.log(`NOW: ${now.toJSON().replace("Z", getTimezoneOffset(now))}`)
    // console.log(`THEN: ${then.toJSON()}`)
    // console.log(`NOW: ${now.toJSON()}`)

    // one way to convert
    // get milliseconds between given date and now
    // const ms = Math.abs(then.getTime() - now.getTime())
    // convert milliseconds to hours
    // const hrs = ms / (60 * 60 * 1000)

    // another way to convert, which yields the hours right away
    const hrs = Math.round(Math.abs(then - now) / 36e5)
    if (hrs < 24) {
        return true
    }

    return false
}