import {
  AUTH_END,
  AUTH_FAIL,
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_SIGN_UP,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_SUCCESS_MSG,
  CLEAR_AUTH_MESSAGE,
  FOLLOW_COMPANY,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  GET_FOLLOW_ITEMS_SUCCESS,
  GET_INTEREST_SUCCESS,
  LOAD_ACCOUNT_FAIL,
  LOAD_ACCOUNT_START,
  LOAD_ACCOUNT_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  CLEAR_RESET_PASSWORD_ERROR,
  RETRIEVE_BUSINESS_ACCOUNT_SUCCESS,
  RETRIEVE_COMPANY_ACCOUNT_SUCCESS,
  SEND_INVITATIONS_FAIL,
  SEND_INVITATIONS_START,
  SEND_INVITATIONS_SUCCESS,
  UNFOLLOW_COMPANY,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  UPDATE_BUSINESS_ACCOUNT_FAIL,
  UPDATE_BUSINESS_ACCOUNT_START,
  UPDATE_BUSINESS_ACCOUNT_SUCCESS,
  UPDATE_COMPANY_ACCOUNT_FAIL,
  UPDATE_COMPANY_ACCOUNT_START,
  UPDATE_COMPANY_ACCOUNT_SUCCESS,
  UPDATE_INTEREST,
  VERIFY_EMAIL_FAIL,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  IS_LINKEDIN,
  IS_MOUNTED_POD,
  DEACTIVATE_ACCOUNT,
  REACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  DELETE_COMPANY,
  CLEAR_ACCOUNT_FAIL_MSG,
  HIDE_COMPANY_PEAS_START,
  HIDE_COMPANY_PEAS_SUCCESS,
  HIDE_COMPANY_PEAS_FAIL,
  UNHIDE_COMPANY_PEAS_SUCCESS,
  UNHIDE_COMPANY_PEAS_FAIL,
  UNHIDE_COMPANY_PEAS_START,
  CLEAR_FORGOT_PASSWORD_MESSAGES,
  SHOW_OAUTH_MODAL,
  CHECK_OAUTH_MODAL,
  TOTAL_COMPANY_PEAS_REMOVE,
  TOTAL_COMPANY_PEAS_ADD,
  CURRENT_SCHEDULED_PEAS,
  REMOVE_SCHEDULED_PEAS,
  STORIES_BROADCAST_COUNTER,
} from "../types"

const DEFAULT_STATE = {
  isAuthenticated: false,
  errorMessage: "",
  successMessage: "",
  authSuccessMessage: "",
  hasSentMail: false,
  loading: false,
  loadingAccount: false,
  token: localStorage.getItem("token"),
  account: null,
  interest: {
    categories: [],
    tags: [],
    companies: [],
  },
  following: [],
  business: null,
  company: null,
  isLinkedIn: false,
  accountUpdateErrorMsg: "",
  businessAccountUpdateErrorMsg: "",
  isMounted: false,
  showOAuthModal: true,
  checkOAuthModal: false,
  totalCompanyPeas: null,
  storiesBroadcastCounter: null,
  scheduledPeasIds: []
}

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case SEND_INVITATIONS_START:
    case UPDATE_BUSINESS_ACCOUNT_START:
    case UPDATE_COMPANY_ACCOUNT_START:
    case AUTH_START:
    case VERIFY_EMAIL_START:
    case UPDATE_ACCOUNT_START:
    case HIDE_COMPANY_PEAS_START:
    case UNHIDE_COMPANY_PEAS_START:
      return { ...state, loading: true }
    case LOAD_ACCOUNT_START:
      return { ...state, loading: true, loadingAccount: true }
    case HIDE_COMPANY_PEAS_FAIL:
    case UNHIDE_COMPANY_PEAS_FAIL:
      return { ...state, loading: false }
    case SEND_INVITATIONS_FAIL:
      return { ...state, loading: false }
    case SEND_INVITATIONS_SUCCESS:
      return { ...state, loading: false }
    case UPDATE_BUSINESS_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        businessAccountUpdateErrorMsg: payload,
      }
    case CLEAR_ACCOUNT_FAIL_MSG:
      return {
        ...state,
        accountUpdateErrorMsg: "",
        businessAccountUpdateErrorMsg: "",
      }
    case UPDATE_COMPANY_ACCOUNT_FAIL:
    case AUTH_END:
      return { ...state, loading: false }
    case AUTH_SIGN_UP:
      return { ...state, isAuthenticated: true, errorMessage: "" }
    case AUTH_SIGN_IN:
      return { ...state, isAuthenticated: true, errorMessage: "" }
    case AUTH_SIGN_OUT:
    case DELETE_ACCOUNT:
    case DELETE_COMPANY:
      return DEFAULT_STATE
    case AUTH_FAIL:
      return { ...state, isAuthenticated: false, errorMessage: payload }
    case REACTIVATE_ACCOUNT:
      return {
        ...state,
        account: payload,
      }
    case DEACTIVATE_ACCOUNT:
      return {
        ...state,
        account: payload,
      }
    case HIDE_COMPANY_PEAS_SUCCESS:
      return { ...state, company: action.payload, loading: false }
    case UNHIDE_COMPANY_PEAS_SUCCESS:
      return { ...state, company: action.payload, loading: false }
    case AUTH_SUCCESS:
      localStorage.setItem("token", payload.token)
      localStorage.setItem(
        "account_type",
        payload.account.account_type || undefined
      )
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        errorMessage: "",
      }
    case AUTH_SUCCESS_MSG:
      return {
        ...state,
        authSuccessMessage: payload,
      }
    case CLEAR_AUTH_MESSAGE:
      return { ...state, errorMessage: "", authSuccessMessage: "" }
    case LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        account: payload,
        loading: false,
        loadingAccount: false,
      }
    case LOAD_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        loadingAccount: false,
        token: "invalid",
      }
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: payload,
        loading: false,
        hasSentMail: action.hasSentMail,
      }
    case UPDATE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        accountUpdateErrorMsg: payload,
      }
    case RETRIEVE_COMPANY_ACCOUNT_SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
        totalCompanyPeas: payload.peas
      }
    case UPDATE_COMPANY_ACCOUNT_SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case RETRIEVE_BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        business: payload,
        loading: false,
      }
    case UPDATE_BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        business: payload,
        loading: false,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: payload,
      }
    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        errorMessage: payload,
      }
    case CLEAR_RESET_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      }
    case CLEAR_FORGOT_PASSWORD_MESSAGES:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      }
    case VERIFY_EMAIL_FAIL:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      }
    case VERIFY_EMAIL_SUCCESS:
      localStorage.setItem("token", payload.token)
      localStorage.setItem(
        "account_type",
        payload.account.account_type || undefined
      )

      return {
        ...state,
        token: payload.token,
        account: payload.account,
        isAuthenticated: true,
        successMessage: payload.message,
        errorMessage: "",
        loading: false,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: payload,
        loading: false,
      }
    case GET_INTEREST_SUCCESS:
      return {
        ...state,
        interest: payload,
      }
    case UPDATE_INTEREST:
      return {
        ...state,
        interest: payload,
      }
    case GET_FOLLOW_ITEMS_SUCCESS:
      return {
        ...state,
        following: [...state.following, ...payload],
      }
    case UNFOLLOW_COMPANY:
      return {
        ...state,
        following: state.following.filter(
          (followingItem) => followingItem.id !== payload
        ),
      }
    case FOLLOW_COMPANY:
      return {
        ...state,
        following: [...state.following, payload],
      }
    case IS_LINKEDIN:
      return {
        ...state,
        isLinkedIn: payload,
      }
    case IS_MOUNTED_POD:
      return {
        ...state,
        isMounted: payload,
      }
    case SHOW_OAUTH_MODAL:
      return {
        ...state,
        showOAuthModal: payload,
      }
    case CHECK_OAUTH_MODAL:
      return {
        ...state,
        checkOAuthModal: payload,
      }
    case TOTAL_COMPANY_PEAS_REMOVE:
      return {
        ...state,
        totalCompanyPeas: state.totalCompanyPeas - 1,
      }
    case TOTAL_COMPANY_PEAS_ADD:
      return {
        ...state,
        totalCompanyPeas: state.totalCompanyPeas + 1,
      }
    case CURRENT_SCHEDULED_PEAS:
      const filteredScheduledStories = state.scheduledPeasIds.includes(payload)
      ? state.scheduledPeasIds
      : [...state.scheduledPeasIds, payload];

      return {
        ...state,
        scheduledPeasIds: filteredScheduledStories,
      }
    case REMOVE_SCHEDULED_PEAS:
      return {
        ...state,
        scheduledPeasIds: state.scheduledPeasIds.filter(item => item !== payload)
      }
    case STORIES_BROADCAST_COUNTER:
      return {
        ...state,
        storiesBroadcastCounter: payload,
      }
    default:
      return state
  }
}
