import React, { useState } from "react"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import styles from "./DropdownItem.module.scss"

const DropdownItem = ({
  item,
  setShowDropdown,
  account,
  setShowLoginModal,
}) => {
  const [showDropdownItems, setShowDropdownItems] = useState(false)
  const history = useHistory()
  const isMobile = useMediaQuery({
    query: "only screen and (max-width: 768px)",
  })

  const { label, separator, callback, children, style, belongsTo } = item

  const handleClick = (label) => {
    if (
      (belongsTo === "pod" && account && account.account_type !== "Basic") ||
      (belongsTo === "app" && account && account.account_type === "Basic")
    ) {
      callback()
      setShowDropdown(false)
    } else if (
      (belongsTo === "app" && account && account.account_type !== "Basic") ||
      (belongsTo === "pod" && account && account.account_type === "Basic") ||
      (!account && label !== "Join")
    ) {
      if (isMobile) {
        history.push("/sign-in")
      } else {
        setShowLoginModal(true)
      }
      setShowDropdown(false)
    } else if (label === "Company") {
      callback()
      setShowDropdownItems(!showDropdownItems)
    } else {
      callback()
      setShowDropdown(false)
    }
  }

  return (
    <div>
      {!children ? (
        <div
          className={classNames(
            styles.DropdownItem,
            style && style.fontColor ? styles[style.fontColor] : ""
          )}
        >
          <span onClick={() => handleClick(label)}>{label}</span>
        </div>
      ) : (
        <>
          <div
            className={classNames(
              styles.DropdownItem,
              style && style.fontColor
                ? styles[style.fontColor]
                : "d-flex justify-content-between align-items-center"
            )}
            onClick={() => handleClick(label)}
          >
            <div>{label}</div>
            <span className={styles.ArrowDownWrapper}>
              <FontAwesomeIcon
                className={styles.DownIcon}
                icon={faChevronDown}
              />
            </span>
          </div>
          {showDropdownItems ? (
            <div className={styles.DropdownChildren}>
              {children.map((child, index) => (
                <DropdownItem
                  key={index}
                  item={child}
                  setShowDropdown={setShowDropdown}
                  account={account}
                  setShowLoginModal={setShowLoginModal}
                />
              ))}
            </div>
          ) : null}
        </>
      )}
      {separator && <div className={styles.Separator} />}
    </div>
  )
}

export default DropdownItem
