// AUTH
export const AUTH_START = "AUTH_START"
export const AUTH_END = "AUTH_END"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_SUCCESS_MSG = "AUTH_SUCCESS_MSG"
export const AUTH_FAIL = "AUTH_FAIL"
export const CLEAR_AUTH_MESSAGE = "CLEAR_AUTH_MESSAGE"
export const CLEAR_FORGOT_PASSWORD_MESSAGES = "CLEAR_FORGOT_PASSWORD_MESSAGES"
export const LOAD_ACCOUNT_START = "LOAD_ACCOUNT_START"
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS"
export const LOAD_ACCOUNT_FAIL = "LOAD_ACCOUNT_FAIL"
export const AUTH_SIGN_UP = "AUTH_SIGN_UP"
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT"
export const AUTH_SIGN_IN = "AUTH_SIGN_IN"
export const AUTH_LINK_LINKEDIN = "AUTH_LINK_LINKEDIN"
export const AUTH_UNLINK_LINKEDIN = "AUTH_UNLINK_LINKEDIN"
export const UPDATE_ACCOUNT_START = "UPDATE_ACCOUNT_START"
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS"
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL"
export const RETRIEVE_BUSINESS_ACCOUNT_START = "RETRIEVE_BUSINESS_ACCOUNT_START"
export const RETRIEVE_BUSINESS_ACCOUNT_SUCCESS =
  "RETRIEVE_BUSINESS_ACCOUNT_SUCCESS"
export const RETRIEVE_BUSINESS_ACCOUNT_FAIL = "RETRIEVE_BUSINESS_ACCOUNT_FAIL"
export const UPDATE_BUSINESS_ACCOUNT_START = "UPDATE_BUSINESS_ACCOUNT_START"
export const UPDATE_BUSINESS_ACCOUNT_SUCCESS = "UPDATE_BUSINESS_ACCOUNT_SUCCESS"
export const UPDATE_BUSINESS_ACCOUNT_FAIL = "UPDATE_BUSINESS_ACCOUNT_FAIL"
export const RETRIEVE_COMPANY_ACCOUNT_START = "RETRIEVE_COMPANY_ACCOUNT_START"
export const RETRIEVE_COMPANY_ACCOUNT_SUCCESS =
  "RETRIEVE_COMPANY_ACCOUNT_SUCCESS"
export const RETRIEVE_COMPANY_ACCOUNT_FAIL = "RETRIEVE_COMPANY_ACCOUNT_FAIL"
export const UPDATE_COMPANY_ACCOUNT_START = "UPDATE_COMPANY_ACCOUNT_START"
export const UPDATE_COMPANY_ACCOUNT_SUCCESS = "UPDATE_COMPANY_ACCOUNT_SUCCESS"
export const UPDATE_COMPANY_ACCOUNT_FAIL = "UPDATE_COMPANY_ACCOUNT_FAIL"
export const SEND_INVITATIONS_START = "SEND_INVITATIONS_START"
export const SEND_INVITATIONS_SUCCESS = "SEND_INVITATIONS_SUCCESS"
export const SEND_INVITATIONS_FAIL = "SEND_INVITATIONS_FAIL"
export const AUTH_ERROR = "AUTH_ERROR"
export const DASHBOARD_GET_DATA = "DASHBOARD_GET_DATA"
export const UPDATE_INTERESTS = "UPDATE_INTERESTS"
export const FOLLOW_COMPANY = "FOLLOW_COMPANY"
export const UNFOLLOW_COMPANY = "UNFOLLOW_COMPANY"
export const DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT"
export const REACTIVATE_ACCOUNT = "REACTIVATE_ACCOUNT"
export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_COMPANY = "DELETE_COMPANY"
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"
export const RESET_PASSWORD_START = "RESET_PASSWORD_START"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"
export const CLEAR_RESET_PASSWORD_ERROR = "CLEAR_RESET_PASSWORD_ERROR"
export const VERIFY_EMAIL_START = "VERIFY_EMAIL_START"
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS"
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL"
export const IS_LINKEDIN = "IS_LINKEDIN"
export const CLEAR_ACCOUNT_FAIL_MSG = "CLEAR_ACCOUNT_FAIL_MSG"
export const IS_MOUNTED_POD = "IS_MOUNTED_POD"
export const SHOW_OAUTH_MODAL = "SHOW_OAUTH_MODAL"
export const CHECK_OAUTH_MODAL = "CHECK_OAUTH_MODAL"

// UI
export const HIDE_COOKIES_MESSAGE = "HIDE_COOKIES_MESSAGE"
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE"
export const SET_TOUCHED = "SET_TOUCHED"
export const SET_SEARCH_SUBMITTED = "SET_SEARCH_SUBMITTED"
export const SET_HOME_VISITED = "SET_HOME_VISITED"
export const SET_SHOULD_DO_HOME_ANIMATION = "SET_SHOULD_DO_HOME_ANIMATION"
export const SET_HOME_AUTHENTICATED = "SET_HOME_AUTHENTICATED"
export const SB_SET_SEARCH_KEY_PRESSED = "SB_SET_SEARCH_KEY_PRESSED"
export const SELECT_FILTER = "SELECT_FILTER"
export const SORT_BY = "SORT_BY"
export const RESET_FILTERS = "RESET_FILTERS"

// Interests
export const GET_INTEREST_START = "GET_INTEREST_START"
export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS"
export const GET_INTEREST_FAIL = "GET_INTEREST_FAIL"
export const GET_COMPANIES = "GET_COMPANIES"
export const UPDATE_INTEREST = "UPDATE_INTEREST"

// Following
export const FOLLOWING_TOGGLE_CHANNEL_SELECTED_STATUS =
  "FOLLOWING_TOGGLE_CHANNEL_SELECTED_STATUS"
export const GET_FOLLOW_ITEMS_START = "GET_FOLLOW_ITEMS_START"
export const GET_FOLLOW_ITEMS_SUCCESS = "GET_FOLLOW_ITEMS_SUCCESS"
export const GET_FOLLOW_ITEMS_FAIL = "GET_FOLLOW_ITEMS_FAIL"

// Company
export const COMPANY_SET_COMPANY = "COMPANY_SET_COMPANY"
export const RETRIEVE_COMPANY_START = "RETRIEVE_COMPANY_START"
export const RETRIEVE_COMPANY_SUCCESS = "RETRIEVE_COMPANY_SUCCESS"
export const RETRIEVE_COMPANY_FAIL = "RETRIEVE_COMPANY_FAIL"
export const CLEAR_COMPANY = "CLEAR_COMPANY"
export const TOTAL_COMPANY_PEAS_REMOVE = "TOTAL_COMPANY_PEAS_REMOVE"
export const TOTAL_COMPANY_PEAS_ADD = "TOTAL_COMPANY_PEAS_ADD"
export const CURRENT_SCHEDULED_PEAS = "CURRENT_SCHEDULED_PEAS"
export const REMOVE_SCHEDULED_PEAS = "REMOVE_SCHEDULED_PEAS"
export const STORIES_BROADCAST_COUNTER = "STORIES_BROADCAST_COUNTER"

export const PEA_SET_PEA = "PEA_SET_PEA"

export const SET_SHOW_LOGIN_MODAL = "SET_SHOW_LOGIN_MODAL"
export const SET_SHOW_SIGNUP_MODAL = "SET_SHOW_SIGNUP_MODAL"

export const CLEAR_OPEN_FROM = "CLEAR_OPEN_FROM"
export const SET_SHOW_PEAPAGE_MODAL = "SET_SHOW_PEAPAGE_MODAL"
export const SET_IS_FAVORITE = "SET_IS_FAVORITE"
export const SET_IS_WATCHLIST = "SET_IS_WATCHLIST"

export const HIDE_COMPANY_PEAS_START = "HIDE_COMPANY_PEAS_START"
export const HIDE_COMPANY_PEAS_FAIL = "HIDE_COMPANY_PEAS_FAIL"
export const HIDE_COMPANY_PEAS_SUCCESS = "HIDE_COMPANY_PEAS_SUCCESS"

export const UNHIDE_COMPANY_PEAS_START = "UNHIDE_COMPANY_PEAS_START"
export const UNHIDE_COMPANY_PEAS_FAIL = "UNHIDE_COMPANY_PEAS_FAIL"
export const UNHIDE_COMPANY_PEAS_SUCCESS = "UNHIDE_COMPANY_PEAS_SUCCESS"

// Stories
export const GET_STORIES_START = "GET_STORIES_START"
export const GET_STORIES_SUCCESS = "GET_STORIES_SUCCESS"
export const GET_STORIES_FAIL = "GET_STORIES_FAIL"
export const USER_INTEREST_STORIES_START = "USER_INTEREST_STORIES_START"
export const USER_INTEREST_STORIES_FAIL = "USER_INTEREST_STORIES_FAIL"
export const STOP_GET_STORIES = "STOP_GET_STORIES"

export const GET_WATCH_LIST_STORIES_START = "GET_WATCH_LIST_STORIES_START"
export const GET_WATCH_LIST_STORIES_SUCCESS = "GET_WATCH_LIST_STORIES_SUCCESS"
export const GET_WATCH_LIST_STORIES_FAIL = "GET_WATCH_LIST_STORIES_FAIL"

export const STORIES = "STORIES"
export const GET_SERIES_STORIES_START = "GET_SERIES_STORIES_START"
export const GET_SERIES_STORIES_SUCCESS = "GET_SERIES_STORIES_SUCCESS"
export const GET_SERIES_STORIES_FAIL = "GET_SERIES_STORIES_FAIL"

export const GET_COMPANY_STORIES_SUCCESS = "GET_COMPANY_STORIES_SUCCESS"

export const RETRIEVE_STORY_START = "RETRIEVE_STORY_START"
export const RETRIEVE_STORY_SUCCESS = "RETRIEVE_STORY_SUCCESS"
export const RETRIEVE_STORY_FAIL = "RETRIEVE_STORY_FAIL"

export const RETRIEVE_STORY_STATISTICS_START = "RETRIEVE_STORY_STATISTICS_START"
export const RETRIEVE_STORY_STATISTICS_SUCCESS =
  "RETRIEVE_STORY_STATISTICS_SUCCESS"
export const RETRIEVE_STORY_STATISTICS_FAIL = "RETRIEVE_STORY_STATISTICS_FAIL"

export const ADD_EMBED_START = "ADD_EMBED_START"
export const ADD_EMBED_SUCCESS = "ADD_EMBED_SUCCESS"
export const ADD_EMBED_FAIL = "ADD_EMBED_FAIL"

export const UPDATE_EMBED_START = "UPDATE_EMBED_START"
export const UPDATE_EMBED_SUCCESS = "UPDATE_EMBED_SUCCESS"
export const UPDATE_EMBED_FAIL = "UPDATE_EMBED_FAIL"

export const GET_COMMENTS_START = "GET_COMMENTS_START"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_FAIL = "GET_COMMENTS_FAIL"

export const PAGINATE_COMMENTS_SUCCESS = "PAGINATE_COMMENTS_SUCCESS"
export const PAGINATE_COMMENTS_URL = "PAGINATE_COMMENTS_URL"
export const PAGINATE_PREVIOUS_COMMENTS_URL = "PAGINATE_PREVIOUS_COMMENTS_URL"
export const PAGINATE_PREVIOUS_COMMENTS_SUCCESS =
  "PAGINATE_PREVIOUS_COMMENTS_SUCCESS"
export const EMPTY_COMMENTS_FOR_NOTIFICATION = "EMPTY_COMMENTS_FOR_NOTIFICATION"

export const PAGINATE_REPLIES_SUCCESS = "PAGINATE_REPLIES_SUCCESS"
export const PAGINATE_REPLIES_URL = "PAGINATE_REPLIES_URL"

export const GET_REPLIES_START = "GET_REPLIES_START"
export const GET_REPLIES_SUCCESS = "GET_REPLIES_SUCCESS"
export const GET_REPLIES_FAIL = "GET_REPLIES_FAIL"

export const ADD_COMMENT = "ADD_COMMENT"
export const ADD_REPLY = "ADD_REPLY"
export const REMOVE_COMMENT_ASSIGNMENT = "REMOVE_COMMENT_ASSIGNMENT"

export const UPDATE_COMMENT_START = "UPDATE_COMMENT_START"
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS"
export const UPDATE_COMMENT_FAIL = "UPDATE_COMMENT_FAIL"

export const DELETE_COMMENT_START = "DELETE_COMMENT_START"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_FAIL = "DELETE_COMMENT_FAIL"

export const CLEAR_COMPANY_STORIES = "CLEAR_COMPANY_STORIES"
export const CLEAR_PEA_VIDEO_STORIES = "CLEAR_PEA_VIDEO_STORIES"
export const CLEAR_STORY = "CLEAR_STORY"
export const CLEAR_COMMENTS = "CLEAR_COMMENTS"

export const ADD_PEA_START = "ADD_PEA_START"
export const ADD_PEA_SUCCESS = "ADD_PEA_SUCCESS"
export const ADD_PEA_FAIL = "ADD_PEA_FAIL"

export const UPDATE_PEA_START = "UPDATE_PEA_START"
export const UPDATE_PEA_SUCCESS = "UPDATE_PEA_SUCCESS"
export const UPDATE_PEA_FAIL = "UPDATE_PEA_FAIL"

export const DELETE_PEA_START = "DELETE_PEA_START"
export const DELETE_PEA_SUCCESS = "DELETE_PEA_SUCCESS"
export const DELETE_PEA_FAIL = "DELETE_PEA_FAIL"
export const FILTERED_BROADCASTING_PEAS = "FILTERED_BROADCASTING_PEAS"
export const FILTERED_HOME_STORIES = "FILTERED_HOME_STORIES"

export const GET_LABELS_START = "GET_LABELS_START"
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS"
export const GET_LABELS_FAIL = "GET_LABELS_FAIL"

export const ADD_LABEL_START = "ADD_LABEL_START"
export const ADD_LABEL_SUCCESS = "ADD_LABEL_SUCCESS"
export const ADD_LABEL_FAIL = "ADD_LABEL_FAIL"

export const ADD_PEA_LABELS_START = "ADD_PEA_LABELS_START"
export const ADD_PEA_LABELS_SUCCESS = "ADD_PEA_LABELS_SUCCESS"
export const ADD_PEA_LABELS_FAIL = "ADD_PEA_LABELS_FAIL"

export const SORT_STORIES = "SORT_STORIES"
export const SET_TOP_STORY = "SET_TOP_STORY"
export const COMPANY_FEATURED_VIDEO_START = "COMPANY_FEATURED_VIDEO_START"
export const COMPANY_FEATURED_VIDEO_FAIL = "COMPANY_FEATURED_VIDEO_FAIL"
export const COMPANY_FEATURED_VIDEO = "COMPANY_FEATURED_VIDEO"

export const SORT_SERIES_STORIES = "SORT_SERIES_STORIES"
export const SET_STORY_FILTERS = "SET_STORY_FILTERS"
export const CLEAR_STORY_FILTERS = "CLEAR_STORY_FILTERS"

export const RETRIEVE_UPLOAD_TOKEN_START = "RETRIEVE_UPLOAD_TOKEN_START"

export const UPLOAD_SPROUT_VIDEO_START = "UPLOAD_SPROUT_VIDEO_START"
export const UPLOAD_SPROUT_VIDEO_SUCCESS = "UPLOAD_SPROUT_VIDEO_SUCCESS"
export const UPLOAD_SPROUT_VIDEO_FAIL = "UPLOAD_SPROUT_VIDEO_FAIL"

export const UPLOAD_SPROUT_VIDEO_POSTER = "UPLOAD_SPROUT_VIDEO_POSTER"

export const RETRIEVE_SERIES = "RETRIEVE_SERIES"

export const LIKE_PEA = "LIKE_PEA"
export const WATCHLIST_PEA = "WATCHLIST_PEA"
export const FILTERED_WATCHLIST = "FILTERED_WATCHLIST"

export const TOGGLE_PEA_DROPDOWN = "TOGGLE_PEA_DROPDOWN"

export const SET_LABEL_VALUE = "SET_LABEL_VALUE"

// Search Box
export const GET_FILTER_SERIES = "GET_FILTER_SERIES"
export const TOGGLE_SERIES_FILTER = "TOGGLE_SERIES_FILTER"
export const GET_TAGS = "GET_TAGS"

// Peas
export const RETRIEVE_PEA_START = "RETRIEVE_PEA_START"
export const RETRIEVE_PEA_SUCCESS = "RETRIEVE_PEA_SUCCESS"
export const RETRIEVE_PEA_FAIL = "RETRIEVE_PEA_FAIL"

// Modals
export const SET_SOCIAL_BROADCAST_MODAL = "SET_SOCIAL_BROADCAST_MODAL"
export const SET_SEGMENT_MODAL = "SET_SEGMENT_MODAL"
export const SET_ENGAGE_MODAL = "SET_ENGAGE_MODAL"
export const SET_PRODUCTION_WORKFLOW_MODAL = "SET_PRODUCTION_WORKFLOW_MODAL"
export const SET_STATISTICS_MODAL = "SET_STATISTICS_MODAL"
export const SET_UPLOAD_PEA_MODAL = "SET_UPLOAD_PEA_MODAL"
export const SET_FORGOT_PASSWORD_MODAL = "SET_FORGOT_PASSWORD_MODAL"
export const CLOSE_MODALS = "CLOSE_MODALS"
export const SET_MISSION_MAP_MODAL = "SET_MISSION_MAP_MODAL"

// Accounts
export const GET_ACCOUNTS_START = "GET_ACCOUNTS_START"
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS"
export const GET_ACCOUNTS_FAIL = "GET_ACCOUNTS_FAIL"
export const DELETE_COMPANY_ACCOUNT_START = "DELETE_COMPANY_ACCOUNT_START"
export const DELETE_COMPANY_ACCOUNT_SUCCESS = "DELETE_COMPANY_ACCOUNT_SUCCESS"
export const DELETE_COMPANY_ACCOUNT_FAIL = "DELETE_COMPANY_ACCOUNT_FAIL"

export const MAKE_AS_ADMIN_START = "MAKE_AS_ADMIN_START"
export const MAKE_AS_ADMIN_SUCCESS = "MAKE_AS_ADMIN_SUCCESS"
export const MAKE_AS_ADMIN_FAIL = "MAKE_AS_ADMIN_FAIL"

export const MAKE_AS_USER_START = "MAKE_AS_USER_START"
export const MAKE_AS_USER_SUCCESS = "MAKE_AS_USER_SUCCESS"
export const MAKE_AS_USER_FAIL = "MAKE_AS_USER_FAIL"

// notifications
export const GET_ACCOUNT_NOTIFICATION_START = "GET_ACCOUNT_NOTIFICATION_START"
export const GET_NOTIFICATION_LOADER_START = "GET_NOTIFICATION_LOADER_START"
export const GET_NOTIFICATION_LOADER_FAIL = "GET_NOTIFICATION_LOADER_FAIL"
export const GET_ACCOUNT_NOTIFICATION_SUCCESS =
  "GET_ACCOUNT_NOTIFICATION_SUCCESS"
export const GET_ACCOUNT_NOTIFICATION_FAIL = "GET_ACCOUNT_NOTIFICATION_FAIL"
export const UPDATE_ACCOUNT_NOTIFICATION_SUCCESS =
  "UPDATE_ACCOUNT_NOTIFICATION_SUCCESS"
export const INCREMENT_NOTIFICATION = "INCREMENT_NOTIFICATION"
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION"
export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT"
export const DECREASE_NOTIFICATION_COUNT = "DECREASE_NOTIFICATION_COUNT"
export const INCREASE_NOTIFICATION_COUNT = "INCREASE_NOTIFICATION_COUNT"
export const REFRESH_ACCOUNT_NOTIFICATION = "REFRESH_ACCOUNT_NOTIFICATION"
export const NOTIFICATION_NOT_EXIST = "NOTIFICATION_NOT_EXIST"
export const SUCCESS_PAGINATE_NOTIFICATION = "SUCCESS_PAGINATE_NOTIFICATION"
export const FAIL_PAGINATE_NOTIFICATION = "FAIL_PAGINATE_NOTIFICATION"

// workday guide stories
export const GET_WORKDAYGUIDE_STORIES_START = "GET_WORKDAYGUIDE_STORIES_START"
export const GET_WORKDAYGUIDE_STORIES_END = "GET_WORKDAYGUIDE_STORIES_END"
export const GET_WORKDAYGUIDE_STORIES_SUCCESS =
  "GET_WORKDAYGUIDE_STORIES_SUCCESS"
export const GET_WORKDAYGUIDE_STORIES_FAIL = "GET_WORKDAYGUIDE_STORIES_FAIL"

export const FILTER_WORKDAYGUIDE_STORIES_START =
  "FILTER_WORKDAYGUIDE_STORIES_START"
export const FILTER_WORKDAYGUIDE_STORIES_END = "FILTER_WORKDAYGUIDE_STORIES_END"
export const FILTER_WORKDAYGUIDE_STORIES_SUCCESS =
  "FILTER_WORKDAYGUIDE_STORIES_SUCCESS"
export const FILTER_WORKDAYGUIDE_STORIES_FAIL =
  "FILTER_WORKDAYGUIDE_STORIES_FAIL"
export const WORKDAY_GUIDE_FILTER = "WORKDAY_GUIDE_FILTER"

export const WORKDAY_GUIDE_SORT_BY_DROPDOWN = "WORKDAY_GUIDE_SORT_BY_DROPDOWN"
export const WORKDAY_GUIDE_DESIRED_EFFECT_DROPDOWN =
  "WORKDAY_GUIDE_DESIRED_EFFECT_DROPDOWN"

export const TRIGGERED_NOTIF_COMMENT_ID = "TRIGGERED_NOTIF_COMMENT_ID"
export const TRIGGERED_NOTIF_PAGE_NUMBER = "TRIGGERED_NOTIF_PAGE_NUMBER"
export const HIDE_EMPTY_COMMENTS_ERR = "HIDE_EMPTY_COMMENTS_ERR"
export const SHOW_NOTIFICATIONS_DROPDOWN = "SHOW_NOTIFICATIONS_DROPDOWN"
export const TOGGLE_PEA_DROPLIST_START = "TOGGLE_PEA_DROPLIST_START"
export const TOGGLE_PEA_DROPLIST_SUCCESS = "TOGGLE_PEA_DROPLIST_SUCCESS"
export const FILTERED_AIRDROP_STORIES = "FILTERED_AIRDROP_STORIES"
export const TOGGLE_PEA_DROPLIST_FAIL = "TOGGLE_PEA_DROPLIST_FAIL"
