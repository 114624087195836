import {
  SB_SET_SEARCH_KEY_PRESSED,
  SELECT_FILTER,
  SET_SEARCH_SUBMITTED,
  SET_SEARCH_VALUE,
  SET_SHOULD_DO_HOME_ANIMATION,
  SET_HOME_AUTHENTICATED,
  SET_TOUCHED,
  SORT_BY,
  SET_HOME_VISITED,
  RESET_FILTERS,
  GET_FILTER_SERIES,
  TOGGLE_SERIES_FILTER,
  AUTH_SIGN_OUT,
  DELETE_ACCOUNT,
  GET_TAGS,
} from "../types"
import update from "immutability-helper"

const DEFAULT_STATE = {
  searchValue: "",
  touched: false,
  isSearchSubmitted: false,
  shouldDoHomeAnimation: true,
  isHomeVisited: false,
  searchKeyPressed: false,
  selectedFilter: "all",
  sortByValue: { value: "most_likes", label: "Most Likes" },
  filterCategories: [],
  filterSeries: [],
  isSeriesOnly: false,
  tags: [],
  filterTags: [],
}

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_SEARCH_VALUE:
      return { ...state, searchValue: payload }
    case SET_TOUCHED:
      return { ...state, touched: payload }
    case SET_SEARCH_SUBMITTED:
      return { ...state, isSearchSubmitted: payload }
    case SET_HOME_VISITED:
      return { ...state, isHomeVisited: payload }
    case SET_SHOULD_DO_HOME_ANIMATION:
      return { ...state, shouldDoHomeAnimation: payload }
    case SET_HOME_AUTHENTICATED:
      return { ...state, isHomeAuthenticated: payload }
    case SELECT_FILTER:
      return { ...state, selectedFilter: payload }
    case SORT_BY:
      return { ...state, sortByValue: payload }
    case SB_SET_SEARCH_KEY_PRESSED:
      return { ...state, searchKeyPressed: payload }
    case RESET_FILTERS:
      return {
        ...state,
        filterTags: state.filterTags.map((tag) => {
          tag.selected = false
          return tag
        }),
        filterCategories: state.filterCategories.map((category) => {
          category.selected = false
          return category
        }),
      }
    case GET_FILTER_SERIES:
      return {
        ...state,
        filterSeries: payload.series.filter(
          (series) => series.company === payload.companyId
        ),
      }
    case GET_TAGS:
      return {
        ...state,
        tags: payload,
      }
    case TOGGLE_SERIES_FILTER:
      return {
        ...state,
        isSeriesOnly: !state.isSeriesOnly,
      }
    case AUTH_SIGN_OUT:
      return DEFAULT_STATE
    case DELETE_ACCOUNT:
      return DEFAULT_STATE
    default:
      return state
  }
}
