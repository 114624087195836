import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { connect } from "react-redux"
import Masonry from "react-masonry-component"
import InfiniteScroll from "react-infinite-scroller"
import VideoData from "./VideoData"
import VideoCarousel from "./VideoCarousel"
import VideoItem from "./VideoItem/VideoItem"
import { sortVideos } from "./VideoDataHelper"
import * as actions from "../../store/actions"
import store from "../../store/store"
import { PeaLoader } from "../UI/Loader/Loader"
import TuneRecommendations from "./TuneRecommendations"
import { showSearchedStories, filterStoriesByLabel } from "../../utils/utils"
import "./VideoPlayer.scss"

const peaLoaders = [
  { id: 1, format: "Rectangle" },
  { id: 2, format: "Square" },
  { id: 3, format: "Rectangle" },
  { id: 4, format: "Square" },
  { id: 5, format: "Square" },
  { id: 6, format: "Rectangle" },
  { id: 7, format: "Square" },
  { id: 8, format: "Rectangle" },
]

const VideoPlayer = ({
  type,
  sort,
  filter,
  isAuth = false,
  searchPressed,
  afterSearchPressed,
  disableInfiniteScroll = false,
  homeStories,
  companyStories,
  peaVideoStories,
  showHeader,
  peaPage,
  homePage,
  companyPage,
  peaVideoPage,
  hasMore,
  homeInterestHasMore,
  getStories,
  getUserInterestSortedStories,
  company,
  loading,
  callFrom,
  isCompanyPage,
  searchValue,
  selectedFilter,
  isSeriesOnly,
  labelValue,
  account,
  seriesOnlyPage,
  setShowPeaPageModal,
  clearCompanyStories,
  currentVideo,
  interest,
  userInterestStoriesPage,
  stopFetchingStories,
  startFetchInterestStories,
}) => {
  const [videos, setVideos] = useState([])
  const [filteredStories, setFilteredStories] = useState([])
  const [interestId, setInterestId] = useState(null)
  const [filterValue, setFilterValue] = useState(null)
  const intervalRef = useRef(null);

  useEffect(() => {
    let stories = []

    if (callFrom.includes("home")) {
      stories = homeStories
    } else if (callFrom.includes("company")) {
      stories = companyStories
    } else if (callFrom.includes("peaVideo")) {
      stories = peaVideoStories
    }

    setFilteredStories(stories)
  }, [homeStories, companyStories, peaVideoStories])

  const accountInteresetId = useMemo(() => {
    return interest?.id
  }, [interest])

  const selectedLabel = useMemo(() => {
    return selectedFilter
  }, [selectedFilter])

  useEffect(() => {
    if (
      accountInteresetId &&
      (interest.tags.length >= 1 || interest.categories.length >= 1) &&
      accountInteresetId !== interestId
    ) {
      setInterestId(accountInteresetId)
    } else {
      setInterestId(null)
    }
  }, [interest])

  useEffect(() => {
    if (
      selectedFilter !== "all" &&
      selectedLabel !== "all" &&
      selectedFilter === labelValue
    ) {
      if (filterValue !== selectedLabel) {
        setFilterValue(selectedLabel)
      }
    } else {
      setFilterValue(null)
    }
  }, [filterValue])

  const getPage = () => {
    let page = 1

    if (callFrom.includes("home")) {
      page = homePage
    } else if (callFrom.includes("company")) {
      page = companyPage
    } else if (callFrom.includes("peaVideo")) {
      page = peaVideoPage
    }

    return page
  }

  const showTuneRecommendations = (index) =>
    isAuth && type === "Gallery" && index !== 0 && index % 6 === 0

  const loadStories = () => {
    if (loading) return

    let companyId = undefined

    if (company) {
      companyId = company
    }

    if (account) {
      getStories(
        getPage(),
        companyId,
        callFrom,
        account.account_type,
        isSeriesOnly,
        seriesOnlyPage
      )
    } else {
      getStories(
        getPage(),
        companyId,
        callFrom,
        null,
        isSeriesOnly,
        seriesOnlyPage
      )
    }
    // eslint-disable-next-line
  }


  useEffect(()=> {
    if (!startFetchInterestStories && accountInteresetId && interestId && callFrom === "home") {
      getUserInterestSortedStories(interestId, null, userInterestStoriesPage)
    }
    if (!startFetchInterestStories && selectedLabel !== 'all' && filterValue && callFrom === "home") {
      getUserInterestSortedStories(null, filterValue, userInterestStoriesPage)
    }
  }, [interestId,filterValue])


  useEffect(
    () => loadStories(),
    [
      callFrom,
      company,
      account,
      isSeriesOnly,
      seriesOnlyPage,
    ]
  )

  useEffect(() => {
    clearCompanyStories()
  }, [company])

  useEffect(() => {
    let initialVideos = filteredStories

    if (type === "Gallery") {
      setVideos(showHeader ? initialVideos : initialVideos.slice(0, 6))
      if (searchPressed) {
        afterSearchPressed()
      }
    } else if (type === "WorkdayGuide") {
      setVideos(initialVideos.slice(0, 6))
    } else {
      setVideos(initialVideos)
    }
  }, [
    filter,
    searchPressed,
    afterSearchPressed,
    showHeader,
    type,
    filteredStories,
  ])

  useEffect(() => {
    if (videos && callFrom.includes("home")) {
      intervalRef.current = setInterval(() => {
        const currentDate = new Date()
        let filteredVideos = videos.filter(video => {
          const unbroadcastDate = video.unbroadcast_date !== null ? new Date(video.unbroadcast_date) : video.unbroadcast_date;
          if(unbroadcastDate){
            return unbroadcastDate < currentDate;
          }
        });
        if (filteredVideos.length > 0) {
          store.dispatch({type: 'FILTERED_HOME_STORIES', payload: filteredVideos})
        }
      }, 2 * 60 * 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [videos]);


  const getMoreStories = () => {
    if (stopFetchingStories) return
    if (!hasMore || loading) return

    if (hasMore && !loading && callFrom === "home") {
      let companyId = undefined

      if (company) {
        companyId = company
      }

      if (account) {
        getStories(
          getPage(),
          companyId,
          callFrom,
          account.account_type,
          isSeriesOnly,
          seriesOnlyPage
        )
      } else {
        getStories(
          getPage(),
          companyId,
          callFrom,
          null,
          isSeriesOnly,
          seriesOnlyPage
        )
      }
      setVideos(filteredStories)
    } else {
      return
    }

    if (
      homeInterestHasMore &&
      !loading &&
      accountInteresetId &&
      interestId &&
      callFrom === "home"
    ) {
      getUserInterestSortedStories(interestId, null, userInterestStoriesPage)
    }
    if (
      homeInterestHasMore &&
      !loading &&
      selectedLabel &&
      filterValue !== null &&
      callFrom === "home"
    ) {
      getUserInterestSortedStories(null, filterValue, userInterestStoriesPage)
    }
  }

  const ids = filteredStories.map((story) => story.id)
  const filterVids = filteredStories.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  )
  const sortedVideos =
    (callFrom === "home" || callFrom === "company") && sort
      ? sortVideos(sort, filterVids)
      : filterVids
  const filterForRelatedVideos =
    callFrom === "peaVideo"
      ? sortedVideos.filter((video) => video.id !== currentVideo.id)
      : sortedVideos
  const filterCompanyVideos =
    callFrom === "company"
      ? filterForRelatedVideos.filter((video) => video.company === company)
      : filterForRelatedVideos
  const filteredVideos = filter
    ? filter(filterCompanyVideos)
    : filterCompanyVideos
  const filtered = labelValue
    ? filterStoriesByLabel(filteredVideos, labelValue)
    : filteredVideos
  const seriesOnlyVideos = isSeriesOnly
    ? filtered.filter((video) => video.series)
    : filtered
  const searchFilteredVideos =
    searchValue && !labelValue
      ? showSearchedStories(seriesOnlyVideos, searchValue)
      : seriesOnlyVideos

  const inModal = !showHeader

  const renderVideo = (video) => (
    <>
      <VideoItem
        peaPage={peaPage}
        key={video.id}
        format={video.format}
        video={video}
        isAuth={isAuth}
        inModal={inModal}
        disableHover={isCompanyPage ? true : false}
        companyPage={isCompanyPage}
        primaryId={video.primaryId ? video.primaryId : video.id}
        setShowPeaPageModal={setShowPeaPageModal}
      />
      {type === "WorkdayGuide" && <h3>{video.title}</h3>}
      <style jsx>
        {`
          h3 {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            color: #2f2e41;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        `}
      </style>
    </>
  )

  const renderVideoOrCarousel = (item) => {
    if (Array.isArray(item.url)) {
      let videoItems = item.url.map((video, index) =>
        renderVideo(
          {
            ...item,
            url: video,
            id: item.id + "_" + index,
            primaryId: item.id,
            labels: item.labels,
          },
          true
        )
      )
      let key = "carousel_" + item.id
      return (
        <VideoCarousel key={key} videoList={videoItems} carouselKey={key} />
      )
    } else {
      return renderVideo(item)
    }
  }

  const masonryOptions = {
    transitionDuration: "0.2s",
    gutter: 20,
    horizontalOrder: false,
    fitWidth: true,
    itemSelector: ".gridItem",
  }

  let masonry = (
    <Masonry
      options={masonryOptions}
      disableImagesLoaded={false}
      updateOnEachImageLoad={false}
      className={
        showHeader
          ? "grid"
          : companyPage
            ? "CompanyGallery"
            : "gridPeaVideoPage"
      }
    >
      {sortedVideos.length &&
        (!filteredVideos.length || !searchFilteredVideos.length) &&
        !loading ? (
        <div className="noVideoText">
          <h3>No videos matching your interest</h3>
        </div>
      ) : null}
      {searchFilteredVideos.length > 0
        ? searchFilteredVideos.map((item, index) => (
          <div
            className={`${inModal && !companyPage ? "gridItem gridItemModal" : "gridItem"
              } ${type === "Related" && inModal && "gridItemModal"}`}
            key={index}
          >
            {showTuneRecommendations(index) ? (
              <TuneRecommendations idKey={index + "_tune"} />
            ) : null}
            <section
              className={
                peaPage
                  ? "PeaVideoPageMobile"
                  : showHeader
                    ? "VideoContainer"
                    : "PeaVideoPageContainer"
              }
            >
              {renderVideoOrCarousel(item)}
              {item.title && !companyPage && (
                <section className="videoDescription">
                  <VideoData
                    categories={item.categories}
                    tags={item.tags}
                    title={item.title}
                  />
                </section>
              )}
            </section>
          </div>
        ))
        : null}
    </Masonry>
  )

  let masonryLoading = (
    <Masonry
      options={masonryOptions}
      disableImagesLoaded={false}
      updateOnEachImageLoad={false}
      className={
        showHeader
          ? "grid"
          : companyPage
            ? "CompanyGallery"
            : "gridPeaVideoPage"
      }
    >
      {peaLoaders.map((peaLoader) => {
        const { id, format } = peaLoader

        return (
          <div
            className={`${inModal && !companyPage ? "gridItem gridItemModal" : "gridItem"
              } ${type === "Related" && inModal && "gridItemModal"}`}
            key={id}
          >
            <PeaLoader format={format} />
          </div>
        )
      })}
    </Masonry>
  )

  if (loading && videos.length < 1) {
    return (
      <Masonry
        options={masonryOptions}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
        className={
          showHeader
            ? "grid"
            : companyPage
              ? "CompanyGallery"
              : "gridPeaVideoPage"
        }
      >
        {peaLoaders.map((peaLoader) => {
          const { id, format } = peaLoader

          return (
            <div
              className={`${inModal && !companyPage ? "gridItem gridItemModal" : "gridItem"
                } ${type === "Related" && inModal && "gridItemModal"}`}
              key={id}
            >
              <PeaLoader format={format} />
            </div>
          )
        })}
      </Masonry>
    )
  }

  return (
    <Fragment>
      {Array.isArray(videos) && videos.length > 0 ? (
        <>
          {!disableInfiniteScroll ? (
            <InfiniteScroll
              loadMore={getMoreStories}
              hasMore={hasMore}
              loader={<div key={0} className="Loading" />}
            >
              {masonry}
            </InfiniteScroll>
          ) : (
            { masonry }
          )}
          {loading && masonryLoading}
        </>
      ) : null}
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuthenticated,
  homePage: state.stories.homePage,
  companyPage: state.stories.companyPage,
  peaVideoPage: state.stories.peaVideoPage,
  loading: state.stories.storiesLoading,
  hasMore: state.stories.homeHasMore,
  homeInterestHasMore: state.stories.homeInterestHasMore,
  homeStories: state.stories.homeStories,
  companyStories: state.stories.companyStories,
  peaVideoStories: state.stories.peaVideoStories,
  showPeaPageModal: state.peaPageModal.showPeaPageModal,
  isSeriesOnly: state.searchBox.isSeriesOnly,
  seriesOnlyPage: state.stories.seriesOnlyPage,
  searchValue: state.searchBox.searchValue,
  labelValue: state.stories.labelValue,
  account: state.auth.account,
  interest: state.auth.interest,
  userInterestStoriesPage: state.stories.userInterestStoriesPage,
  stopFetchingStories: state.stories.stopFetchingStories,
  startFetchInterestStories: state.stories.startFetchInterestStories,
})

const mapDispatchToProps = (dispatch) => ({
  clearCompanyStories: () => dispatch(actions.clearCompanyStories()),
  setShowPeaPageModal: (data, id, openFrom, companyId) =>
    dispatch(actions.setShowPeaPageModal(data, id, openFrom, companyId)),
  getStories: (
    page,
    company,
    callFrom,
    account_type,
    isSeriesOnly,
    seriesOnlyPage
  ) =>
    dispatch(
      actions.getStories(
        page,
        company,
        callFrom,
        account_type,
        isSeriesOnly,
        seriesOnlyPage
      )
    ),
  getUserInterestSortedStories: (id, filterValue, userInterestStoriesPage) =>
    dispatch(
      actions.getUserInterestSortedStories(
        id,
        filterValue,
        userInterestStoriesPage
      )
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VideoPlayer))
