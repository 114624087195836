import React from "react";
import styles from "./Filter.module.scss";
import Button from "../Button/Button";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Filter = ({
  filters,
  type,
  selectFilter,
  selectedFilter,
  isSearchSubmitted,
  setIsSearchSubmitted,
  setSearchValue,
  setLabelValue
}) => {
  const handleFilterSelect = filter => {
    selectFilter(filter);
    setSearchValue(filter);
    setLabelValue(filter);
    type === "Home" && setIsSearchSubmitted(true);
  };

  return (
    <div className={cx(styles.Filter, styles[type])}>
      {filters.map(({ title, value, highlighted = false }) => (
        <Button
          key={title}
          btnType={`Filter${type}`}
          highlighted={highlighted}
          selected={selectedFilter === value}
          onClick={() => handleFilterSelect(value)}
        >
          {title}
        </Button>
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedFilter: state.searchBox.selectedFilter,
    isSearchSubmitted: state.searchBox.isSearchSubmitted
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setSearchValue: data => dispatch(actions.setSearchValue(data)),
    selectFilter: id => dispatch(actions.selectFilter(id)),
    setIsSearchSubmitted: value => dispatch(actions.setIsSearchSubmitted(value)),
    setLabelValue: value => dispatch(actions.setLabelValue(value))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
