import React from "react"
import { connect } from "react-redux"
import Button from "../../UI/Button/Button"
import { closeModals } from "../../../store/actions/modal.action"
import { ReactComponent as BackIcon } from "../../../icons/back.svg"
import broadcastingPodLogo from "../../../icons/logo/logo-broadcasting-pod.png"
import styles from "./HeaderBackButton.module.scss"

const HeaderBackButton = ({
  closeModals,
  setShowTooltip,
  setDefaultValue,
  setComment,
  setActiveThread,
  handleCommentDropdownToggle,
  setLabelError,
  setLinkError,
  hideErrorMessage,
  setPreviousCommentsUrl,
}) => {
  return (
    <div className={styles.HeaderBackButton}>
      <img
        src={broadcastingPodLogo}
        className={styles.Logo}
        alt="broadcasting-logo"
      />
      <Button
        onClick={() => {
          closeModals()
          if (setDefaultValue) {
            setDefaultValue("")
          }
          if (setComment) {
            setComment("")
          }
          if (setActiveThread) {
            setActiveThread(null)
          }
          if (setShowTooltip) {
            setShowTooltip(false)
          }
          if (handleCommentDropdownToggle) {
            handleCommentDropdownToggle()
          }
          if (setLabelError) {
            setLabelError("")
          }
          if (setLinkError) {
            setLinkError(false)
          }if (hideErrorMessage) {
            hideErrorMessage()
          }
          if (setPreviousCommentsUrl) {
            setPreviousCommentsUrl(null)
          }
        }}
        btnType="WhiteShadow"
        buttonStyle={styles.BackButton}
      >
        <BackIcon className={styles.BackIcon} />
      </Button>
    </div>
  )
}

export default connect(null, { closeModals })(HeaderBackButton)
