import React, { useRef, useState, useEffect, Children } from 'react';
import { Timeline, Tween } from 'react-gsap';
import Image from '../UI/Image/Image';
import Button from '../UI/Button/Button';
import watch from '../../icons/ic_watch.png';
import * as actions from '../../store/actions';

import styles from './HeroSection.module.scss';

import SwooshIcon from '../../icons/swoosh.svg';
import SwooshIconTopLeft from '../../icons/swoosh_top_left.png';
import SwooshIconBottomLeft from '../../icons/swoosh_bottom_left.png';
import SwooshIconBottomRight from '../../icons/swoosh_bottom_right.png';
import SwooshIconTopRight from '../../icons/swoosh_top_right.png';
import SwooshIconCenter from '../../icons/swoosh_center.png';
import SwooshIconCenterRight from '../../icons/swoosh_center_right.png';

import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';

function HeroSection({
  setShowLoginModal,
  setShowPeaPageModal,
  getTopStory,
  companyFeaturedVideo,
  loading,
}) {
  const [showJoin, setShowJoin] = useState(false);

  const isMobileDevice = useMediaQuery({
    query: 'only screen and (max-width: 1000px)',
  });
  const history = useHistory();

  useEffect(() => {
    console.log("yup", process.env.REACT_APP_PEABOX_COMPANY_ID);
    getTopStory(process.env.REACT_APP_PEABOX_COMPANY_ID);
  }, []);

  const navigateLogin = () => {
    if (isMobileDevice) {
      history.push('/sign-in');
    } else {
      setShowLoginModal(true);
      setShowJoin(true);
    }
  };

  const Animation = ({
    x1 = 0,
    y1 = 0,
    x2 = 0,
    y2 = 0,
    opacity1 = 0,
    opacity2 = 1,
    delay = 0,
    duration = 0,
    target,
  }) => {
    return (
      <Timeline>
        <Tween
          from={{ opacity: opacity1, y: y1, x: x1 }}
          to={{ y: y2, opacity: opacity2, x: x2 }}
          duration={duration}
          delay={delay}
        >
          {target}
        </Tween>
      </Timeline>
    );
  };
  return (
    <section className={styles.heroContainer}>
      <div className={styles.heroText}>
        <h1 className={styles.title}>
          Transform your <span>Org</span> into a Studio and your{' '}
          <span>People</span> into the Producers and Stars
        </h1>
        <p className={styles.subtitle}>
          Safely manage company & user generated videos (UGC). Schedule &
          Broadcast as helpful micro-tv programmes, across targeted workday
          channels & segments
        </p>
        <div className={styles.callToAction}>
          <Button
            btnType='Blue'
            buttonStyle={styles.StartButton}
            onClick={() => navigateLogin()}
          >
            START FREE
          </Button>

          <div className={styles.subCTA}>
            {loading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                />
                <span className='sr-only'>Loading...</span>
              </>
            ) : (
              <>
                <Image src={watch} alt='' />

                <span className={styles.MainSpan}>
                  Watch this
                  <Link
                    to={`/pea/${companyFeaturedVideo?.id}`}
                    className={styles.VideoWrapper}
                    onClick={() => {
                      // setShowPeaPageModal(true, companyFeaturedVideo?.id, "business", process.env.REACT_APP_PEABOX_COMPANY_ID)
                      setShowPeaPageModal(
                        null,
                        null,
                        'business',
                        process.env.REACT_APP_PEABOX_COMPANY_ID
                      );
                      setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }, 2000);
                    }}
                  >
                    <span className={styles.microHero}>Micro-Hero</span>
                  </Link>
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.SwooshContainer}>
        <div className={styles.SwooshTopLine}>
          <Animation
            x1={'-30%'}
            duration={1}
            delay={2.0}
            target={<img src={SwooshIconTopLeft} height={'48px'}></img>}
          ></Animation>

          <Animation
            y1={'-30%'}
            duration={1}
            delay={1.2}
            target={<img src={SwooshIcon}></img>}
          />
          <Animation
            x1={'-30%'}
            duration={1}
            delay={2.0}
            target={<img src={SwooshIconTopRight}></img>}
          />
        </div>
        <div className={styles.SwooshCenterLine}>
          <Animation
            y1={'50%'}
            duration={1.5}
            delay={0.2}
            target={
              <div>
                <img src={SwooshIconCenter}></img>
                <img src={SwooshIconCenterRight} />
              </div>
            }
          />
        </div>
        <div className={styles.SwooshBottomLine}>
          <Animation
            x1={'-30%'}
            duration={1}
            delay={2.0}
            target={<img src={SwooshIconBottomLeft}></img>}
          />
          <Animation
            x1={'-30%'}
            duration={1}
            delay={2.0}
            target={<img src={SwooshIconBottomRight}></img>}
          />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    shouldDoHomeAnimation: state.searchBox.shouldDoHomeAnimation,
    companyFeaturedVideo: state.stories.companyFeaturedVideo,
    loading: state.stories.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowLoginModal: (id) => dispatch(actions.setShowLoginModal(id)),
    setShowPeaPageModal: (data, id, openFrom, companyId) =>
      dispatch(actions.setShowPeaPageModal(data, id, openFrom, companyId)),
    getTopStory: (a) => {
      dispatch(actions.getTopStory(a));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
