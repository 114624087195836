import React from "react";
import styles from "./Card.module.scss";
import classNames from "classnames";

const Card = ({ cardStyles, children, cardRef, ...props }) => {
  return (
    <div
      ref={cardRef}
      className={classNames(styles.Card, cardStyles && cardStyles)}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
