import store from "../store/store";
import { getNotifications, getNotificationsCount, refreshNotifications } from "../store/actions"
import { arrayBuffToJson } from "./utils";

export const watchCommentNotification = ({ buff, account, company }) => {
	let parsedData = null
	try {
		if(buff !== "connection established"){
			parsedData = JSON.parse(buff);
		}
	  } catch (e) {
		// let uIntParse = new Uint16Array(buff);
		// let buffer = arrayBuffToJson(uIntParse);
		// parsedData = JSON.parse(buffer);
	  }
	try {
		if (parsedData?.event === 'comment' && parsedData?.data?.company?.id === company?.id) {
			// if (buff?.data?.notifExclusive && buff?.data?.notifExclusive === account.id) {
			// 	store.dispatch({ type: 'INCREMENT_NOTIFICATION', payload: buff?.data?.pea })	
			// }
			// store.dispatch({ type: 'INCREMENT_NOTIFICATION', payload: buff?.data?.pea })
			store.dispatch(refreshNotifications("false", "comment"))
			store.dispatch(getNotificationsCount("false", "comment"))
			// store.dispatch({ type: 'INCREASE_NOTIFICATION_COUNT', payload: 1 })
		}
	} catch (e) { }
};

export default null;