
import React from "react";
import { themes } from '../../../const'
const Avatar =  (props) => {
  const {size, sizeMobile, src, className, alt} = props

  return (
    <>
      <div className={`avatar ${className || ''}`}>
        {src && <img src={src} alt={alt} />}
      </div>
      <style jsx>
        {`
          .avatar{
            position: relative;
            width: ${size}px;
            height: ${size}px;
            flex: 0 0 ${size}px;
            border-radius: 50%;
            overflow: hidden;

          }
            @media ${themes.breakpointsDown.md}{
                .avatar{
                    width: ${sizeMobile ? sizeMobile : size}px;
                    height: ${sizeMobile ? sizeMobile : size}px;
                    height: flex ${sizeMobile ? sizeMobile : size}px;
                }
            }

          .avatar img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            margin: 0 !important;
          }
        `}
      </style>
    </>
  )
}

export default Avatar