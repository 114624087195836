import React, { Fragment, useRef, useEffect, useState } from "react"
import dayjs from "dayjs"
import { connect } from "react-redux"
import { Col, Form, Image, Row } from "react-bootstrap"
import {
  getComments,
  paginateComments,
} from "../../../store/actions/storiesActions"
import styles from "../PeaVideo.module.scss"

function Comments({
  comments,
  addComment,
  pea,
  isAuth,
  getComments,
  account,
  paginateComments,
  commentNextPageUrl,
  loadingComments,
}) {
  const [showComments, setShowComments] = useState(false)
  let commentsRef = useRef()

  useEffect(() => {
    handleGetComments()
  }, [getComments, pea.id])

  const handleGetComments = async () => {
    const shouldShowComments = await getComments(
      pea.id,
      false,
      null,
      "peaVideo"
    )
    if (shouldShowComments) {
      setShowComments(true)
    }
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && e.target.value) {
      addComment(pea.id, e.target.value)
      e.target.value = ""
    }
  }

  const getDisplayName = (account) => {
    if (account.username) {
      return account.username
    } else if (account.first_name) {
      return account.first_name
    } else {
      return account.email
    }
  }

  const handleScroll = (e) => {
    const bottom =
      Math.floor(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight ||
      Math.floor(e.target.scrollHeight - e.target.scrollTop) + 1 ===
        e.target.clientHeight

    if (bottom && commentNextPageUrl && !loadingComments) {
      paginateComments(commentNextPageUrl, "peaVideo")
    }
  }

  if (!account) {
    return <></>
  }

  return (
    <section className={styles.CommentsWrapper} onScroll={handleScroll}>
      <Row
        ref={commentsRef}
        className={styles.CommentsList}
        id="ContainerElementID"
      >
        {comments.length > 0 && showComments
          ? comments
              .filter((comment) => comment.pea === pea.id)
              .map((comment, key) => (
                <Col
                  key={key}
                  xs={12}
                  sm={12}
                  className={styles.CommentWrapper}
                >
                  <Row style={{ alignItems: "center" }}>
                    <Fragment>
                      <Col
                        md={1}
                        xs="auto"
                        lg={2}
                        className={styles.CommentsAvatar}
                        key={key}
                      >
                        <Image
                          src={comment.author.account_image}
                          roundedCircle
                        />
                      </Col>
                      <Col lg={10} md={11} xs>
                        <div className={styles.Comment} key={key}>
                          <strong>{getDisplayName(comment.author)}</strong>{" "}
                          {dayjs(comment.created_at).format("MMM DD, YYYY")}{" "}
                          <br />
                          {comment.comment}
                        </div>
                      </Col>
                    </Fragment>
                  </Row>
                </Col>
              ))
          : null}
      </Row>
      {loadingComments &&
                    <div className={styles.commentScenter}>
                     <span
                     className="spinner-border spinner-border-sm"
                     role="status"
                     aria-hidden="true"
                   />
                   <span className="sr-only">Loading...</span>
                   </div>
                   }  
      {isAuth && (
        <Row className={styles.CommentsForm}>
          <Col lg={2} md={1} xs="auto" className={styles.CommentsAvatar}>
            <Image src={account.account_image} roundedCircle />
          </Col>
          <Col lg={10} md={11} xs>
            <Form.Control
              type="text"
              placeholder="Add a comment"
              onKeyUp={handleKeyUp}
              className={styles.CommentFormControl}
            />
          </Col>
        </Row>
      )}
    </section>
  )
}

const mapStateToProps = (state) => ({
  comments: state.stories.comments,
  commentNextPageUrl: state.stories.peaVideoCommentNextPageUrl,
  loadingComments: state.stories.peaVideoLoadingComments,
})

export default connect(mapStateToProps, { getComments, paginateComments })(
  Comments
)
