import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { Timeline, Tween } from 'react-gsap';
import { useHistory } from 'react-router-dom';
import { isSafari } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Row, Col, Container } from 'react-bootstrap';
import Gallery from '../Gallery/Gallery';
import CMS from '../../cms/HomeContent.json';
import * as actions from '../../store/actions';
import SmallIcon5 from '../../icons/swoosh.svg';
import SmallIcon2 from '../../icons/super_peaM.svg';
import Filter from '../../components/UI/Filter/Filter';
import Background from '../../icons/Ilustracao_Peabox_V4-01.svg';
import SmallIcon3 from '../../icons/Ilustracao_Peabox_V3-06.svg';
import SmallIcon4 from '../../icons/Ilustracao_Peabox_V3-05.svg';
import { useCurrentHeight } from '../../components/HOCs/WindowSize';
import BackgroundEmpty from '../../icons/Ilustracao_Peabox_V3-03.svg';
import styles from './Home.module.scss';

const ForgotPassword = loadable(() =>
  import('../Auth/ForgotPassword/ForgotPassword')
);

const Home = ({
  isSearchSubmitted,
  isHomeVisited,
  shouldDoHomeAnimation,
  setShouldDoHomeAnimation,
  setIsHomeVisited,
  isHomeAuthenticated,
  setHomeAuthenticated,
  isAuth,
  account,
}) => {
  const [documentScrollTop, setDocumentScrollTop] = useState(0);
  const history = useHistory();
  const [authToken, setAuthToken] = useState(null);

  const {
    en: { title, description },
  } = CMS;
  const timelineHome = useRef(null);
  const timelineGallery = useRef(null);
  let windowSize = useCurrentHeight(),
    windowHeight = windowSize.height;

  const isMobileDevice = useMediaQuery({ query: '(max-width: 768px)' });
  const isTabletDevice = useMediaQuery({ minWidth: 769, maxWidth: 1199 });

  const cookies = localStorage.getItem('showCookiesMessage') !== 'false';

  const FILTERS = [
    { title: 'Wellness@Work', value: 'wellness@work', highlighted: true },
    { title: 'How-to-Heroes!', value: 'how-to-heroes!' },
    { title: 'Hiring-Heroes!', value: 'hiring-heroes!' },
    { title: 'Fashion@Work', value: 'fashion@work', highlighted: true },
    { title: 'Create@Work', value: 'create@work' },
    { title: 'Fitness@Work', value: 'fitness@work', highlighted: true },
    { title: 'Leaders@Work', value: 'leaders@work' },
    { title: 'Home@Work', value: 'home@work' },
    { title: 'Vegan@Work', value: 'vegan@work' },
    { title: 'Learning@Work', value: 'learning@work', highlighted: true },
    { title: 'Trailblazers!', value: 'trailblazers!' },
    { title: 'My-First-Job', value: 'my-first-job' },
  ];

  const handleScroll = () => {
    setDocumentScrollTop(document.scrollingElement.scrollTop || 0);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const renderSmallIcons = () => (
    <div className={styles.SmallIconWrapper}>
      <img
        src={SmallIcon2}
        className={`${styles.SmallIcon2} ${
          cookies ? styles.SmallIcon2Cookies : ''
        }`}
        alt=''
      />
      <img
        src={SmallIcon3}
        className={`${styles.SmallIcon3} ${
          cookies ? styles.SmallIcon3Cookies : ''
        } ${
          isSafari && cookies
            ? styles.SmallIcon3CookiesSafari
            : isSafari
            ? styles.SmallIcon3Safari
            : ''
        }`}
        alt=''
      />
      <img
        src={SmallIcon4}
        className={`${styles.SmallIcon4} ${
          cookies ? styles.SmallIcon4Cookies : ''
        } ${
          isSafari && cookies
            ? styles.SmallIcon4CookiesSafari
            : isSafari
            ? styles.SmallIcon4Safari
            : ''
        }`}
      />
    </div>
  );

  useEffect(() => {
    if (isHomeVisited) {
      return;
    }

    let timelineGsapHome = timelineHome?.current?.getGSAP();
    let timelineGsapGallery = timelineGallery?.current?.getGSAP();

    if (timelineGsapHome && timelineGsapGallery) {
      if (isSearchSubmitted && !shouldDoHomeAnimation) {
        timelineGsapHome.pause();
        timelineGsapHome.progress(1);
        timelineGsapGallery.pause();
        timelineGsapGallery.progress(1);
      } else if (isSearchSubmitted && shouldDoHomeAnimation) {
        timelineGsapHome.play();
        timelineGsapGallery.play();
      } else {
        timelineGsapHome.pause();
        timelineGsapHome.progress(0);
        timelineGsapGallery.pause();
        timelineGsapGallery.progress(0);
      }
    }
  }, [
    timelineHome,
    timelineGallery,
    isSearchSubmitted,
    shouldDoHomeAnimation,
    isHomeVisited,
  ]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
      } catch (error) {
        console.log('Error at Fetch Token: ', error);
      }
    };

    getToken();

    if (isHomeAuthenticated === undefined) {
      setHomeAuthenticated(isAuth);
    } else if (isAuth && isAuth !== isHomeAuthenticated) {
      setHomeAuthenticated(isAuth);
    } else if (!isAuth) {
      setHomeAuthenticated(isAuth);
    }

    if (account?.account_type !== 'Basic' && isAuth && authToken) {
      history.push('/broadcasting-pod');
    }
  }, [isAuth, isHomeAuthenticated, setHomeAuthenticated, authToken]);

  let durationAnimation = 1.5;

  return (
    <>
      {!isMobileDevice && <ForgotPassword />}
      {localStorage.getItem('token') && isAuth ? (
        <div hidden={false} className={styles.gallery}>
          <Gallery />
        </div>
      ) : isHomeVisited ? (
        <div hidden={false} className={styles.gallery}>
          <Gallery />
        </div>
      ) : (
        <>
          <Timeline
            onComplete={() => {
              setShouldDoHomeAnimation(false);
              setIsHomeVisited();
            }}
            paused
            ref={timelineHome}
            target={
              <div className={styles.WrapperHome}>
                {isMobileDevice ? (
                  <img src={BackgroundEmpty} className={styles.Background} />
                ) : isTabletDevice ? (
                  <img src={Background} className={styles.Background} />
                ) : (
                  <img src={Background} className={styles.Background} />
                )}
                {isMobileDevice && renderSmallIcons()}
                <section className={styles.ScrollableArea}>
                  <img
                    src={SmallIcon5}
                    alt='Swoosh'
                    className={`${styles.SmallIcon5}`}
                  />
                  <div className={styles.Home}>
                    <div
                      className={`${styles.HomeTitle} ${
                        cookies ? styles.HomeTitleCookies : ''
                      }`}
                    >
                      <h1>
                        {title}
                        <br />
                        <strong>{description}</strong>
                      </h1>
                    </div>
                    <Filter filters={FILTERS} type='Home' />
                  </div>
                </section>
              </div>
            }
          >
            <Tween
              from={{ position: 'absolute' }}
              to={{ y: -windowHeight }}
              ease='Linear.easeNone'
              duration={durationAnimation}
            />
            <Tween
              to={{ hidden: true }}
              ease='Linear.easeNone'
              duration={durationAnimation}
            />
          </Timeline>
          <Timeline
            ref={timelineGallery}
            paused
            target={
              <div hidden={!isSearchSubmitted} className={styles.gallery}>
                <Container>
                  <Row className='justify-content-center'>
                    <Col lg={10}>
                      <Gallery />
                    </Col>
                  </Row>
                </Container>
              </div>
            }
          >
            <Tween
              from={{ y: windowHeight - documentScrollTop }}
              to={0}
              duration={durationAnimation}
              ease='Linear.easeNone'
            />
            <Tween
              from={{ pointerEvents: 'none' }}
              to={{ pointerEvents: 'all' }}
              duration={durationAnimation}
            />
          </Timeline>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuthenticated,
  touched: state.searchBox.touched,
  isSearchSubmitted: state.searchBox.isSearchSubmitted,
  shouldDoHomeAnimation: state.searchBox.shouldDoHomeAnimation,
  isHomeAuthenticated: state.searchBox.isHomeAuthenticated,
  isHomeVisited: state.searchBox.isHomeVisited,
  account: state.auth.account,
});

const mapDispatchToProps = (dispatch) => ({
  setTouched: (touched) => dispatch(actions.setTouched(touched)),
  setIsSearchSubmitted: (value) =>
    dispatch(actions.setIsSearchSubmitted(value)),
  setShouldDoHomeAnimation: (value) =>
    dispatch(actions.setShouldDoHomeAnimation(value)),
  setHomeAuthenticated: (value) =>
    dispatch(actions.setHomeAuthenticated(value)),
  setIsHomeVisited: () => dispatch(actions.setIsHomeVisited()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
