import React from 'react';
import Footer from '../../components/Footer';
import HeroSection from '../../components/HeroSection';

const BusinessLandingPage = () => {
    return (
        <div>
            <main>
                <HeroSection />
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}

export default BusinessLandingPage;