import React from "react"
import classNames from "classnames"
import styles from "./BasicDropdown.module.scss"

const BasicDropdown = ({ dropdownStyles, isVisible, children, onScroll}) => {
  if (!isVisible) {
    return <></>
  }

  return (
    <div id="notifDropdown" className={classNames(styles.BasicDropdown, dropdownStyles)} onScroll={onScroll}>
      {children}
    </div>
  )
}

export default BasicDropdown
