import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import Button from '../../../UI/Button/Button';
import styles from './FollowingItem.module.scss';

const cx = classNames.bind(styles);

const FollowingItem = ({
  item,
  type,
  showButton = true,
  onFollow,
  onUnfollow,
  isFollowing,
  following,
  companies,
  searchInfoModal,
  account,
}) => {
  const history = useHistory();
  const followingItemCompany =
    type === 'Story'
      ? companies.find((company) => company.id === item.company)
      : null;

  const navigateTo = (item) => {
    if (type === 'Story') {
      return `/pea/${item.id}`;
    } else if (type === 'Company') {
      return `/company/${item.company.id}`;
    } else {
      return `/company/${item.id}`;
    }
  };

  return (
    <>
      {item?.deactivated_at ? (
        <></>
      ) : (
        <div className={cx(styles.FollowingItem, styles[type])}>
          <Link
            to={
              type === 'Story'
                ? `/pea/${item?.id}`
                : type === 'Company'
                ? `/company/${item?.id}`
                : `/${item?.name?.replaceAll(' ', '').toLowerCase()}/peabox.tv`
            }
            className={styles.CompanyLink}
          >
            <div
              className={styles.IconWrapper}
              onClick={() => searchInfoModal(false)}
            >
              <img
                alt='Company'
                src={type === 'Company' ? item.company.image : item.image}
                className={styles.Icon}
              />
            </div>
          </Link>

          <div className={styles.ItemDetails}>
            <Link
              to={
                type === 'Story'
                  ? `/pea/${item?.id}`
                  : type === 'Company'
                  ? `/company/${item?.id}`
                  : `/${item?.id}/peabox.tv`
              }
              className={styles.CompanyLink}
            >
              <div
                className={styles.Name}
                onClick={() => {
                  searchInfoModal(false);
                }}
              >
                {type === 'Story'
                  ? item.title
                  : type === 'Company'
                  ? item.company.name
                  : item.name}
              </div>
              <div
                className={styles.Description}
                onClick={() => {
                  searchInfoModal(false);
                }}
              >
                {type === 'Story' ? (
                  <>
                    {followingItemCompany ? (
                      <>by {followingItemCompany.name}</>
                    ) : null}
                  </>
                ) : type === 'Company' ? (
                  item.company.description
                ) : (
                  item.description
                )}
              </div>
            </Link>
          </div>
          {showButton && (
            <div className={styles.ButtonWrapper}>
              <Button
                btnType={isFollowing ? 'Success' : 'Follow'}
                key={isFollowing ? 'following' : 'follow'}
                onClick={() => {
                  if (account && account.deactivated_at) {
                    toast.error(
                      'Following or unFollowing company is not allowed for deactivated accounts.'
                    );
                  } else {
                    if (!isFollowing) {
                      onFollow();
                    } else {
                      if (following && following.length > 0) {
                        const followItem = following.find(
                          (followingItem) =>
                            followingItem.company.id === item.id
                        );
                        onUnfollow({ followingItem: followItem.id });
                      } else {
                        onUnfollow({ followingItem: item.id });
                      }
                    }
                  }
                }}
              >
                {isFollowing ? 'Following' : 'Follow'}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FollowingItem;
