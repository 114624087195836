import React from "react"
import { connect } from "react-redux"
import { useMediaQuery } from "react-responsive"
import { Link, useHistory } from "react-router-dom"
import Button from "../UI/Button/Button"
import * as actions from "../../store/actions"
import snackBox from "../../icons/recommendation/snack-box.png"
import "./TuneRecommendations.css"

const TuneRecommendations = ({
  idKey,
  channels,
  account,
  setShowLoginModal,
}) => {
  const history = useHistory()
  const isMobileDevice = useMediaQuery({
    query: "only screen and (max-width: 768px)",
  })

  const getDisplayName = () => {
    if (account.username) {
      return account.username
    } else if (account.first_name) {
      return account.first_name
    } else {
      return account.email
    }
  }

  return (
    <div className="RecommendationBox" key={idKey}>
      <div className="RecommendationInfo">
        <span className="UserName">{getDisplayName()},</span>
        <br />
        <p>Build your own Snack box</p>
      </div>
      <div className="ButtonWrapper buttonWrapperDesktop">
        {account && account.account_type === "Basic" ? (
          <Link className="LinkWrapper" to="customize-interests">
            <Button btnType="Recommendation">
              {channels.filter((channel) => channel.selected === true).length >
              0
                ? "Done for today"
                : "Build Now"}
            </Button>
          </Link>
        ) : (
          <div
            className="LinkWrapper"
            onClick={() => {
              if (!isMobileDevice) {
                setShowLoginModal(true)
              } else {
                history.push("/sign-in")
              }
            }}
          >
            <Button btnType="Recommendation">
              {channels.filter((channel) => channel.selected === true).length >
              0
                ? "Done for today"
                : "Build Now"}
            </Button>
          </div>
        )}
      </div>
      <div className="RecommendationInfoMobile">
        <span className="UserName">{getDisplayName()},</span>
        <p>Build your own Snack box</p>
        <div className="ButtonWrapper">
          {account && account.account_type === "Basic" ? (
            <Link className="LinkWrapper" to="customize-interests">
              <Button btnType="Recommendation">
                {channels.filter((channel) => channel.selected === true)
                  .length > 0
                  ? "Done for today"
                  : "Build Now"}
              </Button>
            </Link>
          ) : (
            <div
              className="LinkWrapper"
              onClick={() => {
                if (!isMobileDevice) {
                  setShowLoginModal(true)
                } else {
                  history.push("/sign-in")
                }
              }}
            >
              <Button btnType="Recommendation">
                {channels.filter((channel) => channel.selected === true)
                  .length > 0
                  ? "Done for today"
                  : "Build Now"}
              </Button>
            </div>
          )}
        </div>
      </div>
      <img className="snackBoxImg" src={snackBox} alt="Snack Box" />
    </div>
  )
}

const mapStateToProps = (state) => ({
  channels: state.interests.channels,
  account: state.auth.account,
})

const mapDispatchToProps = (dispatch) => ({
  setShowLoginModal: (id) => dispatch(actions.setShowLoginModal(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TuneRecommendations)
