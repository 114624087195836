import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import * as actions from "../../store/actions"
import Header from "../../components/Header/Header"
import styles from "./Layout.module.scss"

const Layout = ({
  searchValue,
  setSearchValue,
  isHomeVisited,
  children,
  togglePeaDropdown,
  toggleWorkdayGuideSortbyFilter,
  toggleWorkdayGuideDesiredEffectFilter,
}) => {
  const { pathname } = useLocation()
  const [showMiniLogo, setShowMiniLogo] = useState(true)
  const [headerType, setHeaderType] = useState(null)
  const [showHeader, setShowHeader] = useState(true)
  const [watchlistDropdownVisible, setWatchlistDropdownVisible] =
    useState(false)
  const [followingDropdownVisible, setFollowingDropdownVisible] =
    useState(false)
  const [airDropDropdownVisible, setAirDropDropdownVisible] = useState(false)

  const changeHeaderConfig = () => {
    if(pathname.includes("/business")){
      setHeaderType("BusinessLandingPage")
    }
    if (
      pathname === "/" ||
      (pathname.includes("/company") && /\d/.test(pathname))
    ) {
      setHeaderType("Gallery")
      if (isHomeVisited) {
        setShowMiniLogo(false)
      }
    } else if (pathname.includes("/settings/basic")) {
      setHeaderType("Settings")
      setShowMiniLogo(false)
    } else if (
      pathname.includes("/broadcasting-pod") ||
      pathname.includes("/settings/business") ||
      pathname.includes("/settings/company")
    ) {
      setHeaderType("Pod")
      setShowMiniLogo(false)
    } else if (pathname.includes("/embed/pea")) {
      setShowHeader(false)
    } else if (pathname.includes("/workday-guide")) {
      setHeaderType("Workday")
      setShowMiniLogo(false)
    }
  }

  useEffect(() => {
    changeHeaderConfig()
    // eslint-disable-next-line
  }, [isHomeVisited, pathname])

  const handleMainClick = (e) => {
    setAirDropDropdownVisible(false)
    setWatchlistDropdownVisible(false)
    setFollowingDropdownVisible(false)
    if (e.target.id !== "openPeaDropdown") {
      togglePeaDropdown(null, "")
    }
  }

  return (
    <div
      className={`${styles.Layout} ${
        pathname === "/settings/basic" || pathname === "/settings/company"
          ? styles.Mobilelayout
          : ""
      }`}
    >
      <ToastContainer />
      {showHeader && (
        <div
          onClick={() => {
            togglePeaDropdown(null, "")
            toggleWorkdayGuideSortbyFilter()
            toggleWorkdayGuideDesiredEffectFilter()
          }}
        >
          <Header
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            type={headerType}
            setType={setHeaderType}
            minilogo={showMiniLogo}
            airDropDropdownVisible={airDropDropdownVisible}
            setAirDropDropdownVisible={setAirDropDropdownVisible}
            watchlistDropdownVisible={watchlistDropdownVisible}
            setWatchlistDropdownVisible={setWatchlistDropdownVisible}
            followingDropdownVisible={followingDropdownVisible}
            setFollowingDropdownVisible={setFollowingDropdownVisible}
            isWorkdDayGuidePage={pathname.includes("/workday-guide")}
          />
        </div>
      )}
      <main className={styles.MainContainer} onClick={handleMainClick}>
        {children}
      </main>
    </div>
  )
}

const mapStateToProps = (state) => ({
  searchValue: state.searchBox.searchValue,
  isHomeVisited: state.searchBox.isHomeVisited,
  selectedFilter: state.searchBox.selectedFilter,
  peaDropdownOpen: state.stories.peaDropdownOpen,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchValue: (data) => dispatch(actions.setSearchValue(data)),
    togglePeaDropdown: (e, id) => dispatch(actions.togglePeaDropdown(e, id)),
    toggleWorkdayGuideSortbyFilter: (event) =>
      dispatch(actions.toggleWorkdayGuideSortbyFilter(event)),
    toggleWorkdayGuideDesiredEffectFilter: (event) =>
      dispatch(actions.toggleWorkdayGuideDesiredEffectFilter(event)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
