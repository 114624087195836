import React, { useEffect, useState } from "react"
import Iframe from "react-iframe"
import classNames from "classnames"
import styles from "./Embed.module.scss"

const Embed = ({ link, type, embedStyles, parentWidth, parentHeight, ...props }) => {
  const [url, setUrl] = useState('')
  const [title, setTitle] = useState('')

  useEffect(()=>{
    setUrl(link)
    setTitle(link)
  },[link])
  useEffect(() => {
    if (type === "Canva") {
      setUrl(`${link}?embed`)
    } else if (type === "Eventbrite") {
      const splitLink = link.split("-")
      const embedId = splitLink[splitLink.length - 1]
      setUrl(embedId)
    }

    if (type === "Crosswordlabs") {
      setTitle(
        link
          .split("/")
          [link.split("/").length - 1].split("-")
          .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
          .join(" ")
      )
    }
  }, [link, type])

  useEffect(() => {
    if (type === "Eventbrite") {
      window.EBWidgets.createWidget({
        widgetType: "checkout",
        eventId: parseInt(url),
        iframeContainerId: `eventbrite-widget-container-${url}`,
      })
    }
  }, [url, type])

  if (type === "Glassdoor") {
    const glassDoorWidth = parentWidth ? parentWidth : 300; 
    const glassDoorHeight = parentHeight ? parentHeight : 402;
    return (
      <>
        {link ? (
          <div
            style={{ height: "100%", width: "100%", overflowX: "hidden" }}
            className={classNames(
              styles.Embed,
              styles.Embed_Glassdoor,
              embedStyles && embedStyles,
              styles.GDWidget
            )}
            dangerouslySetInnerHTML={{
              __html: `<iframe scrolling="no" src="https://www.glassdoor.com/api/api.htm?version=1&amp;action=employer-review&amp;t.s=w-m&amp;format=${glassDoorWidth}x${glassDoorHeight}&amp;employerId=${link}&amp;t.a=i&amp;responseType=embed" name="widgetIFrame" style="visibility: visible; border: none; overflow: hidden; width: 100%; height: 405px;"></iframe>`,
            }}
          />
        ) : null}
      </>
    )
  }

  if (type === "Crosswordlabs") {
    return (
      <>
        {link ? (
          <div
            style={{ height: "100%" }}
            className={classNames(styles.Embed, embedStyles && embedStyles)}
            dangerouslySetInnerHTML={{
              __html: `
              <div style="display:flex; flex-direction: column; height: 400px;">
                <p style="margin: 0 auto; font-size: 20px; font-weight: 600; z-index: 2;">${title}</p>
                <iframe style="height: 90%; top: 10%" margin-top: 10px; width:inherit; margin:auto; display:block" frameborder="0" src=${link}></iframe>
              </div>
              `,
            }}
          />
        ) : null}
      </>
    )
  }

  return type === "Eventbrite" ? (
    <div
      style={{ height: "100%" }}
      id={`eventbrite-widget-container-${url}`}
      className={classNames(embedStyles)}
    ></div>
  ) : (
    <Iframe
      url={url}
      className={classNames(styles.Embed, embedStyles && embedStyles)}
      {...props}
    />
  )
}

export default Embed
