export const sortVideos = (sort, videos) => {
  // console.log('this are the videos', videos);
  if (!sort || !videos) return;

  switch (sort.value) {
    case 'most_likes':
      return videos.sort((a, b) => {
        if (a.likes.length > b.likes.length) return -1;
        if (a.likes.length < b.likes.length) return 1;
        return 0;
      });
    case 'most_recent':
      return videos.sort((a, b) => {
        if (Date.parse(a.created_at) > Date.parse(b.created_at)) return -1;
        if (Date.parse(a.created_at) < Date.parse(b.created_at)) return 1;
        return 0;
      });
    case 'most_popular':
      return videos.sort((a, b) => {
        if (
          a.views_count + a.likes.length + a.shares >
          b.views_count + b.likes.length + b.shares
        )
          return -1;
        if (
          a.views_count + a.likes.length + a.shares <
          b.views_count + b.likes.length + b.shares
        )
          return 1;
        return 0;
      });
    case 'short_life':
      return videos.sort((a, b) => {
        if (a?.short_life_hrs > b?.short_life_hrs) return -1;
        if (a?.short_life_hrs < b?.short_life_hrs) return 1;
        return 0;
      });

    default:
      return videos;
  }
};

export const splitByColumns = (videos, page, isMobileDevice) => {
  let columns = isMobileDevice ? 1 : 4,
    current = 0;
  let results = Array.from(Array(4), () => []);

  videos.forEach((element, index) => {
    results[current++].push({ ...element, id: page + '_' + index });

    if (current === columns) {
      current = 0;
    }
  });

  return {
    col1: results[0],
    col2: results[1],
    col3: results[2],
    col4: results[3],
  };
};
