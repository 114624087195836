import {
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_START,
  GET_ACCOUNTS_SUCCESS,
  DELETE_COMPANY_ACCOUNT_START,
  DELETE_COMPANY_ACCOUNT_SUCCESS,
  DELETE_COMPANY_ACCOUNT_FAIL,
  MAKE_AS_ADMIN_START,
  MAKE_AS_ADMIN_SUCCESS,
  MAKE_AS_ADMIN_FAIL,
  MAKE_AS_USER_START,
  MAKE_AS_USER_SUCCESS,
  MAKE_AS_USER_FAIL,
} from "../types"

const initialState = {
  loading: false,
  memberLoading: false,
  accounts: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_ACCOUNTS_START:
      return { ...state, loading: true }
    case GET_ACCOUNTS_SUCCESS:
      return { ...state, loading: false, accounts: payload }
    case GET_ACCOUNTS_FAIL:
      return { ...state, loading: false }
    case DELETE_COMPANY_ACCOUNT_START:
      return { ...state, memberLoading: true }
    case DELETE_COMPANY_ACCOUNT_SUCCESS:
      return {
        ...state,
        memberLoading: false,
        accounts: state.accounts.filter((account) => account.id !== payload),
      }
    case DELETE_COMPANY_ACCOUNT_FAIL:
      return { ...state, memberLoading: false }
    case MAKE_AS_ADMIN_START:
    case MAKE_AS_USER_START:
      return { ...state, memberLoading: true }
    case MAKE_AS_ADMIN_FAIL:
    case MAKE_AS_USER_FAIL:
      return { ...state, memberLoading: false }
    case MAKE_AS_ADMIN_SUCCESS:
    case MAKE_AS_USER_SUCCESS:
      return {
        ...state,
        memberLoading: false,
        accounts: [
          ...state.accounts.filter((account) => account.id !== payload.id),
          payload,
        ],
      }
    default:
      return state
  }
}
