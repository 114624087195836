import React from "react";
import classNames from "classnames";

const Image = ({ src, imageStyles, ...props }) => {
  return (
    <img className={classNames("lazyload", imageStyles)} src={src} {...props} />
  );
};

export default Image;
