import fiftyTwoThenSeventeen from './icons/labels/52-mins-then-17.jpeg';
import bathroomBreaks from './icons/labels/bathroom-breaks.jpeg';
import coffeePod from './icons/labels/coffee-pod.jpeg';
import commute from './icons/labels/commute.jpeg';
import cookAlong from './icons/labels/cook-along.jpeg';
import creative from './icons/labels/creative.jpeg';
import employeeAssist from './icons/labels/employee-assist.jpeg';
import finances from './icons/labels/finances.jpeg';
import gamingGroup from './icons/labels/gaming-group.jpeg';
import gymBites from './icons/labels/gym-bites.jpeg';
import happyHour from './icons/labels/happy-hour.jpeg';
import inspireMe from './icons/labels/inspire-me.jpeg';
import learningPod from './icons/labels/learning-pod.jpeg';
import mindBody from './icons/labels/mind-body.jpg';
import onDemandPeas from './icons/labels/on-demand-peas.jpeg';
import randomIntros from './icons/labels/random-intros.jpeg';
import scrumMeet from './icons/labels/scrum-meet.jpeg';
import socialImpact from './icons/labels/social-impact.jpeg';
import tuneMySpace from './icons/labels/tune-my-space.jpeg';
import helpDesk from './icons/labels/helpDesk.jpg';
import petMeetup from './icons/labels/petMeetup.jpg';
import travelCentre from './icons/labels/travelCentre.jpg';
import retailTherapy from './icons/labels/retailTherapy.jpg';
import theCommute from './icons/labels/theCommute.jpg';
import magnifying from './icons/labels/magnifying.jpg';

export const companySizes = [
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 500, label: 500 },
  { value: 1000, label: 1000 },
];

export const techSectors = [
  { value: 'Aerospace/SpaceTech', label: 'Aerospace/SpaceTech' },
  { value: 'AdTech/Digital Marketing', label: 'AdTech/Digital Marketing' },
  { value: 'AgTech/Food Innovation', label: 'AgTech/Food Innovation' },
  { value: 'AI/Machine Learning', label: 'AI/Machine Learning' },
  { value: 'Analytics/Big Data', label: 'Analytics/Big Data' },
  { value: 'Astrology/AstroTech', label: 'Astrology/AstroTech' },
  { value: 'AudioTech', label: 'AudioTech' },
  { value: 'B2B SaaS/Business Software', label: 'B2B SaaS/Business Software' },
  { value: 'B2C SaaS/Consumer Apps', label: 'B2C SaaS/Consumer Apps' },
  { value: 'Beauty & Fashion', label: 'Beauty & Fashion' },
  {
    value: 'BioTech/Life Science/Pharma',
    label: 'BioTech/Life Science/Pharma',
  },
  { value: 'Blockchain/Crypto/NFT/Web3', label: 'Blockchain/Crypto/NFT/Web3' },
  { value: 'CleanTech', label: 'CleanTech' },
  { value: 'Colleges/Academies', label: 'Colleges/Academies' },
  {
    value: 'Communications and Connectivity',
    label: 'Communications and Connectivity',
  },
  { value: 'ConstructionTech', label: 'ConstructionTech' },
  { value: 'Cybersec', label: 'Cybersec' },
  { value: 'eCommerce/Online Retail', label: 'eCommerce/Online Retail' },
  { value: 'Education/EdTech', label: 'Education/EdTech' },
  { value: 'Energy & Environment', label: 'Energy & Environment' },
  { value: 'FemTech', label: 'FemTech' },
  { value: 'Film/Entertainment/Media', label: 'Film/Entertainment/Media' },
  { value: 'Fintech/Banking/Insuretech', label: 'Fintech/Banking/Insuretech' },
  { value: 'Financial Health', label: 'Financial Health' },
  {
    value: 'Fitness/Wellness/Sport Tech',
    label: 'Fitness/Wellness/Sport Tech',
  },
  { value: 'Gaming', label: 'Gaming' },
  { value: 'GovTech/LegalTech', label: 'GovTech/LegalTech' },
  {
    value: 'Healthcare/HealthTech/MedTech/Digital Health',
    label: 'Healthcare/HealthTech/MedTech/Digital Health',
  },
  { value: 'HR Tech/Recruitment', label: 'HR Tech/Recruitment' },
  {
    value: 'Industrial Tech/Mining/Water Tech',
    label: 'Industrial Tech/Mining/Water Tech',
  },
  { value: 'Internet Dating', label: 'Internet Dating' },
  { value: 'KidTech', label: 'KidTech' },
  { value: 'LegalTech', label: 'LegalTech' },
  { value: 'Magazine/Publications', label: 'Magazine/Publications' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Marketplace', label: 'Marketplace' },
  { value: 'MarTech', label: 'MarTech' },
  { value: 'Meditation/Spirituality', label: 'Meditation/Spirituality' },
  { value: 'NoCode', label: 'NoCode' },
  { value: 'Online Plants & Flowers', label: 'Online Plants & Flowers' },
  { value: 'PetTech/Pet Care', label: 'PetTech/Pet Care' },
  {
    value: 'Real Estate/Construction/PropTech',
    label: 'Real Estate/Construction/PropTech',
  },
  { value: 'Sharing Economy', label: 'Sharing Economy' },
  { value: 'Smart City/Infrastructure', label: 'Smart City/Infrastructure' },
  {
    value: 'Social Media/Messaging Apps',
    label: 'Social Media/Messaging Apps',
  },
  {
    value: 'Sustainability/Green Tech/CleanTech',
    label: 'Sustainability/Green Tech/CleanTech',
  },
  { value: 'Taxi App', label: 'Taxi App' },
  { value: 'Telecom/VOIP VR/AR/IoT', label: 'Telecom/VOIP VR/AR/IoT' },
  { value: 'Translation & Localization', label: 'Translation & Localization' },
  { value: 'Transportation & Logistics', label: 'Transportation & Logistics' },
  {
    value: 'TravelTech/Tourism/Hospitality',
    label: 'TravelTech/Tourism/Hospitality',
  },
  { value: 'Video/Online Streaming', label: 'Video/Online Streaming' },
  { value: '3D Printing', label: '3D Printing' },
];

export const content = [
  { value: 'Episodic', label: 'Episodic' },
  { value: 'Series', label: 'Series' },
  { value: 'Employer', label: 'Employer' },
];

export const companyRoles = [
  { label: 'Business Development', value: 1 },
  { label: 'Videographer', value: 2 },
  { label: 'Recruitment Marketing', value: 3 },
  { label: 'Sales Team', value: 4 },
  { label: 'Guest Star / Producer', value: 5 },
  { label: 'HR / People', value: 6 },
  { label: 'Talent Acquisition', value: 7 },
  { label: 'Developer Relations (DevRel)', value: 8 },
  { label: 'Product / UX Design', value: 9 },
  { label: 'Employer Branding', value: 10 },
  { label: 'Marketing / PR', value: 11 },
  { label: 'Talent Experience', value: 12 },
];

export const CATEGORIES = [
  { id: 1, name: 'All', type: '' },
  { id: 2, name: 'Series', type: '' },
  { id: 3, name: 'Employer', type: '' },
  { id: 4, name: 'Product', type: '' },
  { id: 5, name: 'Animated', type: '' },
  { id: 6, name: 'Demos', type: '' },
  { id: 7, name: 'Promo', type: '' },
  { id: 8, name: 'Career-Heroes!', type: 'TV Channels' },
  { id: 9, name: 'Employee', type: '' },
  { id: 10, name: 'Customer', type: '' },
  { id: 11, name: 'Event-Heroes!', type: 'TV Channels' },
  { id: 12, name: 'Testimonial', type: '' },
  { id: 13, name: 'Founder', type: '' },
  { id: 14, name: 'Investor', type: '' },
  { id: 15, name: 'Expert', type: '' },
  { id: 16, name: 'Workspace', type: '' },
  { id: 17, name: 'Growth', type: '' },
  { id: 18, name: 'Social Responsibility', type: '' },
  { id: 19, name: 'D&I', type: '' },
  { id: 20, name: 'Startup-Heroes!', type: 'TV Channels' },
  { id: 21, name: 'Enterprise', type: '' },
  { id: 22, name: 'Thought Leadership', type: '' },
  { id: 23, name: 'Influencer', type: '' },
  { id: 24, name: 'Green Initiatives', type: '' },
  { id: 25, name: 'Green-Tech', type: 'TV Channels' },
  { id: 26, name: 'AI-Heroes', type: 'TV Channels' },
  { id: 27, name: 'FutureWorking', type: 'TV Channels' },
  { id: 28, name: 'Cloud Working', type: '' },
  { id: 29, name: 'Home@Work', type: 'TV Channels' },
  { id: 30, name: 'Covid@Work', type: 'TV Channels' },
  { id: 31, name: 'Vlog@Work', type: 'TV Channels' },
  { id: 32, name: 'Stress Relief', type: '' },
  { id: 33, name: 'Culture', type: '' },
  { id: 34, name: 'Learning@Work', type: 'TV Channels' },
  { id: 35, name: 'Wellness@Work', type: 'TV Channels' },
  { id: 36, name: 'Fashion@Work', type: 'TV Channels' },
  { id: 37, name: 'Fitness@Work', type: 'TV Channels' },
  { id: 38, name: 'Plants@Work', type: '' },
  { id: 39, name: 'Pets@Work', type: 'TV Channels' },
  { id: 40, name: 'Cycle@Work', type: '' },
  { id: 41, name: 'Safety@Work', type: '' },
  { id: 42, name: 'Running@Work', type: '' },
  { id: 43, name: 'Yoga@Work', type: '' },
  { id: 44, name: 'Work Life Balance', type: '' },
  { id: 45, name: 'L&D', type: '' },
  { id: 46, name: 'Parents@Work', type: '' },
  { id: 47, name: 'Languages@Work', type: '' },
  { id: 48, name: 'Autism@Work', type: '' },
  { id: 49, name: 'Agile Working', type: '' },
  { id: 50, name: 'Relocation', type: '' },
  { id: 51, name: 'Commute', type: '' },
  { id: 52, name: 'Mindfullness', type: '' },
];

export const LABELS = [
  {
    id: 1,
    name: 'How-to-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 2,
    name: 'Startup-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 3,
    name: 'Career-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 4,
    name: 'Rainbow-Infinity:)',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 5,
    name: 'Wellness@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 6,
    name: 'Fitness@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 7,
    name: 'Learning@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 8,
    name: 'Gaming@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 9,
    name: 'Peabox-Originals',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 10,
    name: 'Pea-Podcasts!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 11,
    name: 'Parents@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  // {
  //   id: 12,
  //   name: 'Vlog@Work',
  //   type: 'TV Channels',
  //   icon: '',
  //   broadcasting: 'public',
  // },
  {
    id: 13,
    name: 'BLM@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 14,
    name: 'Trailblazers!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 15,
    name: 'Pets@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  // {
  //   id: 16,
  //   name: 'FutureWorking',
  //   type: 'TV Channels',
  //   icon: '',
  //   broadcasting: 'public',
  // },
  {
    id: 17,
    name: 'Focus@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 18,
    name: 'Fashion@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 19,
    name: 'Travel@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 20,
    name: 'Gender-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 21,
    name: 'Home@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 22,
    name: 'Create@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 23,
    name: 'My-First-Job',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 24,
    name: 'Talking-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 25,
    name: 'Money-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 26,
    name: 'Leaders@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 27,
    name: 'Music@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 28,
    name: 'Food+Drink',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 29,
    name: 'No-Code!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 30,
    name: 'Deep-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 31,
    name: 'AI-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 32,
    name: 'Ecommerce',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 33,
    name: 'Ed-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 34,
    name: 'Fin-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 35,
    name: 'HR-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  { id: 36, name: 'Health-Tech', type: 'TV Channels', icon: '' },
  { id: 37, name: 'Learning Brand', type: 'Brand Type', icon: '' },
  { id: 38, name: 'Talent Brand', type: 'Brand Type', icon: '' },
  { id: 39, name: 'Personal Brand', type: 'Brand Type', icon: '' },
  { id: 40, name: 'Employer Brand', type: 'Brand Type', icon: '' },
  { id: 41, name: 'Team / Department Brand', type: 'Brand Type', icon: '' },
  { id: 42, name: 'Internal Employer Brand', type: 'Brand Type', icon: '' },
  { id: 43, name: 'Event Brand', type: 'Brand Type', icon: '' },
  { id: 44, name: 'Test A', type: 'A/B Testing', icon: '' },
  { id: 45, name: 'Test B', type: 'A/B Testing', icon: '' },
  { id: 46, name: 'Test C', type: 'A/B Testing', icon: '' },
  { id: 47, name: 'Test D', type: 'A/B Testing', icon: '' },
  { id: 48, name: 'Peabox.tv', type: 'Touchpoints', icon: '' },
  { id: 49, name: 'Whatsapp Business', type: 'Touchpoints', icon: '' },
  { id: 50, name: 'Slack', type: 'Touchpoints', icon: '' },
  { id: 51, name: 'Linkedin', type: 'Touchpoints', icon: '' },
  { id: 52, name: 'WeChat', type: 'Touchpoints', icon: '' },
  // { id: 53, name: "Landbot", type: "Touchpoints", icon: "" },
  { id: 54, name: 'LinkedIn InMail', type: 'Touchpoints', icon: '' },
  { id: 55, name: 'Careers Page / Workable', type: 'Touchpoints', icon: '' },
  // { id: 56, name: "Chatbot", type: "Touchpoints", icon: "" },
  { id: 57, name: 'Twitter', type: 'Touchpoints', icon: '' },
  // { id: 58, name: "Product / Landing Page", type: "Touchpoints", icon: "" },
  { id: 59, name: 'Snapchat', type: 'Touchpoints', icon: '' },
  { id: 60, name: 'Eventbrite Page', type: 'Touchpoints', icon: '' },
  { id: 61, name: 'Facebook', type: 'Touchpoints', icon: '' },
  { id: 62, name: 'Email / Gmail', type: 'Touchpoints', icon: '' },
  { id: 63, name: 'Messenger', type: 'Touchpoints', icon: '' },
  { id: 64, name: 'Mailchimp Email', type: 'Touchpoints', icon: '' },
  { id: 69, name: 'Artist', type: 'Target Personas', icon: '' },
  { id: 70, name: 'Musician', type: 'Target Personas', icon: '' },
  { id: 71, name: 'Surprise Me!', type: 'Target Responses', icon: '' },
  { id: 72, name: 'Calm Me!', type: 'Target Responses', icon: '' },
  { id: 73, name: 'Reassure Me!', type: 'Target Responses', icon: '' },
  { id: 74, name: 'Impress Me!', type: 'Target Responses', icon: '' },
  { id: 75, name: 'Excite Me!', type: 'Target Responses', icon: '' },
  { id: 76, name: 'Inspire Me!', type: 'Target Responses', icon: '' },
  { id: 77, name: 'Motivate Me!', type: 'Target Responses', icon: '' },
  { id: 78, name: 'Educate Me!', type: 'Target Responses', icon: '' },
  { id: 79, name: 'Help Me!', type: 'Target Responses', icon: '' },
  { id: 80, name: 'Japan', type: 'Target Locations', icon: '' },
  { id: 81, name: 'Italy', type: 'Target Locations', icon: '' },
  {
    id: 191,
    name: 'Blockchain!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 192,
    name: 'IoT-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 193,
    name: 'SaaS-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 194,
    name: 'Travel-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 195,
    name: 'Mobile-Apps',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 196,
    name: 'Fashion-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 197,
    name: 'Green-Tech',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 198,
    name: 'VR-and-AR',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 199,
    name: '5G-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 200,
    name: 'RPA-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 201,
    name: 'Internal Brand',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'internal',
  },
  {
    id: 202,
    name: 'Locked',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'internal',
  },
  {
    id: 203,
    name: 'Peabox Originals',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'admin',
  },
  {
    id: 204,
    name: 'Peabox Academy',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'admin',
  },
  // { id: 201, name: "Autism@Work", type: "TV Channels", icon: "" },
  // { id: 202, name: "Agile Working", type: "TV Channels", icon: "" },
  // { id: 203, name: "Relocation", type: "TV Channels", icon: "" },
  // { id: 204, name: "Commute", type: "TV Channels", icon: "" },
  // { id: 205, name: "Mindfullness", type: "TV Channels", icon: "" },
  // { id: 206, name: "Instructional (Pod)", type: "TV Channels", icon: "" },
  { id: 207, name: 'Series', type: 'Pea Tags', icon: '' },
  { id: 208, name: 'Employer', type: 'Pea Tags', icon: '' },
  { id: 209, name: 'Product', type: 'Pea Tags', icon: '' },
  { id: 210, name: 'Animated', type: 'Pea Tags', icon: '' },
  { id: 211, name: 'Demos', type: 'Pea Tags', icon: '' },
  { id: 212, name: 'Promo', type: 'Pea Tags', icon: '' },
  { id: 213, name: 'Careers', type: 'Pea Tags', icon: '' },
  { id: 214, name: 'Employee', type: 'Pea Tags', icon: '' },
  { id: 215, name: 'Customer', type: 'Pea Tags', icon: '' },
  { id: 216, name: 'Event', type: 'Pea Tags', icon: '' },
  { id: 217, name: 'Testimonial', type: 'Pea Tags', icon: '' },
  { id: 218, name: 'Founder', type: 'Pea Tags', icon: '' },
  { id: 219, name: 'Investor', type: 'Pea Tags', icon: '' },
  { id: 220, name: 'Expert', type: 'Pea Tags', icon: '' },
  { id: 221, name: 'Workspace', type: 'Pea Tags', icon: '' },
  { id: 222, name: 'Growth', type: 'Pea Tags', icon: '' },
  { id: 223, name: 'Social Responsibility', type: 'Pea Tags', icon: '' },
  { id: 224, name: 'D&I', type: 'Pea Tags', icon: '' },
  { id: 225, name: 'Startup', type: 'Pea Tags', icon: '' },
  { id: 226, name: 'Enterprise', type: 'Pea Tags', icon: '' },
  { id: 227, name: 'Thought Leadership', type: 'Pea Tags', icon: '' },
  { id: 228, name: 'Influencer', type: 'Pea Tags', icon: '' },
  { id: 229, name: 'Green Initiatives', type: 'Pea Tags', icon: '' },
  { id: 230, name: 'Greentech', type: 'Pea Tags', icon: '' },
  { id: 231, name: 'AI', type: 'Pea Tags', icon: '' },
  { id: 232, name: 'Future Working', type: 'Pea Tags', icon: '' },
  { id: 233, name: 'Cloud Working', type: 'Pea Tags', icon: '' },
  { id: 234, name: 'Home Working', type: 'Pea Tags', icon: '' },
  { id: 235, name: 'Covid-19', type: 'Pea Tags', icon: '' },
  { id: 236, name: 'Vlogs', type: 'Pea Tags', icon: '' },
  { id: 237, name: 'Stress Relief', type: 'Pea Tags', icon: '' },
  { id: 238, name: 'Culture', type: 'Pea Tags', icon: '' },
  { id: 239, name: 'Learning@Work', type: 'Pea Tags', icon: '' },
  { id: 240, name: 'Wellness@Work', type: 'Pea Tags', icon: '' },
  { id: 241, name: 'Fashion@Work', type: 'Pea Tags', icon: '' },
  { id: 242, name: 'Fitness@Work', type: 'Pea Tags', icon: '' },
  { id: 243, name: 'Plants@Work', type: 'Pea Tags', icon: '' },
  { id: 244, name: 'Pets@Work', type: 'Pea Tags', icon: '' },
  { id: 245, name: 'Cycle@Work', type: 'Pea Tags', icon: '' },
  { id: 246, name: 'Safety@Work', type: 'Pea Tags', icon: '' },
  { id: 247, name: 'Running@Work', type: 'Pea Tags', icon: '' },
  { id: 248, name: 'Yoga@Work', type: 'Pea Tags', icon: '' },
  { id: 249, name: 'Work Life Balance', type: 'Pea Tags', icon: '' },
  { id: 250, name: 'L&D', type: 'Pea Tags', icon: '' },
  { id: 251, name: 'Parents@Work', type: 'Pea Tags', icon: '' },
  { id: 252, name: 'Languages@Work', type: 'Pea Tags', icon: '' },
  { id: 253, name: 'Autism@Work', type: 'Pea Tags', icon: '' },
  { id: 254, name: 'Agile Working', type: 'Pea Tags', icon: '' },
  { id: 255, name: 'Relocation', type: 'Pea Tags', icon: '' },
  { id: 256, name: 'Commute', type: 'Pea Tags', icon: '' },
  { id: 257, name: 'Mindfullness', type: 'Pea Tags', icon: '' },
  { id: 258, name: 'Instructional (Pod)', type: 'Pea Tags', icon: '' },
  {
    icon: commute,
    type: 'Workday Feed',
    name: '8:00 The Commute',
    info: 'Undervalued moments',
    dayPeriod: 'Morning',
    id: 259,
  },
  {
    icon: mindBody,
    type: 'Workday Feed',
    name: '9:00 Mind+Body',
    info: 'Morning Peas & tranquility',
    dayPeriod: 'Morning',
    id: 260,
  },
  {
    icon: coffeePod,
    type: 'Workday Feed',
    name: '9:30 Expresso Bar!',
    info: 'Undervalued moments',
    dayPeriod: 'Morning',
    id: 261,
  },
  {
    icon: magnifying,
    type: 'Workday Feed',
    name: '9:45 Careers Café',
    info: 'Jobs in Peas',
    dayPeriod: 'Morning',
    id: 262,
  },
  {
    icon: scrumMeet,
    type: 'Workday Feed',
    name: '10:00 Scrum Meet',
    info: 'Agile Working',
    dayPeriod: 'Morning',
    id: 263,
  },
  {
    icon: randomIntros,
    type: 'Workday Feed',
    name: '10:30 Random Intros',
    info: 'Open doors and water coolers',
    dayPeriod: 'Morning',
    id: 264,
  },
  {
    icon: helpDesk,
    type: 'Workday Feed',
    name: '10:45 IT Help Desk',
    info: 'No Code Requried',
    dayPeriod: 'Morning',
    id: 265,
  },
  {
    icon: employeeAssist,
    type: 'Workday Feed',
    name: '11:00 Employee Assist',
    info: 'Peas to your rescue!',
    dayPeriod: 'Morning',
    id: 266,
  },
  {
    icon: creative,
    type: 'Workday Feed',
    name: '11:11 Be Creative',
    info: 'Stimuli for the right-brain',
    dayPeriod: 'Morning',
    id: 267,
  },
  {
    icon: learningPod,
    type: 'Workday Feed',
    name: '11:20 Daily Bookclub',
    info: 'Books in Peas',
    dayPeriod: 'Morning',
    id: 268,
  },
  {
    icon: tuneMySpace,
    type: 'Workday Feed',
    name: '11:30 Tune-My-Space',
    info: 'From plants to desktops',
    dayPeriod: 'Morning',
    id: 269,
  },
  {
    icon: finances,
    type: 'Workday Feed',
    name: '12:00 My Finances',
    info: 'Your work-life "bank" balance',
    dayPeriod: 'Morning',
    id: 270,
  },
  {
    icon: gymBites,
    type: 'Workday Feed',
    name: '12:30 Gym Bites',
    info: 'Fuel your lunchtime sesh!',
    dayPeriod: 'Afternoon',
    id: 271,
  },
  {
    icon: retailTherapy,
    type: 'Workday Feed',
    name: '12:45 Retail Therapy',
    info: 'Shop in Peas',
    dayPeriod: 'Afternoon',
    id: 272,
  },
  {
    icon: cookAlong,
    type: 'Workday Feed',
    name: '13:00 Cook-Along',
    info: 'Micro-Menus for busy peeps',
    dayPeriod: 'Afternoon',
    id: 273,
  },
  {
    icon: petMeetup,
    type: 'Workday Feed',
    name: '13:30 Pet Meet-up',
    info: 'Lunchtime petting',
    dayPeriod: 'Afternoon',
    id: 274,
  },
  {
    icon: gamingGroup,
    type: 'Workday Feed',
    name: '13:40 Gaming Group',
    info: 'Twitchy Peas in Discord',
    dayPeriod: 'Afternoon',
    id: 275,
  },
  {
    icon: mindBody,
    type: 'Workday Feed',
    name: '14:00 Mind+Body',
    info: 'Afternoon Peas & Tranquility',
    dayPeriod: 'Afternoon',
    id: 276,
  },
  {
    icon: tuneMySpace,
    type: 'Workday Feed',
    name: '14:15 Tune-My-Space',
    info: 'From plants to desktops',
    dayPeriod: 'Afternoon',
    id: 277,
  },
  {
    icon: employeeAssist,
    type: 'Workday Feed',
    name: '14:25 EmployeeAssist',
    info: 'Peas to your rescue!',
    dayPeriod: 'Afternoon',
    id: 278,
  },
  {
    icon: socialImpact,
    type: 'Workday Feed',
    name: '14:30 Social Impact',
    info: '2 minute missions',
    dayPeriod: 'Afternoon',
    id: 279,
  },
  {
    icon: travelCentre,
    type: 'Workday Feed',
    name: '14:45 Travel Centre',
    info: 'Flying Peas',
    dayPeriod: 'Afternoon',
    id: 280,
  },
  {
    icon: learningPod,
    type: 'Workday Feed',
    name: '15:00 Learning Pod',
    info: 'How-to-Heroes',
    dayPeriod: 'Afternoon',
    id: 281,
  },
  {
    icon: helpDesk,
    type: 'Workday Feed',
    name: '15:30 IT Help Desk',
    info: 'No Code Required',
    dayPeriod: 'Afternoon',
    id: 282,
  },
  {
    icon: happyHour,
    type: 'Workday Feed',
    name: '16:00 Happy Hour',
    info: 'Share some Peas & love!',
    dayPeriod: 'Afternoon',
    id: 283,
  },
  {
    icon: theCommute,
    type: 'Workday Feed',
    name: '17:00 The Commute',
    info: 'Undervalued moments',
    dayPeriod: 'Evening',
    id: 284,
  },
  {
    icon: gamingGroup,
    type: 'Workday Feed',
    name: '17:20 Gaming Group',
    info: 'Twitchy Peas in Discord',
    dayPeriod: 'Evening',
    id: 285,
  },
  {
    icon: retailTherapy,
    type: 'Workday Feed',
    name: '17:30 Retail Therapy',
    info: 'Shop in Peas',
    dayPeriod: 'Evening',
    id: 286,
  },
  {
    icon: gymBites,
    type: 'Workday Feed',
    name: '18:00 Gym Bites',
    info: 'Fuel your evening sesh!',
    dayPeriod: 'Evening',
    id: 287,
  },
  {
    icon: petMeetup,
    type: 'Workday Feed',
    name: '18:20 Pet Meet-up',
    info: 'Afterwork petting',
    dayPeriod: 'Evening',
    id: 288,
  },
  {
    icon: mindBody,
    type: 'Workday Feed',
    name: '18:30 Mind+Body',
    info: 'Afterwork Peas & tranquility',
    dayPeriod: 'Evening',
    id: 289,
  },
  {
    icon: cookAlong,
    type: 'Workday Feed',
    name: '19:00 Cook-Along',
    info: 'Micro-Menus for busy peeps',
    dayPeriod: 'Evening',
    id: 290,
  },
  {
    icon: tuneMySpace,
    type: 'Workday Feed',
    name: '19:30 Tune-My-Space',
    info: 'From plants to desktops',
    dayPeriod: 'Evening',
    id: 291,
  },
  {
    icon: learningPod,
    type: 'Workday Feed',
    name: '19:45 Evening Bookclub',
    info: 'Books in Peas',
    dayPeriod: 'Evening',
    id: 292,
  },
  {
    icon: finances,
    type: 'Workday Feed',
    name: '20:00 My Finances',
    info: 'Your work-life "bank" balance',
    dayPeriod: 'Evening',
    id: 293,
  },
  {
    icon: onDemandPeas,
    type: 'Workday Feed',
    name: '20:30 Peabox Originals',
    info: 'Organic bites!',
    dayPeriod: 'Evening',
    id: 294,
  },
  { id: 295, name: 'Gen Z', type: 'Target Personas', icon: '' },
  { id: 296, name: 'Millennial', type: 'Target Personas', icon: '' },
  { id: 297, name: 'Female', type: 'Target Personas', icon: '' },
  { id: 298, name: 'Developer', type: 'Target Personas', icon: '' },
  { id: 299, name: 'Marketing Manager', type: 'Target Personas', icon: '' },
  { id: 300, name: 'Aerospace/SpaceTech', type: 'Target Industries', icon: '' },
  {
    id: 301,
    name: 'AdTech/Digital Marketing',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 302,
    name: 'AgTech/Food Innovation',
    type: 'Target Industries',
    icon: '',
  },
  { id: 303, name: 'AI/Machine Learning', type: 'Target Industries', icon: '' },
  { id: 304, name: 'Analytics/Big Data', type: 'Target Industries', icon: '' },
  { id: 305, name: 'Astrology/AstroTech', type: 'Target Industries', icon: '' },
  { id: 306, name: 'AudioTech', type: 'Target Industries', icon: '' },
  {
    id: 307,
    name: 'B2B SaaS/Business Software',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 308,
    name: 'B2C SaaS/Consumer Apps',
    type: 'Target Industries',
    icon: '',
  },
  { id: 309, name: 'Beauty & Fashion', type: 'Target Industries', icon: '' },
  {
    id: 310,
    name: 'BioTech/Life Science/Pharma',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 311,
    name: 'Blockchain/Crypto/NFT/Web3',
    type: 'Target Industries',
    icon: '',
  },
  { id: 312, name: 'CleanTech', type: 'Target Industries', icon: '' },
  { id: 313, name: 'Colleges/Academies', type: 'Target Industries', icon: '' },
  {
    id: 314,
    name: 'Communications and Connectivity',
    type: 'Target Industries',
    icon: '',
  },
  { id: 315, name: 'ConstructionTech', type: 'Target Industries', icon: '' },
  { id: 316, name: 'Cybersec', type: 'Target Industries', icon: '' },
  {
    id: 317,
    name: 'eCommerce/Online Retail',
    type: 'Target Industries',
    icon: '',
  },
  { id: 318, name: 'Education/EdTech', type: 'Target Industries', icon: '' },
  {
    id: 319,
    name: 'Energy & Environment',
    type: 'Target Industries',
    icon: '',
  },
  { id: 400, name: 'FemTech', type: 'Target Industries', icon: '' },
  {
    id: 403,
    name: 'Film/Entertainment/Media',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 401,
    name: 'Fintech/Banking/Insuretech',
    type: 'Target Industries',
    icon: '',
  },
  { id: 402, name: 'Financial Health', type: 'Target Industries', icon: '' },
  {
    id: 404,
    name: 'Fitness/Wellness/Sport Tech',
    type: 'Target Industries',
    icon: '',
  },
  { id: 405, name: 'Gaming', type: 'Target Industries', icon: '' },
  { id: 406, name: 'GovTech/LegalTech', type: 'Target Industries', icon: '' },
  {
    id: 408,
    name: 'Healthcare/HealthTech/MedTech/Digital Health',
    type: 'Target Industries',
    icon: '',
  },
  { id: 407, name: 'HR Tech/Recruitment', type: 'Target Industries', icon: '' },
  {
    id: 500,
    name: 'Industrial Tech/Mining/Water Tech',
    type: 'Target Industries',
    icon: '',
  },
  { id: 409, name: 'Internet Dating', type: 'Target Industries', icon: '' },
  { id: 501, name: 'KidTech', type: 'Target Industries', icon: '' },
  { id: 5020, name: 'LegalTech', type: 'Target Industries', icon: '' },
  {
    id: 5030,
    name: 'Magazine/Publications',
    type: 'Target Industries',
    icon: '',
  },
  { id: 5040, name: 'Manufacturing', type: 'Target Industries', icon: '' },
  { id: 5050, name: 'Marketplace', type: 'Target Industries', icon: '' },
  { id: 5060, name: 'MarTech', type: 'Target Industries', icon: '' },
  {
    id: 5070,
    name: 'Meditation/Spirituality',
    type: 'Target Industries',
    icon: '',
  },
  { id: 5080, name: 'NoCode', type: 'Target Industries', icon: '' },
  {
    id: 5090,
    name: 'Online Plants & Flowers',
    type: 'Target Industries',
    icon: '',
  },
  { id: 5110, name: 'PetTech/Pet Care', type: 'Target Industries', icon: '' },
  {
    id: 5120,
    name: 'RealEstate/Construction/PropTech',
    type: 'Target Industries',
    icon: '',
  },
  { id: 5130, name: 'Sharing Economy', type: 'Target Industries', icon: '' },
  {
    id: 5140,
    name: 'Smart City/Infrastructure',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 5150,
    name: 'Social Media/Messaging Apps',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 5160,
    name: 'Sustainability/Green Tech/CleanTech',
    type: 'Target Industries',
    icon: '',
  },
  { id: 5170, name: 'Taxi App', type: 'Target Industries', icon: '' },
  {
    id: 5100,
    name: 'Telecom/VOIP VR/AR/IoT',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 5180,
    name: 'Translation & Localisation',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 5190,
    name: 'Transportation & Logistics',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 5200,
    name: 'TravelTech/Tourism/Hospitality',
    type: 'Target Industries',
    icon: '',
  },
  {
    id: 5210,
    name: 'Video/Online Streaming',
    type: 'Target Industries',
    icon: '',
  },
  { id: 5220, name: '3D Printing', type: 'Target Industries', icon: '' },
  { id: 502, name: 'Consumer Brand', type: 'Brand Type', icon: '' },
  { id: 503, name: 'Careers Page / Webflow', type: 'Touchpoints', icon: '' },
  { id: 504, name: 'Medium', type: 'Touchpoints', icon: '' },
  { id: 505, name: 'Canva', type: 'Touchpoints', icon: '' },
  { id: 506, name: 'Articulate/Rise 360', type: 'Touchpoints', icon: '' },
  { id: 507, name: 'Notion', type: 'Touchpoints', icon: '' },
  { id: 508, name: 'Coda', type: 'Touchpoints', icon: '' },
  {
    id: 509,
    name: 'Vegan@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 510,
    name: 'Covid@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  { id: 511, name: 'Sustainable Marketing', type: 'Pea Tags', icon: '' },
  { id: 512, name: 'Pilot', type: 'A/B Testing', icon: '' },
  { id: 513, name: 'Prototype', type: 'A/B Testing', icon: '' },
  { id: 514, name: 'Teaser', type: 'A/B Testing', icon: '' },
  { id: 515, name: 'Freemium Short-Life!', type: 'A/B Testing', icon: '' },
  { id: 516, name: 'MVP', type: 'A/B Testing', icon: '' },
  { id: 517, name: 'POC', type: 'A/B Testing', icon: '' },
  {
    id: 518,
    name: 'Event-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 519,
    name: 'Hiring-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 520,
    name: 'Recruitment@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 521,
    name: 'Sustainable-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 522,
    name: 'Dating-Heroes!',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  {
    id: 523,
    name: 'Coding@Work',
    type: 'TV Channels',
    icon: '',
    broadcasting: 'public',
  },
  { id: 524, name: 'Service Brand', type: 'Brand Type', icon: '' },
  { id: 525, name: 'Corporate Brand', type: 'Brand Type', icon: '' },
  { id: 526, name: 'Technology Brand', type: 'Brand Type', icon: '' },
  { id: 527, name: 'Influencer Brand', type: 'Brand Type', icon: '' },
  { id: 528, name: 'Geographic Brand', type: 'Brand Type', icon: '' },
  { id: 529, name: 'Business-to-business (B2B)', type: 'Brand Type', icon: '' },
  { id: 530, name: 'Business-to-consumer (B2C)', type: 'Brand Type', icon: '' },
  {
    id: 531,
    name: 'Business-to-government (B2G)',
    type: 'Brand Type',
    icon: '',
  },
  { id: 532, name: 'Business-to-employee (B2E)', type: 'Brand Type', icon: '' },
  { id: 533, name: 'Business-to-talent (B2T)', type: 'Brand Type', icon: '' },
  { id: 535, name: 'Get Hires/Recruits', type: 'Mission Objectives', icon: '' },
  {
    id: 536,
    name: 'Get Job Applications',
    type: 'Mission Objectives',
    icon: '',
  },
  {
    id: 537,
    name: 'Get Sales/Purchases',
    type: 'Mission Objectives',
    icon: '',
  },
  {
    id: 538,
    name: 'Get (Form) "Submits"',
    type: 'Mission Objectives',
    icon: '',
  },
  { id: 539, name: 'Get Schedulings', type: 'Mission Objectives', icon: '' },
  { id: 540, name: 'Get Registrations', type: 'Mission Objectives', icon: '' },
  {
    id: 541,
    name: 'Get Emails/Sign-ups',
    type: 'Mission Objectives',
    icon: '',
  },
  {
    id: 542,
    name: 'Get (Survey) Feedbacks',
    type: 'Mission Objectives',
    icon: '',
  },
  { id: 544, name: 'Get Pea "Likes"', type: 'Mission Objectives', icon: '' },
  { id: 545, name: 'Get Pea "Plays"', type: 'Mission Objectives', icon: '' },
  { id: 546, name: 'Get Pea "Shares"', type: 'Mission Objectives', icon: '' },
  {
    id: 547,
    name: 'Get Pea Page "Plays"',
    type: 'Mission Objectives',
    icon: '',
  },
  {
    id: 548,
    name: 'Get "Upvotes" (Product Hunt)',
    type: 'Mission Objectives',
    icon: '',
  },
  {
    id: 549,
    name: 'Get "Likes" (Spotify)',
    type: 'Mission Objectives',
    icon: '',
  },
  {
    id: 550,
    name: 'Get "Likes" (LinkedIn)',
    type: 'Mission Objectives',
    icon: '',
  },
  { id: 551, name: 'Nurture', type: 'Mission Objectives', icon: '' },
  { id: 552, name: 'Educate', type: 'Mission Objectives', icon: '' },
  { id: 553, name: 'Help', type: 'Mission Objectives', icon: '' },
  { id: 554, name: 'Calm', type: 'Mission Objectives', icon: '' },
  { id: 555, name: 'Support', type: 'Mission Objectives', icon: '' },
  { id: 556, name: 'Surprise', type: 'Mission Objectives', icon: '' },
  { id: 557, name: 'Motivate/Inspire', type: 'Mission Objectives', icon: '' },
  { id: 558, name: 'Engage/Entertain', type: 'Mission Objectives', icon: '' },
  { id: 559, name: 'Capture Info', type: 'Mission Objectives', icon: '' },
  { id: 560, name: 'Market To', type: 'Mission Objectives', icon: '' },
  { id: 561, name: 'Experiment/Test', type: 'Mission Objectives', icon: '' },
];

export const WORKDAYLABELS = [
  {
    icon: commute,
    type: 'Workday Feed',
    time: '8:00',
    name: 'The Commute',
    info: 'Undervalued moments',
    dayPeriod: 'Morning',
    id: 259,
  },
  {
    icon: mindBody,
    type: 'Workday Feed',
    time: '9:00',
    name: 'Mind+Body',
    info: 'Morning Peas & tranquility',
    dayPeriod: 'Morning',
    id: 260,
  },
  {
    icon: coffeePod,
    type: 'Workday Feed',
    time: '9:30',
    name: 'Expresso Bar!',
    info: 'Undervalued moments',
    dayPeriod: 'Morning',
    id: 261,
  },
  {
    icon: magnifying,
    type: 'Workday Feed',
    time: '9:45',
    name: 'Careers Café',
    info: 'Jobs in Peas',
    dayPeriod: 'Morning',
    id: 262,
  },
  {
    icon: scrumMeet,
    type: 'Workday Feed',
    time: '10:00',
    name: 'Scrum Meet',
    info: 'Agile Working',
    dayPeriod: 'Morning',
    id: 263,
  },
  {
    icon: randomIntros,
    type: 'Workday Feed',
    time: '10:30',
    name: 'Random Intros',
    info: 'Open doors and water coolers',
    dayPeriod: 'Morning',
    id: 264,
  },
  {
    icon: helpDesk,
    type: 'Workday Feed',
    time: '10:45',
    name: 'IT Help Desk',
    info: 'No Code Requried',
    dayPeriod: 'Morning',
    id: 265,
  },
  {
    icon: employeeAssist,
    type: 'Workday Feed',
    time: '11:00',
    name: 'Employee Assist',
    info: 'Peas to your rescue!',
    dayPeriod: 'Morning',
    id: 266,
  },
  {
    icon: creative,
    type: 'Workday Feed',
    time: '11:11',
    name: 'Be Creative',
    info: 'Stimuli for the right-brain',
    dayPeriod: 'Morning',
    id: 267,
  },
  {
    icon: learningPod,
    type: 'Workday Feed',
    time: '11:20',
    name: 'Daily Bookclub',
    info: 'Books in Peas',
    dayPeriod: 'Morning',
    id: 268,
  },
  {
    icon: tuneMySpace,
    type: 'Workday Feed',
    time: '11:30',
    name: 'Tune-My-Space',
    info: 'From plants to desktops',
    dayPeriod: 'Morning',
    id: 269,
  },
  {
    icon: finances,
    type: 'Workday Feed',
    time: '12:00',
    name: 'My Finances',
    info: 'Your work-life "bank" balance',
    dayPeriod: 'Morning',
    id: 270,
  },
  {
    icon: gymBites,
    type: 'Workday Feed',
    time: '12:30',
    name: 'Gym Bites',
    info: 'Fuel your lunchtime sesh!',
    dayPeriod: 'Afternoon',
    id: 271,
  },
  {
    icon: retailTherapy,
    type: 'Workday Feed',
    time: '12:45',
    name: 'Retail Therapy',
    info: 'Shop in Peas',
    dayPeriod: 'Afternoon',
    id: 272,
  },
  {
    icon: cookAlong,
    type: 'Workday Feed',
    time: '13:00',
    name: 'Cook-Along',
    info: 'Micro-Menus for busy peeps',
    dayPeriod: 'Afternoon',
    id: 273,
  },
  {
    icon: petMeetup,
    type: 'Workday Feed',
    time: '13:30',
    name: 'Pet Meet-up',
    info: 'Lunchtime petting',
    dayPeriod: 'Afternoon',
    id: 274,
  },
  {
    icon: gamingGroup,
    type: 'Workday Feed',
    time: '13:40',
    name: 'Gaming Group',
    info: 'Twitchy Peas in Discord',
    dayPeriod: 'Afternoon',
    id: 275,
  },
  {
    icon: mindBody,
    type: 'Workday Feed',
    time: '14:00',
    name: 'Mind+Body',
    info: 'Afternoon Peas & Tranquility',
    dayPeriod: 'Afternoon',
    id: 276,
  },
  {
    icon: tuneMySpace,
    type: 'Workday Feed',
    time: '14:15',
    name: 'Tune-My-Space',
    info: 'From plants to desktops',
    dayPeriod: 'Afternoon',
    id: 277,
  },
  {
    icon: employeeAssist,
    type: 'Workday Feed',
    time: '14:25',
    name: 'EmployeeAssist',
    info: 'Peas to your rescue!',
    dayPeriod: 'Afternoon',
    id: 278,
  },
  {
    icon: socialImpact,
    type: 'Workday Feed',
    time: '14:30',
    name: 'Social Impact',
    info: '2 minute missions',
    dayPeriod: 'Afternoon',
    id: 279,
  },
  {
    icon: travelCentre,
    type: 'Workday Feed',
    time: '14:45',
    name: 'Travel Centre',
    info: 'Flying Peas',
    dayPeriod: 'Afternoon',
    id: 280,
  },
  {
    icon: learningPod,
    type: 'Workday Feed',
    time: '15:00',
    name: 'Learning Pod',
    info: 'How-to-Heroes',
    dayPeriod: 'Afternoon',
    id: 281,
  },
  {
    icon: helpDesk,
    type: 'Workday Feed',
    time: '15:30',
    name: 'IT Help Desk',
    info: 'No Code Required',
    dayPeriod: 'Afternoon',
    id: 282,
  },
  {
    icon: happyHour,
    type: 'Workday Feed',
    time: '16:00',
    name: 'Happy Hour',
    info: 'Share some Peas & love!',
    dayPeriod: 'Afternoon',
    id: 283,
  },
  {
    icon: theCommute,
    type: 'Workday Feed',
    time: '17:00',
    name: 'The Commute',
    info: 'Undervalued moments',
    dayPeriod: 'Evening',
    id: 284,
  },
  {
    icon: gamingGroup,
    type: 'Workday Feed',
    time: '17:20',
    name: 'Gaming Group',
    info: 'Twitchy Peas in Discord',
    dayPeriod: 'Evening',
    id: 285,
  },
  {
    icon: retailTherapy,
    type: 'Workday Feed',
    time: '17:30',
    name: 'Retail Therapy',
    info: 'Shop in Peas',
    dayPeriod: 'Evening',
    id: 286,
  },
  {
    icon: gymBites,
    type: 'Workday Feed',
    time: '18:00',
    name: 'Gym Bites',
    info: 'Fuel your evening sesh!',
    dayPeriod: 'Evening',
    id: 287,
  },
  {
    icon: petMeetup,
    type: 'Workday Feed',
    time: '18:20',
    name: 'Pet Meet-up',
    info: 'Afterwork petting',
    dayPeriod: 'Evening',
    id: 288,
  },
  {
    icon: mindBody,
    type: 'Workday Feed',
    time: '18:30',
    name: 'Mind+Body',
    info: 'Afterwork Peas & tranquility',
    dayPeriod: 'Evening',
    id: 289,
  },
  {
    icon: cookAlong,
    type: 'Workday Feed',
    time: '19:00',
    name: 'Cook-Along',
    info: 'Micro-Menus for busy peeps',
    dayPeriod: 'Evening',
    id: 290,
  },
  {
    icon: tuneMySpace,
    type: 'Workday Feed',
    time: '19:30',
    name: 'Tune-My-Space',
    info: 'From plants to desktops',
    dayPeriod: 'Evening',
    id: 291,
  },
  {
    icon: learningPod,
    type: 'Workday Feed',
    time: '19:45',
    name: 'Evening Bookclub',
    info: 'Books in Peas',
    dayPeriod: 'Evening',
    id: 292,
  },
  {
    icon: finances,
    type: 'Workday Feed',
    time: '20:00',
    name: 'My Finances',
    info: 'Your work-life "bank" balance',
    dayPeriod: 'Evening',
    id: 293,
  },
  {
    icon: onDemandPeas,
    type: 'Workday Feed',
    time: '20:30',
    name: 'Peabox Originals',
    info: 'Organic bites!',
    dayPeriod: 'Evening',
    id: 294,
  },
];

export const WEBSITE_URL = `${window.location.protocol}//${window.location.hostname}`;

export const themes = {
  breakpointsUp: {
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
    xxl: '(min-width: 1400px)',
  },
  breakpointsDown: {
    sm: '(max-width: 575.98px)',
    md: '(max-width: 767.98px)',
    lg: '(max-width: 991.98px)',
    xl: '(max-width: 1199.98px)',
    xxl: '(max-width: 1399.98px)',
  },
  breakpointsOnly: {
    sm: '(min-width: 576px) and (max-width: 767.98px)',
    md: '(min-width: 768px) and (max-width: 991.98px)',
    lg: '(min-width: 992px) and (max-width: 1199.98px)',
    xl: '(min-width: 1200px) and (max-width: 1399.98px)',
    xxl: '(min-width: 1400px)',
  },
};

export const footerArmYourPeas = [
  {
    title: 'Peabox (owned):',
    link: '',
    sub_list: [
      {
        title: 'Notion',
        link: 'https://peabox.notion.site/Peabox-Templates-93a2a25b462a4b84ae05aaa6c645003a',
      },
      {
        title: 'Evernote',
        link: 'https://www.evernote.com/shard/s349/sh/0ea9265a-d775-341e-86d4-96e70d3d9c21/0c6547b9de57aa628ca1e3ff7f1e14d9',
      },
    ],
  },
  {
    title: 'Typeform (owned)',
    link: 'https://www.typeform.com/templates/',
    sub_list: [],
  },
  {
    title: 'SurveyMonkey (owned)',
    link: 'https://www.surveymonkey.co.uk/mp/survey-types/?ut_source=mp&ut_source2=survey-templates&ut_source3=megamenu',
    sub_list: [],
  },
  {
    title: 'Landbot (owned)',
    link: 'https://landbot.io/chatbot-templates',
    sub_list: [],
  },
];

export const footerIntegrations = [
  {
    title: 'Chrome Extension:',
    link: '',
    sub_list: [
      {
        title: 'For LinkedIn',
        link: 'https://www.peabox.tv.com/',
      },
      {
        title: 'For Gmail',
        link: 'https://www.peabox.tv.com/',
      },
    ],
  },
];
export const footerResources = [
  {
    title: 'Our Pea Story',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'How-to-Heroes!',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Pea Vlog',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Peabox Originals',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Pea Community',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Pea Nerch',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'FAQs',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'System Status',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Developers / API',
    link: 'https://www.peabox.tv.com/',
  },
];

export const footerKnowUs = [
  {
    title: 'About US',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Career Heroes!',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Contact Us',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'News in Peas',
    link: 'https://www.peabox.tv.com/',
  },
  {
    title: 'Terms and Conditions',
    link: 'https://www.peabox.tv.com/',
  },
];

export default themes;
