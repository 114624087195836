import React from "react"
import classNames from "classnames"
import { ReactComponent as LoaderSVG } from "../../../icons/loader.svg"
import styles from "./Loader.module.scss"

const cx = classNames.bind(styles)

export const PeaLoader = ({ format = "Rectangle", loaderStyles }) => {
  return (
    <div
      className={cx(
        styles.Loader,
        format === "Square"
          ? styles.PeaLoaderSquare
          : styles.PeaLoaderRectangle,
        loaderStyles
      )}
    ></div>
  )
}

export const SearchResultLoader = () => {
  return (
    <div className={cx(styles.SearchResultLoaderWrapper)}>
      <div className={cx(styles.Loader, styles.SearchResultLoaderImage)}></div>
      <div className={cx(styles.SearchResultLoaderDetailsWrapper)}>
        <div
          className={cx(styles.Loader, styles.SearchResultLoaderTitle)}
        ></div>
        <div
          className={cx(styles.Loader, styles.SearchResultLoaderCompany)}
        ></div>
      </div>
    </div>
  )
}

export const BroadcastPeaLoader = ({ loaderStyles }) => {
  return (
    <div
      className={cx(styles.Loader, styles.BroadcastPeaLoader, loaderStyles)}
    ></div>
  )
}

export const Loader = ({ loaderStyles }) => {
  return <LoaderSVG className={cx(styles.LoaderSVG, loaderStyles)} />
}
