import {
    GET_ACCOUNT_NOTIFICATION_START,
    GET_ACCOUNT_NOTIFICATION_SUCCESS,
    UPDATE_ACCOUNT_NOTIFICATION_SUCCESS,
    GET_ACCOUNT_NOTIFICATION_FAIL,
    SHOW_NOTIFICATIONS_DROPDOWN,
    NOTIFICATION_COUNT,
    AUTH_SIGN_OUT,
    DECREASE_NOTIFICATION_COUNT,
    INCREASE_NOTIFICATION_COUNT,
    REFRESH_ACCOUNT_NOTIFICATION,
    GET_NOTIFICATION_LOADER_FAIL,
    GET_NOTIFICATION_LOADER_START, 
    NOTIFICATION_NOT_EXIST,
    SUCCESS_PAGINATE_NOTIFICATION,
    FAIL_PAGINATE_NOTIFICATION
} from "../types"

const DEFAULT_STATE = {
    accountNotifications: [],
    notificationsCount: 0,
    showNotificationsDropdown: false,
    notificationLoader: false,
    notificationNotExist: null,
    paginateNotificationPage: false,
    paginateUrl: null,
    loadNotifications: false,
    removedNotificationIds: [],
};

export default (state = DEFAULT_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ACCOUNT_NOTIFICATION_START:
          return {
            ...state,
            loadNotifications: true,
          };
        case GET_NOTIFICATION_LOADER_START:{
          return {
            ...state,
            notificationLoader: true,
          }
        };
        
        case GET_NOTIFICATION_LOADER_FAIL:{
          return {
            ...state,
            notificationLoader: false,
            loadNotifications: false,
          }
        };
        case SUCCESS_PAGINATE_NOTIFICATION:{
          return {
            ...state,
            paginateNotificationPage: true,
            paginateUrl: payload ? payload : null,
          }
        };
        case FAIL_PAGINATE_NOTIFICATION:{
          return {
            ...state,
            notificationLoader: false,
            paginateNotificationPage: false,
            paginateUrl: null
          }
        };
        
        case GET_ACCOUNT_NOTIFICATION_SUCCESS:
          const uniqueNotifications = payload.filter(newItem => {
            return (
              !state.removedNotificationIds.includes(newItem.id) &&
              !state.accountNotifications.some(existingItem => existingItem.id === newItem.id)
            );
          });
          return {
            ...state,
            accountNotifications: [...state.accountNotifications, ...uniqueNotifications],
            removedNotificationIds: [],
            loadNotifications: false,
          };
        case REFRESH_ACCOUNT_NOTIFICATION:
          return {
            ...state,
            accountNotifications: payload,
          };
        case UPDATE_ACCOUNT_NOTIFICATION_SUCCESS:
          const x = state.accountNotifications.filter((n) => n.id !== payload)

          let notificationIds = [...state.removedNotificationIds]
          if (state.loadNotifications) {
            let removedNotificationId = payload;
            notificationIds.push(removedNotificationId);
          }
        
          return {
            ...state,
            accountNotifications: x,
            removedNotificationIds: notificationIds

          }
        case NOTIFICATION_NOT_EXIST:
          return {
            ...state,
            notificationNotExist: payload
          }
        case SHOW_NOTIFICATIONS_DROPDOWN:
          return {
            ...state,
            showNotificationsDropdown: payload
          }
        case NOTIFICATION_COUNT:
          return {
            ...state,
            notificationsCount: payload
          }
        case DECREASE_NOTIFICATION_COUNT:
          return {
            ...state,
            notificationsCount: state.notificationsCount -= payload
          }
        case INCREASE_NOTIFICATION_COUNT:
          return {
            ...state,
            notificationsCount: state.notificationsCount += payload
          }
        case AUTH_SIGN_OUT:
          return DEFAULT_STATE
        // case GET_ACCOUNT_NOTIFICATION_FAIL:
        //   return {
        //     ...state,
        //     pea: payload,
        //   };
    
        default:
          return state;
    }
};