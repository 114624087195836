import store from '../store/store';
import { toast } from 'react-toastify';
import axios from './axiosConfig';

const checkIfScheduled = (pea) => {
  if (pea && pea.broadcast_date) {
    if (pea.broadcast_date > new Date().toISOString()) {
      return true;
    }
  }
  return false;
};

const checkIfSavedToPod = (pea) => {
  if (pea && pea.save_to_pod) {
    return true;
  }
};

export const sortVideos = (sort, videos, topPea) => {
  if (!sort || !videos) return;

  const filteredVideo = videos.filter((video) => video.id !== topPea.id);

  switch (sort.value) {
    case 'most_likes':
      filteredVideo.sort((a, b) => {
        if (a.likes.length > b.likes.length) return -1;
        if (a.likes.length < b.likes.length) return 1;
        return 0;
      });
      return topPea ? [topPea, ...filteredVideo] : filteredVideo;
    case 'most_recent':
      filteredVideo.sort((a, b) => {
        if (Date.parse(a.created_at) > Date.parse(b.created_at)) return -1;
        if (Date.parse(a.created_at) < Date.parse(b.created_at)) return 1;
        return 0;
      });
      return topPea ? [topPea, ...filteredVideo] : filteredVideo;
    case 'most_popular':
      filteredVideo.sort((a, b) => {
        if (
          a.views_count + a.likes.length + a.shares >
          b.views_count + b.likes.length + b.shares
        )
          return -1;
        if (
          a.views_count + a.likes.length + a.shares <
          b.views_count + b.likes.length + b.shares
        )
          return 1;
        return 0;
      });
      return topPea ? [topPea, ...filteredVideo] : filteredVideo;
    default:
      return videos;
  }
};

export const filterVideos = (filters, videos) => {
  if (!filters || !videos) return [];

  let filteredVideos = videos;

  filters.forEach((filter) => {
    const { type, name } = filter;

    switch (type) {
      case 'publish':
        const publishFilters = filters.filter((f) => f.type === 'publish');
        if (publishFilters.length > 0) {
          filteredVideos = filteredVideos.filter((video) =>
            publishFilters.some((pf) => applyPublishFilter(pf, video))
          );
        }
        break;
      case 'channels':
      case 'labels':
        const labelFilters = filters.filter((f) => f.type === type);
        if (labelFilters.length > 0) {
          filteredVideos = filteredVideos.filter((video) =>
            labelFilters.some(
              (lf) => applyLabelFilter(lf, video, type) // Pass the 'type' variable
            )
          );
        }
        break;
      case 'ratio':
        const ratioFilters = filters.filter((f) => f.type === 'ratio');
        if (ratioFilters.length > 0) {
          filteredVideos = filteredVideos.filter((video) =>
            ratioFilters.some(
              (rf) =>
                video.format === (rf.name === '1:1' ? 'Square' : 'Rectangle')
            )
          );
        }
        break;
      case 'tags':
      case 'engagements':
        const typeFilters = filters.filter((f) => f.type === type);
        if (typeFilters.length > 0) {
          filteredVideos = filteredVideos.filter((video) =>
            typeFilters.some(
              (tf) => video[type] && video[type].includes(tf.name)
            )
          );
        }
        break;
      default:
        return filteredVideos;
    }
  });

  return filteredVideos;
};

// Helper functions to apply filters

function applyPublishFilter(filter, video) {
  const { name } = filter;
  if (name === 'Series' || name.toString().toLowerCase().includes('series')) {
    return video.series;
  } else if (
    name === 'Short-Life!' ||
    name.toString().toLowerCase().includes('short-life!')
  ) {
    return video.unbroadcast_date;
  } else if (name === 'Broadcasted') {
    return !checkIfSavedToPod(video) && !checkIfScheduled(video);
  } else if (name === 'Unaired') {
    return checkIfSavedToPod(video) || checkIfScheduled(video);
  }
  return true; // Allow other cases
}

function applyLabelFilter(filter, video, type) {
  const { name } = filter;
  if (type === 'channels') {
    return (
      video['labels'] &&
      video['labels'].some((channel) =>
        channel
          .split(' ')
          .join('')
          .toLowerCase()
          .includes(name.split(' ').join('').toLowerCase())
      )
    );
  } else {
    // Handle other label types (e.g., 'labels') as before
    return (
      video[type] &&
      video[type].some((item) =>
        item
          .split(' ')
          .join('')
          .toLowerCase()
          .includes(name.split(' ').join('').toLowerCase())
      )
    );
  }
}

export const modifyArray = (array, item) => {
  if (!item) return array;

  const found = array.find((arrayItem) => arrayItem === item);

  if (found) {
    return array.filter((arrayItem) => arrayItem !== item);
  } else {
    return [...array, item];
  }
};

export const roundNumber = (number) => {
  if (number > 1000 && number < 1000000) {
    return `${(Math.abs(number) / 1000).toFixed(2)}K`;
  } else if (number > 1000000 && number < 1000000000) {
    return `${(Math.abs(number) / 1000000).toFixed(2)}M`;
  } else {
    return number;
  }
};

export const parseRank = (number) => {
  if (number === 0) return 0;

  const lastDigit = +number.toString().split('').pop();

  if (lastDigit === 1 || lastDigit === '1') {
    return `${number}st`;
  } else if (lastDigit === 2 || lastDigit === '2') {
    return `${number}nd`;
  } else if (lastDigit === 3 || lastDigit === '3') {
    return `${number}rd`;
  } else {
    return `${number}th`;
  }
};

export const stripHtml = (html) => {
  let parser = new DOMParser().parseFromString(html, 'text/html');
  return parser.body.textContent || '';
};

export const arrayBuffToJson = (buff) => {
  const pBuff = new TextDecoder('utf-8').decode(buff);
  try {
    const x = JSON.parse(pBuff);
    return JSON.parse(x);
  } catch (e) {
    return pBuff;
  }
};

export const toOrdinalNumber = (n) => {
  let deci = n % 10,
    centi = n % 100;
  if (deci === 1 && centi !== 11) {
    return n + 'st';
  }
  if (deci === 2 && centi !== 12) {
    return n + 'nd';
  }
  if (deci === 3 && centi !== 13) {
    return n + 'rd';
  }
  return n + 'th';
};

export const getDisplayName = (company, account) => {
  if (company && company.name) {
    return company.name;
  } else if (account.username) {
    return account.username;
  } else if (account.first_name) {
    return account.first_name;
  } else {
    return account.email;
  }
};

export const getDisplayFirstName = (company, account) => {
  if (account.first_name) {
    return account.first_name;
  } else if (company && company.name) {
    return company.name;
  } else if (account.username) {
    return account.username;
  } else {
    return account.email;
  }
};

export const showSearchedStories = (stories, searchTerm) => {
  return searchTerm && stories.length > 0
    ? stories.filter(
        (_) =>
          _.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          _.categories.some(
            (c) => c.toLowerCase() === searchTerm.toLowerCase()
          ) ||
          _.tags.some((t) => t.toLowerCase() === searchTerm.toLowerCase())
      )
    : [];
};

export const filterStoriesByLabel = (stories, labelValue) => {
  return stories.filter((_) =>
    _.labels.some((l) => {
      const label = l
        .split('-Target Personas')[0]
        .split('-Target Locations')[0]
        .split('-Target Industries')[0]
        .split('-Brand Type')[0]
        .split('-Mission Objectives')[0]
        .split('-Target Responses')[0]
        .split('-TV Channels')[0]
        .split('-Workday Feed')[0]
        .split('-Touchpoints')[0]
        .split('-Pea Tags')[0]
        .split('-A/B Testing')[0]
        .toLowerCase();

      return label.includes(labelValue);
    })
  );
};

// export const signInToLinkedin = () => {
//   const config = {
//     method: "get",
//     url: "https://api.linkedin.com/v2/me",
//     headers: {
//       Authorization: `Bearer ${process.env.REACT_APP_LINKEDIN_ACCESS_TOKEN}`,
//     },
//   }

//   axios(config)
//     .then((res) => {
//       console.log("signInToLinkedin", res.data)
//     })
//     .catch((error) => {
//       console.log(error)
//     })
// }

export const shareToLinkedin = async (text, userId) => {
  const body = {
    userId: userId,
    text: text,
  };

  try {
    const response = await axios.post('/accounts/linkedin/share/', body);
    toast.success('Pea shared on Linkedin');
    store.dispatch({ type: 'SHOW_OAUTH_MODAL', payload: true });
  } catch (error) {
    if (error.response.status === 401) {
      toast.error(error.response.data.message);
      store.dispatch({ type: 'SHOW_OAUTH_MODAL', payload: false });
    } else if (error.response.status === 422) {
      toast.error(error.response.data.message);
      store.dispatch({ type: 'SHOW_OAUTH_MODAL', payload: true });
    } else if (error.response.status === 400) {
      toast.error(error.response.data.message);
      store.dispatch({ type: 'SHOW_OAUTH_MODAL', payload: true });
    }
  }
};
