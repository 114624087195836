import React, { useState } from "react";
import styles from "./Accordion.module.scss";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "../../../icons/help-dropdown/arrow_png.svg";

const Accordion = ({ items, title, accordionStyles, setShowDropdown }) => {
  const handleLinkClick = (action) => {
    action();
    setShowDropdown(false);
  };

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={classNames(styles.Accordion, accordionStyles)}>
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={styles.TitleWrapper}
      >
        <h4 className={styles.Title}>{title}</h4>
        <ArrowIcon className={styles.ArrowIcon} />
      </div>

      {items.length > 0 && (
        <ul
          style={{
            maxHeight: showMenu ? "500px" : 0,
          }}
          className={styles.AccordionItems}
        >
          {items.map((item) => {
            const { id, icon, title, action } = item;

            return (
              <li key={id} className={styles.AccordionItem}>
                <span
                  onClick={() => handleLinkClick(action)}
                  className={styles.Link}
                >
                  {icon && (
                    <img src={icon} alt={title} className={styles.Icon} />
                  )}
                  <div
                    className={styles.Title}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></div>
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

Accordion.defaultProps = {
  items: [],
};

export default Accordion;
