import React from "react"
import classNames from "classnames"
import styles from "./Badge.module.scss"

const cx = classNames.bind(styles)

const Badge = (props) => {
  const { text, badgeStyles } = props
  return (
    <div
      className={cx(styles.Badge, badgeStyles, !text ? styles["Empty"] : "")}
    >
      {text}
    </div>
  )
}

export default Badge
