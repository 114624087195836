import {
  FOLLOWING_TOGGLE_CHANNEL_SELECTED_STATUS,
  FOLLOW_COMPANY,
  GET_FOLLOW_ITEMS_SUCCESS,
  UNFOLLOW_COMPANY,
} from "../types";

import update from "immutability-helper";

const DEFAULT_STATE = {
  channels: [
    {
      id: 1,
      name: "Apple Inc.",
      image: "https://image.flaticon.com/icons/svg/37/37150.svg",
      description: "Discover the innovative world of Apple.",
      selected: true,
    },
    {
      id: 2,
      name: "Tandem Bank",
      image:
        "https://pbs.twimg.com/profile_images/1148530395752910848/YU8Vwt8m_400x400.jpg",
      description: "Getting you and your money in Tandem.",
      selected: true,
    },
    {
      id: 3,
      name: "Facebook",
      image: "https://image.flaticon.com/icons/svg/124/124010.svg",
      description: "The bookstore of the present.",
      selected: true,
    },
    {
      id: 4,
      name: "Twitter",
      image: "https://image.flaticon.com/icons/svg/124/124021.svg",
      description: "We've got all your data.",
      selected: true,
    },
  ],
  following: [],
};

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FOLLOWING_TOGGLE_CHANNEL_SELECTED_STATUS: {
      const channel = action.payload;
      const index = state.channels.findIndex((item) => item.id === channel.id);
      const updatedChannel = {
        ...channel,
        selected: !channel.selected,
      };

      return update(state, {
        channels: { $merge: { [index]: updatedChannel } },
      });
    }

    case GET_FOLLOW_ITEMS_SUCCESS:
      return {
        ...state,
        following: payload,
      };
    case FOLLOW_COMPANY:
      return {
        ...state,
        following: [...state.following, payload],
      };
    case UNFOLLOW_COMPANY:
      return {
        ...state,
        following: state.following.filter(
          (followingItem) => followingItem.id !== payload
        ),
      };

    default:
      return state;
  }
};
