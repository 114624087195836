import React from 'react';
import Select from 'react-select';

export default (props) => {
  return (
    <Select
      {...props}
      options={props.options}
      value={props.input.value}
      onChange={(value) => props.input.onChange(value)}
      onBlur={() => props.input.onBlur()}
    />
  )
}
