import React, { Component } from 'react';

class VideoWrapper extends Component {
  componentDidUpdate(prevProps) {
    const { src, isPlaying, videoProps, changeAutoPlayToNewVideo, onPause } =
        this.props,
      video = videoProps.ref.current;
    if (prevProps.src !== src) {
      video.load();
      video.play().catch();
      changeAutoPlayToNewVideo(video);
    } else if (prevProps.isPlaying !== isPlaying) {
      if (typeof onPause === 'function') {
        // Check if onPause is a function
        if (isPlaying) {
          onPause();
        } else {
          video.pause();
        }
      }
    }
  }

  render() {
    const { video, defaultURL, videoProps, onPlay, onPause } = this.props;
    return (
      <>
        <video
          onPlay={onPlay}
          onPause={onPause}
          {...videoProps}
          playsInline
          muted
          disablePictureInPicture
        >
          <source src={video ? video.url : defaultURL} type='video/mp4' />
          Your browser is not supported!
        </video>
      </>
    );
  }
}

export default VideoWrapper;
