import store from "../store"
import { getFilterSeries } from "./searchBoxActions"
import {
  retrieveStory,
  clearStory,
  retrieveStoryStatistics,
  getComments,
} from "./storiesActions"
import {
  SET_SEGMENT_MODAL,
  SET_SOCIAL_BROADCAST_MODAL,
  SET_ENGAGE_MODAL,
  SET_PRODUCTION_WORKFLOW_MODAL,
  SET_STATISTICS_MODAL,
  SET_UPLOAD_PEA_MODAL,
  CLOSE_MODALS,
  SET_FORGOT_PASSWORD_MODAL,
  SET_MISSION_MAP_MODAL,
  TRIGGERED_NOTIF_COMMENT_ID,
  TRIGGERED_NOTIF_PAGE_NUMBER,
  HIDE_EMPTY_COMMENTS_ERR,
} from "../types"

const getLatestStory = (getState) => {
  const stories = getState().stories.stories

  if (stories.length > 0) {
    return stories[0]
  }
}

const retrieveModalStory =
  (isVisible, id = null) =>
  async (dispatch, getState) => {
    if (isVisible && id) {
      dispatch(retrieveStory(id))
    } else if (isVisible && !id) {
      const pea = await getLatestStory(getState)
      dispatch(retrieveStory(pea.id))
    } else if (!isVisible && !id) {
      dispatch(clearStory())
    }
  }

export const setSocialBroadcastModal = (isVisible, id) => (dispatch) => {
  dispatch(retrieveModalStory(!isVisible, id))
  dispatch({ type: SET_SOCIAL_BROADCAST_MODAL, payload: !isVisible })
}

export const setMissionMapModal = (isVisible, id) => async (dispatch) => {
  dispatch(retrieveModalStory(!isVisible, id))
  dispatch({ type: SET_MISSION_MAP_MODAL, payload: !isVisible })
}

export const setSegmentModal = (isVisible, id) => (dispatch) => {
  const invertedIsVisible = !isVisible
  dispatch(retrieveModalStory(invertedIsVisible, id))
  dispatch({ type: SET_SEGMENT_MODAL, payload: invertedIsVisible })
}

export const setEngageModal = (isVisible, id) => async (dispatch) => {
  const invertedIsVisible = !isVisible
  dispatch(retrieveModalStory(invertedIsVisible, id))
  dispatch({ type: SET_ENGAGE_MODAL, payload: invertedIsVisible })
}

export const setProductionWorkflowModal =
  (isVisible, id, commentId) => async (dispatch) => {
    const invertedIsVisible = !isVisible
    await dispatch(retrieveModalStory(invertedIsVisible, id))

    let pageNumber = null
    let unsubscribe = null

    unsubscribe = store.subscribe(() => {
      if (!unsubscribe) return
      const notId = store.getState().modal.triggeredNotifPageNumber
      pageNumber = notId
    })

    if (invertedIsVisible) {
      if (commentId) {
        setTimeout(async () => {
          if (pageNumber) {
            await dispatch(getComments(id, true, null, undefined, pageNumber))
          }
        }, 0)

        setTimeout(() => {
          unsubscribe()
          dispatch({
            type: TRIGGERED_NOTIF_PAGE_NUMBER,
            payload: null,
          })
        }, 1500)
      } else {
        await dispatch(getComments(id, true, null))
      }
    } else {
      dispatch({
        type: TRIGGERED_NOTIF_COMMENT_ID,
        payload: null,
      })
    }
    dispatch({
      type: SET_PRODUCTION_WORKFLOW_MODAL,
      payload: invertedIsVisible,
    })
  }

export const setStatisticsModal = (isVisible, id) => (dispatch) => {
  const invertedIsVisible = !isVisible
  dispatch(retrieveModalStory(invertedIsVisible, id))

  if (invertedIsVisible) {
    dispatch(retrieveStoryStatistics(id))
  }
  dispatch({ type: SET_STATISTICS_MODAL, payload: invertedIsVisible })
}

export const setUploadPeaModal = (isVisible, storyId) => async (dispatch) => {
  const invertedIsVisible = !isVisible

  if (storyId) {
    await dispatch(retrieveStory(storyId))
  } else if (!storyId) {
    dispatch(clearStory())
  }

  await dispatch(getFilterSeries())
  dispatch({ type: SET_UPLOAD_PEA_MODAL, payload: invertedIsVisible })
}

export const setForgotPasswordModal = (isVisible) => (dispatch) => {
  dispatch({ type: SET_FORGOT_PASSWORD_MODAL, payload: !isVisible })
}

export const closeModals = () => (dispatch) => {
  dispatch({ type: CLOSE_MODALS })
}

export const hideErrorMessage = () => (dispatch) => {
  dispatch({ type: HIDE_EMPTY_COMMENTS_ERR, payload: true })
}
