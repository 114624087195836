import React, { useCallback, useEffect, useState } from "react"
import classNames from "classnames"
import ReactSelect from "react-select"
import { useDropzone } from "react-dropzone"
import { Controller } from "react-hook-form"
import { MentionsInput } from "react-mentions"
import CreatableSelect from "react-select/creatable"
import Tooltip from "../Tooltip/Tooltip"
import { ReactComponent as InfoIcon } from "../../../icons/info.svg"
import styles from "./Input.module.scss"
import "./Input.scss"

export const Input = ({
  label,
  variant,
  inputRef,
  error,
  errorMsg,
  subLabel,
  errorStyles,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.Input,
        error && styles.InputError,
        variant && variant
      )}
    >
      {label && <label htmlFor={label}>{label}</label>}
      <input autoComplete="off" ref={inputRef} {...props} />
      <span className={styles.ErrorMessage}>
        {error && errorMsg && errorMsg}
      </span>
      {subLabel && <span className={styles.SubLabel}>{subLabel}</span>}
    </div>
  )
}

export const FileInput = ({ label, onChange, variant, inputRef, ...props }) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.FileInput,
        variant && variant
      )}
    >
      <input onChange={onChange} type="file" ref={inputRef} {...props} />
      {label && <label htmlFor={label}>{label}</label>}
    </div>
  )
}

const customStyles = {
  control: () => ({
    borderRadius: "5px",
    border: "1px solid #cccccc",
    display: "flex",
    minHeight: "42px",
  }),
  placeholder: () => ({
    color: "#8e8e8e",
  }),
}

export const SelectInput = ({
  label,
  options,
  variant,
  rules,
  error,
  errorMsg,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.SelectInput,
        variant && variant
      )}
    >
      {label && <label htmlFor={label}>{label}</label>}
      <Controller
        as={ReactSelect}
        options={options}
        styles={customStyles}
        placeholder="Choose Option"
        rules={rules}
        {...props}
      />
      <span className={styles.ErrorMessage}>
        {error && errorMsg && errorMsg}
      </span>
    </div>
  )
}

const getCreatableStyles=(error)=>{

  const creatableSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor:state.isFocused || state.isSelected ? "#4FD986" : "#ffffff",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: error?"#ff5151":state.isFocused || state.isSelected ? "#6b62ff" : "#cccccc",
    }),
    multiValue: (styles, { data }) => {
      const value = data.value
  
      return {
        ...styles,
        backgroundColor: "#e6e6e6",
        border:
          value && typeof value === "string" && value.includes("@")
            ? "1px solid #4A7DFF"
            : "1px solid #e6e6e6",
        padding: "4px 6px",
      }
    },
    multiValueLabel: (styles, { data }) => {
      const value = data.value
  
      return {
        ...styles,
        color:
          value && typeof value === "string" && value.includes("@")
            ? "#4A7DFF"
            : "#4a4a4a",
        fontSize: "13px",
      }
    },
    multiValueRemove: (styles, { data }) => {
      const value = data.value
  
      return {
        ...styles,
        color:
          value && typeof value === "string" && value.includes("@")
            ? "#4A7DFF"
            : "#4a4a4a",
        cursor: "pointer",
      }
    },
    placeholder: (styles) => {
      return {
        ...styles,
        color: "#8e8e8e",
      }
    },
  }
  return creatableSelectStyles
}

export const CreatableSelectInput = ({
  label,
  options,
  variant,
  defaultValue,
  error,
  errorMsg,
  rules,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.SelectInput,
        error && styles.InputError,
        variant && variant
      )}
    >
      {label && <label htmlFor={label}>{label}</label>}
      <Controller
        as={CreatableSelect}
        options={options}
        styles={getCreatableStyles(error)}
        placeholder="Choose Option"
        defaultValue={defaultValue}
        rules={rules}
        {...props}
      />
      <span className={styles.ErrorMessage}>
        {error && errorMsg && errorMsg}
      </span>
    </div>
  )
}

export const TextAreaInput = ({
  label,
  rows = 3,
  inputRef,
  variant,
  error,
  errorMsg,
  maxLength,
  changeHandler,
  ...props
}) => {
  const [textCount, setTextCount] = useState(props?.defaultValue?.length || 0)

  const changeTextCount = (e) => {
    if (!maxLength) return
    
    if (changeHandler) {
      changeHandler()
    }
    const value = e.target.value
    setTextCount(value.length)

  }

  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.TextAreaInput,
        error && styles.InputError,
        variant && variant
      )}
    >
      {label && <label htmlFor={label}>{label}</label>}

      <div className={classNames(maxLength && styles.TextWithCounter)}>
        <textarea
          ref={inputRef}
          rows={rows}
          maxLength={maxLength}
          onChange={(e) => changeTextCount(e)}
          {...props}
        />
        {maxLength && (
          <span className={styles.TextCounter}>
            {textCount}/{maxLength}
          </span>
        )}
      </div>

      {error && errorMsg && (
        <span className={styles.ErrorMessage}>{errorMsg}</span>
      )}
    </div>
  )
}

export const RadioInput = ({
  id,
  label,
  value,
  valueLabel,
  inputRef,
  variant,
  error,
  errorMsg,
  disabled,
  radioState,
  ...props
}) => {

  return (
    <div
      id={id}
      className={classNames(
        styles.FormGroup,
        styles.RadioInput,
        variant && variant
      )}
    >
      {label && (
        <label id={id} htmlFor={label} className={disabled && styles.disabled}>
          {label}
        </label>
      )}
      <div className={styles.ValueWrapper} id={id}>
        <input
          id={id}
          type="radio"
          ref={inputRef}
          value={value}
          checked={radioState}
          disabled={disabled}
          {...props}
        />
        <p id={id} className={styles.ValueLabel}>
          {valueLabel ? valueLabel : value}
        </p>
      </div>
    </div>
  )
}

export const CheckboxInput = ({
  label,
  labelPosition = "right",
  value,
  valueLabel,
  inputRef,
  variant,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.CheckboxInput,
        variant && variant
      )}
    >
      {label && labelPosition === "left" && (
        <label className={className && className} htmlFor={label}>
          {label}
        </label>
      )}

      <div className={styles.ValueWrapper}>
        <input
          className={className && className}
          type="checkbox"
          ref={inputRef}
          value={value}
          {...props}
        />
        <p className={styles.ValueLabel}>{valueLabel ? valueLabel : value}</p>
      </div>

      {label && labelPosition === "right" && (
        <label className={className && className} htmlFor={label}>
          {label}
        </label>
      )}
    </div>
  )
}

export const CheckboxToggleInput = ({
  label,
  labelPosition = "right",
  variant,
  inputRef,
  onChange,
  defaultChecked,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.CheckboxToggleInput,
        variant && variant
      )}
    >
      {label && labelPosition === "left" && (
        <label className={styles.LeftLabel} htmlFor={label}>
          {label}
        </label>
      )}
      <label className={styles.Switch}>
        <input
          type="checkbox"
          ref={inputRef}
          checked={defaultChecked}
          {...props}
          onChange={onChange}
        />
        <span className={styles.Slider}></span>
      </label>
      {label && labelPosition === "right" && (
        <label className={styles.RightLabel} htmlFor={label}>
          {label}
        </label>
      )}
    </div>
  )
}

export const RadioTabInput = ({
  label,
  variant,
  inputRef,
  children,
  defaultChecked,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.RadioTabInput,
        variant && variant
      )}
    >
      <div className={styles.ValueWrapper}>
        <input
          type="radio"
          ref={inputRef}
          checked={defaultChecked}
          {...props}
        />
        <div className={styles.ValueBorder}></div>
        <div className={styles.ValueContent}>{children}</div>
      </div>
    </div>
  )
}

export const MentionInput = ({
  label,
  variant,
  value,
  data,
  markup,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.MentionInput,
        variant && variant
      )}
    >
      <MentionsInput className="mentions-input" value={value} {...props}>
        {children}
      </MentionsInput>
    </div>
  )
}

export const DropzoneInput = ({
  formError,
  setValue,
  variant,
  label,
  tooltipText,
  setError,
  ...props
}) => {
  const [fileName, setFileName] = useState("")

  useEffect(() => {
    if (formError && formError.type === "required") {
      setError("image", {
        type: "custom",
        message: "This field is required",
      })
    }

    // eslint-disable-next-line
  }, [formError])

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    setValue(props.name, file)
    setFileName(file.name)

    if (file) {
      if (
        (file.type.split("/")[file.type.split("/").length - 1] === "jpeg" &&
          file.name.split(".")[file.name.split(".").length - 1] !== "jfif") ||
        file.type.split("/")[file.type.split("/").length - 1] === "jpg" ||
        file.type.split("/")[file.type.split("/").length - 1] === "png"
      ) {
        console.log("yesss")
      } else {
        setError("image", {
          type: "custom",
          message: "File type not supported",
        })
      }
    }

    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  })

  return (
    <div
      className={classNames(
        styles.FormGroup,
        styles.DropzoneInput,
        formError && formError.message && styles.DropzoneError,
        variant && variant
      )}
    >
      <Controller
        render={() => (
          <div className={styles.Dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            {formError && formError.message
              ? formError.message
              : fileName
              ? fileName
              : label}
            {tooltipText && (
              <Tooltip
                text={tooltipText}
                tooltipStyles={styles.DropzoneTooltip}
              >
                <InfoIcon className={styles.DropzoneInfoIcon} />
              </Tooltip>
            )}
          </div>
        )}
        {...props}
      />
    </div>
  )
}
