import axios from "../../utils/axiosConfig";
import {
  FOLLOWING_TOGGLE_CHANNEL_SELECTED_STATUS,
  FOLLOW_COMPANY,
  GET_FOLLOW_ITEMS_FAIL,
  GET_FOLLOW_ITEMS_START,
  GET_FOLLOW_ITEMS_SUCCESS,
  UNFOLLOW_COMPANY,
} from "../types";
import { tokenConfig } from "./authActions";
import { toast } from "react-toastify";

export const toggleChannelFollowingSelectedStatus =
  (data) => (dispatch, getState) => {
    dispatch({
      type: FOLLOWING_TOGGLE_CHANNEL_SELECTED_STATUS,
      payload: data,
    });
  };

export const getFollowItems = () => async (dispatch, getState) => {
  dispatch({ type: GET_FOLLOW_ITEMS_START });

  await axios
    .get("/accounts/follow-item", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_FOLLOW_ITEMS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_FOLLOW_ITEMS_FAIL });

      console.log(err);
    });
};

export const unfollowCompany = (followItem) => (dispatch, getState) => {
  axios
    .delete(`/accounts/follow-item/${followItem}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: UNFOLLOW_COMPANY, payload: followItem });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const followCompany = (company) => (dispatch, getState) => {
  const body = JSON.stringify({
    company: company,
  });

  axios
    .post("/accounts/following/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: FOLLOW_COMPANY, payload: res.data });
    })
    .catch((err) => {
      // console.log(err);
      console.log(err);
      let msg = "Something went wrong.";
      if (err.response && err.response.data) {
        if (typeof err.response.data === 'object' && Object.keys(err.response.data).length > 0) {
          let e = [];
          for (let k in err.response.data) {
            e.push(`${err.response.data[k]}`.charAt(0).toUpperCase() + `${err.response.data[k]}`.slice(1))
          }
          msg = e.join(' | ')
          toast.error(msg)
        } else if (typeof err.response.data === 'string') {
            msg = err.response.data
            toast.error(msg)
        }
      }
    });
};
