import React, { Fragment, useEffect, useState } from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faHeart, faUser } from "@fortawesome/free-regular-svg-icons"
import Button from "../../../UI/Button/Button"
import * as actions from "../../../../store/actions"
import { PeaLoader } from "../../../UI/Loader/Loader"
import { roundNumber } from "../../../../utils/utils"
import styles from "./VideoUserTooltip.module.scss"

const VideoUserTooltip = ({
  video,
  isAuth,
  company,
  account,
  followCompany,
  unfollowCompany,
  following,
  getCompanyStories,
  companyPreviewStories,
  setShowLoginModal,
}) => {
  const [isFollowing, setIsFollowing] = useState(false)
  const [followingItem, setFollowingItem] = useState({})
  const [featuredPeas, setFeaturedPeas] = useState([])
  const [loading, setLoading] = useState(false)

  let followingItems = following.map((followingItem) => followingItem.company.id);

  // const navigateTo = (video) => `/company/${video.company}`
  const navigateTo = (video) =>  `/${video.company_name?video.company_name.replaceAll(" ","").toLowerCase():video.company}/peabox.tv`
  
  useEffect(() => {
    if (followingItems.length > 0) {
      const isCompanyFollowed = followingItems.includes(company)
      setIsFollowing(isCompanyFollowed)

      if (isCompanyFollowed && followingItems.length > 0) {
        setFollowingItem(
          following.find(
            (followingItem) => followingItem.company.id === company
          )
        )
      }
    }
  }, [followingItems, company, following])

  useEffect(() => {
    if (company && company && featuredPeas.length === 0) {
      getCompanyStories({ company })
    }
  }, [company])

  useEffect(() => {
    if (
      companyPreviewStories &&
      companyPreviewStories.length > 0 &&
      featuredPeas.length === 0
    ) {
      setFeaturedPeas(
        companyPreviewStories.filter((v) => v.company === company)
      )
    }
  }, [companyPreviewStories])

  const followButtonHandler = async (company) => {
    if (account && account.deactivated_at) {
      toast.error(
        "Following or unFollowing company is not allowed for deactivated accounts."
      )
    } else {
      if (!loading) {
        if (account && account.account_type === "Basic") {
          setLoading(true)
          if (!isFollowing) {
            const result = await followCompany({ company })
            setIsFollowing(true)
            if (result) {
              setLoading(false)
            }
          } else {
            const result = await unfollowCompany({
              followingItem: followingItem.id,
            })
            setIsFollowing(false)
            if (result) {
              setLoading(false)
            }
          }
        } else {
          setShowLoginModal(true)
        }
      }
    }
  }

  return (
    <Fragment>
      <div>
        <img
          alt="user"
          src={video.company_image}
          className={styles.TooltipUserPicture}
        />
        <span className={styles.TooltipUserName}>
          <Link to={navigateTo(video)}>{video.company_name}</Link>
        </span>
        {isAuth && (
          <span className={styles.FollowingButtonWrapper}>
            <Button
              buttonStyle={classNames(
                styles.FollowButton,
                isFollowing ? styles.FollowingButton : ""
              )}
              btnType={isFollowing ? "Success" : "Follow"}
              key={isFollowing ? "following" : "follow"}
              onClick={() => followButtonHandler(company)}
              loading={loading}
            >
              {isFollowing ? "Following" : "Follow"}
            </Button>
          </span>
        )}
      </div>
      <div className={styles.TooltipPicturesWrapper}>
        {featuredPeas.length > 0 ? (
          featuredPeas.map((featuredPea) => {
            const { id, image } = featuredPea

            return (
              <Link key={id} to={`/pea/${id}`}>
                <img src={image} alt={id} className={styles.TooltipPea} />
              </Link>
            )
          })
        ) : (
          <>
            <PeaLoader format="Square" loaderStyles={styles.TooltipPeaLoader} />
            <PeaLoader format="Square" loaderStyles={styles.TooltipPeaLoader} />
            <PeaLoader format="Square" loaderStyles={styles.TooltipPeaLoader} />
          </>
        )}
      </div>
      <div className={styles.TooltipUserStats}>
        <div className={styles.UserStat}>
          <FontAwesomeIcon className={styles.UserStatIcon} icon={faUser} />
          {roundNumber(video.company_followers)}
        </div>
        <div className={styles.UserStat}>
          <FontAwesomeIcon className={styles.UserStatIcon} icon={faEye} />
          {roundNumber(video.views_count)}
        </div>
        <div className={styles.UserStat}>
          <FontAwesomeIcon className={styles.UserStatIcon} icon={faHeart} />
          {roundNumber(video.likes.length)}
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  account: state.auth.account,
  following: state.following.following,
  companyPreviewStories: state.stories.companyPreviewStories,
})

const mapDispatchToProps = (dispatch) => ({
  followCompany: ({ company }) => dispatch(actions.followCompany({ company })),
  unfollowCompany: ({ followingItem }) =>
    dispatch(actions.unfollowCompany({ followingItem })),
  getCompanyStories: ({ company }) =>
    dispatch(actions.getCompanyStories({ company })),
  setShowLoginModal: (id) => dispatch(actions.setShowLoginModal(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoUserTooltip)
