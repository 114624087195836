import { SET_SHOW_PEAPAGE_MODAL, CLEAR_OPEN_FROM } from "../types"

const DEFAULT_STATE = {
  showPeaPageModal: false,
  videoId: null,
  openFrom: null,
  companyId: null,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SHOW_PEAPAGE_MODAL:
      return {
        ...state,
        showPeaPageModal: action.payload,
        videoId: action.videoId,
        openFrom: action.openFrom,
        companyId: action.companyId,
      }
    case CLEAR_OPEN_FROM:
      return {
        ...state,
        openFrom: null,
      }
    default:
      return state
  }
}
