import React, { useLayoutEffect, useState } from "react"
import qs from "qs"
import { compose } from "redux"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import { isSafari } from "react-device-detect"
import { useMediaQuery } from "react-responsive"
import { LinkedIn } from "react-linkedin-login-oauth2"
import { useHistory, useLocation } from "react-router-dom"
import { companyRoles } from "../../../const"
import * as actions from "../../../store/actions"
import LogoGreen from "../../../icons/LogoGreen.svg"
import Button from "../../../components/UI/Button/Button"
import SideImageLogo2 from "../../../icons/SideImage_2.svg"
import PeaLogoLetterin from "../../../icons/LogoBlue01.svg"
import SideImageLogo3 from "../../../icons/SideImage_3.svg"
import { generateAccessToken } from "../../../utils/linkedin"
import { Loader } from "../../../components/UI/Loader/Loader"
import LinkedinLogo from "../../../components/UI/Logo/LinkedinLogo"
import CustomInput from "../../../components/UI/Inputs/CustomInput"
import SelectInput from "../../../components/UI/Inputs/SelectInput.js"
import styles from "./Signup.module.scss"

const theme = {
  colors: {
    primary: "var(--color-primary)",
    primary25: "#DEEBFF",
    neutral0: "white",
  },
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "solid 1px var(--very-light-pink)",
  }),
}

const SelectCustomInput = (props) => {
  return (
    <>
      <SelectInput
        {...props}
        styles={customStyles}
        theme={theme}
        className={styles.selectRoleBusinessWrapper}
        classNamePrefix={"custom"}
      />
      {props.meta.touched && props.meta.error && (
        <div className={styles.ErrorMessage}>
          {<span>{props.meta.error}</span>}
        </div>
      )}
    </>
  )
}

const SideImage = ({ isMobile, isBusinessAccount }) => {
  if (!isMobile && !isBusinessAccount) {
    return <img src={SideImageLogo2} className={styles.SideImageLogo} />
  } else if (!isMobile && isBusinessAccount) {
    return <img src={SideImageLogo3} className={styles.SideImageLogo} />
  }
  return null
}

const validate = (v) => {
  const errors = {}
  if (!v.email) {
    errors.email = "Email is required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v.email)) {
    errors.email = "Invalid email format"
  }

  if (!v.password) {
    errors.password = "Password is required"
  } else if (v.password.length < 8) {
    errors.password = "Password must be 8 chacracters or more"
  }

  if (!v.companyRole) {
    errors.companyRole = "Company role is required"
  }
  return errors
}

const Signup = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const isDesktopOrTablet = useMediaQuery({
    query: "only screen and (min-width: 769px)",
  })
  const history = useHistory()
  const location = useLocation()
  const { company } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [isBusinessAccount, setIsBusinessAccount] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useLayoutEffect(()=> {
    if(company){
      setIsBusinessAccount(true)
    }
  }, [])

  if (location.pathname === "/sign-up" && isDesktopOrTablet) {
    props.setShowSignUpModal(true)

    if (company) {
      history.push(`/?company=${company}`)
    } else {
      history.push(`/`)
    }
  }

  const onSubmit = async (formData) => {
    const { email, password, companyRole } = formData
    setSubmitting(true)
    let success = await props.signUp({ email, password, companyRole, company })
    setSubmitting(false)
  }

  const responseLinkedin = async (res) => {
    props.signInLinkedInResult(res)
    let success = res
    let response = null;

    if (success) {
      await generateAccessToken({
        auth_code: res,
        redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
        client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
        client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      })
        .then(async (res) => {
          if (res && res.token) {
            response = await props.signupWithLinkedIn(res.token, isBusinessAccount, company)
          }
        })
        .catch((err) => console.log(err))
        
        if(!props.onSuccess){
          if (
            response.account &&
            (response.account.account_type === "Business" ||
              response.account.account_type === "Admin")
          ) {
            history.push("/broadcasting-pod")
          } else {
            history.push("/")
          }
        } else {
          if (
            response.account &&
            (response.account.account_type === "Business" ||
              response.account.account_type === "Admin")
          ) {
            history.push("/broadcasting-pod")
          }
          props.onSuccess()
        }
    }
  }

  const { handleSubmit } = props,
    showLoginModal = () => {
      props.setShowSignUpModal(false)
      props.setShowLoginModal(true)
    }

  return (
    <div className={styles.SignUp}>
      <div className={styles.SignUp__Info}>
        {isMobile ? (
          <>
            <img
              src={PeaLogoLetterin}
              className={`${
                isSafari ? styles.PeaLogoLetterInSafari : styles.PeaLogoLetterIn
              }`}
              alt="peabox"
            />
            <p className={styles.undertext}>
              <strong>Broadcasting</strong> your tiny breaks!
            </p>
          </>
        ) : (
          <section>
            <p className={styles.undertext}>
              <span className={styles.normal}>
                <strong>Broadcasting</strong> your tiny breaks!
              </span>
            </p>
          </section>
        )}
        <SideImage isMobile={isMobile} isBusinessAccount={isBusinessAccount} />
      </div>
      <div className={styles.SignUp__Form}>
        {!isMobile && (
          <div className={styles["SignUp__Form--Image"]}>
            <img src={LogoGreen} alt="main-logo" className={styles.ImageLogo} />
          </div>
        )}
        <div style={{ zIndex: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <Field
                name="email"
                type="text"
                id="email"
                placeholder={`${isBusinessAccount ? "Company" : ""} E-mail`}
                component={CustomInput}
                handleClearError={props.clearAuthMessage}
              />
            </fieldset>
            <fieldset>
              <Field
                name="password"
                type="password"
                id="password"
                placeholder="Create Password"
                component={CustomInput}
                {...{ eyeIcon: true }}
                handleClearError={props.clearAuthMessage}
              />
            </fieldset>
            {isBusinessAccount && (
              <fieldset className={styles.BusinessAccountSelectWrapper}>
                <Field
                  name="companyRole"
                  id="companyRole"
                  placeholder="Select Company Role"
                  options={companyRoles}
                  component={SelectCustomInput}
                  handleClearError={props.clearAuthMessage}
                />
              </fieldset>
            )}
            {props.errorMessage && typeof props.errorMessage === "string" && (
              <div className={styles.ErrorMessageActionLevel}>
                {props.errorMessage}
              </div>
            )}
            {props.authSuccessMessage && (
              <div className={styles.SuccessMessageActionLevel}>
                {props.authSuccessMessage}
              </div>
            )}
            <div className={styles.SignUpButtonWrapper}>
              <Button
                btnType={isBusinessAccount ? "Success" : "Blue"}
                type="submit"
              >
                {submitting ? <Loader /> : "Continue"}
              </Button>
            </div>
            <LinkedIn
              scope="r_liteprofile r_emailaddress w_member_social"
              clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
              redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URI}
              onError={responseLinkedin}
              onSuccess={responseLinkedin}
            >
              {({ linkedInLogin }) => (
                <Button
                  btnType="Linkedin"
                  onClick={linkedInLogin}
                  type="button"
                >
                  <div className={styles.LinkedInWrapper}>
                    <img src={LinkedinLogo} />
                    <span>Continue with LinkedIn</span>
                  </div>
                </Button>
              )}
            </LinkedIn>
            {!isBusinessAccount && (
              <Button
                btnType="Success"
                onClick={() => setIsBusinessAccount(true)}
              >
                Create a Business Account
              </Button>
            )}
            <div className={styles.CreateAccountText}>
              <div>
                <span>Creating an account means you're ok with Peabox's </span>
                <strong>Business Terms of Services</strong> and{" "}
                <strong>Privacy Policy.</strong>
              </div>
              <p>
                Already a member?{" "}
                <strong
                  className={styles.signInLink}
                  onClick={() => {
                    props.clearAuthMessage()
                    !isMobile ? showLoginModal() : history.push("/sign-in")
                  }}
                >
                  Sign in now
                </strong>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  errorMessage: state.auth.errorMessage,
  authSuccessMessage: state.auth.authSuccessMessage,
})

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: "signup", validate })
)(Signup)
