import React, { useEffect, useState } from "react";
import Image from "../UI/Image/Image";
import styles from "./Footer.module.scss";
import MiniLogo from "../../icons/IconVerde.svg"
import LocationIcon from "../../icons/footer/ic_location.png"
import LanguageIcon from "../../icons/footer/ic_language.png"
import LanguageDropIcon from "../../icons/footer/ic_language_drop.png"
import { footerArmYourPeas, footerIntegrations, footerKnowUs, footerResources } from "../../const";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive"
import { connect } from "react-redux";
import * as actions from "../../store/actions"

function Footer({
  account,
  setShowSignUpModal,
  setShowLoginModal
}) {
  
  const [footerProducts, setFooterProducts]=useState()

  const location = useLocation()
  const isMobile = useMediaQuery({ query: "(max-width: 821px)" })
  let history=useHistory()

  useEffect(()=>{
      setFooterProducts(
        [
          {
            title:"Peabox.tv",
            link:"https://www.peabox.tv.com/",    
          },
          {
            title:`Workday Guide`,
            link:account?"/workday-guide":"/login",    
          },
          {
            title:`Power-up / Pricing`,
            link:process.env.REACT_APP_WEBSITE_URL,    
          }
        ]
      )
  },[account])

  const handleLinkClick=(link)=>{

    let routes=["/workday-guide","pricing","peabox.tv"]
    if(routes.includes(link)){
      if(account.account_type==="Basic"){
        history.push(link)
      }
      else{
        if(isMobile){
          history.push("/sign-in")
        }
        else{
          setShowLoginModal(true)  
        }
      }
    }
    else{
      if(isMobile){
        history.push("/sign-up")
      }
      else{
        setShowSignUpModal(true)  
      }
    }
  }

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerServicesContainer}>
        <div className={styles.services}>
          <p>recruit.</p>
          <p>sell.</p>
          <p>nurture.</p>
          <p>educate.</p>
          <p>help.</p>
          <p>capture info.</p>
          <p>gather feedback.</p>
          <p>a/b test.</p>
        </div>
        <div className={styles.servicesDown}>
          <p>brand page.</p>
          <p>broadcast pod.</p>
          <p>hero stats.</p>
          <p>mission maps.</p>
          <p>pea armoury.</p>
          <p>power labels.</p>
        </div>
      </div>
      <div className={styles.footerContent}>
        {
          !isMobile ?
            <div className={styles.footerBottomContent}>
              <div className={styles.img}>
                <Link to={location.pathname}>
                  <Image src={MiniLogo} alt="logo" className={styles.MiniLogo} onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }} />
                </Link>
              </div>
              <ul>
                <h3 className={styles.FooterOptionHeading}>PRODUCT.</h3>
                {
                  footerProducts?.map((res, index) => {
                    return (
                      <li>
                        <div className={styles.footerLink} onClick={()=>handleLinkClick(res.link)}>
                          {res.title}  {res.title === "Workday Guide" ? <sup>New</sup> : ""}
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
              <ul>
                <h3 className={styles.FooterOptionHeading}>
                  ARM YOUR PEAS! <br />
                  (from templates)
                </h3>
                {
                  footerArmYourPeas.map((arm_pea, index) => {

                    return (
                      <li>
                        {
                          arm_pea.sub_list.length == 0 ?
                            <a href={arm_pea.link} className={styles.footerLink} target="_blank">
                              {arm_pea.title}
                            </a>
                            :
                            <>
                              {arm_pea.title}
                              {
                                arm_pea.sub_list.map((sub, index) => {
                                  return (
                                    <a href={sub.link} className={styles.footerLink} target="_blank">
                                      <p className={styles.SubItem}>- {sub.title}</p>
                                    </a>
                                  )
                                })
                              }
                            </>
                        }
                      </li>
                    )
                  })
                }
              </ul>
              <ul>
                <h3 className={styles.FooterOptionHeading}>INTEGRATIONS.</h3>
                {
                  footerIntegrations.map((integration, index) => {

                    return (
                      <li>
                        {
                          integration.sub_list.length == 0 ?
                            <a href={integration.link} className={styles.footerLink} target="_blank">
                              {integration.title}
                            </a>
                            :
                            <>
                              {integration.title}
                              {
                                integration.sub_list.map((sub, index) => {
                                  return (
                                    <a href={sub.link} className={styles.footerLink} target="_blank">
                                      <p className={styles.SubItem}>- {sub.title}</p>
                                    </a>
                                  )
                                })
                              }
                            </>

                        }
                      </li>
                    )
                  })
                }
              </ul>
              <ul>
                <h3 className={styles.FooterOptionHeading}>RESOURCES.</h3>
                {
                  footerResources.map((res, index) => {
                    return (
                      <li><a href={res.link} className={styles.footerLink} target="_blank">{res.title}</a></li>
                    )
                  })
                }
              </ul>
              <ul>
                <h3 className={styles.FooterOptionHeading}>GET TO KNOW US.</h3>
                {
                  footerKnowUs.map((res, index) => {
                    return (
                      <li>
                        <a href={res.link} className={styles.footerLink} target="_blank">
                          {res.title}  {res.title === "Career Heroes!" ? <sup>We are Hiring!</sup> : ""}
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            :
            
            <div>
              <div className={styles.img}>
              <Link to={location.pathname}>
                <Image src={MiniLogo} alt="logo" className={styles.MiniLogo} onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }} />
              </Link>
            </div>
            <div className={styles.footerBottomContent}>
              <div className={styles.LeftOptions}>
            <ul>
              <h3 className={styles.FooterOptionHeading}>PRODUCT.</h3>
              {
                footerProducts?.map((res, index) => {
                  return (
                    <li>
                      <div className={styles.footerLink} onClick={()=>handleLinkClick(res.link)}>
                        {res.title}  {res.title === "Workday Guide" ? <sup>New</sup> : ""}
                      </div>
                    </li>
                  )
                })
              }
            </ul>
            <ul>
              <h3 className={styles.FooterOptionHeading}>INTEGRATIONS.</h3>
              {
                footerIntegrations.map((integration, index) => {
  
                  return (
                    <li>
                      {
                        integration.sub_list.length == 0 ?
                          <a href={integration.link} className={styles.footerLink} target="_blank">
                            {integration.title}
                          </a>
                          :
                          <>
                            {integration.title}
                            {
                              integration.sub_list.map((sub, index) => {
                                return (
                                  <a href={sub.link} className={styles.footerLink} target="_blank">
                                    <p className={styles.SubItem}>- {sub.title}</p>
                                  </a>
                                )
                              })
                            }
                          </>
  
                      }
                    </li>
                  )
                })
              }
            </ul>

            <ul>
              <h3 className={styles.FooterOptionHeading}>GET TO KNOW US.</h3>
              {
                footerKnowUs.map((res, index) => {
                  return (
                    <li>
                      <a href={res.link} className={styles.footerLink} target="_blank">
                        {res.title}  {res.title === "Career Heroes!" ? <sup>We are Hiring!</sup> : ""}
                      </a>
                    </li>
                  )
                })
              }
            </ul>
            </div>

            <div className={styles.RightOptions}>
            <ul>
              <h3 className={styles.FooterOptionHeading}>
                ARM YOUR PEAS! <br />
                (from templates)
              </h3>
              {
                footerArmYourPeas.map((arm_pea, index) => {
  
                  return (
                    <li>
                      {
                        arm_pea.sub_list.length == 0 ?
                          <a href={arm_pea.link} className={styles.footerLink} target="_blank">
                            {arm_pea.title}
                          </a>
                          :
                          <>
                            {arm_pea.title}
                            {
                              arm_pea.sub_list.map((sub, index) => {
                                return (
                                  <a href={sub.link} className={styles.footerLink} target="_blank">
                                    <p className={styles.SubItem}>- {sub.title}</p>
                                  </a>
                                )
                              })
                            }
                          </>
                      }
                    </li>
                  )
                })
              }
            </ul>
          
            <ul>
              <h3 className={styles.FooterOptionHeading}>RESOURCES.</h3>
              {
                footerResources.map((res, index) => {
                  return (
                    <li><a href={res.link} className={styles.footerLink} target="_blank">{res.title}</a></li>
                  )
                })
              }
            </ul>
              </div>
          </div>
            </div>
        }

        <div className={styles.footerBottom}>
          <div className={styles.footerBottomLinks}>
            <div className={styles.FooterMoreContainer}>
              <Image
                src={LocationIcon}
                alt=""
                width="12px"
                height="14.67px"
              />
              <span style={{ marginLeft: "10px", textDecoration: "underline" }}>
                With love, from London
              </span>
            </div>
            <div className={styles.FooterMoreContainer}>
              <Image src={LanguageIcon} alt width="12px" height="12px" />
              <span style={{ marginLeft: "10px", marginRight: "10px" }}>English</span>
              <Image src={LanguageDropIcon} alt width="8" height="6" />
            </div>
            <span style={{ textDecoration: "underline" }}>Cookie Settings</span>
          </div>
          <span>&copy; Peabox</span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  account: state.auth.account,
})

const mapDispatchToProps = (dispatch) => ({
  setShowSignUpModal: (id) => dispatch(actions.setShowSignUpModal(id)),
  setShowLoginModal: (id) => dispatch(actions.setShowLoginModal(id)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Footer)
