import React, { useState } from "react"
import classNames from "classnames"
import Tab from "./Tab/Tab"
import styles from "./PBTabs.module.scss"

const PBTabs = ({ children, initialTab, tabStyles }) => {
  const startingTab = initialTab || children[0].props.label
  const [currentTab, setCurrentTab] = useState(startingTab)

  const onClickCurrentTab = (tab) => setCurrentTab(tab)

  return (
    <div className={classNames(styles.TabContainer, tabStyles)}>
      <ul className={styles.TabsList}>
        {children.map((child) => (
          <Tab
            key={child.props.label}
            label={child.props.label}
            currentTab={currentTab}
            onClick={onClickCurrentTab}
          />
        ))}
      </ul>
      <hr />
      <div className={styles.TabContent + " modal-tab-content-list"}>
        {children.map((child) => {
          return (
            (child.props.label === currentTab && child.props.children) ||
            undefined
          )
        })}
      </div>
    </div>
  )
}

export default PBTabs
