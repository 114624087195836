import {SET_IS_FAVORITE} from "../types";

const DEFAULT_STATE = {
  favorites: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_IS_FAVORITE:
      let favorites = {...state.favorites};
      favorites[action.id] = !(!!state.favorites[action.id]);
      return {...state, favorites: favorites};
    default:
      return state;
  }
};
