import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import authReducer from "./authReducer"
import dashboardReducer from "./dashboard"
import searchBoxReducer from "./searchBoxReducer"
import interestsReducer from "./interestsReducer"
import storiesReducer from "./storiesReducer"
import followingReducer from "./followingReducer"
import loginSignupModaReducer from "./loginSignupModaReducer"
import peaPageModaReducer from "./peaPageModaReducer"
import favoritesReducer from "./favoritesReducer"
import peaReducer from "./peaReducer"
import modalReducer from "./modal.reducer"
import accountReducer from "./account.reducer"
import notificationReducer from "./notificationReducer"

export default combineReducers({
  account: accountReducer,
  auth: authReducer,
  dash: dashboardReducer,
  favorites: favoritesReducer,
  following: followingReducer,
  form: formReducer,
  interests: interestsReducer,
  loginSignupModals: loginSignupModaReducer,
  modal: modalReducer,
  pea: peaReducer,
  peaPageModal: peaPageModaReducer,
  searchBox: searchBoxReducer,
  stories: storiesReducer,
  notifications: notificationReducer,
})
