import React, { Fragment, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Badge from '../UI/Badge/Badge';
import Button from '../UI/Button/Button';
import PBModal from '../UI/PBModal/PBModal';
import SearchBox from '../SearchBox/SearchBox';
import Dropdown from '../UI/Dropdown/Dropdown';
import * as actions from '../../store/actions';
import PBSelect from '../UI/PBSelect/PBSelect';
import Backdrop from '../UI/Backdrop/Backdrop';
import Login from '../../containers/Auth/Login';
import MiniLogo from '../../icons/IconVerde.svg';
import MiniLogo2 from '../../icons/IconBlue.svg';
import Signup from '../../containers/Auth/Signup';
import Accordion from '../UI/Accordion/Accordion';
import LineBreak from '../UI/LineBreak/LineBreak';
import { getDropdownData } from './DropdownDataHelper';
import TwitterIcon from '../../icons/twitter-icon.png';
import SpotifyIcon from '../../icons/spotify-icon.svg';
import { CheckboxToggleInput } from '../UI/Input/Input';
import FacebookIcon from '../../icons/facebook-icon.png';
import InstagramIcon from '../../icons/instagram-icon.png';
import DefaultUserIcon from '../../icons/default_user_icon.png';
import SearchInfoModal from '../SearchInfoModal/SearchInfoModal';
import LogoGreen from '../../icons/LogoGreen.svg';
import { ReactComponent as BurgerIcon } from '../../icons/burger.svg';
import BasicDropdown from '../UI/Dropdown/BasicDropdown/BasicDropdown';
import { ReactComponent as BellIcon } from '../../icons/common/bell2.svg';
import { ReactComponent as LightningIcon } from '../../icons/lightning.svg';
import { ReactComponent as ScoreIcon } from '../../icons/score.svg';
import broadcastingPodLogo from '../../icons/logo/logo-broadcasting-pod.png';
import { setProductionWorkflowModal } from '../../store/actions/modal.action';
import BusinessDropdown from '../UI/Dropdown/BusinessDropdown/BusinessDropdown';
import BroadcastDropdown from '../UI/Dropdown/BroadcastDropdown/BroadcastDropdown';
import { ReactComponent as ChevronDownIcon } from '../../icons/help-dropdown/arrow_png.svg';
import styles from './Header.module.scss';
import store from '../../store/store';

const DropItem = loadable(() => import('../BroadcastingPod/DropItem/DropItem'));
const WatchlistItem = loadable(() =>
  import('../../containers/Watchlist/WatchlistItem/WatchlistItem')
);
const FollowingItem = loadable(() =>
  import('../../containers/Following/FollowingItem/FollowingItem')
);

const cx = classNames.bind(styles);

const Header = ({
  type,
  setType,
  signOut,
  isAuth,
  setIsSearchSubmitted,
  setShouldDoHomeAnimation,
  setSearchValue,
  searchValue,
  selectFilter,
  setLabelValue,
  sortBy,
  sortByValue,
  clearAuthMessage,
  tags,
  series,
  stories,
  miniLogo = false,
  showLoginModal,
  showSignUpModal,
  setShowSignUpModal,
  setShowLoginModal,
  isHomeVisited,
  updateInterest,
  account,
  getFilterSeries,
  getChannels,
  channels,
  followCompany,
  unfollowCompany,
  getAccountInterest,
  interest,
  following,
  getAccountFollowing,
  company,
  socialBroadcastModal,
  setSocialBroadcastModal,
  uploadPeaModal,
  setUploadPeaModal,
  toggleSeriesFilter,
  isSeriesOnly,
  watchlists,
  productionWorkflowModal,
  setProductionWorkflowModal,
  getSeriesStories,
  airDropDropdownVisible,
  setAirDropDropdownVisible,
  watchlistDropdownVisible,
  setWatchlistDropdownVisible,
  followingDropdownVisible,
  setFollowingDropdownVisible,
  getWatchListStories,
  airdropStories,
  accountNotifications,
  getNotifications,
  getNotificationsCount,
  updateNotifications,
  getAccounts,
  showNotificationsDropdown,
  setShowNotificationsDropdown,
  getAirDropStories,
  notificationsCount,
  notificationLoader,
  clearOpenFrom,
  getTags,
  isWorkdDayGuidePage,
  fetchNotificationComments,
  paginateNotificationPage,
  loadNotifications,
  paginateUrl,
}) => {
  const [displayName, setDisplayName] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownItems, setShowDropdownItems] = useState(false);
  const [showSearchInfo, setShowSearchInfo] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [notifPaginationPage, setNotifPaginationPage] = useState(1);
  const [isWorkDayGuidePage, setIsWorkDayGuidePage] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [watchlistLoader, setWatchlistLoader] = useState(false);
  const [accountImage, setAccountImage] = useState('');
  const intervalRef = useRef(null);

  const isMobileDevice = useMediaQuery({
    query: 'only screen and (max-width: 1000px)',
  });

  const isTabletDevice = useMediaQuery({
    query: 'only screen and (max-width: 1000px)',
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (account && isAuth) {
      if (account && account.account_image) {
        setAccountImage(account.account_image);
      } else {
        setAccountImage(DefaultUserIcon);
      }
    }
  }, [account]);

  useEffect(() => {
    if (account && account.business && company && company.id) {
      getAirDropStories();
    }
  }, [account, company]);

  useEffect(() => {
    if (account) {
      intervalRef.current = setInterval(() => {
        const currentDate = new Date();
        if (
          account &&
          account.business &&
          company &&
          company.id &&
          airdropStories.length > 0
        ) {
          let newAirdropStories = airdropStories.filter((airdropStory) => {
            const unbroadcastDate =
              airdropStory.pea.unbroadcast_date !== null
                ? new Date(airdropStory.pea.unbroadcast_date)
                : airdropStory.pea.unbroadcast_date;
            if (unbroadcastDate) {
              return unbroadcastDate < currentDate;
            }
          });
          if (newAirdropStories.length > 0) {
            store.dispatch({
              type: 'FILTERED_AIRDROP_STORIES',
              payload: newAirdropStories,
            });
          }
        } else if ((account.account_type === 'Basic', watchlists.length > 0)) {
          let newWatchlist = watchlists.filter((watchListPea) => {
            const unbroadcastDate =
              watchListPea.unbroadcast_date !== null
                ? new Date(watchListPea.unbroadcast_date)
                : watchListPea.unbroadcast_date;
            if (unbroadcastDate) {
              return unbroadcastDate < currentDate;
            }
          });

          if (newWatchlist.length > 0) {
            store.dispatch({
              type: 'FILTERED_WATCHLIST',
              payload: newWatchlist,
            });
          }
        }
      }, 2 * 60 * 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [account, company, watchlists, airdropStories]);

  useEffect(() => {
    if (company && company.id && account && account.account_type !== 'Basic') {
      getFilterSeries();
    }
  }, [getChannels, getFilterSeries, company]);

  useEffect(() => {
    if (company) {
      getAccounts(company.id);
    }
  }, [getAccounts, company]);

  useEffect(() => {
    if (account && account.interest && account.account_type === 'Basic') {
    }
  }, [account, getAccountInterest]);

  useEffect(() => {
    if (account && account.id) {
      getTags();

      if (account.account_type === 'Basic' && account.interest) {
        getWatchListStories();
        getAccountInterest({ interestId: account.interest });
        getChannels();
        getAccountFollowing({ accountId: account.id });
      } else if (account.account_type !== 'Basic') {
        getNotifications('false');
        getNotificationsCount('false');
      }
    }
  }, [account, getAccountFollowing]);

  useEffect(() => {
    if (account) {
      if (account.first_name && account.last_name) {
        setDisplayName(account.first_name + ' ' + account.last_name);
      } else if (account.first_name) {
        return setDisplayName(account.first_name);
      } else if (account.username) {
        return setDisplayName(account.username);
      } else if (account.email) {
        return setDisplayName(account.email);
      } else {
        setDisplayName('');
      }
    }
  }, [account]);

  useEffect(() => {
    if (account?.account_type === 'Basic') {
      if (notifPaginationPage > 1 && notificationLoader && !watchlistLoader) {
        setWatchlistLoader(true);
        getWatchListStories('', setWatchlistLoader, paginateUrl);
      }
    } else if (account?.account_type !== 'Basic') {
      if (notifPaginationPage > 1 && notificationLoader) {
        getNotifications('false', '', notifPaginationPage);
      }
    }
  }, [notifPaginationPage]);

  useEffect(() => {
    if (account?.account_type === 'Basic') {
      if (watchlists.length == 5 && isRender && paginateNotificationPage) {
        setWatchlistLoader(true);
        getWatchListStories(1, setWatchlistLoader);
      }
    } else if (account?.account_type !== 'Basic') {
      if (
        accountNotifications.length == 3 &&
        isRender &&
        paginateNotificationPage
      ) {
        getNotifications('false', '', 1, 8);
      }
    }
    setIsRender(true);
  }, [accountNotifications.length, watchlists.length]);

  useEffect(() => {
    if (!showNotificationsDropdown) {
      setNotifPaginationPage(1);
    }
  }, [showNotificationsDropdown]);

  useEffect(() => {
    setIsWorkDayGuidePage(location.pathname == '/workday-guide' ? true : false);
  }, [location.pathname]);

  let showSearchBox =
    type === 'Gallery' ||
    type === 'Company' ||
    type === 'Interests' ||
    type === 'PeaPage' ||
    type === 'Workday';
  let showSearchSortBy =
    type === 'Gallery' ||
    type === 'Company' ||
    type === 'Interests' ||
    type === 'PeaPage';
  let showMiniLogo =
    type === 'Gallery' ||
    type === 'Company' ||
    type === 'Interests' ||
    type === 'Settings' ||
    type === 'Workday' ||
    miniLogo;

  let isBusinessType = type === 'Pod';
  let isBusinessLandingPage = type === 'BusinessLandingPage';

  const navigateLogin = () => {
    if (isMobileDevice) {
      history.push('/sign-in');
    } else {
      setShowLoginModal(true);
      setShowJoin(true);
    }
  };
  const navigateJoin = () => {
    if (isMobileDevice) {
      history.push('/sign-up');
    } else {
      setShowSignUpModal(true);
      setShowJoin(true);
    }
  };

  const functionMap = {
    isAuth,
    account,
    signout: () => {
      signOut(history);
      setShowLoginModal(false);
    },
    login: () => navigateLogin(),
    join: () => navigateJoin(),
    interests: () => history.push('/customize-interests'),
    following: () => history.push('/following'),
    watchlist: () => history.push('/watchlist'),
    workdayGuide: () => history.push('/workday-guide'),
    account: account,
    history: history,
  };

  if ((showLoginModal || showSignUpModal) && isMobileDevice) {
    if (showLoginModal && showJoin) {
      setShowLoginModal(false);
      setShowJoin(false);
      navigateLogin();
    } else if (showSignUpModal && showJoin) {
      setShowSignUpModal(false);
      setShowJoin(false);
      navigateJoin();
    }
  }

  if (isMobileDevice && showDropdown) {
    showSearchBox = true;
    showMiniLogo = true;
  }

  if (account && account.id) {
    showMiniLogo = true;
    if (type == 'Settings' && account && account.account_type === 'Basic') {
      showSearchBox = false;
    } else if (account && account.account_type === 'Basic' && type !== 'Pod') {
      showSearchBox = true;
      setType('Gallery');
    } else {
      showSearchBox = true;
    }
  }

  const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].some(function (exactPlatformString) {
      return navigator.platform === exactPlatformString;
    });
  };

  const handleLogoClick = () => {
    setIsSearchSubmitted(false);
    setShouldDoHomeAnimation(true);
    setSearchValue('');
    selectFilter('all');
    setLabelValue('');
    setShowLoginModal(false);
    setShowSearchResults(false);
  };

  const onSearchRequest = () => {
    setShowSearchResults(false);
    setShowDropdown(false);
    setShouldDoHomeAnimation(false);
    setIsSearchSubmitted(true);
    if (location.pathname !== '/' && !location.pathname.match('company')) {
      history.push('/');
    }
  };

  const onClose = () => {
    setIsSearchSubmitted(false);
    setShowSearchResults(false);
  };

  const redirectAuthSuccess = () => {
    setShowLoginModal(false);
  };

  const renderModals = () => (
    <Fragment>
      <PBModal
        show={showLoginModal}
        animation={!isMobileDevice}
        onHide={() => {
          setShowLoginModal(false);
          clearAuthMessage();
        }}
        modalStyle={styles.Login}
        showModalHeader={false}
      >
        <Login onSuccess={redirectAuthSuccess} />
      </PBModal>
      <PBModal
        show={showSignUpModal}
        animation={!isMobileDevice}
        onHide={() => {
          setShowSignUpModal(false);
          clearAuthMessage();
        }}
        modalStyle={styles.SignUp}
        showModalHeader={false}
      >
        <Signup
          onSuccess={() => {
            setShowSignUpModal(false);
          }}
        />
      </PBModal>
    </Fragment>
  );

  const clickTitleHandler = (id, series) => {
    setSocialBroadcastModal(socialBroadcastModal, id);
    if (series) {
      getSeriesStories(1, series, account?.account_type, 'Update');
    }
    setShowDropdown(false);
  };

  const handleAccountClick = () => {
    setShowDropdown(false);
    if (account.business && account.account_type === 'Admin') {
      history.push('/settings/company/profile-company');
    } else if (account.business) {
      history.push('/settings/company/user-profile');
    } else {
      history.push('/settings/basic/profile');
    }
  };

  const handleNotificationScroll = (e) => {
    let page = notifPaginationPage;
    const bottom =
      Math.floor(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight ||
      Math.floor(e.target.scrollHeight - e.target.scrollTop) + 1 ===
        e.target.clientHeight;

    if (bottom) {
      setNotifPaginationPage((page += 1));
    }
  };

  const ids = following.map((followingItem) => followingItem.company.id);
  const filteredFollowings = following.filter(
    ({ company }, index) => !ids.includes(company.id, index + 1)
  );

  const handleClickNotification = async (notif) => {
    if (notif?.comment?.parent) {
      await fetchNotificationComments(notif.pea.id, notif.comment.parent);
    } else {
      await fetchNotificationComments(notif.pea.id, notif.comment.id);
    }
    store.dispatch({ type: 'HIDE_EMPTY_COMMENTS_ERR', payload: false });
    store.dispatch({ type: 'RETRIEVE_STORY_SUCCESS', payload: notif.pea });
    setProductionWorkflowModal(productionWorkflowModal, notif.pea.id, true);
    updateNotifications(notif.id, {
      read: true,
    });
    if (notificationsCount > 0) {
      store.dispatch({ type: 'DECREASE_NOTIFICATION_COUNT', payload: 1 });
    }
    setShowNotificationsDropdown(false);
    setShowDropdown(false);
    store.dispatch({ type: 'EMPTY_COMMENTS_FOR_NOTIFICATION' });
  };

  if (isBusinessType) {
    const path = [
      '/profile-company',
      '/members',
      'user-profile',
      '/account-settings',
      '/chrome-extension',
      '/data-privacy',
    ];
    const isIncludes = path.some((url) => location.pathname.includes(url));
    if (isIncludes) {
      isBusinessType = false;
    }
  }

  return (
    <>
      {!isMobileDevice && (
        <Backdrop show={showSearchResults} onClose={() => onClose()} />
      )}
      <nav
        className={cx(
          styles.Header,
          !isHomeVisited ? cx(styles.HomeHeader) : '',
          account && account.id ? styles[type] : '',
          { Dark: showDropdown },
          iOS() ? cx(styles.HeaderIos) : ''
        )}
      >
        {renderModals(type)}
        <div
          className={cx(
            styles.LogoSearchWrapper,
            account && account.business && styles.BusinessLogoWrapper
          )}
          onClick={() => {
            if (showSearchInfo) {
              setShowSearchInfo(false);
            }
          }}
        >
          <Link
            to={account && account.business ? '/broadcasting-pod' : '/'}
            onClick={() => handleLogoClick()}
            className={styles.Logo}
          >
            {account && account.business ? (
              <img
                className={styles.LogoBusiness}
                src={broadcastingPodLogo}
                alt='broadcastingpod'
              />
            ) : showMiniLogo && account && account.id ? (
              <div onClick={clearOpenFrom}>
                <img
                  className={styles.MiniLogo}
                  src={type === 'PeaPage' ? MiniLogo2 : MiniLogo}
                  alt='mini-logo'
                  width='52'
                />
              </div>
            ) : (
              <img
                src={
                  isBusinessLandingPage && !isMobileDevice
                    ? broadcastingPodLogo
                    : LogoGreen
                }
                alt='main-logo'
              />
            )}
          </Link>
          {showSearchBox &&
            !isBusinessType &&
            account &&
            account.account_type === 'Basic' && (
              <div className={styles.SearchBoxWrapper} style={{ zIndex: 2 }}>
                <SearchBox
                  type={type}
                  dark={showDropdown}
                  onSearch={(value) => {
                    setShowSearchInfo(value);
                    setShowSearchResults(true);
                  }}
                  onSearchRequest={onSearchRequest}
                />
                <SearchInfoModal
                  tags={tags}
                  showModal={showSearchResults}
                  searchTerm={searchValue}
                  closeModal={onSearchRequest}
                  channels={channels}
                  series={series}
                  stories={stories}
                  onFollow={({ company }) => followCompany({ company })}
                  onUnfollow={({ followingItem }) =>
                    unfollowCompany({ followingItem })
                  }
                  updateInterest={({ category, tag }) =>
                    updateInterest({ category, tag })
                  }
                  selectedCategories={interest && interest.categories}
                  selectedTags={interest && interest.tags}
                  isAuth={isAuth}
                  account={account}
                  following={following}
                />
                <div
                  className={`d-md-flex align-items-center justify-content-${
                    isWorkDayGuidePage ? 'end' : 'between'
                  } flex-grow-1 ml-2 mr-sm-2 mr-md-4`}
                >
                  {location.pathname === '/' &&
                    !isMobileDevice &&
                    !isTabletDevice && (
                      <PBSelect
                        type='pod'
                        value={sortByValue}
                        onChange={sortBy}
                        options={[
                          { value: 'most_likes', label: 'Most Likes' },
                          { value: 'most_recent', label: 'Fresh Peas!' },
                          { value: 'most_popular', label: 'Most Popular' },
                          { value: 'short_life', label: 'Short Life' },
                        ]}
                        selectStyles={styles.HeaderSort}
                        disbaleTyping={true}
                      />
                    )}
                  {isWorkDayGuidePage ? (
                    <></>
                  ) : (
                    <div className={styles.SeriesHeader}>
                      <CheckboxToggleInput
                        variant={styles.SeriesHeaderCheckbox}
                        label='Series'
                        onChange={toggleSeriesFilter}
                        defaultChecked={isSeriesOnly}
                      />
                    </div>
                  )}
                  <div
                    className={`${styles.HelpButtonWrapper} d-md-flex d-none`}
                  >
                    <Button
                      btnType='Transparent'
                      onClick={() => {
                        setFollowingDropdownVisible(false);
                        setWatchlistDropdownVisible(!watchlistDropdownVisible);
                        setShowDropdown(false);
                      }}
                      onBlur={() => setWatchlistDropdownVisible(false)}
                    >
                      <span
                        className={`${styles.HelpButton} ${styles.HelpButtonGreen}`}
                      >
                        Watchlist
                        <FontAwesomeIcon
                          className={styles.HelpIcon}
                          icon={faChevronDown}
                        />
                      </span>
                      <BasicDropdown
                        isVisible={watchlistDropdownVisible}
                        dropdownStyles={styles.HeaderDropdown}
                        onScroll={(e) => handleNotificationScroll(e)}
                      >
                        <div className={styles.Watchlist}>
                          <span>My Watchlist</span>
                        </div>
                        {watchlists.map((pea) => (
                          <WatchlistItem
                            isDropdown={true}
                            key={pea.id}
                            pea={pea}
                            isWorkdDayGuidePage={isWorkdDayGuidePage}
                          />
                        ))}
                        {notificationLoader &&
                          paginateNotificationPage &&
                          watchlistLoader && (
                            <div className={styles.CenterLoader}>
                              <span
                                className='spinner-border spinner-border-sm'
                                role='status'
                                aria-hidden='true'
                              />
                              <span className='sr-only'>Loading...</span>
                            </div>
                          )}
                      </BasicDropdown>
                    </Button>
                  </div>
                  <div
                    className={`${styles.HelpButtonWrapper} d-md-flex d-none`}
                  >
                    <Button
                      btnType='Transparent'
                      onClick={() => {
                        setFollowingDropdownVisible(!followingDropdownVisible);
                        setWatchlistDropdownVisible(false);
                        setShowDropdown(false);
                      }}
                      onBlur={() => setFollowingDropdownVisible(false)}
                    >
                      <span className={styles.HelpButton}>
                        Following
                        <FontAwesomeIcon
                          className={styles.HelpIcon}
                          icon={faChevronDown}
                        />
                      </span>
                      <BasicDropdown
                        isVisible={followingDropdownVisible}
                        dropdownStyles={styles.followingDropdown}
                      >
                        <div className={styles.Watchlist}>
                          <span>List of Companies</span>
                        </div>
                        {filteredFollowings &&
                          filteredFollowings
                            .filter(
                              (following) => !following.company.deactivated_at
                            )
                            .map((i) => (
                              <FollowingItem
                                key={i.id}
                                company={i.company}
                                setFollowingDropdownVisible={
                                  setFollowingDropdownVisible
                                }
                              />
                            ))}
                      </BasicDropdown>
                    </Button>
                  </div>
                </div>
              </div>
            )}
        </div>
        {!account && !isBusinessLandingPage && !isMobileDevice ? (
          <div
            className={styles.AuthButtons}
            onClick={() => {
              if (showSearchInfo) {
                setShowSearchInfo(false);
              }
            }}
          >
            {!isMobileDevice && (
              <Fragment>
                <Button
                  onClick={() => navigateLogin()}
                  btnType='SignIn'
                  key='signin'
                >
                  Login
                </Button>
                <Button
                  onClick={() => navigateJoin()}
                  btnType='SignUp'
                  key='signup'
                >
                  Join
                </Button>
              </Fragment>
            )}
            <Dropdown
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              items={getDropdownData({ ...functionMap })}
              showComponent={true}
              setShowLoginModal={setShowLoginModal}
            />
          </div>
        ) : (
          <>
            {isBusinessLandingPage && !isAuth && !isMobileDevice ? (
              <div>
                <Button
                  btnType='Blue'
                  buttonStyle={styles.StartButton}
                  onClick={() => navigateLogin()}
                >
                  START
                </Button>
              </div>
            ) : (
              <div
                className={styles.AuthButtons}
                onClick={() => {
                  if (showSearchInfo) {
                    setShowSearchInfo(false);
                  }
                }}
              >
                {isBusinessType && (
                  <div className={styles.HelpWrapper}>
                    <BusinessDropdown
                      type='Header'
                      dropdownStyles={styles.HeaderDropdown}
                    >
                      <span className={styles.HelpButton}>
                        <ScoreIcon className={styles.ScoreIcon} />
                        Scoreboard!
                        <FontAwesomeIcon
                          className={styles.HelpIcon}
                          icon={faChevronDown}
                        />
                      </span>
                    </BusinessDropdown>
                    <BusinessDropdown
                      type='Header'
                      dropdownStyles={styles.HeaderDropdown}
                    >
                      <span className={styles.HelpButton}>
                        <LightningIcon className={styles.LightningIcon} />
                        Power-Up!
                        <FontAwesomeIcon
                          className={styles.HelpIcon}
                          icon={faChevronDown}
                        />
                      </span>
                    </BusinessDropdown>
                    <div className={styles.HelpButtonWrapper}>
                      <Button
                        btnType='Transparent'
                        onClick={() => {
                          setAirDropDropdownVisible(!airDropDropdownVisible);
                          setShowDropdown(false);
                        }}
                        onBlur={() => setAirDropDropdownVisible(false)}
                      >
                        <span className={styles.HelpButton}>
                          Drop List
                          <FontAwesomeIcon
                            className={styles.HelpIcon}
                            icon={faChevronDown}
                          />
                        </span>
                        <BasicDropdown
                          isVisible={airDropDropdownVisible}
                          dropdownStyles={styles.SkyDropDropdown}
                        >
                          <div className={styles.Heroes}>
                            <span>YOUR TOP MICRO-HEROES</span>
                          </div>
                          {airdropStories.map((pea) => (
                            <DropItem
                              clickTitle={() =>
                                clickTitleHandler(pea.pea.id, pea.pea.series)
                              }
                              key={pea.pea.id}
                              pea={pea.pea}
                            />
                          ))}
                        </BasicDropdown>
                      </Button>
                    </div>
                  </div>
                )}
                {!isMobileDevice && (
                  <Fragment>
                    {account &&
                      account.account_type !== 'Basic' &&
                      location.pathname !== '/' &&
                      isBusinessType && (
                        <div className={styles.TeamWorkflowWrapper}>
                          <Button
                            style={{
                              position: 'relative',
                              padding: '0px',
                              margin: '0 10px',
                            }}
                            onClick={() => {
                              setShowNotificationsDropdown(
                                !showNotificationsDropdown
                              );
                              setShowDropdown(false);
                            }}
                            onBlur={() => setShowNotificationsDropdown(false)}
                          >
                            <span className={styles.HelpButton}>
                              Team Workflow
                            </span>
                            <BellIcon
                              className={styles.BellIcon}
                              width='25'
                              height='28'
                            />
                            <Badge
                              badgeStyles={styles.NotifBadge}
                              text={notificationsCount || ''}
                            />
                            <BasicDropdown
                              isVisible={showNotificationsDropdown}
                              dropdownStyles={classNames(
                                styles.NotificationsDropDropdown,
                                accountNotifications.length <= 3 &&
                                  styles.ThreeDropdownHeight,
                                accountNotifications.length >= 4 &&
                                  styles.FourDropdownHeight,
                                accountNotifications.length > 5 &&
                                  styles.NotificationsDropDropdownHeight
                              )}
                              onScroll={(e) => handleNotificationScroll(e)}
                            >
                              <p>
                                <b>NOTIFICATIONS</b>
                              </p>
                              {accountNotifications &&
                              company &&
                              accountNotifications.length > 0 &&
                              accountNotifications.filter(
                                (n) => n.pea.company === company.id
                              ).length > 0 ? (
                                <>
                                  {accountNotifications.map((n, i) => (
                                    <div
                                      className={styles.NotificationItem}
                                      onClick={() =>
                                        handleClickNotification(
                                          accountNotifications[i]
                                        )
                                      }
                                    >
                                      <p
                                        className={
                                          styles.NotificationItem_PeaTitle
                                        }
                                      >
                                        {n.pea.title}
                                      </p>
                                      <p
                                        className={styles.NotificationItem_Type}
                                      >
                                        {n.notif_type}
                                      </p>
                                    </div>
                                  ))}
                                  {notificationLoader &&
                                    notificationsCount > 0 && (
                                      <div className={styles.CenterLoader}>
                                        <span
                                          className='spinner-border spinner-border-sm'
                                          role='status'
                                          aria-hidden='true'
                                        />
                                        <span className='sr-only'>
                                          Loading...
                                        </span>
                                      </div>
                                    )}
                                </>
                              ) : loadNotifications ? (
                                <div className={styles.CenterLoader}>
                                  <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                  />
                                  <span className='sr-only'>Loading...</span>
                                </div>
                              ) : (
                                <p className={styles.NotificationItem_Empty}>
                                  <i>No new notification</i>
                                </p>
                              )}
                            </BasicDropdown>
                          </Button>
                        </div>
                      )}
                    <Button
                      buttonStyle={styles.UserDetails}
                      btnType='Header'
                      onClick={() => {
                        if (account && account.account_type === 'Admin') {
                          history.push('/settings/company/profile-company');
                        } else if (
                          account &&
                          account.account_type === 'Business'
                        ) {
                          history.push('/settings/company/user-profile');
                        } else {
                          history.push('/settings/basic/profile');
                        }
                      }}
                    >
                      {accountImage ? (
                        <div
                          style={{ marginLeft: '10px', marginRight: '1px' }}
                          className={styles.UserIcon}
                        >
                          <img alt='user' src={accountImage} />
                        </div>
                      ) : null}
                      <span
                        className={classNames(
                          styles.UserName,
                          isBusinessType && styles.BusinessUsername,
                          account &&
                            displayName.length > 5 &&
                            displayName.length < 15
                            ? styles.SmallUsername
                            : account && displayName.length >= 15
                            ? styles.SmallestUsername
                            : null
                        )}
                      >
                        {account && displayName}
                      </span>
                    </Button>
                  </Fragment>
                )}
                {isBusinessType && (
                  <Button
                    disabled={!account || (account && account.deactivated_at)}
                    buttonStyle={styles.UploadButton}
                    btnType='Success'
                    onClick={() => setUploadPeaModal(uploadPeaModal)}
                  >
                    Upload
                  </Button>
                )}
                {isBusinessType &&
                  isMobileDevice &&
                  (showDropdown ? (
                    <BroadcastDropdown setShowDropdown={setShowDropdown}>
                      <Button
                        buttonStyle={classNames(
                          styles.AirDropButtonMobile,
                          styles.TeamWorkFlowMobile,
                          'pb-0'
                        )}
                        btnType='Transparent'
                        onClick={() => setShowDropdown(false)}
                      >
                        <span className={styles.HelpButton}>
                          Scoreboard!
                          <ScoreIcon
                            className={`ml-auto mr-0 ${styles.ScoreIcon}`}
                          />
                        </span>
                      </Button>
                      <Accordion
                        title='Power-Up!'
                        setShowDropdown={setShowDropdown}
                      />
                      <Button
                        buttonStyle={styles.AirDropButtonMobile}
                        btnType='Transparent'
                        onClick={() => {
                          setAirDropDropdownVisible(!airDropDropdownVisible);
                        }}
                        onBlur={() => setAirDropDropdownVisible(false)}
                      >
                        <span className={styles.HelpButton}>
                          Drop List
                          <ChevronDownIcon className={styles.HelpIcon} />
                        </span>
                        <Backdrop
                          show={airDropDropdownVisible}
                          onClose={() => setAirDropDropdownVisible(false)}
                        />
                        <BasicDropdown
                          isVisible={airDropDropdownVisible}
                          dropdownStyles={styles.SkyDropDropdown}
                        >
                          <div className={styles.Heroes}>
                            {!isMobileDevice ? (
                              <span>YOUR TOP MICRO-HEROES</span>
                            ) : (
                              <div>
                                <span>
                                  Drop Your 'Top Peas' into Gmails + InMails!
                                </span>
                              </div>
                            )}
                          </div>
                          {airdropStories.map((pea) => (
                            <DropItem
                              key={pea.pea.id}
                              pea={pea.pea}
                              clickTitle={() =>
                                clickTitleHandler(pea.pea.id, pea.pea.series)
                              }
                            />
                          ))}
                        </BasicDropdown>
                      </Button>
                      <Button
                        buttonStyle={classNames(
                          styles.AirDropButtonMobile,
                          styles.TeamWorkFlowMobile
                        )}
                        btnType='Transparent'
                        onClick={() => {
                          setShowNotificationsDropdown(
                            !showNotificationsDropdown
                          );
                        }}
                        onBlur={() => setShowNotificationsDropdown(false)}
                      >
                        <span className={styles.HelpButton}>
                          Team Workflow
                          <BellIcon width='20' height='28' />
                          <Badge
                            badgeStyles={styles.Badge}
                            text={notificationsCount || ''}
                          />
                        </span>
                        <BasicDropdown
                          isVisible={showNotificationsDropdown}
                          dropdownStyles={
                            styles.NotificationsDropDropdownMobile
                          }
                          onScroll={(e) => handleNotificationScroll(e)}
                        >
                          <p>
                            <b>NOTIFICATIONS</b>
                          </p>
                          {accountNotifications &&
                          accountNotifications.length > 0 ? (
                            <>
                              {accountNotifications.map((n, i) => (
                                <div
                                  className={styles.NotificationItem}
                                  onClick={() =>
                                    handleClickNotification(
                                      accountNotifications[i]
                                    )
                                  }
                                >
                                  <p
                                    className={styles.NotificationItem_PeaTitle}
                                  >
                                    {n.pea.title}
                                  </p>
                                  <p className={styles.NotificationItem_Type}>
                                    {n.notif_type}
                                  </p>
                                </div>
                              ))}
                              {notificationLoader && notificationsCount > 0 && (
                                <div className={styles.CenterLoader}>
                                  <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                  />
                                  <span className='sr-only'>Loading...</span>
                                </div>
                              )}
                            </>
                          ) : loadNotifications ? (
                            <div className={styles.CenterLoader}>
                              <span
                                className='spinner-border spinner-border-sm'
                                role='status'
                                aria-hidden='true'
                              />
                              <span className='sr-only'>Loading...</span>
                            </div>
                          ) : (
                            <p className={styles.NotificationItem_Empty}>
                              <i>No new notification</i>
                            </p>
                          )}
                        </BasicDropdown>
                      </Button>
                      <LineBreak />
                      {isAuth && (
                        <>
                          <div
                            className={`${styles.menuItem} ${styles.companyItem}`}
                            onClick={() =>
                              setShowDropdownItems(!showDropdownItems)
                            }
                          >
                            Company
                            <div
                              className={styles.dropdownIconWrapper}
                              onClick={() =>
                                setShowDropdownItems(!showDropdownItems)
                              }
                            >
                              <FontAwesomeIcon
                                className={styles.dropdownIcon}
                                icon={faChevronDown}
                              />
                            </div>
                          </div>
                          {showDropdownItems ? (
                            <div className={styles.DropdownChildren}>
                              <div
                                className={styles.menuItem}
                                onClick={() => {
                                  signOut(history);
                                  setShowLoginModal(false);
                                  setShowDropdown(false);
                                }}
                              >
                                Sign Out
                              </div>
                              <div
                                className={styles.menuItem}
                                onClick={handleAccountClick}
                              >
                                Account
                              </div>
                              <div
                                className={`${styles.menuItem} ${styles.menuItemColorPurple}`}
                                onClick={() => setShowDropdown(false)}
                              >
                                How-to-Heroes!
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                      <div>
                        <div className={styles.SocialMediaIcons}>
                          <Link
                            to={{ pathname: 'https://facebook.com/' }}
                            target='_blank'
                          >
                            <img
                              alt='facebook'
                              src={FacebookIcon}
                              className={styles.MediaIcon}
                            />
                          </Link>
                          <Link
                            to={{ pathname: 'https://twitter.com/' }}
                            target='_blank'
                          >
                            <img
                              alt='twitter'
                              src={TwitterIcon}
                              className={styles.MediaIcon}
                            />
                          </Link>
                          <Link
                            to={{ pathname: 'https://instagram.com/' }}
                            target='_blank'
                          >
                            <img
                              alt='instagram'
                              src={InstagramIcon}
                              className={styles.MediaIcon}
                            />
                          </Link>
                          <Link
                            to={{ pathname: 'https://spotify.com/' }}
                            target='_blank'
                          >
                            <img
                              alt='spotify'
                              src={SpotifyIcon}
                              className={styles.MediaIcon}
                            />
                          </Link>
                        </div>
                      </div>
                      <Button
                        buttonStyle={styles.UploadButtonMobile}
                        btnType='Success'
                        onClick={() => {
                          setUploadPeaModal(uploadPeaModal);
                          setShowDropdown(false);
                        }}
                        disabled={
                          !account || (account && account.deactivated_at)
                        }
                      >
                        Upload
                      </Button>
                    </BroadcastDropdown>
                  ) : (
                    <BurgerIcon
                      className={styles.HamburgerIcon}
                      onClick={() => setShowDropdown(true)}
                    />
                  ))}
                <Dropdown
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                  items={getDropdownData({ ...functionMap })}
                  showComponent={
                    (!isMobileDevice && isBusinessType) || !isBusinessType
                  }
                  setShowLoginModal={setShowLoginModal}
                />
              </div>
            )}
          </>
        )}
      </nav>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuthenticated,
  searchValue: state.searchBox.searchValue,
  sortByValue: state.searchBox.sortByValue,
  series: state.searchBox.filterSeries,
  stories: state.stories.homeStories,
  broadcastingStories: state.stories.broadcastingStories,
  showLoginModal: state.loginSignupModals.showLoginModal,
  showSignUpModal: state.loginSignupModals.showSignUpModal,
  isHomeVisited: state.searchBox.isHomeVisited,
  account: state.auth.account,
  channels: state.interests.channels,
  interest: state.auth.interest,
  following: state.auth.following,
  company: state.auth.company,
  socialBroadcastModal: state.modal.socialBroadcastModal,
  uploadPeaModal: state.modal.uploadPeaModal,
  isSeriesOnly: state.searchBox.isSeriesOnly,
  notifications: state.stories.notifications,
  watchlists: state.stories.watchlists,
  productionWorkflowModal: state.modal.productionWorkflowModal,
  activeSeries: state.stories.series,
  seriesPeas: state.stories.seriesStories,
  isSearchSubmitted: state.searchBox.isSearchSubmitted,
  broadcastingPage: state.stories.broadcastingPage,
  airdropStories: state.stories.airdropStories,
  accountNotifications: state.notifications.accountNotifications,
  notificationsCount: state.notifications.notificationsCount,
  showNotificationsDropdown: state.notifications.showNotificationsDropdown,
  tags: state.searchBox.tags,
  notificationLoader: state.notifications.notificationLoader,
  paginateNotificationPage: state.notifications.paginateNotificationPage,
  loadNotifications: state.notifications.loadNotifications,
  paginateUrl: state.notifications.paginateUrl,
});

const mapDispatchToProps = (dispatch) => ({
  clearAuthMessage: () => dispatch(actions.clearAuthMessage()),
  signOut: (history) => dispatch(actions.signOut(history)),
  sortBy: (option) => dispatch(actions.sortBy(option)),
  selectFilter: (id) => dispatch(actions.selectFilter(id)),
  setLabelValue: (data) => dispatch(actions.setLabelValue(data)),
  setSearchValue: (data) => dispatch(actions.setSearchValue(data)),
  setIsSearchSubmitted: (value) =>
    dispatch(actions.setIsSearchSubmitted(value)),
  setShouldDoHomeAnimation: (value) =>
    dispatch(actions.setShouldDoHomeAnimation(value)),
  setShowSignUpModal: (id) => dispatch(actions.setShowSignUpModal(id)),
  setShowLoginModal: (id) => dispatch(actions.setShowLoginModal(id)),
  getFilterSeries: () => dispatch(actions.getFilterSeries()),
  getChannels: () => dispatch(actions.getChannels()),
  followCompany: ({ company }) => dispatch(actions.followCompany({ company })),
  unfollowCompany: ({ followingItem }) =>
    dispatch(actions.unfollowCompany({ followingItem })),
  getAccountInterest: ({ interestId }) =>
    dispatch(actions.getAccountInterest({ interestId })),
  getFollowItems: () => dispatch(actions.getFollowItems()),
  getAccountFollowing: ({ accountId }) =>
    dispatch(actions.getAccountFollowing({ accountId })),
  updateInterest: ({ category, tag }) =>
    dispatch(actions.updateInterest({ category, tag })),
  setSocialBroadcastModal: (isVisible, id) =>
    dispatch(actions.setSocialBroadcastModal(isVisible, id)),
  setUploadPeaModal: (isVisible) =>
    dispatch(actions.setUploadPeaModal(isVisible)),
  toggleSeriesFilter: () => dispatch(actions.toggleSeriesFilter()),
  watchlistPea: ({ pea, watch }) =>
    dispatch(actions.watchlistPea({ pea, watch })),
  setProductionWorkflowModal: (b, p, c) =>
    dispatch(setProductionWorkflowModal(b, p, c)),
  getSeriesStories: (page, series, account_type, calledAfter) =>
    dispatch(actions.getSeriesStories(page, series, account_type, calledAfter)),
  getWatchListStories: (a, b, c) =>
    dispatch(actions.getWatchListStories(a, b, c)),
  getNotifications: (a, b, c, d) =>
    dispatch(actions.getNotifications(a, b, c, d)),
  getNotificationsCount: (a, b, c) =>
    dispatch(actions.getNotificationsCount(a, b, c)),
  updateNotifications: (i, d) => dispatch(actions.updateNotifications(i, d)),
  getAccounts: (company) => dispatch(actions.getAccounts(company)),
  setShowNotificationsDropdown: (state) =>
    dispatch(actions.setShowNotificationsDropdown(state)),
  getAirDropStories: () => dispatch(actions.getAirDropStories()),
  clearOpenFrom: () => dispatch(actions.clearOpenFrom()),
  getTags: () => dispatch(actions.getTags()),
  fetchNotificationComments: (peaId, commentId) =>
    dispatch(actions.fetchNotificationComments(peaId, commentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
