import React from "react"
import { toast } from "react-toastify"
import sproutApi from "../../api/sproutApi"
import axios from "../../utils/axiosConfig"
import { tokenConfig } from "./authActions"
import { getTags } from "./searchBoxActions"
import {
  SET_IS_FAVORITE,
  GET_COMMENTS_START,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAIL,
  ADD_COMMENT,
  GET_STORIES_START,
  GET_STORIES_SUCCESS,
  GET_STORIES_FAIL,
  GET_WATCH_LIST_STORIES_START,
  GET_WATCH_LIST_STORIES_SUCCESS,
  GET_WATCH_LIST_STORIES_FAIL,
  RETRIEVE_STORY_START,
  RETRIEVE_STORY_SUCCESS,
  RETRIEVE_STORY_FAIL,
  GET_REPLIES_SUCCESS,
  ADD_REPLY,
  RETRIEVE_STORY_STATISTICS_START,
  RETRIEVE_STORY_STATISTICS_SUCCESS,
  RETRIEVE_STORY_STATISTICS_FAIL,
  CLEAR_STORY,
  REMOVE_COMMENT_ASSIGNMENT,
  ADD_PEA_START,
  ADD_PEA_SUCCESS,
  ADD_PEA_FAIL,
  UPDATE_PEA_START,
  UPDATE_PEA_SUCCESS,
  UPDATE_PEA_FAIL,
  ADD_EMBED_START,
  ADD_EMBED_FAIL,
  ADD_EMBED_SUCCESS,
  UPDATE_EMBED_START,
  UPDATE_EMBED_SUCCESS,
  UPDATE_EMBED_FAIL,
  SET_STORY_FILTERS,
  CLEAR_STORY_FILTERS,
  GET_SERIES_STORIES_START,
  GET_SERIES_STORIES_SUCCESS,
  GET_SERIES_STORIES_FAIL,
  SORT_STORIES,
  SET_TOP_STORY,
  COMPANY_FEATURED_VIDEO,
  SORT_SERIES_STORIES,
  DELETE_PEA_START,
  DELETE_PEA_SUCCESS,
  DELETE_PEA_FAIL,
  UPLOAD_SPROUT_VIDEO_FAIL,
  UPLOAD_SPROUT_VIDEO_START,
  UPLOAD_SPROUT_VIDEO_SUCCESS,
  RETRIEVE_SERIES,
  LIKE_PEA,
  WATCHLIST_PEA,
  RETRIEVE_COMPANY_SUCCESS,
  GET_COMPANY_STORIES_SUCCESS,
  ADD_PEA_LABELS_START,
  ADD_PEA_LABELS_SUCCESS,
  ADD_PEA_LABELS_FAIL,
  UPLOAD_SPROUT_VIDEO_POSTER,
  UPDATE_COMMENT_START,
  UPDATE_COMMENT_FAIL,
  UPDATE_COMMENT_SUCCESS,
  DELETE_COMMENT_START,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  INCREMENT_NOTIFICATION,
  CLEAR_NOTIFICATION,
  GET_WORKDAYGUIDE_STORIES_START,
  GET_WORKDAYGUIDE_STORIES_SUCCESS,
  GET_WORKDAYGUIDE_STORIES_FAIL,
  FILTER_WORKDAYGUIDE_STORIES_START,
  FILTER_WORKDAYGUIDE_STORIES_END,
  FILTER_WORKDAYGUIDE_STORIES_SUCCESS,
  FILTER_WORKDAYGUIDE_STORIES_FAIL,
  WORKDAY_GUIDE_FILTER,
  TOGGLE_PEA_DROPDOWN,
  SET_LABEL_VALUE,
  RETRIEVE_PEA_SUCCESS,
  WORKDAY_GUIDE_SORT_BY_DROPDOWN,
  WORKDAY_GUIDE_DESIRED_EFFECT_DROPDOWN,
  CLEAR_COMPANY_STORIES,
  CLEAR_PEA_VIDEO_STORIES,
  PAGINATE_COMMENTS_SUCCESS,
  PAGINATE_COMMENTS_URL,
  PAGINATE_PREVIOUS_COMMENTS_URL,
  PAGINATE_PREVIOUS_COMMENTS_SUCCESS,
  PAGINATE_REPLIES_SUCCESS,
  PAGINATE_REPLIES_URL,
  CLEAR_COMMENTS,
  CLEAR_COMPANY,
  TRIGGERED_NOTIF_COMMENT_ID,
  TRIGGERED_NOTIF_PAGE_NUMBER,
  USER_INTEREST_STORIES_START,
  USER_INTEREST_STORIES_FAIL,
  STOP_GET_STORIES,
  RETRIEVE_UPLOAD_TOKEN_START,
  GET_NOTIFICATION_LOADER_START,
  GET_NOTIFICATION_LOADER_FAIL,
  FAIL_PAGINATE_NOTIFICATION,
  SUCCESS_PAGINATE_NOTIFICATION,
  FILTERED_BROADCASTING_PEAS,
  TOTAL_COMPANY_PEAS_REMOVE,
  TOTAL_COMPANY_PEAS_ADD,
  COMPANY_FEATURED_VIDEO_START,
  COMPANY_FEATURED_VIDEO_FAIL,
} from "../types"

export const setLabelValue = (value) => (dispatch) => {
  dispatch({
    type: SET_LABEL_VALUE,
    payload: value,
  })
}

export const togglePeaDropdown = (event, id) => (dispatch) => {
  const payload =
    event && event.target && event.target.id === "openPeaDropdown" && id
      ? id
      : ""

  dispatch({
    type: TOGGLE_PEA_DROPDOWN,
    payload: payload,
  })
}

export const setIsFavorite = (id) => (dispatch) => {
  dispatch({
    type: SET_IS_FAVORITE,
    id: id,
  })
}
export const getStories =
  (
    page = 1,
    company = null,
    callFrom,
    account_type,
    isSeriesOnly,
    seriesOnlyPage,
    shouldReload = false
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: GET_STORIES_START })

      let hasMore = false
      let url = `/peas/pea/?page=${page}&page_size=10`

      const businessAccount = getState().auth.business
      const account = getState().auth.account
      const isAuthenticated = getState().auth.isAuthenticated

      if (company) {
        if (
          (account_type === "Admin" || account_type === "Business") && businessAccount &&
          businessAccount.company === company
        ) {
          if (account_type === "Admin") {
            url = `${url}&company_pod=${company}&account_type=admin`
          } else if (account_type === "Business") {
            url = `${url}&company_pod=${company}&account_type=business&account=${account.id}`
          }
        } else {
          url = `${url}&company=${company}`
        }
      }

      if (isSeriesOnly) {
        url = `/peas/pea/?page_size=10&page=${seriesOnlyPage}&series=True`
      }

      if (
        isAuthenticated &&
        (account_type === "Admin" || account_type === "Business")
      ) {
        await axios
          .get(url, tokenConfig(getState))
          .then((res) => {
            if (res.data.next) {
              hasMore = true
            }
            dispatch({
              type: GET_STORIES_SUCCESS,
              payload: res.data.results,
              callFrom,
              hasMore,
              isSeriesOnly,
              shouldReload,
              currentPage: page,
            })
          })
          .catch(() => dispatch({ type: GET_STORIES_FAIL }))
      } else {
        await axios
          .get(url)
          .then((res) => {
            if (res.data.next) {
              hasMore = true
            }
            dispatch({
              type: GET_STORIES_SUCCESS,
              payload: res.data.results,
              hasMore,
              callFrom,
              isSeriesOnly,
            })
          })
          .catch(() => dispatch({ type: GET_STORIES_FAIL }))
      }
    }

export const getUserInterestSortedStories =
  (user_interest_id, filterValue, page) => async (dispatch, getState) => {
    dispatch({ type: GET_STORIES_START })
    dispatch({ type: USER_INTEREST_STORIES_START })

    let url = ""
    if (user_interest_id) {
      url = `/peas/user_interest_sorted_peas/?page=${page}&page_size=10&user_interest_id=${user_interest_id}`
    } else if (filterValue) {
      url = `/peas/user_interest_sorted_peas/?page=${page}&page_size=10&filterValue=${encodeURIComponent(
        filterValue
      )}`
    }

    let hasMore = false
    await axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        if (!res.data) {
          dispatch({ type: STOP_GET_STORIES })
        } else {
          if (res.data.next) {
            hasMore = true
          }
          if (res?.data?.detail === "Invalid page.") {
            dispatch({ type: USER_INTEREST_STORIES_FAIL })
          }
          dispatch({
            type: GET_STORIES_SUCCESS,
            payload: res.data.results,
            callFrom: "userInterestHome",
            hasMore,
          })
        }
      })
      .catch(() => {
        dispatch({ type: USER_INTEREST_STORIES_FAIL })
      })
  }

export const getAirDropStories = () => async (dispatch, getState) => {
  const url = "/peas/droplist"

  await axios
    .get(url, tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: GET_STORIES_SUCCESS,
        payload: res.data.data,
        callFrom: "airdrop",
      })
    )
    .catch((err) => console.log(err))
}

export const getWatchListStories =
  (page = 1, setWatchlistLoader, paginateUrl) =>
    async (dispatch, getState) => {
      dispatch({ type: GET_WATCH_LIST_STORIES_START })
      dispatch({ type: GET_NOTIFICATION_LOADER_START })

      let url = `/peas/pea/get-watchlist/?page=${page}&page_size=10&show_all_episode=True`

      if (paginateUrl) {
        url = paginateUrl
      }
      await axios
        .get(url, tokenConfig(getState))
        .then((res) => {
          if (res.data) {
            dispatch({
              type: GET_WATCH_LIST_STORIES_SUCCESS,
              payload: res.data.results,
            })
            if (setWatchlistLoader) {
              setWatchlistLoader(false)
            }

            if (res.data.next) {
              dispatch({
                type: SUCCESS_PAGINATE_NOTIFICATION,
                payload: res.data.next
              })
            }
            if (!res.data.next) {
              dispatch({
                type: FAIL_PAGINATE_NOTIFICATION,
              })
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: GET_WATCH_LIST_STORIES_FAIL,
          })
          dispatch({
            type: GET_NOTIFICATION_LOADER_FAIL,
          })
          console.log("Error at Get Watchlist: ", err)
        })
    }

export const getSeriesStories =
  (page = 1, series, account_type = null, calledAfter = "") =>
    async (dispatch) => {
      dispatch({ type: GET_SERIES_STORIES_START })
      let hasMore = false
      const { id } = series

      let url = `/peas/pea/?page=${page}&series=${id}&show_all_episode=${true}`

      if (account_type == "Admin") {
        url += "&account_type=admin"
      }
      else if (account_type == "Business") {
        url += "&account_type=business"
      }

      await axios
        .get(url)
        .then((res) => {
          if (res.data.next) {
            hasMore = true
          }
          dispatch({
            type: GET_SERIES_STORIES_SUCCESS,
            payload: res.data.results,
            hasMore: hasMore,
            series: series,
            calledAfter,
            page: page,
          })
        })
        .catch((err) => {
          dispatch({ type: GET_SERIES_STORIES_FAIL })
          console.log(err)
        })
    }

export const getCompanyStories =
  ({ company }) =>
    async (dispatch) => {
      await axios
        .get(`/peas/pea/?company=${company}&page_size=3`)
        .then((res) => {
          dispatch({
            type: GET_COMPANY_STORIES_SUCCESS,
            payload: res.data.results,
          })
        })
        .catch((err) => console.log(err))
    }

export const retrieveStory = (id, company) => async (dispatch) => {
  dispatch({ type: RETRIEVE_STORY_START })

  await axios
    .get(`/peas/pea/${id}${company ? `?company=${company.id}` : ""}`)
    .then((res) => {
      dispatch({ type: RETRIEVE_STORY_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      dispatch({ type: RETRIEVE_STORY_FAIL })
      console.log(err)
      let msg = "Something went wrong."
      if (err.response && err.response.data) {
        if (
          typeof err.response.data === "object" &&
          Object.keys(err.response.data).length > 0
        ) {
          let e = []
          for (let k in err.response.data) {
            e.push(
              `${err.response.data[k]}`.charAt(0).toUpperCase() +
              `${err.response.data[k]}`.slice(1)
            )
          }
          msg = e.join(" | ")
        } else if (typeof err.response.data === "string") {
          msg = err.response.data
        }
      }
      if (company) {
        toast.error(msg)
      }
    })
}

export const retrieveStoryStatistics = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_STORY_STATISTICS_START })

  await axios
    .get(`/peas/pea/statistics/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_STORY_STATISTICS_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      dispatch({ type: RETRIEVE_STORY_STATISTICS_FAIL })
      console.log(err)
    })
}

export const addEmbed = (peaId, type, link) => async (dispatch, getState) => {
  dispatch({ type: ADD_EMBED_START })

  const body = JSON.stringify({
    pea: peaId,
    type: type === "StackShare" ? "Stake Share" : type,
    link,
  })

  await axios
    .post("/peas/embed/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_EMBED_SUCCESS,
        payload: { embed: res.data, id: peaId },
      })
      toast.success("Form embed added successfully")
    })
    .catch(() => {
      dispatch({ type: ADD_EMBED_FAIL })
      toast.error("Something went wrong")
    })
}

export const updateEmbed =
  (id, type, link, peaId) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_EMBED_START })

    const body = JSON.stringify({
      type: type === "StackShare" ? "Stake Share" : type,
      link,
    })

    await axios
      .patch(`/peas/embed/${id}/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_EMBED_SUCCESS,
          payload: { embed: res.data, id: peaId },
        })
        toast.success("Form embed updated successfully")
      })
      .catch(() => {
        dispatch({ type: UPDATE_EMBED_FAIL })
        toast.error("Something went wrong")
      })
  }

export const getComments =
  (pea, isWorkFlow = false, parent = null, callFrom, pageNumber) =>
    async (dispatch) => {
      dispatch({ type: GET_COMMENTS_START })

      let url = `peas/comment?pea=${pea}&page=${pageNumber ? pageNumber : 1}`
      let isFinished = false

      if (isWorkFlow) {
        url = `${url}&workflow=True`
      }

      if (parent) {
        url = `${url}&parent=${parent}`
      }

      await axios
        .get(url)
        .then((res) => {
          if (parent) {
            dispatch({ type: GET_REPLIES_SUCCESS, payload: res.data.results })
            if (res.data?.next) {
              dispatch({
                type: PAGINATE_REPLIES_URL,
                payload: res.data.next,
              })
            }
            isFinished = true
          } else {
            dispatch({ type: GET_COMMENTS_SUCCESS, payload: res.data.results })
            dispatch({
              type: PAGINATE_COMMENTS_URL,
              payload: res.data.next,
              callFrom,
            })
            if (res.data.previous) {
              dispatch({
                type: PAGINATE_PREVIOUS_COMMENTS_URL,
                payload: res.data.previous,
              })
            }
            isFinished = true
          }
        })
        .catch(() => {
          dispatch({ type: GET_COMMENTS_FAIL })
          isFinished = true
        })

      return isFinished
    }

export const paginateComments = (url, callFrom) => async (dispatch) => {
  dispatch({ type: GET_COMMENTS_START })

  await axios
    .get(url)
    .then((res) => {
      dispatch({
        type: PAGINATE_COMMENTS_SUCCESS,
        payload: res.data.results,
        callFrom,
      })
      dispatch({
        type: PAGINATE_COMMENTS_URL,
        payload: res.data.next,
        callFrom,
      })
    })
    .catch(() => {
      dispatch({ type: GET_COMMENTS_FAIL })
    })
}

export const paginatePreviousComments =
  (url, setPreviousCommentsUrl, setLoader) => async (dispatch) => {
    setLoader(true)
    await axios
      .get(url)
      .then((res) => {
        dispatch({
          type: PAGINATE_PREVIOUS_COMMENTS_SUCCESS,
          payload: res.data.results,
        })
        if (res.data.previous) {
          setPreviousCommentsUrl(res.data.previous)
          dispatch({
            type: PAGINATE_PREVIOUS_COMMENTS_URL,
            payload: res.data.previous,
          })
          setLoader(false)
        } else {
          setLoader(false)
          setPreviousCommentsUrl(null)
        }
      })
      .catch(() => {
        dispatch({ type: GET_COMMENTS_FAIL })
        setLoader(false)
      })
  }

export const fetchNotificationComments =
  (peaId, commentId) => async (dispatch, getState) => {
    const body = JSON.stringify({
      pea: peaId,
      comment: commentId,
    })
    let pageNumber = 0
    await axios
      .post(`/peas/comment/notifications/`, body, tokenConfig(getState))
      .then((res) => {
        if (res.data) {
          dispatch({ type: TRIGGERED_NOTIF_COMMENT_ID, payload: commentId })
          dispatch({
            type: TRIGGERED_NOTIF_PAGE_NUMBER,
            payload: res.data.page,
          })
          pageNumber = res.data.page
        }
      })
      .catch((err) => {
        console.log("Err Fetch Notification Comments: ", err)
      })

    return pageNumber
  }

export const paginateReplies = (url, setHide) => async (dispatch) => {
  await axios
    .get(url)
    .then((res) => {
      dispatch({
        type: PAGINATE_REPLIES_SUCCESS,
        payload: res.data.results,
      })
      if (res.data?.next) {
        setHide(res.data?.next)
        dispatch({
          type: PAGINATE_REPLIES_URL,
          payload: res.data.next,
        })
      } else {
        setHide(null)
      }
    })
    .catch(() => {
      dispatch({ type: GET_COMMENTS_FAIL })
    })
}

export const addComment =
  (
    pea,
    comment,
    isAssigned = false,
    isWorkflow = false,
    assignedTo = null,
    parent = null,
    wsClient = null,
    company,
    mentions
  ) =>
    async (dispatch, getState) => {
      let finalComment = comment

      if (isWorkflow) {
        finalComment = `<article class='Comment'>${comment}</article>`
      }

      const body = JSON.stringify({
        pea: pea,
        comment: finalComment,
        is_assigned: isAssigned,
        is_workflow: isWorkflow,
        assigned_to: isAssigned && assignedTo ? assignedTo : null,
        parent: parent && parent,
        mentions: mentions,
      })

      await axios
        .post(`/peas/comment/`, body, tokenConfig(getState))
        .then((res) => {
          if (parent) {
            dispatch({ type: ADD_REPLY, payload: res.data })
          } else {
            dispatch({ type: ADD_COMMENT, payload: res.data })
          }
          if (wsClient) {
            wsClient.send(
              JSON.stringify({
                event: "comment",
                group: "broadcasting_pod",
                data: {
                  pea: pea,
                  company: company,
                },
              })
            )
          }
        })
        .catch((err) => {
          console.log(err)
          let msg = "Something went wrong."
          if (err.response && err.response.data) {
            if (
              typeof err.response.data === "object" &&
              Object.keys(err.response.data).length > 0
            ) {
              let e = []
              for (let k in err.response.data) {
                e.push(
                  `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
                )
              }
              msg = e.join(" | ")
              toast.error(msg)
            } else if (typeof err.response.data === "string") {
              msg = err.response.data
              toast.error(msg)
            }
          }
        })
    }

export const removeCommentAssignment =
  (comment) => async (dispatch, getState) => {
    const body = JSON.stringify({ comment })

    await axios
      .patch(
        `/peas/comment/remove-comment-assignment/${comment}/`,
        body,
        tokenConfig(getState)
      )
      .then((res) =>
        dispatch({ type: REMOVE_COMMENT_ASSIGNMENT, payload: res.data })
      )
      .catch(() => toast.error("Something went wrong"))
  }

export const updateComment =
  ({
    commentId,
    comment,
    isAssigned,
    isWorkflow,
    assignedTo = null,
    parent = null,
    isApproved,
    peaId,
    isEdited = false,
  }) =>
    async (dispatch, getState) => {
      dispatch({ type: UPDATE_COMMENT_START })

      let url = `/peas/comment/${commentId}/?pea=${peaId}`
      let finalComment = comment

      if (isWorkflow) {
        if (!isApproved) {
          finalComment = `<article class='Comment'>${comment}</article>`
        }
        url = `${url}&workflow=True`
      }

      if (parent) {
        url = `${url}&parent=${parent}`
      }

      const body = JSON.stringify({
        comment: finalComment,
        is_assigned: isAssigned,
        is_workflow: isWorkflow,
        assigned_to: isAssigned && assignedTo ? assignedTo : null,
        parent: parent && parent,
        is_approved: isApproved,
        is_edited: isEdited,
      })

      await axios
        .patch(url, body, tokenConfig(getState))
        .then((res) =>
          dispatch({ type: UPDATE_COMMENT_SUCCESS, payload: res.data })
        )
        .catch(() => dispatch({ type: UPDATE_COMMENT_FAIL }))
    }

export const deleteComment =
  ({ commentId, isWorkflow, peaId = null }) =>
    async (dispatch, getState) => {
      dispatch({ type: DELETE_COMMENT_START })

      let url = `/peas/comment/${commentId}/?pea=${peaId}`

      if (isWorkflow) {
        url = `${url}&workflow=True`
      }

      await axios
        .delete(url, tokenConfig(getState))
        .then(() =>
          dispatch({ type: DELETE_COMMENT_SUCCESS, payload: commentId })
        )
        .catch(() => dispatch({ type: DELETE_COMMENT_FAIL }))
    }

export const clearCompanyStories = () => (dispatch) => {
  dispatch({ type: CLEAR_COMPANY_STORIES })
}

export const clearStory = () => (dispatch) => {
  dispatch({ type: CLEAR_STORY })
}

export const clearComments = () => (dispatch) => {
  dispatch({ type: CLEAR_COMMENTS })
}

export const retrieveUploadToken = () => async (dispatch) => {
  dispatch({ type: RETRIEVE_UPLOAD_TOKEN_START })
  let token = ""

  await axios
    .get("/peas/retrieve-upload-token/")
    .then((res) => {
      token = res.data.token
    })
    .catch((err) => {
      console.log(err)
    })

  return token
}

export const uploadSproutVideo =
  ({ title, description, file, token, tags }) =>
    async (dispatch) => {
      dispatch({ type: UPLOAD_SPROUT_VIDEO_START })
      let sproutVideo = null

      let formData = new FormData()
      formData.set("title", title)
      formData.set("description", description)
      formData.set("source_video", file)
      formData.set("download_sd", true)
      formData.set("download_hd", true)
      formData.set("download_source", true)
      formData.set("token", token)
      formData.set("allowed_domains", [window.location.hostname])
      formData.set("tag_names", tags)

      const config = {
        "content-type": "multipart/form-data",
      }

      await sproutApi
        .post("/videos", formData, config)
        .then((res) => {
          dispatch({ type: UPLOAD_SPROUT_VIDEO_SUCCESS })
          sproutVideo = res.data
        })
        .catch((err) => {
          dispatch({ type: UPLOAD_SPROUT_VIDEO_FAIL })
        })

      return sproutVideo
    }

export const uploadSproutVideoPoster =
  ({ videoId, image }) =>
    async (dispatch) => {
      let isSuccess = false
      let formData = new FormData()
      formData.append("video_id", videoId)
      formData.append("video_poster", image, `poster-${videoId}.jpg`)

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }

      await axios
        .post("/peas/upload-video-poster/", formData, config)
        .then((res) => {
          dispatch({ type: UPLOAD_SPROUT_VIDEO_POSTER })
        })
        .catch((err) => {
          console.log(err)
        })

      return isSuccess
    }

export const postPea =
  ({
    referenceId,
    title,
    description,
    image,
    imageCompressed,
    format,
    date,
    dateSet,
    iframe,
    url,
    series,
    tags,
    isFirstEpisode,
    labels,
    broadcastDate,
    unbroadcastDate,
    saveToPod,
    company,
    accessControls,
    short_life_hrs,
    wsClient,
  }) =>
    async (dispatch, getState) => {
      let swoosh = new Audio(process.env.REACT_APP_SWOOSH_MP3_URL)
      dispatch({ type: ADD_PEA_START })
      const account = getState().auth.account
      const opened_series = getState()?.stories?.series
      const series_stories = getState()?.stories?.seriesStories

      let pea = null

      let formData = new FormData()
      if (referenceId) {
        formData.set("reference_id", referenceId)
      }

      if (title) {
        formData.set("title", title)
      }

      if (url) {
        formData.set("url", url)
      }

      if (iframe) {
        formData.set("iframe", iframe)
      }

      if (format) {
        formData.set("format", format)
      }

      if (company) {
        formData.set("company", company)
      }

      if (dateSet && date === "later") {
        formData.set("date", dateSet)
      } else {
        if (date) {
          formData.set("date", date)
        }
      }

      if (description) {
        formData.set("description", description)
      }

      if (image) {
        formData.set("image", image)
      }

      if (series && series.value) {
        formData.set("series_data", series.value)
      }

      if (imageCompressed) {
        formData.append(
          "image_compressed",
          imageCompressed,
          `${image.name}_compressed.jpeg`
        )
      }

      if (labels.length) {
        labels.forEach((label) => {
          if (label && label.type === "Workday Feed") {
            formData.append("labels", `${label.value}-Workday Feed`)
          } else if (label && label.type === "TV Channels") {
            formData.append("labels", `${label.value}-TV Channels`)
          }
        })
      } else {
        formData.append("labels", [])
      }

      if (tags && tags.length) {
        tags.forEach((tag) => {
          if (tag && tag.value) {
            formData.append("tags", tag.value)
          }
        })
      } else {
        formData.append("tags", [])
      }

      if (broadcastDate) {
        formData.set("broadcast_date", broadcastDate)
      }

      if (unbroadcastDate) {
        formData.set("unbroadcast_date", unbroadcastDate)
      }

      if (accessControls && accessControls.length) {
        accessControls.forEach((accessControls) => {
          const acctSplt = `${accessControls.value}`.split("--")
          formData.append("access_controls", acctSplt[0])
        })
      }

      formData.set("save_to_pod", saveToPod)
      formData.set("is_first_episode", isFirstEpisode ?? false)
      formData.set("created_by", [parseInt(account.id)])
      if (short_life_hrs) {
        formData.set("short_life_hrs", short_life_hrs)
      }
      await axios
        .post("/peas/pea/", formData, tokenConfig(getState))
        .then(async (res) => {
          dispatch({ type: ADD_PEA_SUCCESS, payload: res.data })
          pea = res.data
          dispatch(getTags())
          swoosh.play()
          toast.success(
            <>
              Your Pea just Launched! 🚀 <br />
              Equip + track from "Pea Armoury" / Pea Menu
            </>
          )

          if (wsClient) {
            wsClient.send(
              JSON.stringify({
                event: "comment",
                group: "broadcasting_pod",
                data: {
                  pea: res.data.id,
                  company: res.data.company,
                },
              })
            )
          }

          if (
            (series_stories &&
              series_stories.filter((s) => s.id === res.data.id).length > 0) ||
            (res.data.series &&
              opened_series &&
              res.data.series.id === opened_series.id)
          ) {
            dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
          }

          if (wsClient) {
            wsClient.send(
              JSON.stringify({
                event: "comment",
                group: "broadcasting_pod",
                data: {
                  pea: res.data.id,
                  company: res.data.company,
                },
              })
            )
          }

          if (broadcastDate < new Date().toISOString()) {
            dispatch({ type: TOTAL_COMPANY_PEAS_ADD })
          }
          if (!saveToPod && !broadcastDate) {
            dispatch({ type: TOTAL_COMPANY_PEAS_ADD })
          }
        })
        .catch((err) => {
          dispatch({ type: ADD_PEA_FAIL })
          console.log(err)
          let msg = "Something went wrong."
          if (err.response && err.response.data) {
            if (
              typeof err.response.data === "object" &&
              Object.keys(err.response.data).length > 0
            ) {
              let e = []
              for (let k in err.response.data) {
                e.push(
                  `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
                )
              }
              msg = e.join(" | ")
            } else if (typeof err.response.data === "string") {
              msg = err.response.data
            }
          }
          toast.error(msg)
        })
      return pea
    }

export const updatePea =
  ({
    id = null,
    labels,
    image = null,
    imageCompressed = null,
    referenceId = null,
    iframe = null,
    url = null,
    applyLabels,
    shares,
    title,
    description,
    series,
    isFirstEpisode,
    tags,
    accessControls,
    categories,
    format = null,
    date = null,
    dateSet,
    broadcastDate,
    unbroadcastDate,
    saveToPod,
    short_life_hrs,
    company,
    is_segment_modal = false,
  }) =>
    async (dispatch, getState) => {
      dispatch({ type: UPDATE_PEA_START })
      const formData = new FormData()
      const opened_series = getState()?.stories?.series
      const current_story = getState()?.stories?.story
      const broadcast_counter = getState()?.auth.storiesBroadcastCounter

      const broadcasting_stories = getState()?.stories?.broadcastingStories
      let isSeriesOnly = getState()?.searchBox?.isSeriesOnly
      let seriesOnlyPage = getState()?.stories.seriesOnlyPage

      let pea = null

      if (referenceId) {
        formData.set("reference_id", referenceId)
      }

      if (title) {
        formData.set("title", title)
      }

      if (is_segment_modal) {
        formData.set("is_segment_modal", is_segment_modal)
      }

      if (url) {
        formData.set("url", url)
      }

      if (iframe) {
        formData.set("iframe", iframe)
      }

      if (format) {
        formData.set("format", format)
      }

      if (company) {
        formData.set("company", company)
      }

      if (dateSet && date === "later") {
        formData.set("date", dateSet)
      } else {
        if (date) {
          formData.set("date", date)
        }
      }

      if (series && series.value) {
        formData.set("series_data", series.value)
      } else if (series && series.name) {
        formData.set("series_data", series.name)
      } else {
        formData.set("series_data", "")
      }

      if (broadcastDate) {
        formData.set("broadcast_date", broadcastDate)
      }

      if (unbroadcastDate) {
        formData.set("unbroadcast_date", unbroadcastDate)
      }

      if (saveToPod) {
        formData.set("save_to_pod", saveToPod)
      }
      if (short_life_hrs) {
        formData.set("short_life_hrs", short_life_hrs)
      }

      if (isFirstEpisode) {
        formData.set("is_first_episode", isFirstEpisode)
      }

      if (description) {
        formData.set("description", description)
      }

      if (image) {
        formData.set("image", image)
      }

      if (imageCompressed) {
        formData.append(
          "image_compressed",
          imageCompressed,
          `${image.name}_compressed.jpeg`
        )
      }

      if (labels && labels.length) {
        labels.forEach((label) => {
          if (label && (label.value || label.name) && label.type) {
            if (
              label?.name?.endsWith(label.type) ||
              label?.value?.endsWith(label.type)
            ) {
              formData.append("labels", `${label?.value || label?.name}`)
            } else {
              formData.append(
                "labels",
                `${label?.value || label?.name}-${label.type}`
              )
            }
          }
        })
      } else {
        formData.append("labels", [])
      }

      if (categories && categories.value) {
        formData.append("categories", categories.value)
      } else {
        formData.append("categories", [])
      }

      if (tags && tags.length) {
        tags.forEach((tag) => {
          if (tag && (tag.value || tag.name)) {
            formData.append("tags", tag?.value || tag?.name)
          }
        })
      } else {
        formData.append("tags", [])
      }

      if (shares) {
        formData.set("shares", shares)
      }

      if (applyLabels) {
        formData.set("apply_labels", applyLabels)
      }
      if (accessControls && accessControls.length) {
        accessControls.map((acc) => {
          if (typeof acc === "object") {
            const acctSplt = `${acc.value}`.split("--")
            formData.append("access_controls", acctSplt[0])
          } else {
            formData.append("access_controls", acc)
          }
        })
      }

      await axios
        .patch(`/peas/pea/${id}/`, formData, tokenConfig(getState))
        .then((res) => {
          dispatch({ type: UPDATE_PEA_SUCCESS, payload: res.data })
          pea = res.data
          dispatch(getTags())
          // case: when 1st episode is removed from series, then show updated 1st episode on upper section too
          if (
            broadcasting_stories &&
            broadcasting_stories.filter(
              (s) =>
                s.id === res.data.id &&
                s.series &&
                !res.data.series &&
                s.order_series === 1 &&
                res.data.order_series !== 1 &&
                !is_segment_modal
            ).length > 0
          ) {
            dispatch(
              getStories(
                1,
                company,
                "broadcastPea",
                "Admin",
                isSeriesOnly,
                seriesOnlyPage,
                true
              )
            )
            if (!opened_series && current_story.series) {
              dispatch(getSeriesStories(1, current_story.series, "Admin", "Update"))
            }
            else {
              dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
            }
          }

          // When we remove pea from middle of series
          else if (
            current_story &&
            current_story.series &&
            current_story.order_series > 1 &&
            !res.data.series &&
            !is_segment_modal
          ) {
            dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
          }

          if (
            current_story &&
            current_story.id === res.data.id &&
            !current_story.series &&
            res.data.series &&
            !is_segment_modal
          ) {

            dispatch(
              getStories(
                1,
                company,
                "broadcastPea",
                "Admin",
                isSeriesOnly,
                seriesOnlyPage,
                true
              )
            )

            if (opened_series && opened_series.id === res.data.series.id) {
              dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
            }
            dispatch(getTopStory())
          }

          // When we add pea of one series to other series directly and series is opened
          else if (
            opened_series &&
            current_story &&
            current_story.series &&
            res.data.series &&
            current_story.series.id === opened_series.id &&
            res.data.series.id !== current_story.series.id &&
            !is_segment_modal
          ) {
            // When we add title pea of A to B: refresh above section too
            if (current_story.order_series === 1) {
              dispatch(
                getStories(
                  1,
                  company,
                  "broadcastPea",
                  "Admin",
                  isSeriesOnly,
                  seriesOnlyPage,
                  true
                )
              )
              dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
            }
            if (res.data.order_series === 1) {
              dispatch(
                getStories(
                  1,
                  company,
                  "broadcastPea",
                  "Admin",
                  isSeriesOnly,
                  seriesOnlyPage,
                  true
                )
              )
              dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
            } else {
              dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
            }
          }
          // When we add pea of one series to other series directly but no series is opened in below section
          else if (
            current_story &&
            current_story.series &&
            res.data.series &&
            res.data.series.id !== current_story.series.id &&
            !is_segment_modal
          ) {

            // When we add title pea of A to B: refresh above section too
            if (current_story.order_series === 1) {
              dispatch(
                getStories(
                  1,
                  company,
                  "broadcastPea",
                  "Admin",
                  isSeriesOnly,
                  seriesOnlyPage,
                  true
                )
              )
            }
          }

          // When we select Title pea checkbox
          else if (
            opened_series &&
            res.data.series &&
            opened_series.id === res.data.series.id &&
            current_story &&
            current_story.series &&
            current_story.order_series !== res.data.order_series &&
            !is_segment_modal
          ) {
            dispatch(
              getStories(
                1,
                company,
                "broadcastPea",
                "Admin",
                isSeriesOnly,
                seriesOnlyPage,
                true
              )
            )
            dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
          }

          if (shares) {
            dispatch({
              type: RETRIEVE_PEA_SUCCESS,
              payload: res.data,
            })
          }
          if (!shares) {
            toast.success("Pea updated successfully")
          }

          if (broadcast_counter?.save_to_pod !== saveToPod
            ||
            broadcast_counter?.broadcast_date !== broadcastDate
          ) {
            if (saveToPod) {
              dispatch({ type: TOTAL_COMPANY_PEAS_REMOVE })
            }
            else if (broadcastDate > new Date().toISOString()) {
              dispatch({ type: TOTAL_COMPANY_PEAS_REMOVE })
            }
            else if (!saveToPod || !broadcastDate) {
              dispatch({ type: TOTAL_COMPANY_PEAS_ADD })
            }
          }

        })
        .catch((err) => {
          dispatch({ type: UPDATE_PEA_FAIL })
          let msg = "Something went wrong."
          if (err.response && err.response.data) {
            if (
              typeof err.response.data === "object" &&
              Object.keys(err.response.data).length > 0
            ) {
              let e = []
              for (let k in err.response.data) {
                e.push(
                  `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
                )
              }
              msg = e.join(" | ")
            } else if (typeof err.response.data === "string") {
              msg = err.response.data
            }
          }
          if (!shares) {
            toast.error(msg)
          }
        })
      return pea
    }

export const deletePea =
  ({ id }) =>
    async (dispatch, getState) => {
      dispatch({ type: DELETE_PEA_START })

      const opened_series = getState()?.stories?.series
      const currentSeriesStories = getState()?.stories?.seriesStories
      const company = getState()?.auth?.company
      let isSeriesOnly = getState()?.searchBox?.isSeriesOnly
      let seriesOnlyPage = getState()?.stories.seriesOnlyPage
      let topStory = getState()?.stories.topStory
      let allStories = getState()?.stories.broadcastingStories
      const currentStory = allStories.find(story => story.id === id)

      await axios
        .delete(`/peas/pea/${id}`, tokenConfig(getState))
        .then(() => {
          // When deleted story is topPea
          if (topStory.id === id) {
            let new_top = allStories.filter((p) => p.id !== id)[0]
            dispatch(setTopStory(new_top.id))
          }
          let indexOfDeleted = currentSeriesStories
            .map((episode) => episode.id)
            .indexOf(id)

          // series has only one episode and it is deleted
          if (indexOfDeleted === 0 && currentSeriesStories.length === 1) {
            dispatch(
              getStories(
                1,
                company.id,
                "broadcastPea",
                "Admin",
                isSeriesOnly,
                seriesOnlyPage,
                true
              )
            )
          }
          // When 1st episode is deleted but series has other episodes too
          else if (indexOfDeleted === 0 && currentSeriesStories.length > 1) {
            dispatch(
              getStories(
                1,
                company.id,
                "broadcastPea",
                "Admin",
                isSeriesOnly,
                seriesOnlyPage,
                true
              )
            )
            dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
          }
          // When middle episode is deleted
          else if (
            indexOfDeleted > 0 &&
            indexOfDeleted < currentSeriesStories.length - 1
          ) {
            dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
          }

          if (currentStory && currentStory.broadcast_date < new Date().toISOString()) {
            console.log('currentStory inside the if condition: ', currentStory);

          }
          if (currentStory && currentStory.broadcast_date && currentStory.broadcast_date < new Date().toISOString() ||
            (currentStory && !currentStory.save_to_pod && !currentStory.broadcast_date)) {
            dispatch({ type: TOTAL_COMPANY_PEAS_REMOVE })
          }
          dispatch({ type: DELETE_PEA_SUCCESS, payload: id })
          toast.success("Pea Deleted successfully")
        })
        .catch((err) => {
          dispatch({ type: DELETE_PEA_FAIL })
          console.log(err)
          toast.error("Something went wrong")
        })
    }

export const filteredBroadCastStories =
  (filteredVideos) => async (dispatch, getState) => {
    const opened_series = getState()?.stories?.series
    const currentSeriesStories = getState()?.stories?.seriesStories
    const company = getState()?.auth?.company
    let isSeriesOnly = getState()?.searchBox?.isSeriesOnly
    let seriesOnlyPage = getState()?.stories.seriesOnlyPage
    let topStory = getState()?.stories.topStory
    let allStories = getState()?.stories.broadcastingStories
    {
      const existTopStory = filteredVideos.find(
        (item) => topStory.id === item.id
      )
      if (existTopStory) {
        let new_top = allStories.filter((p) => p.id !== existTopStory.id)[0]
        dispatch(setTopStory(new_top.id))
      }

      let indexOfDeleted = null
      for (let i = 0; i < currentSeriesStories.length; i++) {
        const matchedIndex = filteredVideos.findIndex(
          (video) => video.id === currentSeriesStories[i].id
        )
        if (matchedIndex !== -1) {
          indexOfDeleted = i
        }
      }

      if (indexOfDeleted === 0 && currentSeriesStories.length === 1) {
        dispatch(
          getStories(
            1,
            company.id,
            "broadcastPea",
            "Admin",
            isSeriesOnly,
            seriesOnlyPage,
            true
          )
        )
      } else if (indexOfDeleted === 0 && currentSeriesStories.length > 1) {
        dispatch(
          getStories(
            1,
            company.id,
            "broadcastPea",
            "Admin",
            isSeriesOnly,
            seriesOnlyPage,
            true
          )
        )
        dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
      } else if (
        indexOfDeleted > 0 &&
        indexOfDeleted < currentSeriesStories.length - 1
      ) {
        dispatch(getSeriesStories(1, opened_series, "Admin", "Update"))
      }

      const seriesStoryWithInActiveCurrentSeries = filteredVideos.find(
        (item) => item.series !== null
      )

      if (
        seriesStoryWithInActiveCurrentSeries &&
        !indexOfDeleted &&
        currentSeriesStories.length < 1
      ) {
        dispatch(
          getStories(
            1,
            company.id,
            "broadcastPea",
            "Admin",
            isSeriesOnly,
            seriesOnlyPage,
            true
          )
        )
      }

      dispatch({ type: FILTERED_BROADCASTING_PEAS, payload: filteredVideos })
    }
  }

export const likePea =
  ({ peaId, userId, peaVideo, selectedLabel, callFrom }) =>
    async (dispatch, getState) => {
      const body = JSON.stringify({
        pea: peaId,
      })

      const pea = peaVideo ? peaVideo : getState().pea.pea

      await axios
        .patch(`/peas/pea/like/`, body, tokenConfig(getState))
        .then(() => {
          dispatch({
            type: LIKE_PEA,
            payload: { userId, peaId, pea, selectedLabel, callFrom },
          })
        })
        .catch((err) => {
          console.log(err)
          let msg = "Something went wrong."
          if (err.response && err.response.data) {
            if (
              typeof err.response.data === "object" &&
              Object.keys(err.response.data).length > 0
            ) {
              let e = []
              for (let k in err.response.data) {
                e.push(
                  `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
                )
              }
              msg = e.join(" | ")
            } else if (typeof err.response.data === "string") {
              msg = err.response.data
            }
          }
          toast.error(msg)
        })
    }

export const addPeaLabels =
  ({ id, labels, applyLabels }) =>
    async (dispatch, getState) => {
      dispatch({ type: ADD_PEA_LABELS_START })
      let labelsData = []

      if (labels && labels.length) {
        labelsData = labels
          .map((label) => {
            if (label.name && label.type) {
              return `${label.name}-${label.type}`
            }
          })
          .filter((label) => label !== undefined)
      }

      const body = JSON.stringify({
        pea: id,
        labels: labelsData,
        apply_labels: applyLabels,
      })

      await axios
        .post(`/peas/pea/labels/`, body, tokenConfig(getState))
        .then(() => {
          dispatch({
            type: ADD_PEA_LABELS_SUCCESS,
            payload: { labels: labelsData, peaId: id },
          })
        })
        .catch((err) => {
          dispatch({ type: ADD_PEA_LABELS_FAIL })
          console.log(err)
          toast.error("Something went wrong")
        })
    }

export const retrieveCompany =
  ({ id }) =>
    async (dispatch) => {
      axios
        .get(`/accounts/company/${id}`)
        .then((res) =>
          dispatch({ type: RETRIEVE_COMPANY_SUCCESS, payload: res.data })
        )
        .catch((err) => console.log(err))
    }

export const setStoryFilters = (filter) => async (dispatch) => {
  dispatch({ type: SET_STORY_FILTERS, payload: filter })
}

export const clearStoryFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_STORY_FILTERS })
}

export const sortStories = (peas, isSeries) => async (dispatch, getState) => {
  const body = JSON.stringify({
    peas: peas.map((pea) => pea.id),
    is_series: isSeries,
  })
  await axios
    .post(`/peas/peas/sort`, body, tokenConfig(getState))
    .then(() => {
      if (isSeries) {
        dispatch({ type: SORT_SERIES_STORIES, payload: peas })
      } else {
        dispatch({ type: SORT_STORIES, payload: peas })
      }
    })
    .catch((err) => {
      console.log(err)
    })

  return peas[0]
}

export const getTopStory = (company) => async (dispatch, getState) => {
  let url = `/peas/pea/top-pea/`
  dispatch({ type: COMPANY_FEATURED_VIDEO_START })
 
  if (company) {
    console.log("the company", company)
    url = `/peas/pea/top-pea/?company=${company}`
  }

  await axios
    .get(url, tokenConfig(getState))
    .then((res) => {
      if (res.data?.data.length > 0) {
        if (company) {
          dispatch({
            type: COMPANY_FEATURED_VIDEO,
            payload: res.data?.data[0]?.pea,
          })
        } else {
          dispatch({ type: SET_TOP_STORY, payload: res.data?.data[0]?.pea })
        }
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: COMPANY_FEATURED_VIDEO_FAIL })
    })
}

export const setTopStory = (id) => async (dispatch, getState) => {
  const body = JSON.stringify({ pea: id })

  await axios
    .post("/peas/pea/top-pea/", body, tokenConfig(getState))
    .then((res) => {
      if (res.data?.data.length > 0) {
        dispatch({ type: SET_TOP_STORY, payload: res.data?.data[0]?.pea })
      }
    })
    .catch((err) => console.log(err))
}

export const retrieveSeries =
  ({ series }) =>
    async (dispatch) => {
      await axios
        .get(`/peas/series/${series}`)
        .then((res) => {
          dispatch({ type: RETRIEVE_SERIES, payload: res.data })
        })
        .catch((err) => console.log(err))
    }

export const incrementNotification = () => (dispatch) => {
  dispatch({ type: INCREMENT_NOTIFICATION })
}

export const clearNotification = (newNotifs) => (dispatch) => {
  dispatch({ type: CLEAR_NOTIFICATION, payload: newNotifs })
}

export const watchlistPea =
  ({ pea, watch, userId }) =>
    async (dispatch, getState) => {
      let isFinished = false

      const body = JSON.stringify({
        pea: pea.id,
      })

      await axios
        .patch("/peas/pea/watchlist/", body, tokenConfig(getState))
        .then(() => {
          dispatch({ type: WATCHLIST_PEA, payload: { pea, watch, userId } })
          isFinished = true
        })
        .catch((err) => {
          let msg = "Something went wrong."
          if (err.response && err.response.data) {
            if (
              typeof err.response.data === "object" &&
              Object.keys(err.response.data).length > 0
            ) {
              let e = []
              for (let k in err.response.data) {
                e.push(
                  `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
                )
              }
              msg = e.join(" | ")
            } else if (typeof err.response.data === "string") {
              msg = err.response.data
            }
          }
          toast.error(msg)
          isFinished = true
        })

      return isFinished
    }

export const getWorkdayGuideStories =
  (label = "", effect = "") =>
    async (dispatch, getState) => {
      dispatch({ type: GET_WORKDAYGUIDE_STORIES_START })

      let hasMore = false
      let url = `/peas/pea/?page_size=100${label ? `&label=${encodeURIComponent(label)}` : ""
        }${effect ? `&effect=${encodeURIComponent(effect)}` : ""}`
      const isSeriesOnly = getState().searchBox.isSeriesOnly

      if (isSeriesOnly) {
        url = `${url}&series=True`
      }

      await axios
        .get(url)
        .then((res) => {
          if (res.data.next) {
            hasMore = true
          }

          dispatch({
            type: GET_WORKDAYGUIDE_STORIES_SUCCESS,
            payload: {
              label,
              data: res.data.results,
            },
            hasMore: hasMore,
          })
        })
        .catch((err) => {
          dispatch({
            type: GET_WORKDAYGUIDE_STORIES_FAIL,
          })
          console.log(err)
        })
    }

export const setWorkdayGuideFilter = (value) => (dispatch) => {
  dispatch({ type: WORKDAY_GUIDE_FILTER, payload: value })
}

export const filterWorkdayGuideStories =
  (label = "", effect = "") =>
    async (dispatch, getState) => {
      dispatch({ type: FILTER_WORKDAYGUIDE_STORIES_START })

      let hasMore = false
      let url = `/peas/pea/?page_size=100${label ? `&label=${encodeURIComponent(label)}` : ""
        }${effect ? `&effect=${encodeURIComponent(effect)}` : ""}`
      const isSeriesOnly = getState().searchBox.isSeriesOnly

      if (isSeriesOnly) {
        url = `${url}&series=True`
      }

      await axios
        .get(url)
        .then((res) => {
          if (res.data.next) {
            hasMore = true
          }

          dispatch({
            type: FILTER_WORKDAYGUIDE_STORIES_SUCCESS,
            payload: {
              label,
              data: res.data.results,
            },
            hasMore: hasMore,
          })
        })
        .catch((err) => {
          dispatch({
            type: FILTER_WORKDAYGUIDE_STORIES_FAIL,
          })

          console.log(err)
        })
      dispatch({
        type: FILTER_WORKDAYGUIDE_STORIES_END,
      })
    }

export const toggleWorkdayGuideSortbyFilter =
  (event) => (dispatch, getState) => {
    const isOpen = getState().stories.workdayGuideSortByFilter

    let shouldOpen =
      event && event.target && event.target.id === "openSortByFilter" && !isOpen
        ? true
        : false

    dispatch({
      type: WORKDAY_GUIDE_SORT_BY_DROPDOWN,
      payload: shouldOpen,
    })
  }

export const toggleWorkdayGuideDesiredEffectFilter =
  (event) => (dispatch, getState) => {
    const isOpen = getState().stories.workdayGuideDesiredEffectFilter

    let shouldOpen =
      event &&
        event.target &&
        event.target.id === "openDesiredEffectFilter" &&
        !isOpen
        ? true
        : false

    dispatch({
      type: WORKDAY_GUIDE_DESIRED_EFFECT_DROPDOWN,
      payload: shouldOpen,
    })
  }

export const clearPeaVideoStories = () => (dispatch) =>
  dispatch({ type: CLEAR_PEA_VIDEO_STORIES })

export const clearCompany = () => (dispatch) =>
  dispatch({ type: CLEAR_COMPANY })
