import React from "react"
import classNames from "classnames"
import styles from "./Button.module.scss"

const cx = classNames.bind(styles)

const button = ({
  disabled,
  btnType,
  selected,
  highlighted,
  onClick,
  children,
  buttonStyle,
  loading,
  width,
  ...props
}) => {
  return (
    <button
      style={{ width }}
      disabled={disabled}
      className={cx(
        buttonStyle,
        styles.Button,
        { Selected: selected },
        highlighted ? styles["Highlighted"] : "",
        styles[btnType]
      )}
      onClick={onClick}
      {...props}
    >
      {loading ? (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        children
      )}
    </button>
  )
}

export default button
