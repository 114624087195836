import { toast } from "react-toastify"
import axios from "../../utils/axiosConfig"
import { tokenConfig } from "./authActions"
import {
  GET_ACCOUNTS_START,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
  MAKE_AS_ADMIN_START,
  MAKE_AS_ADMIN_FAIL,
  MAKE_AS_ADMIN_SUCCESS,
  MAKE_AS_USER_START,
  MAKE_AS_USER_FAIL,
  MAKE_AS_USER_SUCCESS,
  LOAD_ACCOUNT_SUCCESS,
} from "../types"

export const getAccounts = (company) => (dispatch, getState) => {
  dispatch({ type: GET_ACCOUNTS_START })

  let url = "/accounts/account"

  if (company) {
    url = `${url}?company=${company}`
  }

  axios
    .get(url, tokenConfig(getState))
    .then((res) => dispatch({ type: GET_ACCOUNTS_SUCCESS, payload: res.data }))
    .catch(() => dispatch({ type: GET_ACCOUNTS_FAIL }))
}

export const makeAdmin = (member) => async (dispatch, getState) => {
  dispatch({ type: MAKE_AS_ADMIN_START })
  let url = `/accounts/account/${member.id}/make_admin/`

  let account=getState()?.auth.account
  let formData=new FormData()
  formData.set("id", account.id)
  let is_updated=false
  await axios
    .patch(url, formData ,tokenConfig(getState))
    .then((res) => {
      dispatch({ type: MAKE_AS_ADMIN_SUCCESS, payload: member })
      dispatch({ type: LOAD_ACCOUNT_SUCCESS, payload: res.data })
      toast.success("Company Studio Members updated successfully")
      is_updated=true
    })
    .catch(() => {
      dispatch({ type: MAKE_AS_ADMIN_FAIL })
      toast.error("Edit Role Failed with error")
      is_updated=false
    })
    return is_updated
}

export const makeUser = (member) => (dispatch, getState) => {
  dispatch({ type: MAKE_AS_USER_START })
  let url = `/accounts/account/${member.id}/make_user/`

  axios
    .patch(url, tokenConfig(getState))
    .then(() => {
      dispatch({ type: MAKE_AS_USER_SUCCESS, payload: member })
      toast.success("Company Studio Members updated successfully")
    })
    .catch((err) => {
      dispatch({ type: MAKE_AS_USER_FAIL })

      let msg = "Something went wrong. Please try again."
        if (err.response && err.response.data) {
          if (
            typeof err.response.data === "object" &&
            Object.keys(err.response.data).length > 0
          ) {
            let e = []
            for (let k in err.response.data) {
              e.push(
                `${err.response.data[k]}`.charAt(0).toUpperCase() +
                  `${err.response.data[k]}`.slice(1)
              )
            }
            msg = e.join(" | ")
          } else if (typeof err.response.data === "string") {
            msg = err.response.data
          }
        }
        toast.error(msg)
    })
}
