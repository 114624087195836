import {
  RETRIEVE_PEA_SUCCESS,
  LIKE_PEA,
  WATCHLIST_PEA,
  AUTH_SIGN_OUT,
  CLEAR_STORY,
} from "../types"

const DEFAULT_STATE = { pea: null }

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case CLEAR_STORY:
      return {
        ...state,
        pea: null,
      }
    case AUTH_SIGN_OUT:
      return DEFAULT_STATE
    case RETRIEVE_PEA_SUCCESS:
      return {
        ...state,
        pea: payload,
      }
    case LIKE_PEA:
      if (
        payload.pea &&
        state.pea &&
        payload.pea.id === state.pea.id &&
        payload.pea.likes &&
        payload.pea.likes.includes(payload.userId)
      ) {
        return {
          ...state,
          pea: {
            ...payload.pea,
            likes: payload.pea.likes.filter((p) => p !== payload.userId),
          },
        }
      } else if (
        payload.pea &&
        state.pea &&
        payload.pea.id === state.pea.id &&
        payload.pea.likes &&
        !payload.pea.likes.includes(payload.userId)
      ) {
        return {
          ...state,
          pea: {
            ...payload.pea,
            likes: [...payload.pea.likes, payload.userId],
          },
        }
      }
    case WATCHLIST_PEA:
      if (
        payload.pea &&
        state.pea &&
        payload.pea.id === state.pea.id &&
        payload.pea.watchlists &&
        payload.pea.watchlists.includes(payload.userId)
      ) {
        return {
          ...state,
          pea: {
            ...payload.pea,
            watchlists: payload.pea.watchlists.filter(
              (w) => w !== payload.userId
            ),
          },
        }
      } else if (
        payload.pea &&
        state.pea &&
        payload.pea.id === state.pea.id &&
        payload.pea.watchlists &&
        !payload.pea.watchlists.includes(payload.userId)
      ) {
        return {
          ...state,
          pea: {
            ...payload.pea,
            watchlists: [...payload.pea.watchlists, payload.userId],
          },
        }
      }
    default:
      return state
  }
}
