import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { ReactComponent as DropdownIndicator } from '../../../icons/triangle-down.svg';
import styles from './PBSelect.module.scss';
import './PBSelect.scss';

const theme = {
  colors: {
    primary: 'var(--color-primary)',
    primary25: '#DEEBFF',
    neutral0: 'white',
  },
};

const PBSelect = ({
  label = 'Order by',
  value,
  onChange,
  onBlur,
  options,
  type,
  selectStyles,
  disbaleTyping,
  ...props
}) => {
  let defaultOptions = [
    { value: 'most_likes', label: 'Most Likes' },
    { value: 'most_recent', label: 'Most Recent' },
    { value: 'most_popular', label: 'Most Popular' },
    { value: 'short_life', label: 'Short Life' },
  ];

  return (
    <div className={classNames('SelectBy', styles.PBSelect, selectStyles)}>
      <Select
        isSearchable={!disbaleTyping}
        className={classNames(
          'basic-single',
          type === 'Company'
            ? 'basic-company'
            : type === 'pod'
            ? 'basic-pod'
            : '',
          styles[type]
        )}
        classNamePrefix='react-select'
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        options={options || defaultOptions}
        theme={theme}
        components={{ DropdownIndicator }}
        {...props}
      />
    </div>
  );
};

export default PBSelect;
