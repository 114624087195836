import axios from "../../utils/axiosConfig"
import {
  GET_FILTER_SERIES,
  RESET_FILTERS,
  SB_SET_SEARCH_KEY_PRESSED,
  SELECT_FILTER,
  SET_HOME_AUTHENTICATED,
  SET_HOME_VISITED,
  SET_SEARCH_SUBMITTED,
  SET_SEARCH_VALUE,
  TOGGLE_SERIES_FILTER,
  SET_SHOULD_DO_HOME_ANIMATION,
  SET_TOUCHED,
  SORT_BY,
  GET_TAGS,
} from "../types"

export const setSearchValue = (data) => (dispatch) => {
  if (!data) {
    dispatch(resetFilters())
  }

  dispatch({
    type: SET_SEARCH_VALUE,
    payload: data,
  })
}

export const setTouched = (touched) => (dispatch) => {
  dispatch({
    type: SET_TOUCHED,
    payload: touched,
  })
}

export const setIsSearchSubmitted = (isSubmitted) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_SUBMITTED,
    payload: isSubmitted,
  })
}

export const setIsHomeVisited = () => (dispatch) => {
  dispatch({
    type: SET_HOME_VISITED,
    payload: true,
  })
}

export const setShouldDoHomeAnimation = (shouldDoHomeAnimation) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SHOULD_DO_HOME_ANIMATION,
      payload: shouldDoHomeAnimation,
    })
  }
}

export const setHomeAuthenticated = (isHomeAuthenticated) => (dispatch) => {
  dispatch({
    type: SET_HOME_AUTHENTICATED,
    payload: isHomeAuthenticated,
  })
}

export const setSearchKeyPressed = (data) => (dispatch) => {
  dispatch({
    type: SB_SET_SEARCH_KEY_PRESSED,
    payload: data,
  })
}

export const selectFilter = (id) => (dispatch) => {
  dispatch({
    type: SELECT_FILTER,
    payload: id,
  })
}

export const sortBy = (id) => (dispatch) => {
  dispatch({
    type: SORT_BY,
    payload: id,
  })
}

export const resetFilters = () => (dispatch) => {
  dispatch({
    type: RESET_FILTERS,
  })
}

export const getFilterSeries = () => (dispatch, getState) => {
  const companyId = getState()?.auth?.company?.id

  axios
    .get("/peas/series")
    .then((res) => {
      dispatch({
        type: GET_FILTER_SERIES,
        payload: { series: res.data, companyId },
      })
    })
    .catch((err) => console.log(err))
}

export const getTags = () => (dispatch) => {
  axios
    .get("/peas/tags")
    .then((res) => {
      dispatch({
        type: GET_TAGS,
        payload: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const toggleSeriesFilter = () => (dispatch) => {
  dispatch({ type: TOGGLE_SERIES_FILTER })
}
