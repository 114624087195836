import { SET_SHOW_LOGIN_MODAL, SET_SHOW_SIGNUP_MODAL } from "../types"

export const setShowLoginModal = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOW_LOGIN_MODAL,
    payload: data,
  })
}

export const setShowSignUpModal = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOW_SIGNUP_MODAL,
    payload: data,
  })
}
