import React from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import loadable from "@loadable/component"
import { Field, reduxForm } from "redux-form"
import { isSafari } from "react-device-detect"
import { useMediaQuery } from "react-responsive"
import { LinkedIn } from "react-linkedin-login-oauth2"
import { useHistory, useLocation } from "react-router-dom"
import * as actions from "../../../store/actions"
import LogoGreen from "../../../icons/LogoGreen.svg"
import Button from "../../../components/UI/Button/Button"
import PeaLogoLetterIn from "../../../icons/LogoBlue01.svg"
import SideImageLogo1 from "../../../icons/SideImage_1.svg"
import { generateAccessToken } from "../../../utils/linkedin"
import LinkedinLogo from "../../../components/UI/Logo/LinkedinLogo"
import CustomInput from "../../../components/UI/Inputs/CustomInput.js"
import styles from "./Login.module.scss"

const ForgotPassword = loadable(() =>
  import("../ForgotPassword/ForgotPassword")
)

const validateEmail = (v) => {
  if (!v || v === "") {
    return "This field is required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v)) {
    return "Invalid Email"
  }
}

const validatePassword = (v) => {
  if (!v || v === "") {
    return "This field is required"
  } else if (v.length > 29) {
    return "Length must not be greater than 30 characters"
  }
}

const Login = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
  const isDesktopOrTablet = useMediaQuery({
    query: "only screen and (min-width: 769px)",
  })
  const history = useHistory()
  const location = useLocation()

  if (location.pathname === "/sign-in" && isDesktopOrTablet) {
    props.setShowLoginModal(true)
    history.push("/")
  }

  const onSubmit = async (formData) => {
    const { email, password } = formData
    if (email && password) {
      let response = await props.signIn({ email, password })

      if (response.isSuccess) {
        props.setIsHomeVisited(true)

        if (props.onSuccess) {
          props.onSuccess()
        }

        if (
          response.account &&
          (response.account.account_type === "Business" ||
            response.account.account_type === "Admin")
        ) {
          history.push("/broadcasting-pod")
        } else {
          history.push("/")
        }
      }
    }
  }

  const responseLinkedin = async (res) => {
    props.signInLinkedInResult(res)
    let success = res
    let response = null;

    if (success) {
      await generateAccessToken({
        auth_code: res,
        redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
        client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
        client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      })
        .then(async (res) => {
          if (res && res.token) {
            response = await  props.loginWithLinkedIn(res.token)
          }
        })
        .catch((err) => console.log(err))
        
        if(!props.onSuccess){
          if (
            response.account &&
            (response.account.account_type === "Business" ||
              response.account.account_type === "Admin")
          ) {
            history.push("/broadcasting-pod")
          } else {
            history.push("/")
          }
        } else {
          if (
            response.account &&
            (response.account.account_type === "Business" ||
              response.account.account_type === "Admin")
          ) {
            history.push("/broadcasting-pod")
          }
          props.onSuccess()
        }
    }
  }

  const showForgotPasswordModal = () => {
    props.setShowLoginModal(false)
    props.setForgotPasswordModal(props.forgotPasswordModal)
  }

  const { handleSubmit } = props,
    showSignupModal = () => {
      props.setShowLoginModal(false)
      props.setShowSignUpModal(true)
    }

  return (
    <>
      {isMobile && <ForgotPassword />}
      <div className={styles.SignIn}>
        <div className={styles.SignIn__Info}>
          {isMobile ? (
            <>
              <img
                src={PeaLogoLetterIn}
                className={`${
                  isSafari
                    ? styles.PeaLogoLetterInSafari
                    : styles.PeaLogoLetterIn
                }`}
                alt="peabox"
              />
              <p className={styles.undertext}>
                <strong>Broadcasting</strong> your tiny breaks!
              </p>
            </>
          ) : (
            <section>
              <p className={styles.undertext}>
                <span className={styles.normal}>
                  <strong>Broadcasting</strong> your tiny breaks!
                </span>
              </p>
            </section>
          )}
          {!isMobile && (
            <img src={SideImageLogo1} className={styles.SideImageLogo} />
          )}
        </div>
        <div className={styles.SignIn__Form}>
          {!isMobile && (
            <div className={styles["SignIn__Form--Image"]}>
              <img
                src={LogoGreen}
                alt="main-logo"
                className={styles.ImageLogo}
              />
            </div>
          )}
          <div style={{ zIndex: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <Field
                  name="email"
                  type="text"
                  id="email"
                  placeholder="E-mail"
                  component={CustomInput}
                  className={styles.FormFields}
                  handleClearError={props.clearAuthMessage}
                  validate={validateEmail}
                />
              </fieldset>
              <fieldset>
                <Field
                  name="password"
                  type="password"
                  id="password"
                  placeholder="Password"
                  component={CustomInput}
                  className={styles.FormFields}
                  {...{ eyeIcon: true }}
                  handleClearError={props.clearAuthMessage}
                  validate={validatePassword}
                />
                <div
                  onClick={showForgotPasswordModal}
                  className={styles.ForgotPassword}
                >
                  Forgot your password?
                </div>
              </fieldset>
              {props.errorMessage && typeof props.errorMessage === "string" && (
                <div className={styles.ErrorMessage__Center}>
                  {props.errorMessage}
                </div>
              )}
              <Button
                buttonStyle={styles.ButtonLogIn}
                btnType="Blue"
                type="submit"
              >
                Log in
              </Button>
            </form>
          </div>
          <LinkedIn
            scope="r_liteprofile r_emailaddress w_member_social"
            clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
            redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URI}
            onError={responseLinkedin}
            onSuccess={responseLinkedin}
          >
            {({ linkedInLogin }) => (
              <Button btnType="Linkedin" onClick={linkedInLogin} type="button">
                <div className={styles.LinkedInWrapper}>
                  <img src={LinkedinLogo} />
                  <div>Log in with LinkedIn</div>
                </div>
              </Button>
            )}
          </LinkedIn>
          <div className={styles.CreateAccountText}>
            <span>Need an account?</span>
            <b
              onClick={() => {
                props.clearAuthMessage()
                !isMobile ? showSignupModal() : history.push("/sign-up")
              }}
            >
              Sign up now
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  errorMessage: state.auth.errorMessage,
  forgotPasswordModal: state.modal.forgotPasswordModal,
})

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: "signin" })
)(Login)
