import React from "react";
import styles from "./BroadcastDropdown.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";

const BroadcastDropdown = ({ setShowDropdown, children }) => {
  return (
    <div className={styles.BroadcastDropdown}>
      <div className={styles.Header}>
        <CloseIcon
          className={styles.CloseIcon}
          onClick={() => setShowDropdown(false)}
        />
      </div>

      <div className={styles.ContentWrapper}>
        <div className={styles.Content}>{children}</div>
      </div>
    </div>
  );
};

export default BroadcastDropdown;
