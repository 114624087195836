import React, { useRef, useState } from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { Card, Overlay } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import * as actions from "../../store/actions"
import { ReactComponent as FilterImage } from "../../icons/filter.svg"
import { ReactComponent as MagnifyingGlass } from "../../icons/magnifyingGlass.svg"
import styles from "./SearchBox.module.scss"

const cx = classNames.bind(styles)

const SearchBox = ({
  searchValue,
  setSearchValue,
  setTouched,
  touched,
  type,
  typewriterText,
  children,
  setIsSearchSubmitted,
  setSearchKeyPressed,
  sortBy,
  dark = false,
  onSearch = () => {},
  onSearchRequest,
  className,
}) => {
  const options = [
    { id: 1, value: "most_likes", label: "Most Likes" },
    { id: 2, value: "most_recent", label: "Fresh Peas!" },
    { id: 3, value: "most_popular", label: "Most popular" },
  ]

  const [show, setShow] = useState(false)
  const targetEl = useRef(null)
  const location = useLocation()

  const isMobileDevice = useMediaQuery({ query: "(max-width: 760px)" })
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1024px)" })

  const showTypewriterText =
    !isMobileDevice && searchValue === "" && typewriterText && !touched
  const showInputFilter =
    isMobileOrTablet &&
    (type === "Gallery" || type === "Company" || type === "Interests")

  const handleOnChange = (event) => {
    setSearchValue(event.target.value)
    onSearch(true)
  }

  const keyPressed = (event) => {
    if (event.key === "Enter") {
      setIsSearchSubmitted(true)
      setSearchKeyPressed(true)
      onSearch(false)
      event.target.blur()
      onSearchRequest()
    } else if (
      (type === "Gallery" || type === "Company") &&
      (String.fromCharCode(event.keyCode).match(/(\w|\s)/g) ||
        event.keyCode === 8)
    ) {
      onSearch(true)
    }
  }

  const renderTypewriterText = () => (
    <h3 onClick={() => setTouched(true)}>{typewriterText}</h3>
  )

  const placeholderText = () => {
    return isMobileDevice ||
      touched ||
      type === "Gallery" ||
      type === "Interests"
      ? "Search"
      : ""
  }

  const toggleFilterOptionsVisibility = () => {
    setShow(!show)
  }

  const ChangeFilterParams = (option) => {
    toggleFilterOptionsVisibility()
    sortBy(option)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSearchRequest()
  }

  return (
    <div className={styles.SearchBoxContainer + " " + className}>
      <div
        className={cx(styles.SearchBox, styles[type], {
          FlexStart: !showTypewriterText,
          Dark: dark,
        })}
      >
        <MagnifyingGlass
          className={styles.MagnifyingGlass}
          width={"42px"}
          onClick={onSearchRequest}
        />
        <div
          className={
            styles.Typewriter +
            " " +
            (showTypewriterText ? "" : styles.TypewriterSearchMode)
          }
        >
          {showTypewriterText && renderTypewriterText()}
          <form onSubmit={handleSubmit}>
            <input
              onKeyUp={keyPressed}
              onFocus={() => setTouched(true)}
              onBlur={() => setTouched(false)}
              value={searchValue}
              onChange={(e) => handleOnChange(e)}
              className={cx(styles.InputTypewriter, { Dark: dark })}
              placeholder={placeholderText()}
              id="searchBox"
            />
          </form>
        </div>
        {showInputFilter && (
          <>
            {location.pathname === "/" && (
              <>
                <FilterImage
                  className={styles.FilterImage}
                  ref={targetEl}
                  onClick={toggleFilterOptionsVisibility}
                />
                <Overlay
                  target={targetEl.current}
                  rootClose={true}
                  show={show}
                  placement="bottom"
                >
                  {({
                    placement,
                    arrowProps,
                    show: _show,
                    popper,
                    ...props
                  }) => (
                    <Card
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className={styles.FilterOptions}
                    >
                      {options.map((option) => (
                        <p
                          key={option.id}
                          onClick={() => ChangeFilterParams(option)}
                        >
                          {option.label}
                        </p>
                      ))}
                    </Card>
                  )}
                </Overlay>
              </>
            )}
          </>
        )}
      </div>
      {children}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    searchValue: state.searchBox.searchValue,
    touched: state.searchBox.touched,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sortBy: (option) => dispatch(actions.sortBy(option)),
    setSearchValue: (data) => dispatch(actions.setSearchValue(data)),
    setTouched: (touched) => dispatch(actions.setTouched(touched)),
    setIsSearchSubmitted: (isSubmitted) =>
      dispatch(actions.setIsSearchSubmitted(isSubmitted)),
    setSearchKeyPressed: (value) =>
      dispatch(actions.setSearchKeyPressed(value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox)
