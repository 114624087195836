import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import * as actions from '../../../store/actions';
import { isWithin24hrs } from '../../../utils/datetime';
import heartFilled from '../../../icons/heart-solid.svg';
import heartOutlined from '../../../icons/heart-regular.svg';
import VideoUserTooltip from './VideoUserTooltip/VideoUserTooltip';
import InviewAutoPlayVideo from './AutoPlayMobile/InviewAutoPlayVideo';
import styles from './VideoItem.module.scss';
import Play from '../../../icons/play.svg';

const cx = classNames.bind(styles);

let currentPlaying = null;
const changeAutoPlayToNewVideo = (newVideo) => {
  if (currentPlaying) {
    let isCurrentRefPlaying = !!(
      currentPlaying.currentTime > 0 &&
      !currentPlaying.paused &&
      !currentPlaying.ended &&
      currentPlaying.readyState > 2
    );
    if (isCurrentRefPlaying) {
      currentPlaying.pause();
    }
  }
  currentPlaying = newVideo;
};

const PeaLabelTags = ({ tags, visible, isFreshPea, video, format }) => {
  if (visible && tags) {
    return (
      <div className={styles.LabelTagsContainer}>
        {tags.channels && tags.channels.length > 0 ? (
          <div className={styles.ChannelTag}>
            <span>{tags.channels[0]} +++++</span>
          </div>
        ) : null}
        {tags.targetEffects && tags.targetEffects.length > 0 ? (
          <div className={styles.TargetEffectTag}>
            <span>{tags.targetEffects[0]}</span>
          </div>
        ) : null}

        {/* {isFreshPea ? (
          <div className={styles.TargetEffectTag}>
            <span>Fresh Pea </span>
          </div>
        ) : null} */}

        {video?.short_life_hrs !== null && format != 'Company' && (
          <div className={styles.TargetSortTag}>
            <span>Short life</span>
          </div>
        )}
      </div>
    );
  }
  return null;
};

const VideoItem = ({
  peaPage,
  video,
  isAuth,
  format,
  controls,
  disableHover,
  inModal,
  hoveringCarousel,
  isCarousel = false,
  setIsFavorite,
  companyPage,
  likePea,
  account,
  setShowLoginModal,
  comingSoon,
  primaryId,
  featuredVideo,
  setShowPeaPageModal,
  selectedLabel,
}) => {
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showUserTooltip, setShowUserTooltip] = useState(false);
  const [showTagLabels, setShowTagLabels] = useState(false);
  const [channelLabels, setChannelLabels] = useState([]);
  const [targetEffectLabels, setTargetEffectLabels] = useState([]);

  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1199px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const videoRef = useRef();
  const location = useLocation();

  const videoUserImgRef = useRef();
  const target = videoUserImgRef;

  const isFavorite =
    video && isAuth && account ? video.likes.includes(account.id) : false;

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  // Function to handle video play
  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
    console.log('on play');
  };

  // Function to handle video pause
  const handleVideoPause = () => {
    console.log('on pause');
    setIsVideoPlaying(false);
  };

  useEffect(() => {
    if (
      video &&
      video.likes &&
      video.likes.length > 0 &&
      account &&
      video.likes.includes(account.id)
    ) {
      setIsFavorite(isCarousel ? video.primaryId : video.id);
    }
  }, []);

  useEffect(() => {
    if (!disableHover && isCarousel) {
      if (hoveringCarousel && !showUserDetails) {
        setShowUserDetails(true);
      } else if (!hoveringCarousel && showUserDetails) {
        setShowUserDetails(false);
      }
    }
  }, [hoveringCarousel, showUserDetails, disableHover, isCarousel]);

  const playVideo = async () => {
    let peaVideo = videoRef.current;
    if (!disableHover) {
      const playPromise = peaVideo.play();
      changeAutoPlayToNewVideo(peaVideo);

      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          // Auto-play was prevented
        });
        setShowUserDetails(true);
        setShowTagLabels(true);
      }
    }
  };

  const pauseVideo = () => {
    let video = videoRef.current;
    if (!disableHover) {
      video.pause();
      setShowUserDetails(false);
      setShowUserTooltip(false);
      setShowTagLabels(false);
    }
  };

  const defaultURL =
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';

  const processFormat = (format) => {
    let newFormat = format;
    switch (format) {
      case 'Square':
        newFormat = peaPage
          ? 'SquarePeaPageMobile'
          : companyPage
          ? 'companySquare'
          : inModal
          ? 'SquareModal'
          : newFormat;
        break;
      case 'Rectangle':
        newFormat = peaPage
          ? 'RectanglePeaPageMobile'
          : companyPage
          ? 'companyRectangle'
          : inModal
          ? 'RectangleModal'
          : newFormat;
        break;
      default:
        break;
    }
    return newFormat;
  };

  let videoProps = {
    ref: videoRef,
    key: video ? video.url : '',
    autoPlay: false,
    muted: true,
    poster: video ? video.image : '',
    controls: controls || false,
  };

  useEffect(() => {
    if (video && video.labels && video.labels.length) {
      video.labels
        .filter((l) => l.includes('-Target Responses'))
        .map((l) => {
          const targetEffectLabel = l.split('-Target Responses');
          if (targetEffectLabel[0]) {
            setChannelLabels((prev) => [...prev, targetEffectLabel]);
          }
        });

      video.labels
        .filter((l) => l.includes('-TV Channels'))
        .map((l) => {
          const tvChannelLabel = l.split('-TV Channels');
          if (tvChannelLabel[0]) {
            setTargetEffectLabels((prev) => [...prev, tvChannelLabel]);
          }
        });
    }
  }, []);

  const checkIfFreshPea = () => {
    if (video && video.created_at) {
      return isWithin24hrs(video.created_at);
    }
  };

  let callFrom = '';

  if (location.pathname === '/') {
    callFrom = 'home';
  } else if (location.pathname === '/workday-guide') {
    callFrom = 'workday-guide';
  } else if (location.pathname.includes('/company/')) {
    callFrom = 'company';
  }

  return (
    <>
      {format === 'Company' || comingSoon ? (
        <div className={styles.VideoWrapper}>
          {!isVideoPlaying && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                /* Add these lines for horizontal centering if needed: */
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: '50',
              }}
            >
              <img
                onClick={() => {
                  videoRef.current.play();
                  setIsVideoPlaying(true);
                }}
                src={Play}
                alt='play button'
              />
            </div>
          )}

          <div
            className={cx(
              styles['bg-video__content'],
              styles[processFormat(format)],
              format,
              `bg-video__content`,
              styles.bgVideoWrapper
            )}
          >
            {comingSoon && (
              <div className={styles.ComingSoonMask}>Dropping Soon</div>
            )}
            {isMobile || format === 'Company' ? (
              <InviewAutoPlayVideo
                videoProps={videoProps}
                video={video}
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
                defaultURL={featuredVideo}
                disableOnScroll={!isMobileOrTablet}
                changeAutoPlayToNewVideo={changeAutoPlayToNewVideo}
                on
              />
            ) : (
              <video
                ref={videoRef}
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
                {...videoProps}
                playsInline
                disablePictureInPicture
              >
                <source src={video ? video.url : defaultURL} type='video/mp4' />
                Your browser is not supported!
              </video>
            )}
            {!comingSoon && (
              <>
                <PeaLabelTags
                  video={video}
                  tags={{
                    channels: channelLabels,
                    targetEffects: targetEffectLabels,
                  }}
                  format={format}
                  isFreshPea={
                    checkIfFreshPea() && (showTagLabels || !!companyPage)
                  }
                  visible={true}
                  // visible={isMobile ? true : showTagLabels || !!companyPage}
                />
                <div
                  className={
                    inModal
                      ? styles.UserDetailsContainerInModal
                      : styles.UserDetailsContainer
                  }
                >
                  {showUserDetails && (
                    <div
                      className={
                        inModal
                          ? styles.UserDetailsWrapperInModal
                          : styles.UserDetailsWrapper
                      }
                    >
                      <section
                        className={
                          inModal
                            ? styles.VideoFooterInModal
                            : styles.VideoFooter
                        }
                      >
                        <section
                          className={
                            inModal
                              ? styles.VideoFooterDetailsWrapperInModal
                              : styles.VideoFooterDetailsWrapper
                          }
                        >
                          <Overlay
                            placement='top'
                            trigger='click'
                            show={showUserTooltip}
                            target={target}
                          >
                            <Popover
                              className={styles.DefaultTooltip}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <VideoUserTooltip
                                video={video}
                                isAuth={isAuth}
                                company={video.company}
                              />
                            </Popover>
                          </Overlay>
                          <div
                            onMouseEnter={() =>
                              !isMobileOrTablet && setShowUserTooltip(true)
                            }
                            onMouseLeave={() =>
                              !isMobileOrTablet && setShowUserTooltip(false)
                            }
                          >
                            <img
                              alt='user'
                              ref={videoUserImgRef}
                              src={video.company_image}
                              className={styles.UserPicture}
                            />
                            <span className={styles.UserName}>
                              {/* <Link to={`/company/${video.company}`}> */}
                              <Link
                                to={`/${video.company_name
                                  .replaceAll(' ', '')
                                  .toLowerCase()}/peabox.tv`}
                              >
                                {video.company_name}
                              </Link>
                            </span>
                          </div>
                        </section>
                        <div
                          onClick={(e) => {
                            e.persist();
                            e.preventDefault();
                            e.stopPropagation();

                            if (account && account.deactivated_at) {
                              toast.error(
                                'Liking or unliking peas is not allowed for deactivated accounts.'
                              );
                            } else {
                              if (!isAuth || account.account_type !== 'Basic') {
                                setShowLoginModal(true);
                              } else {
                                if (account) {
                                  setIsFavorite(
                                    isCarousel ? video.primaryId : video.id
                                  );
                                  isCarousel
                                    ? likePea({
                                        peaId: video.primaryId,
                                        userId: account.id,
                                        peaVideo: video,
                                        selectedLabel,
                                        callFrom,
                                      })
                                    : likePea({
                                        peaId: video.id,
                                        userId: account.id,
                                        peaVideo: video,
                                        selectedLabel,
                                        callFrom,
                                      });
                                }
                              }
                            }
                          }}
                        >
                          {isFavorite ? (
                            <div
                              style={{
                                background: 'white',
                                width: '30px',
                                height: '30px',
                                opacity: '.7',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '-10px',
                              }}
                            >
                              <img
                                style={{
                                  width: '16px',
                                  height: '14px',
                                }}
                                src={heartFilled}
                                alt='Heart Filled'
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                background: 'white',
                                width: '30px',
                                height: '30px',
                                opacity: '.7',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '-10px',
                              }}
                            >
                              <img
                                style={{
                                  width: '16px',
                                  height: '14px',
                                }}
                                src={heartOutlined}
                                alt='Heart Outlined'
                              />
                            </div>
                          )}
                        </div>
                      </section>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Link
          to={`/pea/${primaryId}`}
          className={styles.VideoWrapper}
          onMouseEnter={(event) => playVideo(event)}
          onMouseLeave={(event) => pauseVideo(event)}
          onClick={() => {
            setShowPeaPageModal(null, null, callFrom, video.company);
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 2000);
          }}
        >
          <div
            className={cx(
              styles['bg-video__content'],
              styles[processFormat(format)],
              format,
              `bg-video__content`,
              styles.bgVideoWrapper
            )}
          >
            {comingSoon && (
              <div className={styles.ComingSoonMask}>Dropping Soon</div>
            )}
            {isMobile || format === 'Company' ? (
              <InviewAutoPlayVideo
                videoProps={videoProps}
                video={video}
                defaultURL={featuredVideo}
                disableOnScroll={!isMobileOrTablet}
                changeAutoPlayToNewVideo={changeAutoPlayToNewVideo}
              />
            ) : (
              <video {...videoProps} playsInline disablePictureInPicture>
                <source src={video ? video.url : defaultURL} type='video/mp4' />
                Your browser is not supported!
              </video>
            )}
            {!comingSoon && (
              <>
                <PeaLabelTags
                  video={video}
                  tags={{
                    channels: channelLabels,
                    targetEffects: targetEffectLabels,
                  }}
                  format={format}
                  isFreshPea={
                    checkIfFreshPea() &&
                    (showTagLabels || !!companyPage) &&
                    true
                  }
                  visible={true}
                  // visible={isMobile ? true : showTagLabels || !!companyPage}
                />
                <div
                  className={
                    inModal
                      ? styles.UserDetailsContainerInModal
                      : styles.UserDetailsContainer
                  }
                >
                  {showUserDetails && (
                    <div
                      className={
                        inModal
                          ? styles.UserDetailsWrapperInModal
                          : styles.UserDetailsWrapper
                      }
                    >
                      <section
                        className={
                          inModal
                            ? styles.VideoFooterInModal
                            : styles.VideoFooter
                        }
                        onMouseLeave={() =>
                          !isMobileOrTablet && setShowUserTooltip(false)
                        }
                      >
                        <section
                          className={
                            inModal
                              ? styles.VideoFooterDetailsWrapperInModal
                              : styles.VideoFooterDetailsWrapper
                          }
                          onMouseEnter={() =>
                            !isMobileOrTablet && setShowUserTooltip(true)
                          }
                        >
                          <Overlay
                            placement='top'
                            trigger='click'
                            show={showUserTooltip}
                            target={target}
                          >
                            <Popover
                              className={styles.DefaultTooltip}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <VideoUserTooltip
                                video={video}
                                isAuth={isAuth}
                                company={video.company}
                              />
                            </Popover>
                          </Overlay>
                          <div
                            onMouseEnter={() =>
                              !isMobileOrTablet && setShowUserTooltip(true)
                            }
                            onMouseLeave={() =>
                              !isMobileOrTablet && setShowUserTooltip(false)
                            }
                          >
                            <img
                              alt='user'
                              ref={videoUserImgRef}
                              src={video.company_image}
                              className={styles.UserPicture}
                            />
                            <span className={styles.UserName}>
                              {/* <Link to={`/company/${video.company}`}> */}
                              <Link
                                to={`/${video.company_name
                                  .replaceAll(' ', '')
                                  .toLowerCase()}/peabox.tv`}
                              >
                                {video.company_name}
                              </Link>
                            </span>
                          </div>
                        </section>
                        <div
                          onClick={(e) => {
                            e.persist();
                            e.preventDefault();
                            e.stopPropagation();
                            if (account && account.deactivated_at) {
                              toast.error(
                                'Liking or unliking peas is not allowed for deactivated accounts.'
                              );
                            } else {
                              if (!isAuth || account.account_type !== 'Basic') {
                                setShowLoginModal(true);
                              } else {
                                if (account) {
                                  setIsFavorite(
                                    isCarousel ? video.primaryId : video.id
                                  );
                                  isCarousel
                                    ? likePea({
                                        peaId: video.primaryId,
                                        userId: account.id,
                                        peaVideo: video,
                                        selectedLabel,
                                        callFrom,
                                      })
                                    : likePea({
                                        peaId: video.id,
                                        userId: account.id,
                                        peaVideo: video,
                                        selectedLabel,
                                        callFrom,
                                      });
                                }
                              }
                            }
                          }}
                        >
                          {isFavorite ? (
                            <div
                              style={{
                                background: 'white',
                                width: '30px',
                                height: '30px',
                                opacity: '.7',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '-10px',
                              }}
                            >
                              <img
                                style={{
                                  width: '16px',
                                  height: '14px',
                                }}
                                src={heartFilled}
                                alt='Heart Filled'
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                background: 'white',
                                width: '30px',
                                height: '30px',
                                opacity: '.7',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '-10px',
                              }}
                            >
                              <img
                                style={{
                                  width: '16px',
                                  height: '14px',
                                }}
                                src={heartOutlined}
                                alt='Heart Outlined'
                              />
                            </div>
                          )}
                        </div>
                      </section>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.auth.account,
});

const mapDispatchToProps = (dispatch) => ({
  likePea: ({ peaId, userId, peaVideo, selectedLabel, callFrom }) =>
    dispatch(
      actions.likePea({ peaId, userId, peaVideo, selectedLabel, callFrom })
    ),
  setIsFavorite: (id) => dispatch(actions.setIsFavorite(id)),
  setShowLoginModal: (b) => dispatch(actions.setShowLoginModal(b)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoItem);
