import React from "react";
import { ReactComponent as CategoryIconRelax } from "../../icons/categories/iconrelax.svg";
import { ReactComponent as CategoryIconHealth } from "../../icons/categories/iconhealth.svg";
import { ReactComponent as CategoryIconSchool } from "../../icons/categories/iconeducation.svg";

export const icons = {
  event: CategoryIconRelax,
  culture: CategoryIconSchool,
  lifestyle: CategoryIconHealth,
};

export function findCategoryAndTagIcon(categories, tags) {
  const iconKeys = Object.keys(icons);
  let category = null;
  let tag = null;

  for (let i = 0; i < categories.length; i++) {
    if (iconKeys.includes(categories[i].toLowerCase())) {
      category = categories[i];
    }
  }

  for (let i = 0; i < tags.length; i++) {
    if (iconKeys.includes(tags[i].toLowerCase())) {
      tag = tags[i];
    }
  }

  return { category, tag };
}

function VideoData({ categories, tags, title }) {
  let { category, tag } = findCategoryAndTagIcon(categories, tags);
  let Icon = category
    ? icons[category.toLowerCase()]
    : tag
      ? icons[tag.toLowerCase()]
      : null;
  let hasIcon = !!Icon;
  // let metaTitle = hasIcon ? "SUBSCRIBED" : "SPONSORED";
  // console.log(hasIcon);

  return (
    title && (
      <section className={"VideoDataWrapper"}>
        {hasIcon ? (
          <>
            <Icon className={"VideoDataIcon"} />
            <span className="VideoDataTitleWrapper">{title}</span>
          </>
        ) : (
          <></>
        )}
        {/*<section className={"VideoDataTitleWrapper"}>*/}
        {/*<h1 className={"VideoMetaTitle"}>{metaTitle}</h1>*/}
        {/*<div>*/}
        {/*  <h1>{title}</h1>*/}
        {/*</div>*/}
        {/*</section>*/}
      </section>
    )
  );
}

export default VideoData;
